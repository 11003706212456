import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import {
  ReceiptsPaymentsReceiptsOptions,
  TypeOfStatusOptions,
} from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  downloadCSVReceiptsPayments,
  downloadPDFReceiptsPayments,
  getBillTypeList,
  getReceiptsPayments,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import moment from "moment";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ACCOUNT_REPORTS_ENDPOINT } from "../../../../Routing/routes";
import ReceiptPaymentTable from "./ReceiptPaymentTable";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../../assets";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import ButtonG from "../../../../components/Button/ButtonG";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";

export const headCells = [
  { id: "voucherNumber", label: "Voucher Number", width: "90px" },
  { id: "voucherDate", label: "Voucher Date", width: "90px" },
  { id: "daybook", label: "Daybook", width: "90px" },
  { id: "code", label: "Code", width: "90px" },
  { id: "billName", label: "Bill Name", width: "90px" },
  { id: "accountHead", label: "Account Head", width: "90px" },
  { id: "refFlat", label: "Ref Flat", width: "90px" },
  { id: "chqNo", label: "Chq No", width: "90px" },
  { id: "narration", label: "Narration", width: "90px" },
  { id: "paymentId", label: "Payment Id", width: "90px" },
  { id: "fromTo", label: "From/To", width: "90px" },
  { id: "receipt", label: "Receipt", width: "90px" },
  { id: "payment", label: "Payment", width: "90px" },
  { id: "clearanceDate", label: "Clearance Date", width: "90px" },
  { id: "clearanceStatus", label: "Clearance Status", width: "90px" },
  { id: "modeOfPayment", label:"Payment Mode", width: "90px" },
  { id: "postedStatus", label: "Posted Status", width: "90px" },
  { id: "payuRefNo", label: "PayU Ref. No", width: "90px" },
  { id: "viewDownload", label: "View/Download", width: "90px" },
  { id: "discardCancel", label: "Discard/Cancel", width: "90px" },
  { id: "update", label: "Update", width: "90px" },
  { id: "sendEmail", label: "Send Email", width: "90px" },
  { id: "sendSms", label: "Send SMS", width: "90px" },
];

const ReceiptPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, loadingCategories, billTypeList, receiptsPayments } =
    useSelector((state) => state.oldFinancialReportsReducers);

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };

  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");

    if (
      selectedItem.value?.toString() !== searchParams.get("daybook_short_form")
    ) {
      searchParams.delete("daybook_short_form");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["daybook_short_form", selectedItem.value],
        ])}`,
      });
    }
  };

  const handelSelectVoucherType = (selectedItem) => {
    searchParams.delete("page");

    if (
      selectedItem.value?.toString() !== searchParams.get("voucher_filter_type")
    ) {
      searchParams.delete("voucher_filter_type");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["voucher_filter_type", selectedItem.value],
        ])}`,
      });
    }
  };

  const handelSelectPostedStatus = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("ledger_status")) {
      searchParams.delete("ledger_status");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["ledger_status", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleDownloadCSV = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Receipt Payment.xlsx` });
    };
    const data = {
      society_id: loginData?.default_community?.zipgrid_socsrno,
      // societyId: loginData?.default_community?.community_id,
      // fin_year: searchParams.get("year"),
      voucher_filter_type: searchParams.get("voucher_filter_type") || "receipt",
      daybook_short_form: searchParams.get("daybook_short_form"),
      ledger_status: searchParams.get("ledger_status"),
      from_date: searchParams.get("startDate"),
      to_date: searchParams.get("endDate"),
      page: searchParams.get("page") || 1,
      limit: searchParams.get("pageLimit") || 10,

      onSucessCallback,
    };
    dispatch(downloadCSVReceiptsPayments(data));
  };

  const handleDownloadPDF = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Receipt Payment.pdf` });
    };
    const data = {
      society_id: loginData?.default_community?.community_id,
      // societyId: loginData?.default_community?.community_id,
      fin_year: searchParams.get("year"),
      page: searchParams.get("page") || 1,
      limit: searchParams.get("pageLimit") || 10,
      onSucessCallback,
    };
    dispatch(downloadPDFReceiptsPayments(data));
  };

  const handleClear = () => {
    navigate({
      pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
      search: `?${createSearchParams([
        ["tab", 2],
        ["voucher_filter_type", "receipt"],
      ])}`,
    });
  };

  useEffect(() => {
    dispatch(
      getBillTypeList({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        daybook_filter: "receiptPayment",
      })
    );

    dispatch(
      getReceiptsPayments({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        voucher_filter_type:
          searchParams.get("voucher_filter_type") || "receipt",
        daybook_short_form: searchParams.get("daybook_short_form") || null,
        ledger_status: searchParams.get("ledger_status"),
        from_date: searchParams.get("startDate"),
        to_date: searchParams.get("endDate"),
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
      })
    );
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get("voucher_filter_type")) {
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["voucher_filter_type", "receipt"],
        ])}`,
      });
    }
  }, []);

  return (
    <div className="">
      <div>
        <ListHeader leftContent="Receipts/Payments">
          {/* <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
          /> */}
          <span className="border-r border-[#CCC]"></span>
          {/* <ButtonIco
            icon={DownloadExt}
            children="Download PDF"
            className="h-8"
            type="button"
            onClick={handleDownloadPDF}
          /> */}
          <ButtonIco
            icon={DownloadExt}
            children="Download Excel"
            className="h-8"
            type="button"
            onClick={handleDownloadCSV}
          />
        </ListHeader>
      </div>
      <div className="flex gap-3 pb-2">
        <Dropdown2
          options={
            billTypeList
              ? [
                  { daybookname: "All", daybookshfm: "00" },
                  ...billTypeList,
                ]?.map((item) => ({
                  value: item?.daybookshfm,
                  label: item?.daybookname,
                }))
              : []
          }
          placeholder="Select Bill Type"
          className=""
          width="182px"
          height="32px"
          onSelect={(selectedItem) => {
            handelSelectBillType(selectedItem);
          }}
          value={
            searchParams.get("daybook_short_form") && {
              value: searchParams.get("daybook_short_form"),
              label: billTypeList
                ? [
                    { daybookname: "All", daybookshfm: "00" },
                    ...billTypeList,
                  ]?.find(
                    (item) =>
                      item.daybookshfm ===
                      searchParams.get("daybook_short_form")
                  )?.daybookname
                : [],
            }
          }
        />

        <RangeDatePicker
          className="w-48 h-8"
          defaultStartValue={
            searchParams.get("startDate")
              ? new Date(searchParams.get("startDate"))
              : ""
          }
          defaultEndValue={
            searchParams.get("endDate")
              ? new Date(searchParams.get("endDate"))
              : ""
          }
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
        />

        <Dropdown2
          options={ReceiptsPaymentsReceiptsOptions}
          placeholder="Select Receipt"
          className=""
          width="182px"
          height="32px"
          onSelect={(selectedItem) => {
            handelSelectVoucherType(selectedItem);
          }}
          value={
            searchParams.get("voucher_filter_type") && {
              value: searchParams.get("voucher_filter_type"),
              label: ReceiptsPaymentsReceiptsOptions?.find(
                (item) => item.value === searchParams.get("voucher_filter_type")
              )?.label,
            }
          }
        />
        <Dropdown2
          options={TypeOfStatusOptions}
          placeholder="Select Posted Status"
          className=""
          width="172px"
          height="32px"
          onSelect={(selectedItem) => {
            handelSelectPostedStatus(selectedItem);
          }}
          value={
            searchParams.get("ledger_status") && {
              value: searchParams.get("ledger_status"),
              label: TypeOfStatusOptions?.find(
                (item) => item.value === searchParams.get("ledger_status")
              )?.label,
            }
          }
        />
        <span className="border-r border-[#CCC]"></span>
        <ButtonG label="Clear" className="h-8" onClick={handleClear} />
      </div>
      <div className="ml-1">
        <ReceiptPaymentTable
          loading={!!loadingCategories || !!loading}
          data={
            receiptsPayments?.list?.map((item) => ({
              voucherNumber: item?.voucher_number,
              voucherDate: item?.voucher_date
                ? moment(new Date(item?.voucher_date))?.format("DD-MM-YYYY")
                : "-",
              daybook: item?.daybook,
              // code: item?. ,
              billName: item?.code,
              accountHead: item?.account_head,
              refFlat: item?.flat,
              chqNo: item?.cheque_no,
              narration: item?.narration,
              paymentId: item?.payment_id,
              fromTo: item?.from_to,
              receipt: item?.receipt,
              payment: item?.payment,
              clearanceDate: item?.clearance_date
                ? moment(new Date(item?.clearance_date))?.format("DD-MM-YYYY")
                : "-",
              clearanceStatus: item?.clearance_status,
              modeOfPayment: item?.mode_of_payment,
              postedStatus: item?.posted_status,
              payuRefNo: item?.pay_u_ref_no,
              // viewDownload: item?. ,
              // discardCancel: item?. ,
              // update: item?. ,
              // sendEmail: item?. ,
              // sendSms: item?. ,
            })) || []
          }
          columns={headCells}
          checkbox={false}
          pagination={
            receiptsPayments?.list?.length > 0
              ? receiptsPayments?.list?.length
              : 0
          }
          totalCount={receiptsPayments?.total_count}
          totalPages={receiptsPayments?.total_pages}
          start={
            (receiptsPayments?.current_page - 1) *
              (parseInt(receiptsPayments?.page_limit) || 10) +
            1
          }
          end={
            (receiptsPayments?.current_page - 1) *
              (parseInt(receiptsPayments?.page_limit) || 10) +
            receiptsPayments?.list?.length
          }
          currentPage={parseInt(receiptsPayments?.current_page)}
          rowsPerPage={searchParams.get("pageLimit") || 10}
        ></ReceiptPaymentTable>
      </div>
      {!(receiptsPayments?.list?.length > 0) && !loadingCategories && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )}
    </div>
  );
};

export default ReceiptPayment;
