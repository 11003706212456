import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { Box, Tab, Tabs, tabsClasses } from "@mui/material";
import styled from "@emotion/styled";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  BILL_REPORTS_ENDPOINT,
  INVOICE_AUDIT_REPORT_ENDPOINT,
} from "../../../../Routing/routes";
import ServiceTaxReport from "./ServiceTaxReport/ServiceTaxReport";
import FunctionalSettings from "./FunctionalSettings/FunctionalSettings";
import DisplaySettings from "./DisplaySettings/DisplaySettings";
import GLMapping from "./GLMapping/GLMapping";
import InvoiceDetails from "./InvoiceDetails/InvoiceDetails";
import Receipt from "./Receipt/Receipt";
import AdvanceNote from "./AdvanceNote/AdvanceNote";
import CreditNote from "./CreditNote/CreditNote";
import Invoice from "./Invoice/Invoice";

function InvoiceAuditReport() {
  const tabData = [
    {
      index: 1,
      label: "Service Tax",
      tabPanel: ServiceTaxReport,
    },
    {
      index: 2,
      label: "Functional Settings",
      tabPanel: FunctionalSettings,
    },
    {
      index: 3,
      label: "Display Settings",
      tabPanel: DisplaySettings,
    },
    {
      index: 4,
      label: "G/L Mapping",
      tabPanel: GLMapping,
    },
    {
      index: 5,
      label: "Invoice Details",
      tabPanel: InvoiceDetails,
    },
    {
      index: 6,
      label: "Invoice",
      tabPanel: Invoice,
    },
    {
      index: 7,
      label: "Receipt",
      tabPanel: Receipt,
    },
    {
      index: 8,
      label: "Advance Note",
      tabPanel: AdvanceNote,
    },
    {
      index: 9,
      label: "Credit Note",
      tabPanel: CreditNote,
    },
  ];
  const StyledTab = styled(Tab)({
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "'Open Sans', sans-serif",
    borderRight: "1px solid #CCC",
    background: "#FDF2F3",

    "&.Mui-selected": {
      color: "#CF001C",
    },
  });

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState(6);
  const handleChangeTab = (event, newValue) => {
    searchParams.delete("tab");
    navigate({
      pathname: `${INVOICE_AUDIT_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["tab", newValue],
      ])}`,
    });
  };
  useEffect(() => {
    const qtab = parseInt(searchParams.get("tab"));
    if (qtab) setTabValue(qtab);
  }, [searchParams]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Audit Report"></ListHeader>
        </div>
        <div className="mt-3 border border-[#CCC] rounded-lg overflow-hidden rounded-b-none ">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#CF001C",
              },
            }}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                backgroundColor: "#CF001C",
                color: "#ffffff",
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            {tabData?.map((item, index) => (
              <StyledTab
                key={item?.label + index}
                value={item?.index}
                label={item?.label}
              />
            ))}
          </Tabs>
        </div>

        <div>
          {tabData?.map((item, index) => {
            const TabPanelComponent = item?.tabPanel;
            return (
              <TabPanel value={tabValue} index={item?.index}>
                <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-1 rounded-t-none border-t-0 min-h-[500px]">
                  <TabPanelComponent value={item?.label} />
                </div>
              </TabPanel>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default InvoiceAuditReport;
