import React, { useEffect } from "react";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Layout from "../../Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button/Button";
import ViewFacilityDetailsTable from "./ViewFacilityTable";
import { MAKE_BOOKING_ENDPOINT } from "../../../Routing/routes";
import { useDispatch } from "react-redux";
import { getFacilityDetails, getRateChart } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { useSelector } from "react-redux";
import { getRoleList, getRoleWiseList, getVendorConcernedPersonList } from "../../../redux/actions/ClubActions/AddFacilityAction";
import Loader from "../../../components/Loader/Loader";
import ImageComponent from "../../../components/ImageComponent/ImageComponent";
import moment from "moment";

export const facilityDetailsData = [
  {
    key: "booking",
    label: "Booking",
    value: "Yes",
  },
  {
    key: "chargeable",
    label: "Is Chargable",
    value: "Yes",
  },

];

export const headCells = [
  { id: "sNo", label: "Sr. No.", width: "50px" },
  { id: "bookingFrequency", label: "Booking Frequency",sort:true, width: "100px" },
  { id: "timeSlots", label: "Time Slots", sort: true, width: "120px" },
  { id: "chargesForMembers", label: "Charges For Members/Day Amount(Rs.)", sort: true, width: "100px" },
  { id: "chargesForNonMembers", label: "Charges For Non Members/Day Amount(Rs.)", sort: true, width: "100px" },
];

function ViewFacilityDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id} = useParams()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, loadingRateChart, rateChart, facilityDetails} = useSelector(state=>state.bookAFacilityReducer)
  const {employeeRoleList, managingCommitteeRoleList, vendorRoleList, employeeList, managingCommitteeList, vendorList, vendorConcernedPersonList} = useSelector(state=>state.addFacilityReducer)

  useEffect(()=>{
    let data={
      societyId: loginData?.default_community?.community_id,
    }

    dispatch(getFacilityDetails({facilityId: id}))
    dispatch(getRateChart({facilityId: id}))
    dispatch(getRoleList({...data, roleType:'Vendors'}))
    dispatch(getRoleList({...data, roleType:'Employees'}))
    dispatch(getRoleList({...data, roleType:'Managing Staff'}))
  }, [])

  useEffect(()=>{
    let data={
      societyId: loginData?.default_community?.community_id,
    }
    
    if(facilityDetails){
      if(facilityDetails?.facility_manager){
        dispatch(getRoleWiseList({...data, roleType:'Employees',roleTypeId: facilityDetails?.facility_manager}))
      }
      if(facilityDetails?.managing_committees){
        dispatch(getRoleWiseList({...data, roleType:'Managing Staff',roleTypeId: facilityDetails?.managing_committees}))
      }
      if(facilityDetails?.vandor_cat_id){
        dispatch(getRoleWiseList({...data, roleType:'Vendors',roleTypeId: facilityDetails?.vandor_cat_id}))
      }
      if(facilityDetails?.society_vendor_id){
        dispatch(getVendorConcernedPersonList({vendorId: facilityDetails?.society_vendor_id}))
      }
    }

  }, [facilityDetails])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title={`View Facility Details ${(!loading && facilityDetails) ? `-${facilityDetails?.facilities_name} ` : ''}`}
            onClick={() => {
              navigate(-1);
            }}
          >
            <Button label="Make Booking" onClick={()=>navigate(`${MAKE_BOOKING_ENDPOINT}/${id}`)} className="h-8" />
          </ListHeaderBack>
        </div>
        {
          loading || loadingRateChart ?
          <Loader/>
          :
          <>
            <div className="py-1 gap-2 my-1">
              <table className="table-auto w-full capitalize">
                <tbody>
                  <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                      Image:
                      </td>
                      <td className="py-2">
                        <ImageComponent defaultUrl={facilityDetails?.facility_image_url} width={32} height={32} />
                      </td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                      Booking Available Upto:
                      </td>
                      <td className="py-2">{facilityDetails?.bookingAvailableUpto?.split(',')?.[1] ? `${moment(facilityDetails?.bookingAvailableUpto?.split(',')?.[1]).format('DD-MM-YYYY')}` : "-"}</td>
                    </tr>
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                      Unavailable Date:
                      </td>
                      <td className="py-2">{facilityDetails?.unavailableDates || "-"}</td>
                    </tr>
                  {facilityDetailsData?.map((item) => (
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                        {item.label}:
                      </td>
                      <td className="py-2">{facilityDetails?.[item.key]?.toString() || "-"}</td>
                    </tr>
                  ))}
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                    Facility Manager:
                    </td>
                    <td className="py-2">{employeeRoleList?.find(item=> item.id?.toString() === facilityDetails?.facility_manager)?.title || "-"}</td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                    Facility Manager Name:
                    </td>
                    <td className="py-2">
                      {
                        facilityDetails?.facility_manager_name ? 
                        facilityDetails?.facility_manager_name?.split(',')?.map((item, index)=>(
                          <>{employeeList?.find(subItem=> subItem.bridge_table_id?.toString() === item)?.full_name} {index !== (facilityDetails?.facility_manager_name?.split(',')?.length -1) && ', '}</>
                        )) 
                        : "-"
                      }
                    </td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                    Managing Committee:
                    </td>
                    <td className="py-2">{managingCommitteeRoleList?.find(item=> item.id?.toString() === facilityDetails?.managing_committees)?.title || "-"}</td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                    Managing Committee Members:
                    </td>
                    <td className="py-2">
                      {
                        facilityDetails?.managing_committee_member ?
                        facilityDetails?.managing_committee_member?.split(',')?.map((item, index)=>(
                          <>{managingCommitteeList?.filter(i=>i)?.find(subItem=> subItem.subscriber_id?.toString() === item)?.full_name} {index !== (facilityDetails?.managing_committee_member?.split(',')?.length -1) && ', '}</>
                        )) 
                        : "-"
                      }
                    </td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                    Vendor Categroy:
                    </td>
                    <td className="py-2">{vendorRoleList?.find(item=> item.id?.toString() === facilityDetails?.vandor_cat_id)?.title || "-"}</td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                    vendor Name:
                    </td>
                    <td className="py-2">
                      {vendorList?.find(item=> item.id?.toString() === facilityDetails?.society_vendor_id)?.full_name || '-'}
                    </td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                    Concerned Persons:
                    </td>
                    <td className="py-2">
                      {
                        facilityDetails?.vendor_concerned_person ?
                        facilityDetails?.vendor_concerned_person?.split(',')?.map((item, index)=>(
                          <>{vendorConcernedPersonList?.find(subItem=> subItem.id?.toString() === item)?.full_name} {index !== (facilityDetails?.vendor_concerned_person?.split(',')?.length -1) && ', '}</>
                        )) 
                        : "-"
                      }
                    </td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                    Usage Guidance:
                    </td>
                    <td className="py-2">{facilityDetails?.usage_guidance?.toString() || "-"}</td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-48 py-2 text-[#AAAAAA] font-bold align-top">
                      Description:
                    </td>
                    <td className="py-2">{facilityDetails?.instructions?.toString() || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="p-1">
              <div>
                <span className="font-semibold mb-2">Rate Chart</span>
              </div>
              <div className="ml-1">
                <ViewFacilityDetailsTable
                  data={rateChart?.map((item, index)=>({
                    sNo: (index+1)?.toString()?.padStart(2, '0'),
                    bookingFrequency:item.booking_type || '-',
                    timeSlots: item.slot,
                    chargesForMembers: item.charges_for_members || '-',
                    chargesForNonMembers: item.charges_for_nonMembers || '-',
                  }))}
                  columns={headCells}
                  checkbox={false}
                  pagination={false}
                ></ViewFacilityDetailsTable>
              </div>
              {!rateChart?.length && !loading && (
                <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                  No Records
                </div>
              )}
            </div>
          </>
        }
      </div>
    </Layout>
  );
}

export default ViewFacilityDetails;
