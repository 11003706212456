import React, { useEffect, useState } from "react";
import Search from "../../../components/SearchBox/Search";
import FrequentVisitorTable from "./FrequentVisitorTable";
import { getFrequentVisitorList, getVisitorOrVehicleDashboardData } from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ButtonG from "../../../components/Button/ButtonG";
import moment from "moment";
import { RefreshIcon } from "../../../assets";

export const headCells = [
  { id: "sNo", label: "Sr. No.", width: "70px" },
  { id: "image", label: "Image", width: "100px" },
  { id: "name", label: "Name", sort: true, width: "170px" },
  { id: "whomToMeet", label: "Whom to Meet?", sort: true, width: "120px" },
  { id: "purpose", label: "Purpose", sort: true, width: "120px" },
];

const FrequentVisitor = ({filter}) => {
  const [tableFilter, setTableFilter] = useState({
    searchText: '',
    page: 1,
    pageLimit: 10,
    refreshedTime: new Date()
  })
  const dispatch =useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, frequentVisitorList} = useSelector(state=>state.visitorAndVehicleInAndOutReducer)

  const handleSearch = (text)=>{
    if(text){
      setTableFilter(prev=> ({...prev, searchText: text?.trim(), page: 1}))
    }
  }

  const handleClear = ()=>{
    setTableFilter({
      searchText: '',
      page: 1,
      pageLimit: 10
    })
  }
  const getDashboardData = ()=>{
    const data={
      societyId: loginData?.default_community?.community_id,
      ...filter,
      flag: 'visitors'
    }
    dispatch(getVisitorOrVehicleDashboardData(data))
  }

  const handleRefresh = ()=>{
    if(moment(tableFilter.refreshedTime)?.format('DD-MM-YYYY hh:mm A') !== moment()?.format('DD-MM-YYYY hh:mm A')){
      setTableFilter(prev=> ({...prev, refreshedTime: new Date()}))
    }
    getDashboardData()
  }

  const getTableData = ()=>{
    const data={
      societyId: loginData?.default_community?.community_id,
      ...tableFilter,
      ...filter
    }
    dispatch(getFrequentVisitorList(data))
  }

  useEffect(()=>{
    setTableFilter(prev=>({...prev, page: 1}))
  }, [filter])
  
  useEffect(()=>{
    getTableData()
  }, [tableFilter])

  

  return (
    <>
      <div>
        <div className="flex justify-between items-center my-2">
          <div className="flex">
            <Search height="8" placeholder={'Name/Contact'} onclick={handleSearch} value={tableFilter.searchText} />
            <ButtonG label='Clear' className='h-8 ms-2' onClick={handleClear} />
          </div>
          <div className="text-xs flex text-[#aaaaaa]">Updated On: {moment(filter.refreshedTime)?.format('DD-MM-YYYY hh:mm A')} <span className={`ms-2 cursor-pointer ${loading ? 'animate-spin': ''}`} onClick={handleRefresh}><RefreshIcon/></span> </div>
        </div>
        <div className="ml-1">
          <FrequentVisitorTable
              loading={loading}
              data={frequentVisitorList?.list?.map((item, index)=>({
                sNo: (((frequentVisitorList?.current_page_no - 1) * (parseInt(frequentVisitorList?.page_limit) || 10) + 1)+index)?.toString()?.padStart(2, "0"),
                visitoLogId: item?.visitor_log_id,
                phone: `${item?.mobile_country_code ? `+${item?.mobile_country_code?.replace('+', '')}-` : ''}${item.mobile_no}` || '-',
                image: item?.vphoto,
                name: `${item?.vname} ${item?.lname ? item?.lname : ''}`,
                whomToMeet: item?.whomtomeet,
                purpose: item?.purpose,

              }))}
              columns={headCells}
              checkbox={false}
              pagination={frequentVisitorList?.list?.length}
              totalCount={frequentVisitorList?.total_record}
              totalPages={frequentVisitorList?.total_pages}
              start={(frequentVisitorList?.current_page_no - 1) * (parseInt(frequentVisitorList?.page_limit) || 10) + 1}
              end={(frequentVisitorList?.current_page_no - 1) * (parseInt(frequentVisitorList?.page_limit) || 10) + frequentVisitorList?.list?.length}
              currentPage={parseInt(frequentVisitorList?.current_page_no)}
              rowsPerPage={parseInt(frequentVisitorList?.page_limit) || 10}
            setTableFilter={setTableFilter}
          ></FrequentVisitorTable>
        </div>
        {!frequentVisitorList?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </>
  );
};

export default FrequentVisitor;
