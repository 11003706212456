import React, { useEffect, useState } from "react";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Layout from "../../Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { useDispatch } from "react-redux";
import { updateContentManagement } from "../../../redux/actions/SetupRecordsActions/ContentManagementActions/ContentManagementAction";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Loader from "../../../components/Loader/Loader";

function EditContentManagement() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { data, updateloading, error } = useSelector((state) => state.contentReducer);
  const SingleData = data?.find((item) => item.id === parseInt(id)) || null;
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [formData, setFormData] = useState({
    title: "",
    content: "",
  });

  useEffect(() => {
    if (SingleData) {
      setFormData({
        title: SingleData.title,
        content: SingleData.content,
      });
      const { content } = SingleData;
      const contentBlock = htmlToDraft(content || "");
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
    }}
  }, [SingleData]);

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const content = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    setFormData((prev) => ({
      ...prev,
      content,
    }));
  };

  const handleSubmit = (values) => {
    console.log("Submitting values:", values);
    dispatch(
      updateContentManagement({
        id,
        societyid: loginData?.default_community?.community_id,
        content: values.content,
        onSucessBackCall: () => {
          navigate(-1);
        },
      })
    );
  };

  return (
    <Layout>
      <div className="content-container w-full h-full bg-white border border-[#CCC] rounded-lg p-2 ">
        <div className="header border-b border-[#CCC] ">
          <ListHeaderBack
            title="Edit Content"
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div className="p-3 content ">
          <Formik
            enableReinitialize
            initialValues={formData}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, values }) => (
              <Form onSubmit={handleSubmit}>
                <div className="flex flex-col space-y-4">
                  <Section label="Title">{values.title || ""}</Section>
                  <Section label="Description">
                    <div className="p-1 border editor-container h-80">
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "list",
                            "textAlign",
                            "history",
                            "colorPicker",
                          ],
                          inline: {
                            inDropdown: false,
                            options: ["bold", "italic", "underline"],
                          },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: {
                            inDropdown: false,
                            options: ["redo", "undo"],
                          },
                        
                          colorPicker: {
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                          },
                        }}
                        editorStyle={{
                          height: "250px", 
                          width: "100%",
                          padding: "10px",
                          border: "1px solid #f1f1f1",
                          overflowY: "auto", 
                        }}
                      />
                    </div>
                  </Section>
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="px-4 py-2 text-white rounded-md bg-red-650"
                      disabled={updateloading}
                    >
                      {updateloading ? "Saving..." : "Save Changes"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
}

const Section = ({ label, children }) => (
  <div className="flex w-full ">
    <div className="font-bold text-black mb-1 w-[15%]">{label}:</div>
    <div className="w-[85%] ">{children}</div>
  </div>
);

export default EditContentManagement;
