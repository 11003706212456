import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import FilterTextField from "../../../../components/InputFields/FilterTextFields";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { TextInput } from "../../../../components/InputFields/TextInput";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt, UploadEx, PrinterIcon } from "../../../../assets";
import UploadTenantExcelModal from "../../../../components/Modal/UploadTenantExcelModal";
import UploadDebitNoteExcelModal from "../../../../components/Modal/UploadDebitNoteExcelUploadModal";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  ADD_DEBIT_NOTE_ENDPOINT,
  MAKE_PAYMENT_DEBIT_NOTE_MULTI_ENDPOINT,
  MANAGE_DEBIT_DRAFT_ENDPOINT,
  MANAGE_DEBIT_PREVIOUS_ENDPOINT,
  PRINT_INVOICE_AGING_REPORT_ENDPOINT,
  INVOICE_AGING_REPORT_ENDPOINT,
} from "../../../../Routing/routes";
import ManageDebitCurrentTable from "../../../../components/TableComponent/ManageDebitCurrentTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  downloadExcel,
  downloadFileFromURL,
  exportToCSV,
} from "../../../../utils/helpers/downloadMedia";
import {
  getDebitNoteCurrent,
  uploadDebitNoteCurrentExcel,
  downloadDebitNoteCurrentPayment,
  uploadDebitNoteCurrentPayment,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import ButtonG from "../../../../components/Button/ButtonG";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  getInvoiceAgingReport,
  getInvoiceReport,
} from "../../../../redux/actions/ReportActions/FinanceReportActions";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import InvoiceAgingReportTable from "./InvoiceAgingReportTable";
const demoData = {
  success: true,
  error: null,
  error_code: null,
  message: "Data Fetch Successfully",
  data: [
    {
      bill_date: "May 10, 2024",
      bill_number: "0062/2024-25",
      bill_period: "June 2024",
      flat_number: "Wing No. Zipgrid - 1",
      member_name: "SuryaPrakash Prakash Naik",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2983.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0063/2024-25",
      bill_period: "May 2024",
      flat_number: "Wing No. Zipgrid - 2",
      member_name: "Aradya santosh kamble",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2550.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2950,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0064/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 3",
      member_name: "Gajanan Tambe",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2583.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0066/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 5",
      member_name: "Payal Maurya",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2583.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0067/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 6",
      member_name: "Pawan Suresh Sharma",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2583.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0069/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 8",
      member_name: "Tejas Sunila Deshmukh",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "192363.00",
      balance_due: 0,
      status: "Paid",
      "<30": 13263,
      "31-90": 45000,
      "91-180": 135000,
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0070/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 9",
      member_name: "Pratik Pandit Pandit",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2697.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0071/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 10",
      member_name: "Lavesh Bhiku Shigvan",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2983.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0072/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 11",
      member_name: "Shraddha Anil Sawant",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2583.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0073/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 12",
      member_name: "Sagar Prakash Londhe",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2583.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0062/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 1",
      member_name: "SuryaPrakash Prakash Naik",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2983.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0063/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 2",
      member_name: "Aradya santosh kamble",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2550.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2950,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0064/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 3",
      member_name: "Gajanan Tambe",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2583.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0066/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 5",
      member_name: "Payal Maurya",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2583.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0067/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 6",
      member_name: "Pawan Suresh Sharma",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2583.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0069/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 8",
      member_name: "Tejas Sunila Deshmukh",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "192363.00",
      balance_due: 0,
      status: "Paid",
      "<30": 13263,
      "31-90": 45000,
      "91-180": 135000,
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0070/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 9",
      member_name: "Pratik Pandit Pandit",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2697.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0071/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 10",
      member_name: "Lavesh Bhiku Shigvan",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2983.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0072/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 11",
      member_name: "Shraddha Anil Sawant",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2583.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
    {
      bill_date: "Oct 10, 2024",
      bill_number: "0073/2024-25",
      bill_period: "October 2024",
      flat_number: "Wing No. Zipgrid - 12",
      member_name: "Sagar Prakash Londhe",
      bill_head: "Maintainance Bill",
      "age(Days)": "5",
      amount: "2583.00",
      balance_due: 0,
      status: "Paid",
      "<30": 2983,
      "31-90": "-",
      "91-180": "-",
      "181-365": "-",
      ">365 days": "-",
      block: "Zipgrid",
    },
  ],
  paginate: {
    total_page: 5,
    total_count: 44,
    limit: 10,
    current: null,
  },
};
const headCells = [
  { id: "billDate", label: "Bill Date", sort: true, width: "130px" },
  { id: "billNumber", label: "Bill Number", sort: true, width: "160px" },
  { id: "billPeriod", label: "Bill Period", sort: true, width: "100px" },
  {
    id: "flatNumber",
    label: "Flat Number",
    sort: true,
    width: "130px",
  },
  { id: "memberName", label: "Member Name", sort: true, width: "100px" },

  {
    id: "billHead",
    label: "Bill Head",
    sort: true,
    width: "120px",
  },
  {
    id: "age",
    label: "Age(Days)",
    sort: true,
    width: "100px",
  },
  {
    id: "amount",
    label: "Amount",
    sort: false,
    width: "100px",
  },

  {
    id: "balanceDue",
    label: "Balance Due",
    sort: false,
    width: "100px",
  },
  {
    id: "status",
    label: "Status",
    sort: false,
    width: "100px",
  },
  {
    id: "<30",
    label: "<30",
    sort: false,
    width: "100px",
  },
  {
    id: "31_90",
    label: "31-90",
    sort: false,
    width: "100px",
  },
  {
    id: "91_180",
    label: "91-180",
    sort: false,
    width: "100px",
  },
  {
    id: "181_365",
    label: "181-365",
    sort: false,
    width: "100px",
  },
  {
    id: ">365_days",
    label: ">365 days",
    sort: false,
    width: "100px",
  },
];
const ageList = [
  {
    id: "<30",
    label: "Below 30",
    sort: false,
    width: "100px",
  },
  {
    id: "31-90",
    label: "31 to 90",
    sort: false,
    width: "100px",
  },
  {
    id: "91-180",
    label: "91 to 180",
    sort: false,
    width: "100px",
  },
  {
    id: "181-365",
    label: "181 to 365",
    sort: false,
    width: "100px",
  },
  {
    id: ">365 days",
    label: "Above 365 days",
    sort: false,
    width: "100px",
  },
];

const InvoiceAgingReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);

  const [searchData, setSearchData] = useState("");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState(null);

  const { invoiceAgingReport, loading } = useSelector(
    (state) => state.financeReportReducer
  );
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);
  const [filteredItem, setFilteredItem] = useState({
    data: [],
    pagination: { current: 0, limit: 10, total_count: 0, total_page: 0 },
  });
  const [invoiceAgingReportData, setInvoiceAgingReportData] = useState(null);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };
  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleDownloadExcel = () => {
    if (filteredItem?.data?.length) {
      const headData = [...headCells?.map((item) => item?.label), "Block"];
      const data = filteredItem?.data?.map((item) => {
        let dataObject = {};
        Object.entries(item)?.map((item2, index2) => {
          dataObject[headData[index2]] = item2[1];
        });
        return dataObject;
      });
      exportToCSV(data, "Welcome to Zipgrid -  Zipgrid Family Aging report");
    }
  };
  const handlePrintData = () => {
    if (filteredItem?.data?.length > 0) {
      let htmlData =
        "<h2 style='font-family:sans-serif'>Welcome to Zipgrid -  Zipgrid Family Aging report</h2><table style='width: 100%;white-space: pre-wrap; font-family: sans-serif;border-spacing: 0px;font-size:1.2vw;text-align:left;'><tbody><tr>";
      headCells?.map((item, index) => {
        htmlData += `<th style="border-bottom:2px solid darkgrey;padding:5px;">${item?.label}</th>`;
      });
      htmlData +=
        "<th style='border-bottom:2px solid darkgrey;padding:5px;'>Block</th></tr>";
      filteredItem?.data?.map((item, index) => {
        htmlData += "<tr>";
        Object.entries(item)?.map((item2, index2) => {
          htmlData += `<td style='padding:5px'>${item2?.[1]}</td>`;
        });
        htmlData += "</tr>";
      });
      htmlData += "</tbody></table><script>window.print()</script>";
      window.open("", "_blank").document.write(htmlData).window.close();
    }
    // navigate({
    //   pathname: `${PRINT_INVOICE_AGING_REPORT_ENDPOINT}`,
    //   search: `?${createSearchParams([...searchParams.entries()])}`,
    // });
  };

  const handleBillPeriod = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("billPeriod");
    navigate({
      pathname: `${INVOICE_AGING_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["billPeriod", selectedItem],
      ])}`,
    });
  };
  const handleFlatNumber = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("flatNumber");
    navigate({
      pathname: `${INVOICE_AGING_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["flatNumber", selectedItem],
      ])}`,
    });
  };
  const handleAge = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("getAge");
    navigate({
      pathname: `${INVOICE_AGING_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["getAge", selectedItem],
      ])}`,
    });
  };

  const handleBillHead = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("billHead");
    navigate({
      pathname: `${INVOICE_AGING_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["billHead", selectedItem],
      ])}`,
    });
  };

  const handleBlock = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("getBlock");
    navigate({
      pathname: `${INVOICE_AGING_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["getBlock", selectedItem],
      ])}`,
    });
  };
  const handleStatus = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("getStatus");
    navigate({
      pathname: `${INVOICE_AGING_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["getStatus", selectedItem],
      ])}`,
    });
  };

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("searchData")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("searchData");
      navigate({
        pathname: `${INVOICE_AGING_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["searchData", text],
        ])}`,
      });
    } else if (!text) {
      searchParams.delete("searchData");
      navigate({
        pathname: `${INVOICE_AGING_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };
  const handleSearchBy = (text) => {
    if (text && text !== searchParams.get("searchBy")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("searchBy");
      navigate({
        pathname: `${INVOICE_AGING_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["searchBy", text],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    setSearchData("");
    navigate(`${INVOICE_AGING_REPORT_ENDPOINT}`);
  };

  const paginationCount = (value) => {
    return {
      current: searchParams.get("page") || 0,
      limit: searchParams.get("pageLimit") || 10,
      total_count: value?.length,
      total_page: Math.ceil(
        value?.length / Number(searchParams.get("pageLimit") || 10)
      ),
    };
  };

  useEffect(() => {
    dispatch(
      getInvoiceAgingReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("memberName") || "",
        invoiceType: searchParams.get("invoiceType") || "",
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        export: 0,
      })
    );
  }, []);

  useEffect(() => {
    if (invoiceAgingReport?.data?.length > 0) {
      let values = "";
      values = { ...invoiceAgingReport };

      if (searchParams.get("searchData")) {
        values.data = values?.data?.filter(
          (item) =>
            JSON.stringify(item)
              .toLowerCase()
              .indexOf(searchParams.get("searchData")?.toLowerCase()) !== -1
        );
      }
      if (searchParams.get("billPeriod")) {
        values.data = values?.data?.filter(
          (item) => item?.bill_period == searchParams.get("billPeriod")
        );
      }
      if (searchParams.get("flatNumber")) {
        values.data = values?.data?.filter(
          (item) => item?.flat_number == searchParams.get("flatNumber")
        );
      }
      if (searchParams.get("getAge")) {
        values.data = values?.data?.filter(
          (item) =>
            item?.[searchParams.get("getAge")] != "" &&
            item?.[searchParams.get("getAge")] != "-"
        );
      }
      if (searchParams.get("billHead")) {
        values.data = values?.data?.filter(
          (item) => item?.["bill_head"] == searchParams.get("billHead")
        );
      }
      if (searchParams.get("getBlock")) {
        values.data = values?.data?.filter(
          (item) => item?.["block"] == searchParams.get("getBlock")
        );
      }
      if (searchParams.get("getStatus")) {
        values.data = values?.data?.filter(
          (item) => item?.["status"] == searchParams.get("getStatus")
        );
      }
      values.paginate = paginationCount(values?.data);
      let page = Number(searchParams.get("page") || 0);
      let pageLimit = Number(searchParams.get("pageLimit") || 10);
      let spliceData = [];
      spliceData = [...values.data];
      values.data = spliceData.splice(page * pageLimit, pageLimit);
      setFilteredItem({ ...values });
    }
  }, [invoiceAgingReport, searchParams]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Invoice Aging Report">
            <div className="flex text-sm gap-3">
              <button
                type="button"
                onClick={handlePrintData}
                className="flex text-red-650 border  border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
              >
                <PrinterIcon className="mr-2 fill-red-650" /> Print
              </button>
              <ButtonIco
                type="button"
                onClick={handleDownloadExcel}
                icon={DownloadExt}
                children="Download Excel"
              />
            </div>
          </ListHeader>
        </div>
        <div className="p-1 flex flex-row justify-between items-center gap-4 mt-1 ">
          <Search
            height="8"
            onclick={handleSearch}
            onChange={(value) => setSearchData(value)}
            value={searchParams.get("searchData") || searchData}
            placeholder="Member Name"
          />
          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Bill Period</div>

            <Dropdown2
              options={
                invoiceAgingReport?.data?.length
                  ? invoiceAgingReport?.data
                      ?.map((item) => item?.bill_period)
                      .filter(function (item, i, ar) {
                        return ar.indexOf(item) === i;
                      })
                      .map((item) => ({
                        label: item,
                        value: item,
                      }))
                  : []
              }
              placeholder="Bill Period"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handleBillPeriod(selectedItem.value);
              }}
              value={
                searchParams.get("billPeriod") && {
                  value: searchParams.get("billPeriod"),
                  label: searchParams.get("billPeriod"),
                }
              }
            />
          </div>
          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Flat Number</div>
            <Dropdown2
              options={
                invoiceAgingReport?.data?.length
                  ? invoiceAgingReport?.data
                      ?.map((item) => item?.flat_number)
                      .filter(function (item, i, ar) {
                        return ar.indexOf(item) === i;
                      })
                      .map((item) => ({
                        label: item,
                        value: item,
                      }))
                  : []
              }
              placeholder="Flat Number"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                handleFlatNumber(selectedItem.value);
              }}
              value={
                searchParams.get("flatNumber") && {
                  value: searchParams.get("flatNumber"),
                  label: searchParams.get("flatNumber"),
                }
              }
            />
          </div>
          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Age</div>
            <Dropdown2
              options={
                ageList
                  ? ageList.map((item) => ({
                      label: item?.label,
                      value: item?.id,
                    }))
                  : []
              }
              placeholder="Age"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                handleAge(selectedItem.value);
              }}
              value={
                searchParams.get("getAge") && {
                  value: ageList?.find(
                    (item) => item?.id == searchParams.get("getAge")
                  )?.id,
                  label: searchParams.get("getAge"),
                }
              }
            />
          </div>
        </div>
        <div className="flex items-center gap-2 p-2 ">
          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Bill Head</div>
            <Dropdown2
              options={
                invoiceAgingReport?.data?.length
                  ? invoiceAgingReport?.data
                      ?.map((item) => item?.bill_head)
                      .filter(function (item, i, ar) {
                        return ar.indexOf(item) === i;
                      })
                      .map((item) => ({
                        label: item,
                        value: item,
                      }))
                  : []
              }
              placeholder="Bill Head"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                handleBillHead(selectedItem.value);
              }}
              value={
                searchParams.get("billHead") && {
                  value: searchParams.get("billHead"),
                  label: searchParams.get("billHead"),
                }
              }
            />
          </div>
          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Block</div>
            <Dropdown2
              options={
                invoiceAgingReport?.data?.length
                  ? invoiceAgingReport?.data
                      ?.map((item) => item?.block)
                      .filter(function (item, i, ar) {
                        return ar.indexOf(item) === i;
                      })
                      .map((item) => ({
                        label: item,
                        value: item,
                      }))
                  : []
              }
              placeholder="Block"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                handleBlock(selectedItem.value);
              }}
              value={
                searchParams.get("getBlock") && {
                  value: searchParams.get("getBlock"),
                  label: searchParams.get("getBlock"),
                }
              }
            />
          </div>
          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Status</div>
            <Dropdown2
              options={
                invoiceAgingReport?.data?.length
                  ? invoiceAgingReport?.data
                      ?.map((item) => item?.status)
                      .filter(function (item, i, ar) {
                        return ar.indexOf(item) === i;
                      })
                      .map((item) => ({
                        label: item,
                        value: item,
                      }))
                  : []
              }
              placeholder="Status"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                handleStatus(selectedItem.value);
              }}
              value={
                searchParams.get("getStatus") && {
                  value: searchParams.get("getStatus"),
                  label: searchParams.get("getStatus"),
                }
              }
            />
          </div>

          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </div>

        <div>
          <InvoiceAgingReportTable
            loading={loading}
            data={filteredItem?.data?.map((item, index) => ({
              billDate: item?.bill_date,
              billNumber: item?.bill_number,
              billPeriod: item?.bill_period,
              flatNumber: item?.flat_number,
              memberName: item?.member_name,
              billHead: item?.bill_head,
              age: item?.["age(Days)"],
              amount: item?.amount,
              balanceDue: item?.balance_due,
              status: item?.status,
              "<30": item?.["<30"],
              "31_90": item?.["31-90"],
              "91_180": item?.["91-180"],
              "181_365": item?.["181-365"],
              ">365_days": item?.[">365-days"],
            }))}
            columns={headCells}
            checkbox={false}
            pagination={filteredItem?.data?.length}
            totalCount={filteredItem?.paginate?.total_count}
            totalPages={filteredItem?.paginate?.total_page}
            start={
              filteredItem?.paginate?.current * filteredItem?.paginate?.limit +
              1
            }
            end={
              filteredItem?.paginate?.current * filteredItem?.paginate?.limit +
              filteredItem?.data?.length
            }
            currentPage={Number(filteredItem?.paginate?.current)}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            // getTableData={getTableData}
            selected={selected}
            setSelected={setSelected}
          ></InvoiceAgingReportTable>
          {!filteredItem?.data?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default InvoiceAgingReport;
