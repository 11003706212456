import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import TableSortLabel from "@mui/material/TableSortLabel";
import { Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader/Loader";
import {
  AuditIcon,
  EditIcon,
  ViewIcon,
} from "../../assets";
import "./Table.css";
import { AUDIT_LOGS_CONTENT_MANAGEMENT_TEMPLATE, EDIT_CONTENT_MANAGEMENT_TEMPLATE, VIEW_CONTENT_MANAGEMENT_TEMPLATE } from "../../Routing/routes";

function ActionComponent({ id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="View">
        <ViewIcon
          className="fill-[#555] hover:fill-red-650 me-3"
          onClick={() => {
            navigate(`${VIEW_CONTENT_MANAGEMENT_TEMPLATE}/${id}`);
          }}
        />
      </Tooltip>
      <Tooltip title="Edit">
        <EditIcon
          className="fill-[#555] hover:fill-red-650 me-3"
          onClick={() => {
            navigate(`${EDIT_CONTENT_MANAGEMENT_TEMPLATE}/${id}`);
          }}
        />
      </Tooltip>
      <Tooltip title="Audit Log">
        <AuditIcon
          className="fill-[#555] hover:fill-red-650 me-3"
          onClick={() => {
            navigate(`${AUDIT_LOGS_CONTENT_MANAGEMENT_TEMPLATE}/${id}`);
          }}
        />
      </Tooltip>
    </Box>
  );
}
function truncateContent(content, wordLimit) {
  if (!content) {
    return "";
  }

  const plainText = content.replace(/<[^>]+>/g, ""); // Remove HTML tags
  const words = plainText.split(/\s+/); // Split by spaces or new lines

  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "..."; // Limit to word count and add ellipsis
  }

  return plainText; // If content is less than or equal to the limit, show as is
}

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "15px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const ContentManagementTemplateTable = ({
  loading,
  data,
  columns,
  pagination = true,
}) => {
  const [selected, setSelected] = useState([]);

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align={column.numeric ? "right" : "left"}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  {column.label}
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  data?.map((data, rowIndex) => {
                    return (
                      <TableRowStyled
                        key={data?.id}
                        tabIndex={-1}
                      >
                        <TableCellStyled
                          size="small"
                          align="left"
                          onClick={(event) => handleClick(event)}
                        >
                          {rowIndex + 1}
                        </TableCellStyled>

                        <TableCellStyled size="small" align="left">
                          {data?.title}
                        </TableCellStyled>

                        <TableCellStyled
                          size="small"
                          align="left"
                          sx={{ textTransform: "capitalize" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: truncateContent(data?.content, 23),
                            }}
                          />
                        </TableCellStyled>

                        <TableCellStyled size="small" align="left">
                          <ActionComponent id={data.id} />
                        </TableCellStyled>
                      </TableRowStyled>
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

ContentManagementTemplateTable.propTypes = {
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ContentManagementTemplateTable;
