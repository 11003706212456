import * as types from '../../index.types';

export function getInvoiceheadSetting(data) {

    return {
        type: types.GET_INVOICE_HEAD_SETTING,
        data
    };
}

export function updateInvoiceheadSetting(data) {
    return {
        type: types.UPDATE_INVOICE_HEAD_SETTING,
        data
    };
}

export function getGLAccountList(data) {
    return {
        type: types.GET_GL_ACCOUNT_LIST,
        data
    };
}
export function addMoreHead(data) {
    return {
        type: types.ADD_MORE_HEAD,
        data
    };
}
export function getMoreHeadDetails(data) {
    return {
        type: types.GET_MORE_HEAD_DETAILS,
        data
    };
}
export function editMoreHeadDetails(data) {
    return {
        type: types.EDIT_MORE_HEAD_DETAILS,
        data
    };
}
export function deleteMoreHeadDetails(data) {
    return {
        type: types.DELETE_MORE_HEAD_DETAILS,
        data
    };
}
export function deleteUttilitySlab(data) {
    return {
        type: types.DELETE_UTILITY_SLAB,
        data
    };
}
export function addUtilitySlab(data) {
    return {
        type: types.ADD_UTILITY_SLAB,
        data
    };
}
export function getInvoiceFunctionalSetting(data) {
    return {
        type: types.GET_INVOICE_FUNCTIONAL_SETTING,
        data
    };
}
export function saveInvoiceFunctionalSetting(data) {
    return {
        type: types.SAVE_INVOICE_FUNCTIONAL_SETTING,
        data
    };
}
export function getInvoiceSeriesList(data) {
    return {
        type: types.GET_INVOICE_SERIES_LIST,
        data
    };
}
export function deleteInvoiceSeries(data) {
    return {
        type: types.DELETE_INVOICE_SERIES,
        data
    };
}
export function addInvoiceSeries(data) {
    return {
        type: types.ADD_INVOICE_SERIES,
        data
    };
}
export function getTaxableFormatList(data) {
    return {
        type: types.GET_TAXABLE_FORMAT_LIST,
        data
    };
}
export function deleteTaxableMainHead(data) {
    return {
        type: types.DELETE_TAXABLE_MAIN_HEAD,
        data
    };
}
export function addTaxableMainHead(data) {
    return {
        type: types.ADD_TAXABLE_MAIN_HEAD,
        data
    };
}

export function editTaxableMainHead(data) {
    return {
        type: types.EDIT_TAXABLE_MAIN_HEAD,
        data
    };
}
export function addTaxableSubHead(data) {
    return {
        type: types.ADD_TAXABLE_SUB_HEAD,
        data
    };
}
export function actionTaxableSubHead(data) {
    return {
        type: types.ACTION_TAXABLE_SUB_HEAD,
        data
    };
}
export function changeOrderTaxableHead(data) {
    return {
        type: types.CHANGE_ORDER_TAXABLE_HEAD,
        data
    };
}

export function getInvoiceTypeList(data) {
    return {
        type: types.GET_INVOICE_TYPE_LIST,
        data
    };
}
export function addInvoiceType(data) {
    return {
        type: types.ADD_INVOICE_TYPE,
        data
    };
}
export function deleteInvoiceType(data) {
    return {
        type: types.DELETE_INVOICE_TYPE,
        data
    };
}
export function getInvoiceHeadList(data) {
    return {
        type: types.GET_INVOICE_HEAD_LIST,
        data
    };
}
export function getBankDetailList(data) {
    return {
        type: types.GET_BANK_DETAIL_LIST,
        data
    };
}

export function getCreditNoteSettingDetail(data) {
    return {
        type: types.GET_CREDIT_NOTE_SETTINGS_DETAIL,
        data
    };
}
export function memberLedgerList(data) {
    return {
        type: types.GET_MEMBER_LEDGER_LIST,
        data
    };
}
