import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "../../../../../components/TableComponent/Table.css";
import { SortIcon } from "../../../../../assets";
import styled from "@emotion/styled";
import {
  stableSort,
  getComparator,
} from "../../../../../utils/helpers/universalFunctions";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../../components/Loader/Loader";
import ItemsPerPage from "../../../../../components/ItemsPerPage/ItemsPerPage";
import Pagination from "../../../../../components/Pagination/Pagination";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const InvoiceTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
}) => {
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked
      ? data.map((item) => item.employeeRoleId)
      : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    // searchParams.delete("page")
    navigate({
      pathname: `${""}`,
      // search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      // searchParams.delete("pageLimit")
      navigate({
        pathname: `${""}`,
        // search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      });
    }
  };

  const isSelected = (id) => selected.includes(id);

  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table
          stickyHeader
          aria-label="sticky table "
          className=" !border-collapse"
        >
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(data, getComparator(order, orderBy))?.map(
                    (row, rowIndex) => {
                      return (
                        <>
                          <tr key={rowIndex} className="text-sm">
                            <td
                              colSpan={3}
                              className="bg-[#e6e6e6] p-3 text-end border border-[#e6e6e6]"
                            >
                              <p className="mb-1">
                                <span>{row?.name} On</span>
                                <span>{row?.modifi_date_time}</span>
                              </p>
                              <p>
                                <span>{row?.ip_address} </span>
                              </p>
                            </td>
                          </tr>
                          <tr key={rowIndex} className="text-sm font-semibold">
                            <td className="w-[33.33%]  p-3">
                              Invoice Number : {row?.invoiceNumber}
                              {row?.invoice_reference_number}
                            </td>
                            <td className="w-[33.33%]  p-3">{""}</td>
                            <td className="w-[33.33%]  p-3  text-end">
                              Invoice Date : {row?.invoiceDate}
                            </td>
                          </tr>
                          <tr key={rowIndex} className="text-sm font-semibold">
                            <td className="w-[33.33%]  p-3">
                              MemberName : {row?.memberName}
                            </td>
                            <td className="w-[33.33%]  p-3">{""}</td>
                            <td className="w-[33.33%]  p-3  text-end">
                              Bill Period : {row?.billPeriod}
                            </td>
                          </tr>
                          <tr key={rowIndex} className="text-sm font-semibold">
                            <td className="w-[33.33%]  p-3">
                              Unit : {row?.unit}
                            </td>
                            <td className="w-[33.33%]  p-3">{""}</td>
                            <td className="w-[33.33%]  p-3 text-end">
                              Payment Due Date : {row?.paymentDueDate}
                            </td>
                          </tr>

                          {row?.items?.length > 0 && (
                            <tr
                              key={rowIndex}
                              className="text-sm border-y-2 border-[#e6e6e6] font-semibold"
                            >
                              <td className="w-[33.33%]  p-3">Items</td>
                              <td className="w-[33.33%]  p-3">
                                Old Amount(Rs.){" "}
                              </td>
                              <td className="w-[33.33%]  p-3 ">
                                New Amount(Rs.)
                              </td>
                            </tr>
                          )}

                          {row?.change_data?.name?.length > 0 &&
                            row?.change_data?.name?.map((item, index) => (
                              <TableRow key={index} role="checkbox">
                                <TableCellStyled size="small" align="left">
                                  {row?.change_data?.name[index]}
                                </TableCellStyled>
                                <TableCellStyled size="small" align="left">
                                  {row?.change_data?.old_amount[index]}
                                </TableCellStyled>
                                <TableCellStyled size="small" align="left">
                                  {row?.change_data?.new_amount[index]}
                                </TableCellStyled>
                              </TableRow>
                            ))}
                        </>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

export default InvoiceTable;
