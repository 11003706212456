import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/BillingAndInvoiceSetup/InvoiceSettingReqs'

function* getInvoiceHeadSetting(action) {
    try {
        const result = yield call(req.getInvoiceHeadettingReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_INVOICE_HEAD_SETTING_SUCCESS, payload: result.data,glCode: result.gl_code});  
        } else {
            yield put({ type: types.GET_INVOICE_HEAD_SETTING_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateInvoiceUpdateSetting(action) {
    try {
        const result = yield call(req.updateInvoiceHeadettingReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.UPDATE_INVOICE_HEAD_SETTING_SUCCESS, payload: result.data});
            action.data?.onSucessCallback()  
        } else {
            yield put({ type: types.UPDATE_INVOICE_HEAD_SETTING_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getGLAccountList(action) {
    try {
        const result = yield call(req.getGLAccountReq, action);
        if (result.success === true) {
            //notify('success', result.message)
            yield put({ type: types.GET_GL_ACCOUNT_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_GL_ACCOUNT_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* addMoreHead(action) {
    try {
        const result = yield call(req.addMoreHeadsReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.ADD_MORE_HEAD_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()  
        } else {
            yield put({ type: types.ADD_MORE_HEAD_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* editMoreHead(action) {
    try {
        const result = yield call(req.editMoreHeadsReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.ADD_MORE_HEAD_SUCCESS, payload: result.data});  
            //action.data?.onSuccessCallback()  
        } else {
            yield put({ type: types.ADD_MORE_HEAD_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* deleteMoreHead(action) {
    try {
        const result = yield call(req.deleteMoreHeadsReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.DELETE_MORE_HEAD_DETAILS_SUCCESS, payload: result.data});  
            action.data?.onSuccessCallback()  
        } else {
            yield put({ type: types.DELETE_MORE_HEAD_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getMoreHeadDetails(action) {
    try {
        const result = yield call(req.getMoreHeadDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MORE_HEAD_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MORE_HEAD_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteUtiltySlab(action) {
    try {
        const result = yield call(req.deleteUtiltySlab, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.DELETE_UTILITY_SLAB_SUCCESS, payload: result.data});  
            action.data?.onSucessCallback()  
        } else {
            yield put({ type: types.DELETE_UTILITY_SLAB_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* addUtilitySlab(action) {
    try {
        const result = yield call(req.addUtiltySlab, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.ADD_UTILITY_SLAB_SUCCESS, payload: result.data});
            action.data?.onSucessCallback()   
        } else {
            yield put({ type: types.ADD_UTILITY_SLAB_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getInvoiceFunctionalSetting(action) {
    try {
        const result = yield call(req.getInvoiceFunctionalSettingReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_INVOICE_FUNCTIONAL_SETTING_SUCCESS, payload: result.data});
            //action.data?.onSucessCallback()   
        } else {
            yield put({ type: types.GET_INVOICE_FUNCTIONAL_SETTING_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* saveInvoiceFunctionalSetting(action) {
    try {
        const result = yield call(req.saveInvoiceFunctionalSettingReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.SAVE_INVOICE_FUNCTIONAL_SETTING_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.SAVE_INVOICE_FUNCTIONAL_SETTING_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getInvoiceSeriesList(action) {
    try {
        const result = yield call(req.getInvoiceSeriesListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_INVOICE_SERIES_LIST_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.GET_INVOICE_SERIES_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* deleteInvoiceSeries(action) {
    try {
        const result = yield call(req.deleteInvoiceSeriesReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.DELETE_INVOICE_SERIES_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.DELETE_INVOICE_SERIES_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* addInvoiceSeries(action) {
    try {
        const result = yield call(req.addInvoiceSeriesReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.ADD_INVOICE_SERIES_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.ADD_INVOICE_SERIES_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* deleteTaxableMainHead(action) {
    try {
        const result = yield call(req.deleteTaxableMainHeadReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.DELETE_TAXABLE_MAIN_HEAD_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.DELETE_TAXABLE_MAIN_HEAD_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* addTaxableMainHead(action) {
    try {
        const result = yield call(req.addTaxableMainHeadReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.ADD_TAXABLE_MAIN_HEAD_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.ADD_TAXABLE_MAIN_HEAD_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* addTaxableSubHead(action) {
    try {
        const result = yield call(req.addTaxableSubHeadReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.ADD_TAXABLE_SUB_HEAD_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.ADD_TAXABLE_SUB_HEAD_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* actionTaxableSubHead(action) {
    try {
        const result = yield call(req.actionTaxableSubHeadReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.ACTION_TAXABLE_SUB_HEAD_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.ACTION_TAXABLE_SUB_HEAD_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* changeOrderTaxableHead(action) {
    try {
        const result = yield call(req.changeOrderTaxableHeadReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.CHANGE_ORDER_TAXABLE_HEAD_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.CHANGE_ORDER_TAXABLE_HEAD_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getTaxableFormatList(action) {
    try {
        const result = yield call(req.getTaxableFormatListReq, action);
        if (result.success === true) {
            //notify('success', result.message)
            yield put({ type: types.GET_TAXABLE_FORMAT_LIST_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.GET_TAXABLE_FORMAT_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getInvoiceTypeList(action) {
    try {
        const result = yield call(req.getInvoiceTypeListReqs, action);
        if (result.success === true) {
            //notify('success', result.message)
            yield put({ type: types.GET_INVOICE_TYPE_LIST_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.GET_INVOICE_TYPE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* addInvoiceType(action) {
    try {
        const result = yield call(req.addInvoiceTypeListReqs, action);
        if (result.success === true) {
            //notify('success', result.message)
            yield put({ type: types.ADD_INVOICE_TYPE_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.ADD_INVOICE_TYPE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* deleteInvoiceType(action) {
    try {
        const result = yield call(req.deleteInvoiceTypeListReqs, action);
        if (result.success === true) {
            //notify('success', result.message)
            yield put({ type: types.DELETE_INVOICE_TYPE_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.DELETE_INVOICE_TYPE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getInvoiceHeadList(action) {
    try {
        const result = yield call(req.getInvoiceHeadListReq, action);
        if (result.success === true) {
            //notify('success', result.message)
            yield put({ type: types.GET_INVOICE_HEAD_LIST_SUCCESS, payload: result.data});
            //action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.GET_INVOICE_HEAD_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getBankDetailList(action) {
    try {
        const result = yield call(req.getBankDetailListReq, action);
        if (result.success === true) {
            //notify('success', result.message)
            yield put({ type: types.GET_BANK_DETAIL_LIST_SUCCESS, payload: result.data});
            //action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.GET_BANK_DETAIL_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* getCreditNoteSettingDetails(action) {
    try {
        const result = yield call(req.getCreditNoteSettingDetailsReq, action);
        if (result.success === true) {
            //notify('success', result.message)
            yield put({ type: types.GET_CREDIT_NOTE_SETTINGS_DETAIL_SUCCESS, payload: result.data});
            //action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.GET_CREDIT_NOTE_SETTINGS_DETAIL_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* editTaxableMainHead(action) {
    try {
        const result = yield call(req.addTaxableMainHeadReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.EDIT_TAXABLE_MAIN_HEAD_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.EDIT_TAXABLE_MAIN_HEAD_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
function* memberLedgerList(action) {
    try {
        const result = yield call(req.memberLedgerListReq, action);
        if (result.success === true) {
            //notify('success', result.message)
            yield put({ type: types.GET_MEMBER_LEDGER_LIST_SUCCESS, payload: result.data});
            action.data?.onSuccessCallback()   
        } else {
            yield put({ type: types.GET_MEMBER_LEDGER_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}
export default function* invoiceSettingSaga() {
    yield takeLatest(types.GET_INVOICE_HEAD_SETTING, getInvoiceHeadSetting);
    yield takeLatest(types.UPDATE_INVOICE_HEAD_SETTING, updateInvoiceUpdateSetting);
    yield takeLatest(types.GET_GL_ACCOUNT_LIST, getGLAccountList);
    yield takeLatest(types.ADD_MORE_HEAD, addMoreHead);
    yield takeLatest(types.GET_MORE_HEAD_DETAILS, getMoreHeadDetails);
    yield takeLatest(types.DELETE_MORE_HEAD_DETAILS, deleteMoreHead);
    yield takeLatest(types.DELETE_UTILITY_SLAB, deleteUtiltySlab);
    yield takeLatest(types.ADD_UTILITY_SLAB, addUtilitySlab);
    yield takeLatest(types.GET_INVOICE_FUNCTIONAL_SETTING, getInvoiceFunctionalSetting);
    yield takeLatest(types.SAVE_INVOICE_FUNCTIONAL_SETTING, saveInvoiceFunctionalSetting);
    yield takeLatest(types.GET_INVOICE_SERIES_LIST, getInvoiceSeriesList);
    yield takeLatest(types.DELETE_INVOICE_SERIES, deleteInvoiceSeries);
    yield takeLatest(types.ADD_INVOICE_SERIES, addInvoiceSeries);
    yield takeLatest(types.GET_TAXABLE_FORMAT_LIST, getTaxableFormatList);
    yield takeLatest(types.ADD_TAXABLE_MAIN_HEAD, addTaxableMainHead);
    yield takeLatest(types.DELETE_TAXABLE_MAIN_HEAD, deleteTaxableMainHead);
    yield takeLatest(types.ADD_TAXABLE_SUB_HEAD, addTaxableSubHead);
    yield takeLatest(types.ACTION_TAXABLE_SUB_HEAD, actionTaxableSubHead);
    yield takeLatest(types.CHANGE_ORDER_TAXABLE_HEAD, changeOrderTaxableHead);
    yield takeLatest(types.EDIT_MORE_HEAD_DETAILS, editMoreHead);
    yield takeLatest(types.GET_INVOICE_TYPE_LIST, getInvoiceTypeList);
    yield takeLatest(types.ADD_INVOICE_TYPE, addInvoiceType);
    yield takeLatest(types.DELETE_INVOICE_TYPE, deleteInvoiceType);
    yield takeLatest(types.GET_INVOICE_HEAD_LIST, getInvoiceHeadList);
    yield takeLatest(types.GET_BANK_DETAIL_LIST, getBankDetailList);
    yield takeLatest(types.GET_CREDIT_NOTE_SETTINGS_DETAIL, getCreditNoteSettingDetails);
    yield takeLatest(types.EDIT_TAXABLE_MAIN_HEAD, editTaxableMainHead);
    yield takeLatest(types.GET_MEMBER_LEDGER_LIST, memberLedgerList);
}