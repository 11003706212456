import * as type from "../../actions/index.types";

export function downloadBillingReportsReducer(state = {
    loading: false,
    downLoader: false,
    error: null
}, action) {
    switch (action.type) {
        case type.DOWNLOAD_MEMBER_DUE_SUMMARY:
            return {
                ...state,
                loading: true,
                downLoader: true,
            }
        case type.DOWNLOAD_MEMBER_DUE_SUMMARY_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                downLoader: false,
            }
        case type.DOWNLOAD_MEMBER_DUE_SUMMARY_FAILURE:
            return {
                ...state,
                error: action?.message || 'Somthing went wrong.',
                loading: false,
                downLoader: false,
            }


        case type.DOWNLOAD_COUNTED_INVOICE_REPORT:
            return {
                ...state,
                loading: true,
                downLoader: true,
            }
        case type.DOWNLOAD_COUNTED_INVOICE_REPORT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                downLoader: false,
            }
        case type.DOWNLOAD_COUNTED_INVOICE_REPORT_FAILURE:
            return {
                ...state,
                error: action?.message || 'Somthing went wrong.',
                loading: false,
                downLoader: false,
            }

        case type.DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT:
            return {
                ...state,
                loading: true,
                downLoader: true,
            }
        case type.DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                downLoader: false,
            }
        case type.DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT_FAILURE:
            return {
                ...state,
                error: action?.message || 'Somthing went wrong.',
                loading: false,
                downLoader: false,
            }

        default:
            return state;
    }
}