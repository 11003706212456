import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/FinanceReqs/BillingReportsReqs'
import { notify } from '../../../utils/notification';

function* getDebitNotesReport(action) {
    try {
        const result = yield call(req.getDebitNotesReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DEBIT_NOTES_REPORT_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_DEBIT_NOTES_REPORT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadDebitNotesReport(action) {
    try {
        const result = yield call(req.downloadDebitNotesReportReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
            yield put({ type: types.DOWNLOAD_DEBIT_NOTES_REPORT_SUCCESS });
        } else {
            yield put({ type: types.DOWNLOAD_DEBIT_NOTES_REPORT_FAILURE });
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* getDeletedReceipt(action) {
    try {
        const result = yield call(req.getDeletedReceiptReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DELETED_RECEIPT_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_DELETED_RECEIPT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getReceiptDetails(action) {
    try {
        const result = yield call(req.getReceiptDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_RECIEPT_DETAILS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_RECIEPT_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getChequeDepositSlipReport(action) {
    try {
        const result = yield call(req.getChequeDepositSlipReportReq, action);
        yield put({ type: types.GET_CHEQUE_DEPOSIT_SLIP_REPORT_SUCCESS, payload: result });
    } catch (e) {
        console.log(e)
        yield put({ type: types.GET_CHEQUE_DEPOSIT_SLIP_REPORT_FAILURE });

    }
}

function* downloadChequeDepositSlipReport(action) {
    try {
        const result = yield call(req.getChequeDepositSlipReportReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield put({ type: types.DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT_SUCCESS });
            yield call(req.deleteBillingReportExcelReq, action);
        } else {
            yield put({ type: types.DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT_FAILURE });
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* getCountedInvoiceReport(action) {
    try {
        const result = yield call(req.getCountedInvoiceReportReq, action);
        yield put({ type: types.GET_COUNTED_INVOICE_REPORT_SUCCESS, payload: result });
    } catch (e) {
        console.log(e)
        yield put({ type: types.GET_COUNTED_INVOICE_REPORT_FAILURE });
    }
}

function* downloadCountedInvoiceReport(action) {
    try {
        const result = yield call(req.getCountedInvoiceReportReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield put({ type: types.DOWNLOAD_COUNTED_INVOICE_REPORT_SUCCESS });
            yield call(req.deleteBillingReportExcelReq, action);
        } else {
            yield put({ type: types.DOWNLOAD_COUNTED_INVOICE_REPORT_FAILURE });
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* getReceiptList(action) {
    try {
        const result = yield call(req.getReceiptListReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_RECEIPTS_LIST_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_RECEIPTS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadReceiptList(action) {
    try {
        const result = yield call(req.getReceiptListReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield put({ type: types.DOWNLOAD_RECEIPTS_LIST_SUCCESS });
            yield call(req.deleteBillingReportExcelReq, action);
        } else {
            yield put({ type: types.DOWNLOAD_RECEIPTS_LIST_FAILURE });
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* getIncompleteTransaction(action) {
    try {
        const result = yield call(req.getIncompleteTransactionReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_INCOMPLETE_TRANSACTION_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_INCOMPLETE_TRANSACTION_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getIncompleteTransactionDetails(action) {
    try {
        const result = yield call(req.getIncompleteTransactionDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_INCOMPLETE_TRANSACTION_DETAILS_SUCCESS, payload: result?.data });
        } else {
            yield put({ type: types.GET_INCOMPLETE_TRANSACTION_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMemberDueSummary(action) {
    try {
        const result = yield call(req.getMemberDueSummaryReq, action);
        yield put({ type: types.GET_MEMBER_DUE_SUMMARY_SUCCESS, payload: result });
    } catch (e) {
        console.log(e)
        yield put({ type: types.GET_MEMBER_DUE_SUMMARY_FAILURE });
    }
}


function* downloadMemberDueSummaryReport(action) {
    try {
        const result = yield call(req.getMemberDueSummaryReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield put({ type: types.DOWNLOAD_MEMBER_DUE_SUMMARY_SUCCESS });
            yield call(req.deleteBillingReportExcelReq, action);
        } else {
            yield put({ type: types.DOWNLOAD_MEMBER_DUE_SUMMARY_FAILURE });
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}


function* getMemberBillDueReport(action) {
    try {
        const result = yield call(req.getMemberBillDueReportReq, action);
        yield put({ type: types.GET_MEMBER_BILL_DUE_REPORT_SUCCESS, payload: result });
    } catch (e) {
        console.log(e);
        yield put({ type: types.GET_MEMBER_BILL_DUE_REPORT_FAILURE });
    }
}


function* downloadMemberBillDueReport(action) {
    try {
        const result = yield call(req.getMemberBillDueReportReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield put({ type: types.DOWNLOAD_MEMBER_BILL_DUE_REPORT_SUCCESS });
            yield call(req.deleteBillingReportExcelReq, action);
        } else {
            yield put({ type: types.DOWNLOAD_MEMBER_BILL_DUE_REPORT_FAILURE });
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}


function* getOpeningBalanceData(action) {
    try {
        const result = yield call(req.getOpeningBalanceDataReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_OPENING_BALANCE_DATA_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_OPENING_BALANCE_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* billingReportSaga() {
    yield takeLatest(types.GET_DEBIT_NOTES_REPORT, getDebitNotesReport);
    yield takeLatest(types.DOWNLOAD_DEBIT_NOTES_REPORT, downloadDebitNotesReport);
    yield takeLatest(types.GET_DELETED_RECEIPT, getDeletedReceipt);
    yield takeLatest(types.GET_RECIEPT_DETAILS, getReceiptDetails);
    yield takeLatest(types.GET_CHEQUE_DEPOSIT_SLIP_REPORT, getChequeDepositSlipReport);
    yield takeLatest(types.DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT, downloadChequeDepositSlipReport);
    yield takeLatest(types.GET_COUNTED_INVOICE_REPORT, getCountedInvoiceReport);
    yield takeLatest(types.DOWNLOAD_COUNTED_INVOICE_REPORT, downloadCountedInvoiceReport);
    yield takeLatest(types.GET_RECEIPTS_LIST, getReceiptList);
    yield takeLatest(types.DOWNLOAD_RECEIPTS_LIST, downloadReceiptList);
    yield takeLatest(types.GET_INCOMPLETE_TRANSACTION, getIncompleteTransaction);
    yield takeLatest(types.GET_INCOMPLETE_TRANSACTION_DETAILS, getIncompleteTransactionDetails);
    yield takeLatest(types.GET_MEMBER_DUE_SUMMARY, getMemberDueSummary);
    yield takeLatest(types.DOWNLOAD_MEMBER_DUE_SUMMARY, downloadMemberDueSummaryReport);
    yield takeLatest(types.GET_MEMBER_BILL_DUE_REPORT, getMemberBillDueReport);
    yield takeLatest(types.DOWNLOAD_MEMBER_BILL_DUE_REPORT, downloadMemberBillDueReport);
    yield takeLatest(types.GET_OPENING_BALANCE_DATA, getOpeningBalanceData);
}