import * as type from "../../../actions/index.types";

export function contentReducer(
  state = {
    loading: false,
    data: [],
    msg: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_CONTENT_MANAGEMENT_TEMPLATE:
      console.log("reducer");
      return { data: [], loading: true, error: null };
    case type.GET_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS:
      return { loading: false, data: action.payload };
    case type.GET_CONTENT_MANAGEMENT_TEMPLATE_FAILURE:
      return { data: [], loading: false, error: action.payload };

    case type.UPDATE_CONTENT_MANAGEMENT_TEMPLATE:
      return { updateloading: true, error: null };
    case type.UPDATE_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS:
      return { updateloading: false, msg: action.payload.message };
    case type.UPDATE_CONTENT_MANAGEMENT_TEMPLATE_FAILURE:
      return { updateloading: false, error: action.payload };

    case type.AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE:
      return { data: [], auditLoading: true, error: null };
    case type.AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS:
      return { auditLoading: false, data: action.payload };
    case type.AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE_FAILURE:
      return { data: [], auditLoading: false, error: action.payload };

    default:
      return state;
  }
}
