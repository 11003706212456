import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/DeskReqs/DeskReqs/DeskReqs'
import { notify } from "../../../utils/notification";
import { deleteImageFromFirebase, getFirebaseURL, uploadImageOnFirebase } from '../../../firebase/firebaseFunction';
import { complaintDocPath } from '../../../firebase/getFirebasePath';

function* getComplaintDashboardContentList(action) {
    try {
        const result = yield call(req.getComplaintDashboardContentReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_COMPLAINT_DASHBOARD_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_COMPLAINT_DASHBOARD_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getComplaintDashboardList(action) {
    try {
        const result = yield call(req.getComplaintListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_COMPLAINT_DASHBOARD_CONTENT_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_COMPLAINT_DASHBOARD_CONTENT_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getComplaintAgingList(action) {
    try {
        const result = yield call(req.getComplaintAgingListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_COMPLAINT_AGING_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_COMPLAINT_AGING_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getComplaintEmailSms(action) {
    try {
        const result = yield call(req.getComplaintEmailSmsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_COMPLAINT_EMAIL_SMS_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_COMPLAINT_EMAIL_SMS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateComplaintEmailSms(action) {
    try {
        const result = yield call(req.updateComplaintEmailSmsReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            yield put({ type: types.UPDATE_COMPLAINT_EMAIL_SMS_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.UPDATE_COMPLAINT_EMAIL_SMS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getComplaintEscalationSettings(action) {
    try {
        const result = yield call(req.getComplaintEscalationSettingsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_COMPLAINT_ESCALATION_LIST_SETTINGS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_COMPLAINT_ESCALATION_LIST_SETTINGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateComplaintEscalationSettings(action) {
    try {
        const result = yield call(req.updateSpecificDetailsReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            yield put({ type: types.UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getLoggedComplaintTicketDetails(action) {
    try {
        const result = yield call(req.getLoggedTicketDetailReqs, action);
        if (result.statusCode === 200) {
            for (let item of result.data?.attachments) {
                item.attachment_Url = yield getFirebaseURL(item?.attachment)
            }
            yield put({ type: types.GET_COMPLAINT_LOGGED_TICKET_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_COMPLAINT_LOGGED_TICKET_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAssignedComplaintData(action) {
    try {
        const result = yield call(req.getAssignedComplaintDataReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ASSIGNED_COMPLAINT_DATA_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_ASSIGNED_COMPLAINT_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addAssignedComplaintData(action) {
    try {
        const result = yield call(req.addAssignedComplaintDataReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.ADD_ASSIGNED_COMPLAINT_DATA_SUCCESS, payload: result.data });
            action.data?.onSuccessCallback?.()
            notify('success', result?.message)
        } else {
            yield put({ type: types.ADD_ASSIGNED_COMPLAINT_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAssignedReqRoles(action) {
    try {
        const result = yield call(req.getAssignedReqRolesReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ASSIGNED_REQ_ROLES_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_ASSIGNED_REQ_ROLES_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAssignedSubReqRoles(action) {
    try {
        const result = yield call(req.getAssignedSubReqRolesReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ASSIGNED_REQ_SUB_ROLES_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_ASSIGNED_REQ_SUB_ROLES_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAssignedMemberListRoles(action) {
    try {
        const result = yield call(req.getAssignedMembersListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ASSIGNED_MEMBERS_LIST_ROLES_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_ASSIGNED_MEMBERS_LIST_ROLES_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* changeComplaintStatus(action) {
    action.data?.onRequestEndCallback?.()
    try {
        const result = yield call(req.changeComplaintStatusReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.CHANGE_COMPLAINT_STATUS_SUCCESS, payload: action.data });
            yield put({ type: types.CHANGE_COMPLAINT_STATUS_FAILURE });
        } else {
        }
    } catch (e) {
    }
}

function* downloadComplaintsExcelList(action) {
    try {
        const result = yield call(req.downloadComplaintsExcelSheetReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
            yield put({ type: types.DOWNLOAD_COMPLAINTS_EXCEL_SHEET_SUCCESS });
        } else {
            yield put({ type: types.DOWNLOAD_COMPLAINTS_EXCEL_SHEET_FAILURE });
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* getPostComment(action) {
    try {
        const result = yield call(req.getPostCommentReq, action);
        if (result.statusCode === 200) {
            for (let data of result.data.attachments) {
                data.attachment_url = yield getFirebaseURL(data.attachment)
            }
            yield put({ type: types.GET_POST_COMMENT_DATA_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_POST_COMMENT_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addPostComment(action) {
    try {
        let data = { ...action.data };
        if (action.data?.attachments?.length > 0) {
            for (let i in action.data.attachments) {
                if (typeof action.data.attachments[i]?.file !== 'string') {
                    let path = complaintDocPath({ societyId: action.data.societyId, fileName: action.data.attachments[i]?.file?.name });
                    yield uploadImageOnFirebase(action.data.attachments[i]?.file, path)
                    data.attachments[i] = path;
                }
            }
        }
        action.data.attachments = (action.data?.savedAttachments && action.data?.savedAttachments.length > 0)
            ? [...data?.attachments, ...action.data?.savedAttachments]
            : [...data?.attachments]
        const result = yield call(req.addPostCommentReq, action);
        if (result.statusCode === 200) {
            action.data?.onSuccessCallback?.()
            notify('success', 'Comment Added Successfully')
            // if (action?.data?.deleteAttachments?.length > 0) {
            //     for (let i in action?.data?.deleteAttachments) {
            //         yield deleteImageFromFirebase(action.data.deleteAttachments[i].attachment)
            //     }
            // }
            yield put({ type: types.ADD_POST_COMMENT_DATA_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.ADD_POST_COMMENT_DATA_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getComplaintStatusList(action) {
    try {
        const result = yield call(req.getComplaintStatusReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_COMPLAINT_STATUS_DATA_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_COMPLAINT_STATUS_DATA_FAILURE });
        }
    } catch (e) {
    }
}

function* getSocietyDetailsList(action) {
    try {
        const result = yield call(req.getSocietyListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIETY_LIST_DETAILS_DATA_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_SOCIETY_LIST_DETAILS_DATA_FAILURE });
        }
    } catch (e) {
    }
}
function* addComplaintDashboardReview(action) {
    try {
        const result = yield call(req.addComplaintDashboardReviewReq, action);
        if (result.statusCode === 200) {
            action?.data?.onRequestEndCallback?.(action?.data?.review_rating);
            notify('success', 'Review Added Successfully')

            yield put({ type: types.ADD_COMPLAINT_DASHBOARD_REVIEW_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.ADD_COMPLAINT_DASHBOARD_REVIEW_FAILURE });
        }
    } catch (e) {
    }
}

export default function* deskSaga() {
    yield takeLatest(types.GET_COMPLAINT_DASHBOARD_LIST, getComplaintDashboardContentList);
    yield takeLatest(types.GET_COMPLAINT_DASHBOARD_CONTENT_LIST, getComplaintDashboardList);
    yield takeLatest(types.GET_COMPLAINT_AGING_LIST, getComplaintAgingList);
    yield takeLatest(types.GET_COMPLAINT_EMAIL_SMS_LIST, getComplaintEmailSms);
    yield takeLatest(types.UPDATE_COMPLAINT_EMAIL_SMS_LIST, updateComplaintEmailSms);
    yield takeLatest(types.GET_COMPLAINT_ESCALATION_LIST_SETTINGS, getComplaintEscalationSettings);
    yield takeLatest(types.UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS, updateComplaintEscalationSettings);
    yield takeLatest(types.GET_COMPLAINT_LOGGED_TICKET, getLoggedComplaintTicketDetails);
    yield takeLatest(types.GET_ASSIGNED_COMPLAINT_DATA, getAssignedComplaintData);
    yield takeLatest(types.GET_ASSIGNED_MEMBERS_LIST_ROLES, getAssignedMemberListRoles);
    yield takeLatest(types.GET_ASSIGNED_REQ_SUB_ROLES, getAssignedSubReqRoles);
    yield takeLatest(types.GET_ASSIGNED_REQ_ROLES, getAssignedReqRoles);
    yield takeLatest(types.ADD_ASSIGNED_COMPLAINT_DATA, addAssignedComplaintData);
    yield takeLatest(types.CHANGE_COMPLAINT_STATUS, changeComplaintStatus);
    yield takeLatest(types.DOWNLOAD_COMPLAINTS_EXCEL_SHEET, downloadComplaintsExcelList);
    yield takeLatest(types.GET_POST_COMMENT_DATA, getPostComment);
    yield takeLatest(types.ADD_POST_COMMENT_DATA, addPostComment);
    yield takeLatest(types.GET_COMPLAINT_STATUS_DATA, getComplaintStatusList);
    yield takeLatest(types.GET_SOCIETY_LIST_DETAILS_DATA, getSocietyDetailsList);
    yield takeLatest(types.ADD_COMPLAINT_DASHBOARD_REVIEW, addComplaintDashboardReview);

}
