import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getBookingDetails } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { toWords } from 'number-to-words'
import { validateDate } from "../../../utils/helpers/universalFunctions";



function PrintBookingReciept() {
  const {id} = useParams()
  const dispatch = useDispatch()
  const {loading, bookingDetailsData} = useSelector(state=>state.viewBookingReducer)

  useEffect(()=>{
    dispatch(getBookingDetails({bookingId: id}))
  }, [])


  useEffect(() => {
    if(!loading && bookingDetailsData){
      window.print()
    }
  }, [loading]);

  return (
    <div className="mx-5 my-8">
        {
            loading?
            <Loader/>
            :
            <>
                <div className="text-center text-sm">
                    <div className="underline text-xl font-bold">{bookingDetailsData?.societyDetails?.title}</div>
                    <div>(REGN. NO. {bookingDetailsData?.societyDetails?.regn_no}   Dated -{moment(bookingDetailsData?.societyDetails?.date_of_regn)?.format('DD-MM-YYYY')})</div>
                    <div>{bookingDetailsData?.societyDetails?.office_address}</div>
                    <div>GST Registration No. : {bookingDetailsData?.societyDetails?.gst_registration_no}</div>
                </div>
                <div>
                    <div className="font-bold text-center mt-8">{bookingDetailsData?.facilities_name}</div>
                    <div className="flex justify-between">
                        <div><span className="font-bold">Member Name: </span>{bookingDetailsData?.user_name}</div>
                        <div><span className="font-bold">Bill No: </span>{bookingDetailsData?.invoice_number}</div>
                    </div>
                    <div className="flex justify-between">
                        <div><span className="font-bold">Wing & Flat No: </span>{bookingDetailsData?.wing_flat}</div>
                        <div><span className="font-bold">Bill Date: </span>{moment(bookingDetailsData?.date_of_booking).format('DD-MM-YYYY') || "-"}</div>
                    </div>
                    <table className="w-full mt-3 text-sm">
						<tbody className='border border-black'>
                            <tr>
                                <td colspan="2">
                                    <table width="100%" border="1" cellspacing="0" class="dtlstb">
                                        <tbody><tr height="30" valign="top">
                                            <th className='px-2 border border-black' width="45%">Item Description</th>
                                            <th className='px-2 border border-black' align="center" width="15%">Unit Price(Rs.)</th>
                                            <th className='px-2 border border-black' align="center" width="15%">No. of {bookingDetailsData?.charge_on === "day" ? "Days" : "Bookings"}</th>
                                            <th className='px-2 border border-black' align="center" width="15%">Amount (Rs.)</th>
                                        </tr>
                                        <tr height="30" valign="top">
                                            <td align="left">
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                    <tbody>
                                                        <tr class="tbrow">
                                                            <td className='px-2 border border-black'>Booking Frequency</td>
                                                            <td className='px-2 border border-black'> {bookingDetailsData?.booking_frequency}</td>
                                                        </tr>
                                                        {
                                                            bookingDetailsData?.booking_frequency === 'Weekly' &&
                                                            <tr class="tbrow">
                                                                <td width="30%" className='px-2 border border-black'>Book Every</td>
                                                                <td className='px-2 border border-black'> {bookingDetailsData?.booking_day?.replaceAll(',', ', ') || "-"}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            bookingDetailsData?.booking_frequency === 'One Time' ?
                                                            <tr class="tbrow">
                                                                <td width="30%" className='px-2 border border-black'>Date Of Booking</td>
                                                                <td className='px-2 border border-black'> {moment(bookingDetailsData?.from_date).format('DD-MM-YYYY') || "-"}</td>
                                                            </tr>
                                                            :
                                                            <tr class="tbrow">
                                                                <td width="30%" className='px-2 border border-black'>Date Range</td>
                                                                <td className='px-2 border border-black'> 
                                                                    {
                                                                        `From: ${moment(bookingDetailsData?.from_date).format('DD-MM-YYYY')} - To: ${moment(bookingDetailsData?.to_date).format('DD-MM-YYYY')}` || "-"
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                        <tr class="tbrow">
                                                            <td className='px-2 border border-black'>Time-slot</td>
                                                            <td className='px-2 border border-black'> 
                                                            {
                                                                bookingDetailsData?.slot_type === 'hours' ?
                                                                bookingDetailsData?.time_slot
                                                                :
                                                                `${moment(bookingDetailsData?.time_slot?.slice(0, 9), 'hh:mm:ss').format('hh:mm A')} - ${moment(bookingDetailsData?.time_slot?.slice(12), 'hh:mm:ss').format('hh:mm A')} (${bookingDetailsData?.facilities_type})`
                                                            }
                                                            </td>
                                                        </tr>
                                                        {bookingDetailsData?.slot_type  === 'hours' &&
                                                            <tr class="tbrow">
                                                            <td className='px-2 border border-black'>Time Range</td>
                                                            <td className='px-2 border border-black'> 
                                                            {
                                                                `${moment(bookingDetailsData?.start_time, 'hh:mm:ss').format('hh:mm A')} - ${moment(bookingDetailsData?.end_time, 'hh:mm:ss').format('hh:mm A')} (${bookingDetailsData?.facilities_type})`
                                                            }
                                                            </td>
                                                        </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>				
                                            <td className='px-2 border border-black' align="center" valign="top">{bookingDetailsData?.unit_price && (bookingDetailsData?.unit_price !=='Not Chargable') ?parseFloat(bookingDetailsData?.unit_price)?.toFixed(2)  : '0.00'}</td>	
                                            <td className='px-2 border border-black' align="center" valign="top">
                                                {   
                                                    bookingDetailsData?.charge_on == "day" ? 
                                                    bookingDetailsData?.no_of_day
                                                    :   bookingDetailsData?.no_of_booking 
                                                } 
                                            </td>
                                            <td className='px-2 border border-black' align="center" valign="top">
                                                {   
                                                    bookingDetailsData?.unit_price && bookingDetailsData?.unit_price!=="Not Chargable" ?
                                                    bookingDetailsData?.booking_amount?.toFixed(2)
                                                    : '0.00'
                                                }
                                            </td>			
                                        </tr>
                                        <tr>
                                            <td rowspan="10" className='px-2 border border-black' align="center" valign="top"></td>
                                            <td className='px-2 border border-black' colspan="2" align="right" valign="top">Sub Total</td>
                                            <td className='px-2 border border-black' align="center" valign="top">{bookingDetailsData?.unit_price && bookingDetailsData?.unit_price!=="Not Chargable" ? bookingDetailsData?.booking_amount?.toFixed(2) : '0.00'}</td>
                                        </tr>
                                                                        
                                        <tr>
                                            <td className='px-2 border border-black' colspan="2" align="right" valign="top">Security Deposit Amount</td>
                                            <td className='px-2 border border-black' align="center" valign="top">{bookingDetailsData?.security_deposit ? parseFloat(bookingDetailsData?.security_deposit)?.toFixed(2) : 0.00}</td>
                                        </tr>
                                        <tr>
                                            <td className='px-2 border border-black' colspan="2" align="right" valign="top">Grand Total </td>
                                            <td className='px-2 border border-black' align="center" valign="top">{
                                                bookingDetailsData?.unit_price!=="Not Chargable" ?
                                                bookingDetailsData?.total_amount
                                                :
                                                parseFloat(bookingDetailsData?.security_deposit).toFixed(2)          
                                            }</td>
                                        </tr>
                                    </tbody>
                                </table>
							</td>
						</tr>
						<tr>
							<td colspan="2" className="capitalize px-2 border border-black"><strong>Amount in words - {bookingDetailsData?.total_amount ? toWords(parseInt(bookingDetailsData?.total_amount)): ''} Rupees  only</strong></td>
						</tr>
					</tbody>
                    </table>
                    <div className="font-semibold mt-5">Payment Details</div>

                    {
                        bookingDetailsData?.payment_detail?.map((item, index)=>(
                            <div className="text-sm mt-3">
                                <span className="font-semibold mb-3">Reciept No.: {item.receipt_no}</span>
                                <table className="w-full table-fixed">
                                    <thead>
                                        <tr className="font-bold text-left">
                                            <th className="px-2  border border-black">Payment Mode</th>
                                            {
                                                (item.modeofpayment ==='NEFT' ||item.modeofpayment==='Cheque') &&
                                                <th className="px-2  border border-black">{item.modeofpayment ==='NEFT' ? 'Transaction Number' : 'Cheque Number'}</th>
                                            }
                                            {
                                                (item.modeofpayment ==='NEFT' ||item.modeofpayment==='Cheque') &&
                                                <th className="px-2  border border-black">Bank Name</th>
                                            }
                                            <th className="px-2  border border-black">Amount</th>
                                            {
                                                (item.modeofpayment ==='NEFT' ||item.modeofpayment==='Cheque') &&
                                                <th className="px-2  border border-black">{item.modeofpayment ==='NEFT' ? 'Transaction Date' : 'Cheque Date'}</th>
                                            }
                                            <th className="px-2  border border-black">Payment Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="px-2  border border-black">{item.modeofpayment}</td>
                                            {
                                                (item.modeofpayment ==='NEFT' ||item.modeofpayment==='Cheque') &&
                                                <td className="px-2  border border-black">{item?.transaction_number || '-'}</td>
                                            }
                                            {
                                                (item.modeofpayment ==='NEFT' ||item.modeofpayment==='Cheque') &&
                                                <td className="px-2  border border-black">{item?.bank_name || '-'}</td>
                                            }
                                            <td className="px-2  border border-black">{parseFloat(item.total_amount)?.toFixed(2)}</td>
                                            {
                                                (item.modeofpayment ==='NEFT' ||item.modeofpayment==='Cheque') &&
                                                <td className="px-2  border border-black">{ validateDate(item.transaction_date) ? moment(item.transaction_date)?.format('DD-MM-YYYY') : '-'}</td>
                                            }
                                            <td className="px-2  border border-black">{moment(item.date_of_payment)?.format('DD-MM-YYYY')}</td>

                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        ))
                    }
                    <div className="my-5">
                        This is a system generated receipt and does not require a signature.In case of any discrepency in the receipt, immediately inform the issuing authority.
                        <div className="my-3">
                            Generated By: 
                            <span className="font-semibold">{bookingDetailsData?.societyDetails?.title} </span>
                        </div>
                    </div>

                </div>
            </>
        }
    </div>
  );
}

export default PrintBookingReciept;
