import * as types from '../../index.types';

export function getMasterHeadDetails(data) {
    return {
        type: types.GET_MASTER_HEAD_DETAILS,
        data
    };
}

export function updateMasterHeadDetails(data) {
    return {
        type: types.UPDATE_MASTER_HEAD_DETAILS,
        data
    };
}
