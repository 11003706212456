import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/closeIcon.svg";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import Button from "../../../../components/Button/Button";
import {DropdownFM2} from "../../../../components/Dropdown/dropdown2";
import { Form, Formik } from "formik";
import { societyStaffTypeOptions } from "../../../../consts/DropDownOptions";
import { addInvoiceTypeValidation } from "../../../../validationSchema/setupRecordsSchema/invoiceSettingSchema";
import {
  getInvoiceTypeList,
  addInvoiceType
} from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { CircularProgress } from "@mui/material";

const CreateNewInvoiceModal = ({ isOpen, onClose, onCancel, frequency, category }) => {
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const {addLoader } = useSelector(
    (state) => state.invoiceSettingReducer
);
  const handleSave = (values) => {
    // console.log(values);
    dispatch(
      addInvoiceType({
        societyId: loginData?.default_community?.community_id,
        invoiceType: values.cat,
        invoiceTitle: values.title,
        frequency: values.freq,
        onSuccessCallback: () => {
          dispatch(getInvoiceTypeList({
            societyId: loginData?.default_community?.community_id,
          }))
          onClose()
        },
      })
    );
  };
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${isOpen ? "block" : "hidden"
        }`}
    >
      <div className="bg-white rounded-lg w-[400px] px-2 min-h-[350px] h-fit">
        <div className="flex items-center justify-between gap-8 p-2 -mb-2">
          <h2 className="p-2 text-lg font-semibold mb-2 items-center text-center w-[180%] flex justify-end">
            Create New Invoice Type
          </h2>
          <div className="flex justify-end w-1/2">
            <button
              onClick={onClose}
              className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
            >
              <CloseIcon className="w-3 h-3 ml-1 text-gray-600 cursor-pointer fill-current hover:text-gray-800" />
            </button>
          </div>
        </div>
        <Formik
          initialValues={{
            title: '',
            cat: '',
            freq: '1',
          }}
          enableReinitialize
          validationSchema={addInvoiceTypeValidation}
          onSubmit={handleSave}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCCCCC]"></div>
                <div className="p-2 ">
                  <TextInputFM
                    label="Invoice Title"
                    placeholder="Enter Title"
                    className="w-[160px] h-9"
                    name='title'
                    maxLength={20}
                  />
                </div>
                <div className="flex items-center justify-between p-2 mb-4 text-sm">
                  <div>Type/Category</div>
                  <div className="">
                    <DropdownFM2
                      options={category}
                      placeholder="Select Type"
                      className="mb-4"
                      width="160px"
                      height="36px"
                      value={formik.values.cat ? {
                        label: category?.find((detail) => detail.value === formik.values.cat).label,
                        value: formik.values.cat
                      } : null}
                      onSelect={(selectedItem) => {
                        formik.setFieldValue('cat', selectedItem.value)
                      }}
                      //   {...(formik.values.subCategory === "" && {
                      //     value: null,
                      //   })}
                      name={"cat"}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between p-2 mb-4 text-sm">
                    <div>Frequency</div>
                    <div className="">
                      <DropdownFM2
                        options={frequency}
                        placeholder="Select Frequency"
                        className=""
                        width="160px"
                        height="36px"
                        value={formik.values.freq ? {
                          label: frequency?.find((detail) => detail.value === formik.values.freq).label,
                          value: formik.values.cat
                        } : null}
                        onSelect={(selectedItem) => {
                          formik.setFieldValue('freq', selectedItem.value)
                        }}
                        name={"freq"}
                      />
                    </div>
                  </div>
                </div>
                <div className="border-b border-[#CCC]"></div>
                <div className="flex justify-center mt-4">
                  <Button type="submit" label={
                          <span className="inline-block w-12">
                            {addLoader ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={17}
                              />
                            ) : ("Save")}
                          </span>
                        } className="h-8" />
                </div>

              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CreateNewInvoiceModal;
