import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../src/assets/icons/closeIcon.svg";
import { TextArea, TextAreaFM } from "../../../src/components/InputFields/TextArea";
import Button from "../../../src/components/Button/Button";
import { FieldArray, Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import { DropdownFM2 } from "../Dropdown/dropdown2";
import { useSelector } from "react-redux";
import { UploadButtonFM } from "../Button/UploadButton";
import { PostCommentValidation } from "../../validationSchema/deskSchema/logaTicketSchema";
import { useDispatch } from "react-redux";
import { getPostComment } from "../../redux/actions/DeskActions/DeskAction";

const ChangeComplaintStatusModal = ({ id, isOpen, onClose, onConfirm, status, loading, showChangeStatusModal }) => {
    const dispatch = useDispatch();
    const [savedAttachment, setSavedAttachment] = useState([]);
    const { loginData } = useSelector((state) => state.loginReducer);
    const { complaintStatusList } = useSelector(
        (state) => state.deskReducers
    );
    const { postCommentData } = useSelector(
        (state) => state.deskReducers
    );

    useEffect(() => {
        if (showChangeStatusModal.id == id) {
            let pay = { societyId: loginData?.default_community?.community_id };
            dispatch(getPostComment({ ...pay, complaintId: id }));
        }
    }, [showChangeStatusModal])

    useEffect(() => {
        setSavedAttachment(
            postCommentData?.attachments?.map((item) => item.attachment)
        );
    }, [postCommentData])

    return (
        <Formik
            initialValues={{
                status: complaintStatusList?.filter((item) => item.key === status)?.[0]?.value,
                comment: '',
                attachments: [{ file: "" }],
            }}
            validationSchema={PostCommentValidation}
            onSubmit={(values, { resetForm }) => {
                onConfirm(id, values)
                resetForm()
            }}
        >
            {(values) => (
                <div
                    className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-60 z-20 ${isOpen ? "block" : "hidden"
                        }`} key={id}
                >
                    <div className="bg-white rounded-lg w-[400px] px-2 min-h-[300px] h-fit">
                        <div className="flex justify-between p-2 gap-8 items-center -mb-2">
                            <h2 className="p-2 text-lg font-semibold mb-2 items-center text-center w-[180%] flex justify-end">
                                Change Status
                            </h2>
                            <div className="w-1/2 flex justify-end">
                                <button
                                    onClick={() => {
                                        onClose()
                                        values.resetForm()
                                    }}
                                    className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
                                >
                                    <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
                                </button>
                            </div>
                        </div>
                        <div className="border-b border-[#CCCCCC]"></div>

                        <Form key={id}>
                            <div className="mt-2">
                                <div className="px-2 pt-2 flex  items-center">
                                    <div className="mb-2 w-1/2">
                                        Status:
                                    </div>
                                    <DropdownFM2
                                        options={complaintStatusList?.filter((item) => {
                                            // if (status == "Open") return item.key != status && item.key !== "Reopen"
                                            if (status == "Closed") return item.key === "Reopen" || item.key == status
                                            return true
                                        }).map((item) => ({
                                            label: item.key,
                                            value: item.value,
                                        })) || []}
                                        // options={complaintStatusList?.map((item) => ({
                                        //     label: item.key,
                                        //     value: item.value,
                                        // }))}
                                        placeholder={status}
                                        className="text-[11px] ms-4"
                                        width="13rem"
                                        height="32px"
                                        onSelect={(selectedItem) => {
                                            values.setFieldValue(`status`, selectedItem.value)
                                        }}
                                        name='status'
                                        value={complaintStatusList?.filter((item) => item.value === values.values.status).map((item) => ({
                                            label: item.key,
                                            value: item.value,
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className="px-2 pt-2 flex justify-between">
                                {savedAttachment?.length < 5 ?
                                    <>
                                        <div className=" mt-3">
                                            Attachment:
                                        </div>
                                        <div>
                                            <FieldArray
                                                name="attachments"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {values.values.attachments.map(
                                                            (item, index) => (
                                                                <div className=" flex flex-row" key={index + id}>
                                                                    <div>
                                                                        <div className="w-52  mb-1 d-flex">
                                                                            <UploadButtonFM
                                                                                showFileName={true}
                                                                                name={`attachments.${index}.${id}.file`}
                                                                                accept={
                                                                                    "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                                                                }
                                                                                onSelect={(e) =>
                                                                                    values.setFieldValue(
                                                                                        `attachments.${index}.file`,
                                                                                        e
                                                                                    )
                                                                                }
                                                                                fileName={
                                                                                    values.values?.attachments[
                                                                                        index
                                                                                    ]?.file?.name
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className=" align-top">
                                                                        {index !==
                                                                            values.values.attachments
                                                                                ?.length -
                                                                            1 && (
                                                                                <Button
                                                                                    label="Delete"
                                                                                    type="button"
                                                                                    className="h-8 px-4"
                                                                                    onClick={() => {
                                                                                        arrayHelpers.remove(index);
                                                                                    }}
                                                                                />
                                                                            )}

                                                                        {index ==
                                                                            values.values.attachments
                                                                                ?.length -
                                                                            1 &&
                                                                            !values.values.attachments.some(
                                                                                (e) => e.file == ""
                                                                            ) &&
                                                                            values.values?.attachments
                                                                                ?.length
                                                                            //     +
                                                                            // savedAttachment?.length 
                                                                            < 1 && (
                                                                                <Button
                                                                                    label="Add"
                                                                                    type="button"
                                                                                    className="h-8 px-4"
                                                                                    onClick={() => {
                                                                                        arrayHelpers.push({
                                                                                            file: "",
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            ></FieldArray>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="">
                                            Attachment:
                                        </div>
                                        <div className=" text-red-500">Attachment upload limit Reached</div>
                                    </>
                                }
                            </div>
                            <div className="">
                                <div className="p-2">Please Enter Comment</div>
                                <div className="p-2">
                                    <TextAreaFM name='comment' label="" placeholder="Enter Comment" row="5" className="w-[370px]" />
                                </div>
                            </div>
                            <div className="border-b border-[#CCC]"></div>
                            <div className="flex justify-center my-4">
                                <Button label={
                                    <span className="w-12 inline-block">
                                        {
                                            loading ?
                                                <CircularProgress sx={{ color: "white" }} size={17} />
                                                :
                                                <>Submit</>
                                        }
                                    </span>
                                }
                                    type='submit' />
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default ChangeComplaintStatusModal;
