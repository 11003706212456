import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../components/Button/ButtonG";
import Button from "../../../components/Button/Button";
import Layout from "../../Layout/Layout";
import Loader from "../../../components/Loader/Loader";
import DeleteModal from "../../../components/Modal/DeleteModal";
import InvoiceHeadSettingGrid from "../../../components/Grid/InvoiceHeadSettingGrid";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceheadSetting, updateInvoiceheadSetting, deleteMoreHeadDetails } from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { CircularProgress } from "@mui/material";
import { ADD_MORE_HEADS_ENDPOINT } from "../../../Routing/routes";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { Form, Formik,ErrorMessage } from "formik";
import { headSettingValidation } from "../../../validationSchema/setupRecordsSchema/invoiceSettingSchema";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";

const AccountLedgerMapping = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { id } = useParams();
  const { loginData } = useSelector(state => state.loginReducer)
  const [headDetail, setHeadDetail] = useState([])
  const [deletehead, setDeletehead] = useState()
  const [modal, setmodal] = useState(false)
  const { loading, updateLoader, invoiceHeadSetting,deleteLoader,glCode } = useSelector(state => state.invoiceSettingReducer)
  useEffect(() => {
    dispatch(getInvoiceheadSetting({ societyId: loginData?.default_community?.community_id, typeId: id }))

  }, [])
  useEffect(() => {
    if (invoiceHeadSetting) {
      setHeadDetail(invoiceHeadSetting)
    }
  })
  const handleSave = (values, { resetForm }) => {
   // console.log(values.headDetail)
    dispatch(updateInvoiceheadSetting({
      societyId: loginData?.default_community?.community_id,
      typeId: id,
      headDetails: values.headDetail,
      onSuccessCallback: () => {

      }
    }))
  }
  const handleDeleteHead = (id) => {
    setDeletehead(id)
    setmodal(true)
  }
  const confirmDelete = () => {
    dispatch(deleteMoreHeadDetails({
      societyId: loginData?.default_community?.community_id,
      headId: deletehead,
      typeId: id,
      onSuccessCallback: () => {
        setDeletehead('')
        setmodal(false)
        dispatch(getInvoiceheadSetting({ societyId: loginData?.default_community?.community_id, typeId: id }))
      }
    }))
  }
  const onCloseModel = () => {
    setDeletehead('')
    setmodal(false)
  }
  const handleNavigation = (path) => () => {
    navigate(path);
  };
  
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            headDetail       
          }}
          enableReinitialize
          onValidationError={errorValues => {
           console.log(errorValues)
         }}
          validationSchema={headSettingValidation}
          onSubmit={handleSave}
        >
          {(formik) => {
           // console.log(formik.errors)
            return (
              <Form>
                <div className="p-2 border-b border-[#CCCC]">
                  <ListHeaderBack
                    title="Account Ledger Mapping"
                    onClick={handleNavigation(-1)}
                  >
                    {/* <ListHeader leftContent="Account Ledger Mapping"> */}
                    {/* <ButtonG label="Cancel" /> */}
                    {/* <div className="border-r border-[#CCC] h-full"></div> */}
                    {getPermission(loginData, [permissions.ADD_MORE_INVOICE_HEAD]) && <Button label="Add More Head" onClick={
                      handleNavigation(`${ADD_MORE_HEADS_ENDPOINT}/${id}`)
                    } />}
                    {getPermission(loginData, [permissions.EDIT_INVOICE_HEAD_SETTINGS]) && <Button label={
                      <span className="inline-block w-12">
                        {
                          updateLoader ?
                            <CircularProgress sx={{ color: "white" }} size={17} />
                            :
                            'Save'
                        }
                      </span>
                    } disabled={loading || updateLoader} type="submit" />}
                  </ListHeaderBack>
                </div>
                <div>
                  {loading ?
                    <Loader /> :
                    <InvoiceHeadSettingGrid
                      loading={loading}
                      data={formik.values.headDetail}
                      glCode={glCode}
                      setHeadDeatail={setHeadDetail}
                      handleNavigation={handleNavigation}
                      deletehead={deletehead}
                      setDeletehead={setDeletehead}
                      handleDeleteHead={handleDeleteHead}
                      typeId={id}
                      setFieldValue={formik.setFieldValue}
                      loginData={loginData}
                    />

                  }
                </div>
              </Form>
            )
          }}
        </Formik>

        <DeleteModal
          isOpen={modal}
          onCancel={onCloseModel}
          loading={deleteLoader}
          onDelete={confirmDelete}
        />
      </div>
    </Layout>
  );
};

export default AccountLedgerMapping;
