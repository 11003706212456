import React, { useEffect } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Search from "../../../components/SearchBox/Search";
import Layout from "../../Layout/Layout";
import { useDispatch } from "react-redux";
import { getDeletedReceipt } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import { useSelector } from "react-redux";
import DeleteReceiptsTable from "./DeleteReceiptsTable";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { DELETE_RECEIPT_ENDPOINT } from "../../../Routing/routes";
import moment from "moment";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import ButtonG from "../../../components/Button/ButtonG";
import { getInvoiceTypeList } from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

export const headCells = [
  { id: "sNo", label: "Sr. No.", width: "45px" },
  { id: "date", label: "Date", sort: false, width: "77px" },
  { id: "unitNumber", label: "Unit", sort: true, width: "80px" },
  { id: "type", label: "Type", sort: true, width: "70px" },
  { id: "amount", label: "Amount", sort: true, width: "80px" },
  { id: "userId", label: "User Ids", sort: true, width: "110px" },
  {
    id: "transactionRefNo",
    label: "Chq/Ref No.",
    sort: true,
    width: "140px",
  },
  { id: "userType", label: "User Type", sort: true, width: "100px" },
  { id: "ip", label: "Ip Address", sort: true, width: "110px" },
  { id: "status", label: "Status", sort: true, width: "90px" },
  { id: "action", label: "Action", sort: false },
];

const rows = [];

const DeleteReceipts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, deletedReceiptList } = useSelector(
    (state) => state.billingReportsReducer
  );
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("search");
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      navigate({
        pathname: `${DELETE_RECEIPT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text],
        ])}`,
      });
    } else {
      searchParams.delete("search");
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      navigate({
        pathname: DELETE_RECEIPT_ENDPOINT,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };
  const handleInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("invoiceType");
    navigate({
      pathname: `${DELETE_RECEIPT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["invoiceType", selectedItem],
      ])}`,
    });
  };
  const handleClear = () => {
    navigate(`${DELETE_RECEIPT_ENDPOINT}`);
  };
  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 0,
      searchText: searchParams.get("search") || "",
      pageLimit: searchParams.get("pageLimit") || 10,
      invoiceType: searchParams.get("invoiceType") || "",
    };

    dispatch(getDeletedReceipt(data));
  };

  useEffect(() => {
    getTableData();
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Delete Receipt">
            <Dropdown2
              options={
                typeLists.length
                  ? typeLists?.map((item) => ({
                      label: item?.invoice_title,
                      value: item?.id,
                    }))
                  : []
              }
              placeholder="Invoice type"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handleInvoiceType(selectedItem.value);
              }}
              value={
                searchParams.get("invoiceType") && {
                  value: searchParams.get("invoiceType"),
                  label: typeLists.length
                    ? typeLists?.find(
                        (item) =>
                          item?.id === parseInt(searchParams.get("invoiceType"))
                      )?.invoice_title
                    : "",
                }
              }
            />
            <div className="border-r border-[#CCC]"></div>
            <Search
              placeholder="Search by cheque or transaction"
              height="8"
              onclick={handleSearch}
              value={searchParams.get("search")}
            />
            <div className="border-r border-[#CCC]"></div>
            <ButtonG label="Clear" className="h-8" onClick={handleClear} />
          </ListHeader>
        </div>

        <div className="ml-1">
          <DeleteReceiptsTable
            loading={loading}
            data={deletedReceiptList?.data?.map((item, index) => ({
              //sNo: (((deletedReceiptList?.current - 1) * deletedReceiptList?.limit + 1)+index)?.toString()?.padStart(2, "0"),
              id: item?.enc_key,
              sNo: (index + 1)?.toString()?.padStart(2, "0"),
              date:
                moment(item?.date, "YYYY-MM-DD hh:mm:ss").format(
                  "DD-MM-YYYY"
                ) || "-",
              unitNumber: item?.unit || "-",
              type: item?.modeofpayment || "-",
              amount: item?.amount || "-",
              userId: item?.email || "-",
              transactionRefNo:
                item?.cheque_number || item?.transaction_number || "-",
              userType: item?.modified_type || "-",
              ip: item?.IpAddress || "-",
              status: item?.status || "-",
            }))}
            columns={headCells}
            checkbox={false}
            pagination={deletedReceiptList?.data?.length}
            totalCount={deletedReceiptList?.paginate?.total_count}
            totalPages={deletedReceiptList?.paginate?.total_page}
            start={
              deletedReceiptList?.paginate?.current *
                deletedReceiptList?.paginate?.limit +
              1
            }
            end={
              deletedReceiptList?.paginate?.current *
                deletedReceiptList?.paginate?.limit +
              deletedReceiptList?.data?.length
            }
            currentPage={parseInt(deletedReceiptList?.paginate?.current)}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></DeleteReceiptsTable>

          {!deletedReceiptList?.data?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default DeleteReceipts;
