import React, { useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { TextArea } from "../../../../components/InputFields/TextArea";
import TextRadio from "../../../../components/InputFields/TextRadio";
import { useDispatch } from "react-redux";
import { rejectInvoicePayment } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import { useSelector } from "react-redux";
import { notify } from "../../../../utils/notification";
import { useNavigate } from "react-router-dom";
import {
  VIEW_MANAGE_DEBIT_NOTE_ENDPOINT,
  VIEW_MANAGE_INVOICE_CURRENT_ENDPOINT,
} from "../../../../Routing/routes";
import { rejectSingleReceiptDebitNote } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";

const RejectPaymentModal = ({
  isOpen,
  onCancel,
  text,
  onConfirm,
  loading,
  invoiceid,
  paymentInvoiceId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sendRejectEmail, setSendRejectEmail] = useState(false);
  const [reasonType, setReasonType] = useState("other");
  const [reason, setReason] = useState("");

  const { loginData } = useSelector((state) => state.loginReducer);
  const { updateLoader } = useSelector((state) => state.manageInvoiceReducer);
  const handleTimeOfDayChange = (value) => {
    setReasonType(value);
  };

  const handleTextAreaChange = (e) => {
    setReason(e.target.value);
  };

  const handleCheckboxChange = () => {
    setSendRejectEmail(!sendRejectEmail);
  };

  const handleConfirm = () => {
    if (reason !== "") {
      let data = {
        societyId: loginData?.default_community?.community_id,
        paymentInvoiceId,
        sendRejectEmail,
        reasonType: reasonType,
        reason: reason,
        onSuccessCallback: () => {
          onConfirm();
        },
      };
      dispatch(rejectSingleReceiptDebitNote(data));
    } else {
      notify("warning", "Please Enter reason");
    }
  };

  const ReasonforRejectPayment = [
    {
      name: "reasonReject",
      value: "chequeDishonour",
      label: "Cheque Dishonour",
    },
    { name: "reasonReject", value: "other", label: "Other" },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title="Reject Payment"
      onCancel={onCancel}
      onConfirm={handleConfirm}
      loading={updateLoader}
    >
      <div className="text-[16px] text-center">
        <div className="flex flex-col items-center gap-2">
          <div className="ml-[76px] mb-2">
            <TextRadio
              label=""
              options={ReasonforRejectPayment}
              defaultSelected="other"
              onChange={handleTimeOfDayChange}
              className={""}
            />
          </div>
          <TextArea
            label="Reason:"
            placeholder="Enter Reason"
            className="w-56 ml-4"
            row={4}
            onChange={handleTextAreaChange}
          />
        </div>
        <div className="mt-4">
          <label className="flex items-center justify-start text-sm">
            <Checkbox
              text="Send Email"
              initialchecked={sendRejectEmail}
              onChange={handleCheckboxChange}
            />
          </label>
        </div>
      </div>
    </Modal>
  );
};

export default RejectPaymentModal;
