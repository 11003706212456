import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../api";
import axiosInstance2 from "../../../axiosInstance2";
import { logOut } from "../../../../utils/helpers/logOut";
import { errorMsg } from "../../../../consts/responseMessages";

function handleApiError(errors) {
  console.log("errors", errors);
  const { statusCode, message } = errors.response?.data || {};

  if (statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(message)) {
    logOut();
  } else if (statusCode === 400 || statusCode === 401) {
    notify("error", message);
    if (statusCode === 401) logOut();
  } else {
    notify("warning", "Something went wrong");
  }
  throw errors;
  // return errors;
}

export async function contentManagementListReq(action) {
  return axiosInstance2
    .get(
      `${apiEndpoints.GET_CONTENT_MANAGEMENT_TEMPLATE_LIST_API}/${action.data.society_id}`
    )
    .then((response) => {
      return response;
    })
    .catch(handleApiError);
}



export async function updateContentManagementReq(action) {
  const BODY = {
    id: action.data.id,
    societyid: action.data.societyid,
    content: action.data.content,

  };
  console.log('body data in update',BODY)
    return axiosInstance2
    .put(apiEndpoints.UPDATE_CONTENT_MANAGEMENT_TEMPLATE_API, { ...BODY })
    .then((response) => {
      console.log('update response' , response)
      return response;
    })
    .catch(handleApiError);
}

export async function auditLogContentManagementReq(action) {

    console.log(`${apiEndpoints.AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE_API}?societyid=${action.data.societyid}&id=${action.data.id}`)
  return axiosInstance2
    .get(`${apiEndpoints.AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE_API}?societyid=${action.data.societyid}&id=${action.data.id}`)
    .then((response) => {
        console.log("response",response)
      return response;
    })
    .catch(handleApiError);
}
