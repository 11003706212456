import React, { useEffect, useState } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Button from "../../../components/Button/Button";
import Layout from "../../Layout/Layout";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceSeriesList,
  deleteInvoiceSeries,
} from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import AddInvoiceSeriesModal from "../../../components/Modal/AddInvoiceSeriesModal";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";

const InvoiceSeriesSetup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { typeId } = useParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const [seriesList, setSeriesList] = useState([]);
  const [addModal, setModal] = useState(false);
  const [deleteId, setdeleteId] = useState();
  const { loading, updateLoader, serierLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const headCells = [
    { id: "srno", label: "Sr. No.", sort: false },
    { id: "invoice_type", label: "Invoice Type", sort: false },
    { id: "from_date", label: "From Date", sort: false },
    { id: "to_date", label: "To Date", sort: false },
    { id: "prefix", label: "Prefix", sort: false },
    { id: "str_no", label: "Start Number", sort: false },
    { id: "suffix", label: "Suffix", sort: false },
    { id: "is_zero", label: "Is Zero Prepend", sort: false },
    { id: "no_digit", label: "Number Of Digit", sort: false },
    { id: "Action", label: "Action", sort: false },
  ];

  useEffect(() => {
    dispatch(
      getInvoiceSeriesList({
        societyId: loginData?.default_community?.community_id,
        typeId : typeId,
      })
    );
  }, [loginData]);
  useEffect(() => {
    if (serierLists) {
      setSeriesList(serierLists);
    }
  }, [serierLists]);
  // const handleSave = (values) => {
  //   dispatch(
  //     addMoreHead({
  //       societyId: loginData?.default_community?.community_id,
  //       headName: values.headName,
  //       glCode: values.glCode,
  //       hsnCode: values.hsnCode,
  //       type: values.type,
  //       amount: values.amount,
  //       formula: values.formula,
  //       onSuccessCallback: () => {
  //         navigate(-1);
  //       },
  //     })
  //   );
  // };
  const handleSlabDelete = (seriesId) => {
    setdeleteId(seriesId)
    dispatch(
      deleteInvoiceSeries({
        societyId: loginData?.default_community?.community_id,
        seriesId: seriesId,
        onSuccessCallback: () => {
          dispatch(
            getInvoiceSeriesList({
              societyId: loginData?.default_community?.community_id,
              typeId : typeId
            })
          );
          setdeleteId("")
        },
      })
    );
  };
  const handleNavigation = (path) => () => {
    navigate(path);
  };
  //console.log(seriesList);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
        <ListHeaderBack
              title="Invoice Series List"
              onClick={handleNavigation(-1)}
          >
          {getPermission(loginData, [permissions.ADD_INVOICE_SERIES_SETTINGS]) && <div className="flex gap-3">
              <span className="border-l border-[#CCC]"></span>
              <Button
                label="Add Invoice Series"
                className="h-8"
                onClick={() => setModal(true)}
                disabled={loading || updateLoader}
              />
            </div>}
          </ListHeaderBack>
         
        </div>
        <TableContainer
          className=" border-t border-[#CCC]"
          sx={{ minHeight: "500px" }}
        >
          <Table stickyHeader aria-label="sticky table" className="px-2">
            <TableHead>
              <TableRow>
                {headCells.map((column) => (
                  <TableCell
                    size="small"
                    align={column.numeric ? "right" : "left"}
                    sortDirection={false}
                    sx={{
                      pl: 0,
                      pr: 1,
                      fontWeight: 600,
                      zIndex: 0,
                      color: "#AAAAAA",
                      wordWrap: "break-word",
                      fontSize: "13px",
                      width: column.width || "fit-content",
                      minWidth: column.width || "fit-content",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                <TableCell colSpan={10}><Loader /></TableCell>
                </TableRow>
              ) : seriesList ? (
                seriesList.map((series, index) => {
                  return (
                    <TableRow>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {series.invoice_type}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {moment(new Date(series.from_date))?.format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {moment(new Date(series.to_date))?.format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {series.prefix}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {series.star_number}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {series.sufix}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {series.isZero == 1 ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {series.number_digit}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {getPermission(loginData, [permissions.DELETE_INVOICE_SERIES_SETTINGS]) && <Button
                          label={
                            <span className="inline-block w-12">
                              {deleteId===series.id ? (
                                <CircularProgress
                                  sx={{ color: "white" }}
                                  size={17}
                                />
                              ) : (
                                "Delete"
                              )}
                            </span>
                          }
                          className="h-8"
                          onClick={() => handleSlabDelete(series.id)}
                          disabled={loading || updateLoader}
                        />}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={10}
                  >
                    No Recorde Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <AddInvoiceSeriesModal
        isOpen={addModal}
        onClose={() => {
          setModal(false);
        }}
        typeId={typeId}
      />
    </Layout>
  );
};

export default InvoiceSeriesSetup;
