import * as Yup from "yup";
import "yup-phone-lite";

Yup.addMethod(Yup.array, "checkUnique", function (propertyName, message) {
  return this.test("unique", message, function (list) {
    const uniqueSet = new Set();
    const { path } = this;
    list.forEach((item, index)=>{
        if (uniqueSet.has(item[`${propertyName}`])) {
            throw this.createError({
                path: `${path}[${index}].${propertyName}`,
                message,
            });
            // return false;
          } else {
            uniqueSet.add(item[`${propertyName}`]);
          }
    })  
    return true;
  });
}
);

export const ChequeDepositSlipValidation = () => {
    return Yup.object({
        bankId: Yup.string(),
        wingId: Yup.string(),
        flatId: Yup.string(),
        accountNo: Yup.string(),
        memberName: Yup.string(),
        dateType: Yup.string(),
        fromDate: Yup.date(),
        toDate:  Yup.date(),
    });
   };
  

  export const MemberDueSummaryValidation = () => {
  return Yup.object({
      reportType: Yup.string().required("*Required"),
      invoiceType: Yup.string(),
      wingId: Yup.string(),
      flatId: Yup.string(),
      flatArea: Yup.string(),
      memberName: Yup.string(),
      toDate:  Yup.date(),
  });
  };
  


  