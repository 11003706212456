import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { EInvoiceStatusOptions } from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getBillTypeList,
  getEInvocieReports,
  getFinanceMembersList,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import EInvoiceReportTable from "./EInvoiceReportTable";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import moment from "moment";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { BILL_REPORTS_ENDPOINT } from "../../../../Routing/routes";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "60px" },
  {
    id: "irn",
    label: "IRN",
    width: "200px",
  },
  { id: "ackNo", label: "Ack No.", width: "90px" },
  { id: "ackDate", label: "Ack Date", width: "90px" },
  { id: "docNo", label: "Doc No.", width: "90px" },
  { id: "docDate", label: "Doc Date", width: "90px" },
  { id: "docType", label: "Doc Type", width: "90px" },
  { id: "invValue", label: "Inv Value", width: "90px" },
  { id: "recipientGstin", label: "Recipient GSTIN", width: "90px" },
  { id: "status", label: "Status", width: "90px" },
  { id: "actions", label: "Actions", width: "60px" },
];

const EInvoiceReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, billTypeList, eInvoiceReports } = useSelector(
    (state) => state.oldFinancialReportsReducers
  );

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${BILL_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };

  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("billType")) {
      searchParams.delete("billType");
      navigate({
        pathname: `${BILL_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["billType", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    // if(moment(selectedDate).format('YYYY-MM-DD') !== searchParams.get("startDate")){
    //   searchParams.delete('page')
    //   searchParams.delete('pageLimit')
    //   searchParams.delete("startDate")
    //   navigate({
    //     pathname: `${VIEW_BOOKINGS_ENDPOINT}`,
    //     search: `?${createSearchParams([...searchParams.entries(), ["startDate", moment(selectedDate).format('YYYY-MM-DD')]])}`,
    //   })
    // }
  };

  const handleEndDate = (selectedDate) => {
    // if(moment(selectedDate).format('YYYY-MM-DD') !== searchParams.get("endDate")){
    //   searchParams.delete('page')
    //   searchParams.delete('pageLimit')
    //   searchParams.delete("endDate")
    //   navigate({
    //     pathname: `${VIEW_BOOKINGS_ENDPOINT}`,
    //     search: `?${createSearchParams([...searchParams.entries(), ["endDate", moment(selectedDate).format('YYYY-MM-DD')]])}`,
    //   })
    // }
  };

  const handleClear = () => {
    navigate(BILL_REPORTS_ENDPOINT);
  };

  useEffect(() => {
    dispatch(
      getBillTypeList({ society_id: "S387", daybook_filter: "allType" })
    );

    dispatch(getFinanceMembersList({ society_registration: "MYAS387" }));

    dispatch(
      getEInvocieReports({
        society_id: "S387",
        // society_registration: "MYAS387",
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
        billType: searchParams.get("billType"),
        financeMemberList: searchParams.get("financeMemberList"),
        from_date: searchParams.get("from_date"),
        to_date: searchParams.get("to_date"),
      })
    );
  }, [searchParams]);

  return (
    <div className="">
      <div>
        <ListHeader leftContent="EInvoice Report">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
          />
          <span className="border-r border-[#CCC]"></span>
          <Dropdown2
            options={
              billTypeList?.map((item) => ({
                value: item?.adbregnno,
                label: item?.daybookname,
              })) || []
            }
            placeholder="Select Bill Type"
            className=""
            width="182px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectBillType(selectedItem);
            }}
            value={
              searchParams.get("billType") && {
                value: searchParams.get("billType"),
                label: billTypeList?.find(
                  (item) => item.adbregnno === searchParams.get("billType")
                )?.daybookname,
              }
            }
          />
          <Dropdown2
            options={EInvoiceStatusOptions}
            placeholder="Select IRN Status Type"
            className=""
            width="182px"
            height="32px"
            onSelect={(selectedItem) => {}}
            //   {...(formik.values.subCategory === "" && {
            //     value: null,
            //   })}
            name={"subCategory"}
          />
          <span className="border-r border-[#CCC]"></span>
          <RangeDatePicker
            className="w-44 h-8"
            // defaultStartValue={searchParams.get("startDate") ? new Date(searchParams.get("startDate")) : ''}
            // defaultEndValue={searchParams.get("endDate") ? new Date(searchParams.get("endDate")) : ''}
            onStartDateChange={handleStartDate}
            onEndDateChange={handleEndDate}
          />
        </ListHeader>
      </div>
      <div className="ml-1">
        <EInvoiceReportTable
          loading={loading}
          data={
            eInvoiceReports?.map((item, index) => ({
              sNo: (index+1)?.toString()?.padStart(2, '0'),
              irn: item?.['einvcr.irn'],
              ackNo: item?.['einvcr.ackno']?.value,
              ackDate: item?.['einvcr.ackdt'],
              docNo: item?.strbillnumber,
              docDate: item?.billdate,
              // docType: item?.BIB_recNo,
              invValue: item?.totalbillamt?.value,
              recipientGstin: item?.gstin_no,
              status: item?.BIB_adjustamt,
              actions: item?.BIB_amountonint,
            })) || []
          }
          columns={headCells}
          checkbox={false}
          // pagination={eInvoiceReports?.length}
          // totalCount={eInvoiceReports?.length}
          // totalPages={eInvoiceReports?.page}
          // start={eInvoiceReports?.current * eInvoiceReports?.limit + 1}
          // end={eInvoiceReports?.current * eInvoiceReports?.limit + eInvoiceReports?.length}
          // currentPage={(eInvoiceReports?.current) + 1}
          // rowsPerPage={searchParams.get("pageLimit") || 10}
        ></EInvoiceReportTable>
      </div>
      {!eInvoiceReports?.length && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )}
    </div>
  );
};

export default EInvoiceReport;
