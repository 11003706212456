import * as Yup from "yup";

  export const AddFacilityValidation = () => {
    return Yup.object({
        facilityName: Yup.string().required('*Required'),
        booking: Yup.string(),
        isChargeble: Yup.string()
            .when(['booking'], (booking, schema) => {
                return booking[0] === 'Applicable' ?
                schema.required("*Required")
                :
                schema
            }),
        chargeOn: Yup.string()
            .when(['booking'], (booking, schema) => {
                return booking[0] === 'Applicable' ?
                schema.required("*Required")
                :
                schema
            }),
        bookingAvailableUpto: Yup.string()
            .when(['booking'], (booking, schema) => {
                    return booking[0] === 'Applicable' ?
                    schema.required("*Required")
                    :
                    schema
                }),
        advanceBookingWindow: Yup.string(),
        securityDepositAmount: Yup.string()
            .when(['booking'], (booking, schema) => {
                    return booking[0] === 'Applicable' ?
                    schema.required("*Required")
                    :
                    schema
                }),
        securityDepositAccId: Yup.string()
            .when(['booking'], (booking, schema) => {
                    return booking[0] === 'Applicable' ?
                    schema.required("*Required")
                    :
                    schema
                }),
        facilityChargeAccId: Yup.string()
            .when(['booking'], (booking, schema) => {
                    return booking[0] === 'Applicable' ?
                    schema.required("*Required")
                    :
                    schema
                }),
        bankAccId: Yup.string()
            .when(['booking'], (booking, schema) => {
                    return booking[0] === 'Applicable' ?
                    schema.required("*Required")
                    :
                    schema
                }),
        bookingPerFlatCalendar: Yup.string().nullable(),
        bookingPerFlat: Yup.number()
            .when(['bookingPerFlatCalendar'], (bookingPerFlatCalendar, schema) => {
                return bookingPerFlatCalendar[0] ?
                    schema.required("*Required")
                    :
                    schema
            })
        ,
        bookingAutoApprove: Yup.string(),
        haveUnavailableDates: Yup.string(),
        unavailableDates: Yup.array().of(
            Yup.object().shape({
              date: Yup.string()
                .when(['haveUnavailableDates'], (haveUnavailableDates, schema) => {
                    return haveUnavailableDates[0] === 'Yes' ?
                    schema.required("*Required")
                    :
                    schema
                }),
            })
        ),
        facilityManagerId: Yup.string(),
        employeeId: Yup.array().of(
            Yup.string()
        ),
        managingCommitteId: Yup.string(),
        managingCommitteMemberId: Yup.array().of(
            Yup.string()
        ),
        vendorCategoryId: Yup.string(),
        vendorId: Yup.string(),
        concernedPersonId: Yup.array().of(
            Yup.string()
        ),
        attachments: Yup.string(),
        guidance: Yup.string(),
        description: Yup.string()
    });
  };