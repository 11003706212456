import React, { useEffect, useState } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Search from "../../../components/SearchBox/Search";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import Layout from "../../Layout/Layout";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { DownloadExt } from "../../../assets";
import RangeDatePicker from "../../../components/DatePicker/RangeDatePicker";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  downloadDebitNotesReport,
  getDebitNotesReport,
} from "../../../redux/actions/FinanceActions/BillingReportsAction";
import DebitNoteHistoryTable from "./DebitNoteHistoryTable";
import { validateDate } from "../../../utils/helpers/universalFunctions";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  DEBIT_NOTE_HISTORY_ENDPOINT,
  PRINT_DEBIT_NOTE_HISTORY_ENDPOINT,
} from "../../../Routing/routes";
import ButtonG from "../../../components/Button/ButtonG";
import { downloadExcel } from "../../../utils/helpers/downloadMedia";
import { CircularProgress } from "@mui/material";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";
import { getInvoiceTypeList } from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

export const headCells = [
  { id: "sNo", label: "Sr. No.", width: "80px" },
  { id: "date", label: "Date", sort: false, width: "80px" },
  { id: "unitNumber", label: "Unit Number", sort: true, width: "120px" },
  { id: "type", label: "Type", sort: true, width: "90px" },
  { id: "number", label: "Cheque Number", sort: true, width: "140px" },
  {
    id: "transaction_number",
    label: "Transaction Number",
    sort: false,
    width: "160px",
  },
  {
    id: "transactionRefNo",
    label: "Ref No.",
    sort: true,
    width: "100px",
  },
  { id: "status", label: "Status", sort: true, width: "95px" },
  { id: "amount", label: "Amount" },
];

const rows = [];

const DebitNoteHistory = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { downLoader } = useSelector(
    (state) => state.downloadDebitNotesReportReducer
  );
  const { loading, debitNotesReportList } = useSelector(
    (state) => state.billingReportsReducer
  );
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("transactionId")) {
      searchParams.delete("page");
      searchParams.delete("transactionId");
      navigate({
        pathname: DEBIT_NOTE_HISTORY_ENDPOINT,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["transactionId", text?.trim()],
        ])}`,
      });
    } else if (!text) {
      searchParams.delete("transactionId");
      navigate({
        pathname: DEBIT_NOTE_HISTORY_ENDPOINT,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };

  const handelSelectInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("typeid");
    navigate({
      pathname: `${DEBIT_NOTE_HISTORY_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["typeid", selectedItem.value],
      ])}`,
    });
  };

  const handelSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${DEBIT_NOTE_HISTORY_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handelSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${DEBIT_NOTE_HISTORY_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectMode = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("modeOfPayment")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("modeOfPayment");
      navigate({
        pathname: `${DEBIT_NOTE_HISTORY_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["modeOfPayment", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectSearchBy = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("searchBy")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("searchBy");
      navigate({
        pathname: `${DEBIT_NOTE_HISTORY_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["searchBy", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("fromDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("fromDate");
      navigate({
        pathname: `${DEBIT_NOTE_HISTORY_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["fromDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("toDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("toDate");
      navigate({
        pathname: `${DEBIT_NOTE_HISTORY_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["toDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(DEBIT_NOTE_HISTORY_ENDPOINT);
  };

  const handlePrint = () => {
    window.open(
      `${PRINT_DEBIT_NOTE_HISTORY_ENDPOINT}?${createSearchParams([
        ...searchParams.entries(),
      ])}`
    );
  };

  const handleDownloadExcel = (flag) => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Debit Note History.xlsx` });
    };
    const data = {
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      pageLimit: searchParams.get("pageLimit") || 10,
      transactionId: searchParams.get("transactionId") || "",
      modeOfPayment: searchParams.get("modeOfPayment") || "",
      InvoiceType: searchParams.get("typeid") || 1,
      fromDate: searchParams.get("fromDate") || "",
      toDate: searchParams.get("toDate") || "",
      wingId: searchParams.get("wingId") || "",
      flatId: searchParams.get("flatId") || "",
      searchBy: searchParams.get("searchBy") || "",
      onSucessCallback,
    };
    dispatch(downloadDebitNotesReport(data));
  };

  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 0,
      pageLimit: searchParams.get("pageLimit") || 10,
      transactionId: searchParams.get("transactionId") || "",
      modeOfPayment: searchParams.get("modeOfPayment") || "",
      InvoiceType: searchParams.get("typeid") || 1,
      fromDate: searchParams.get("fromDate") || "",
      toDate: searchParams.get("toDate") || "",
      wingId: searchParams.get("wingId") || "",
      flatId: searchParams.get("flatId") || "",
      searchBy: searchParams.get("searchBy") || "",
    };

    dispatch(getDebitNotesReport(data));
  };

  useEffect(() => {
    getTableData();
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      getWingsAndFlatDetails({
        societyId: loginData?.default_community?.community_id,
      })
    );

    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );

    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: searchParams.get("wingId"),
        })
      );
    }
  }, []);

  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists);
    }
  }, [typeLists]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Debit Note History">
            {getPermission(loginData, [permissions.HISTORY_DEBIT_NOTE]) && (
              <div className="flex text-sm gap-3">
                <ButtonIco
                  icon={faPrint}
                  onClick={handlePrint}
                  children="Print"
                />
                <div className="border-r border-[#CCC]"></div>
                {downLoader ? (
                  <ButtonIco
                    className=" w-32 justify-center"
                    children={
                      <CircularProgress sx={{ color: "#d32f2f" }} size={17} />
                    }
                  />
                ) : (
                  <ButtonIco
                    icon={DownloadExt}
                    children="Download Excel"
                    onClick={handleDownloadExcel}
                  />
                )}
              </div>
            )}
          </ListHeader>
        </div>
        <>
          <div className="py-1 flex flex-col justify-between gap-2 my-1">
            <div className="p-1 flex flex-row justify-between items-center gap-4 mt-1">
              <Search
                height="8"
                onclick={handleSearch}
                value={searchParams.get("transactionId")}
                placeholder="Search Ref No."
              />
              <div className="flex justify-between items-center gap-3">
                <div className="text-sm">Invoice Type</div>
                <Dropdown2
                  options={
                    invoiceTypeList
                      ? invoiceTypeList?.map((item) => ({
                          label: item?.invoice_title,
                          value: item?.id,
                        }))
                      : []
                  }
                  placeholder="Invoice type"
                  className="text-[11px] ms-4"
                  width="172px"
                  height="32px"
                  onSelect={(selectedItem) => {
                    handelSelectInvoiceType(selectedItem);
                  }}
                  value={
                    searchParams.get("typeid") && {
                      value: searchParams.get("typeid"),
                      label: invoiceTypeList
                        ? invoiceTypeList?.find(
                            (item) =>
                              item?.id === parseInt(searchParams.get("typeid"))
                          )?.invoice_title
                        : "",
                    }
                  }
                />
              </div>
              <div className="flex justify-between items-center gap-3">
                <div className="text-sm">Search By</div>
                <Dropdown2
                  options={
                    (debitNotesReportList?.date_filters_list &&
                      Object.entries(
                        debitNotesReportList?.date_filters_list
                      )?.map((item) => ({
                        value: item?.[0],
                        label: item?.[1],
                      }))) ||
                    []
                  }
                  placeholder="Search By"
                  className="text-[11px] ms-4"
                  width="160px"
                  height="32px"
                  onSelect={(selectedItem) => {
                    handleSelectSearchBy(selectedItem);
                  }}
                  value={
                    debitNotesReportList?.date_filters_list &&
                    searchParams.get("searchBy")
                      ? {
                          value: searchParams.get("searchBy"),
                          label: Object.entries(
                            debitNotesReportList?.date_filters_list
                          )?.find(
                            (item) => item[0] === searchParams.get("searchBy")
                          )?.[1],
                        }
                      : null
                  }
                />
                <div className="flex justify-between gap-3 items-center">
                  <RangeDatePicker
                    className="w-48 h-8"
                    defaultStartValue={
                      searchParams.get("fromDate")
                        ? new Date(searchParams.get("fromDate"))
                        : ""
                    }
                    defaultEndValue={
                      searchParams.get("toDate")
                        ? new Date(searchParams.get("toDate"))
                        : ""
                    }
                    onStartDateChange={handleStartDate}
                    onEndDateChange={handleEndDate}
                  />
                </div>
              </div>
            </div>
            <div className="p-1 flex items-center gap-2">
              <Dropdown2
                options={
                  wingsData?.map((item) => ({
                    value: item?.wing_id,
                    label: item?.number,
                  })) || []
                }
                placeholder="Select Wing"
                className="text-[11px] ms-4"
                width="172px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectWing(selectedItem);
                }}
                value={
                  searchParams.get("wingId") && {
                    value: searchParams.get("wingId"),
                    label: wingsData?.find(
                      (item) =>
                        item.wing_id?.toString() === searchParams.get("wingId")
                    )?.number,
                  }
                }
              />
              <Dropdown2
                options={
                  (searchParams.get("wingId") &&
                    flatsData?.manage_flat_details?.map((item) => ({
                      value: item?.flat_id,
                      label: item?.flat_no,
                    }))) ||
                  []
                }
                placeholder="Select Flat"
                className="text-[11px] ms-4"
                width="172px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectFlat(selectedItem);
                }}
                value={
                  searchParams.get("flatId") && {
                    value: searchParams.get("flatId"),
                    label: flatsData?.manage_flat_details?.find(
                      (item) =>
                        item.flat_id?.toString() === searchParams.get("flatId")
                    )?.flat_no,
                  }
                }
              />
              <Dropdown2
                options={
                  (debitNotesReportList?.available_payment_modes &&
                    Object.entries(
                      debitNotesReportList?.available_payment_modes
                    )?.map((item) => ({
                      value: item?.[1]?.title,
                      label: item?.[1]?.title,
                    }))) ||
                  []
                }
                placeholder="Payment Mode"
                className="text-[11px] ms-4"
                width="172px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleSelectMode(selectedItem);
                }}
                value={
                  searchParams.get("modeOfPayment") && {
                    value: searchParams.get("modeOfPayment"),
                    label: searchParams.get("modeOfPayment"),
                  }
                }
              />
              <ButtonG label="Clear" className="h-8" onClick={handleClear} />
            </div>
          </div>
          <div className="ml-1">
            <DebitNoteHistoryTable
              loading={loading}
              data={debitNotesReportList?.debitNote_history?.map(
                (item, index) => ({
                  sNo: (
                    debitNotesReportList?.paginate?.current *
                      debitNotesReportList?.paginate?.limit +
                    1 +
                    index
                  )
                    ?.toString()
                    ?.padStart(2, "0"),
                  date: validateDate(item?.payment_date)
                    ? moment(new Date(item?.payment_date))?.format("DD-MM-YYYY")
                    : "-",
                  unitNumber:
                    `${item?.wing?.number}-${item?.flat?.flat_no}` || "-",
                  type: item?.modeofpayment || "-",
                  number: item?.cheque_number || "-",
                  transaction_number: item?.transaction_number || "-",
                  transactionRefNo: item?.transactionid || "-",
                  status: item?.status || "-",
                  amount: item?.grandtotal,
                })
              )}
              columns={headCells}
              checkbox={false}
              pagination={debitNotesReportList?.debitNote_history?.length}
              totalCount={debitNotesReportList?.paginate?.total_count}
              totalPages={debitNotesReportList?.paginate?.total_page}
              start={
                debitNotesReportList?.paginate?.current *
                  debitNotesReportList?.paginate?.limit +
                1
              }
              end={
                debitNotesReportList?.paginate?.current *
                  debitNotesReportList?.paginate?.limit +
                debitNotesReportList?.debitNote_history?.length
              }
              currentPage={parseInt(debitNotesReportList?.paginate?.current)}
              rowsPerPage={searchParams.get("pageLimit") || 10}
              getTableData={getTableData}
            ></DebitNoteHistoryTable>

            {!debitNotesReportList?.debitNote_history?.length && !loading && (
              <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                No Records
              </div>
            )}
          </div>
        </>
      </div>
    </Layout>
  );
};

export default DebitNoteHistory;
