import React, { useEffect } from "react";
import ListHeader from "../../../../../components/ListHeader/ListHeader";

import ButtonG from "../../../../../components/Button/ButtonG";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { INVOICE_AUDIT_REPORT_ENDPOINT } from "../../../../../Routing/routes";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import { getInvoiceAuditReport } from "../../../../../redux/actions/ReportActions/FinanceReportActions";
import CreditNoteTable from "./CreditNoteTable";

export const headCells = [
  { id: "item", label: "Items:", width: "33.33%" },
  { id: "oldData", label: "Old Data", width: "33.33%" },
  { id: "newData", label: "New Data", width: "33.33%" },
];

const CreditNote = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, invoiceAuditReport } = useSelector(
    (state) => state.financeReportReducer
  );

  useEffect(() => {
    dispatch(
      getInvoiceAuditReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("memberName") || "",
        invoiceType: searchParams.get("invoiceType") || "",
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        showType: "credit-edit-audit",
      })
    );
  }, [searchParams]);

  return (
    <div className="mt-1">
      <div className="">
        <ListHeader leftContent="Credit Note Audit Log"></ListHeader>
      </div>
      <div className="ml-1">
        <CreditNoteTable
          loading={loading}
          data={invoiceAuditReport?.data}
          columns={headCells}
          checkbox={false}
          pagination={true}
        ></CreditNoteTable>
      </div>
      {/* {!loading && !invoiceAuditReport?.length && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )} */}
    </div>
  );
};

export default CreditNote;
