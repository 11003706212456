import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { downloadCountedInvoiceReport, getCountedInvoice } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import { validateDate } from "../../../utils/helpers/universalFunctions";
import parse from 'html-react-parser';
import { DownloadExt, PrinterIcon } from "../../../assets";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { downloadFileFromURL } from "../../../utils/helpers/downloadMedia";
import { CircularProgress } from "@mui/material";

export const headingsCells = [
  { id: 'sNo', label: 'S No.', width: '45px' },
  { id: 'billNo', label: 'Bill No.', sort: true, width: '100px' },
  { id: 'wingFlat', label: 'Wing No. & Flat No.', sort: true, width: '100px' },
  { id: 'memberName', label: 'Member Name', sort: true, width: '80px' },
  { id: 'propertyTax', label: 'Property Tax', sort: true, width: '90px' },
  { id: 'transNo', label: 'Water Charges', sort: true, width: '100px' },
  { id: 'status', label: 'Common Electricity Charges', sort: true, width: '100px' },
  { id: 'amount', label: 'Expenses on Lift Maintenance', sort: true, width: '80px' },
  { id: 'amount', label: 'Repairs & Maintenance Fund', sort: true, width: '80px' },
  { id: 'amount', label: 'Sinking Fund Contribution', sort: true, width: '80px' },
  { id: 'amount', label: 'Service Charges- House Keeping', sort: true, width: '80px' },
  { id: 'amount', label: 'Service Charges- Office & Genral Charges', sort: true, width: '80px' },
  { id: 'amount', label: 'Service Charges- Printing & Stationery', sort: true, width: '80px' },
  { id: 'amount', label: 'Security Charges', sort: true, width: '80px' },
  { id: 'amount', label: 'Parking Charges', sort: true, width: '80px' },
  { id: 'amount', label: 'Interest on the defaulted Charges', sort: true, width: '80px' },
  { id: 'amount', label: 'Repayment of Loan and Interest', sort: true, width: '80px' },
  { id: 'amount', label: 'Non Occupancy Charges', sort: true, width: '80px' },
  { id: 'amount', label: 'Insurance Charges', sort: true, width: '80px' },
  { id: 'amount', label: 'Lease Rent', sort: true, width: '80px' },
  { id: 'amount', label: 'Non Agricultural Tax', sort: true, width: '80px' },
  { id: 'amount', label: 'Other Charges- Function Ch.', sort: true, width: '80px' },
  { id: 'amount', label: 'Other Charges', sort: true, width: '80px' },
  { id: 'amount', label: 'Major Repair Fund', sort: true, width: '80px' },
  { id: 'amount', label: 'Painting Fund', sort: true, width: '80px' },
  { id: 'amount', label: 'Service Charge', sort: true, width: '80px' },
  { id: 'amount', label: 'SGST', sort: true, width: '80px' },
  { id: 'amount', label: 'Water Tax', sort: true, width: '80px' },
  { id: 'amount', label: 'Other Tax', sort: true, width: '80px' },
  { id: 'amount', label: 'Debit Note', sort: true, width: '80px' },
  { id: 'amount', label: 'Cheque Return Charges', sort: true, width: '80px' },
  { id: 'amount', label: 'Other1', sort: true, width: '80px' },
  { id: 'amount', label: 'Other2', sort: true, width: '80px' },
  { id: 'amount', label: 'Other3', sort: true, width: '80px' },
  { id: 'amount', label: 'Other4', sort: true, width: '80px' },
  { id: 'amount', label: 'Parking charge', sort: true, width: '80px' },
  { id: 'amount', label: 'Sub Total', sort: true, width: '80px' },
  { id: 'amount', label: 'Principal Arrears', sort: true, width: '80px' },
  { id: 'amount', label: 'Debitnote Arrears', sort: true, width: '80px' },
  { id: 'amount', label: 'Interest Arrears', sort: true, width: '80px' },
  { id: 'amount', label: 'Current Period Interest', sort: true, width: '80px' },
  { id: 'amount', label: 'Past Advances & Dues', sort: true, width: '80px' },
  { id: 'amount', label: 'Grand Total', sort: true, width: '80px' }
];

const CountedInvoiceReport = () => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { printLoader, countedInvoiceReport } = useSelector(
    (state) => state.billingReportsReducer
  );
  const { downLoader } = useSelector((state) => state.downloadBillingReportsReducer)

  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      export: 0,
    }

    dispatch(getCountedInvoice(data))
  }

  const handleDownloadExcel = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      export: 1,
      onSuccessCallback: (url) => {
        downloadFileFromURL({ url, fileName: "sample.csv" });
      },
    }
    dispatch(downloadCountedInvoiceReport(data));
  };

  useEffect(() => {

    getTableData()

    const afterprint = (e) => {
      e.preventDefault()
    };

    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    }
  }, [])

  // useEffect(() => {
  //   if (!printLoader && countedInvoiceReport) {
  //     setTimeout(function () { window.print(); }, 2000);
  //   }
  // }, [dispatch]);

  return (
    <>
      {
        printLoader || !countedInvoiceReport ?
          <Loader />
          :
          <>
            <div className="flex justify-end p-2 drop-shadow-sm gap-2 noprint">
              <button
                type="button"
                onClick={() => window.print()}
                className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
              >
                <PrinterIcon className="mr-2 fill-red-650" /> Print
              </button>
              <div className="border-r border-[#CCC]"></div>
              {downLoader ?
                <ButtonIco className=" w-32 justify-center" children={<CircularProgress sx={{ color: "#d32f2f" }} size={17} />} />
                :
                <ButtonIco icon={DownloadExt} children='Download Excel' onClick={handleDownloadExcel} />
              }
            </div>
            {typeof countedInvoiceReport === 'string' && countedInvoiceReport.trim() !== '' ?
              <>
                {parse(countedInvoiceReport)}
              </>
              :
              <div className="text-lg text-[#AAAAAA] mt-10 text-center italic">
                No Records
              </div>
            }
          </>
      }
    </>
  );
};

export default CountedInvoiceReport;

{/* <div className=" p-1">
  <table className="table-auto w-full mx-auto border-collapse border border-black">
    <thead>
      <tr>
        {
          headingsCells?.map((item, index) => (
            <td className={`text-sm p-1 h-16 font-semibold text-[14px] border border-black w-[7%]`} key={index}>
              {item?.label}
            </td>
          ))
        }
      </tr>
    </thead>
    <tbody>
      {
        countedInvoiceReport?.debitNote_history?.map((item, index) => (
          <tr className="text-[#222] text-sm" key={index}>
            <td className={`p-1 border border-black align-top w-[5%]`}>{(index + 1)?.toString()?.padStart(2, '0')}</td>

            <td className={`p-1 border border-black align-top w-[13%]`}>{validateDate(item?.payment_date) ? moment(new Date(item?.payment_date))?.format('DD-MM-YYYY') : '-'}</td>
            <td className={`p-1 border border-black align-top w-[13%]`}>{`${item?.wing?.number}-${item?.flat?.flat_no}` || '-'}</td>
            <td className={`p-1 border border-black align-top w-[13%]`}>{item?.modeofpayment || '-'}</td>
            <td className={`p-1 border border-black align-top w-[13%]`}>{item?.cheque_number || '-'}</td>
            <td className={`p-1 border border-black align-top w-[13%]`}>{item?.transactionid || '-'}</td>
            <td className={`p-1 border border-black align-top w-[13%]`}>{item?.status || '-'}</td>
            <td className={`p-1 border border-black align-top w-[13%]`}>{item?.grandtotal}</td>
          </tr>
        ))
      }

    </tbody>
  </table>
  {!countedInvoiceReport?.debitNote_history?.length && !printLoader && (
    <div className="text-sm my-10 text-center italic">
      No Records
    </div>
  )}
</div> */}
