import React, { useEffect, useState } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import ButtonG from "../../../../components/Button/ButtonG";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getSocietyAccessSetting,
  updateSocietyAccessSetting,
} from "../../../../redux/actions/SetupRecordsActions/ManageRolesAndRightsActions/ManageRolesAndRightsAction";
import { CircularProgress } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";

const settingOptions = [
  {
    label: "Finance ",
    key: "payment",
  },
  {
    label: "Gate Management ",
    key: "vms",
  },
  {
    label: "Complaints ",
    key: "help_desk",
  },
  {
    label: "Announcement ",
    key: "announcement",
  },
  {
    label: "Social & Communciations ",
    key: "missed_call_feature",
  },
  {
    label: "Gallery ",
    key: "album",
  },
  {
    label: "Vault ",
    key: "vault",
  },
  {
    label: "Facility Management ",
    key: "booking",
  },
  {
    label: "ZipBox ",
    key: "zip_box",
  },
];

function CheckboxGroup({ viewListRole, setViewListRoles }) {
  const handleSingleCheckbox = (e, item) => {
    setViewListRoles((prev) => {
      let list = { ...prev };
      list["service_config"][item.key] = !list["service_config"][item.key];
      return list;
    });
  };

  return (
    <>
      {settingOptions?.map((item, index) => {
        return (
          <div key={index} className="mt-3">
            <Checkbox
              initialchecked={viewListRole?.service_config?.[item.key]}
              text={item.label}
              onChange={(e) => {
                handleSingleCheckbox(e, item);
              }}
            />
          </div>
        );
      })}
    </>
  );
}

const ManageSocietyRoles = () => {
  const [viewListRole, setViewListRoles] = useState({
    zip_mart_promotion: false,
    service_config: {
      zip_box: false,
      album: false,
      help_desk: false,
      booking: false,
      vault: false,
      vehicle_management: false,
      missed_call_feature: false,
      announcement: false,
      payment: false,
      other_services: false,
      zip_chat: false,
      zip_mart: [],
      vms: false,
    },
    zip_box_promotion: false,
  });
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, updateLoader, societyAccessSetting } = useSelector(
    (state) => state.manageRoleAndRightsReducer
  );

  const reset = () => {
    setViewListRoles(JSON.parse(societyAccessSetting?.settings));
  };

  const handleSave = () => {
    dispatch(
      updateSocietyAccessSetting({
        societyId: loginData?.default_community?.community_id,
        accessSetting: JSON.stringify(viewListRole),
      })
    );
  };

  useEffect(() => {
    dispatch(
      getSocietyAccessSetting({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  useEffect(() => {
    if (societyAccessSetting?.settings) {
      setViewListRoles(JSON.parse(societyAccessSetting?.settings));
    }
  }, [societyAccessSetting]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Manage Society Modules">
            <div className="border-r border-[#CCC]"></div>
            <ButtonG label="Reset" className="px-3" onClick={reset} />
            <Button
              label={
                <span className="w-12 inline-block">
                  {updateLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    "Save"
                  )}
                </span>
              }
              onClick={handleSave}
              disabled={loading || updateLoader}
            />
          </ListHeader>
        </div>
        <div>
          {loading ? (
            <Loader />
          ) : (
            <div className="grid grid-cols-3 mx-2 text-sm gap-y-1.5">
              <CheckboxGroup
                viewListRole={viewListRole}
                setViewListRoles={setViewListRoles}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ManageSocietyRoles;
