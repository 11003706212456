import React from "react";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { Form, Formik } from "formik";
import { updateMasterHeadDetails } from "../../../../redux/actions/FinanceActions/SettingActions/InvoiceDetailsAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { areaTypeOptions, invoiceDurationOptions } from "../../../../consts/DropDownOptions";
import { InvoiceDetailsValidation } from "../../../../validationSchema/financeSchema/settingSchema";
import { MultiSelectFM } from "../../../../components/MultiSelect/MultiSelect";

const InvoiceDetailsForm = ({ formRef, invoiceDetails, wingId }) => {
  const { loginData } = useSelector(state => state.loginReducer)
  const dispatch = useDispatch()
  const nocPeriodList = [
    {
      label: "Days",
      value: "day",
    },
    {
      label: "Months",
      value: "month",
    },
  ]

  return (
    <Formik
      initialValues={{
       // duration: invoiceDetails?.bill_duration || '',
        annualPropertyTax: invoiceDetails?.annual_property_tax || '',
        useAnnualPropertyTax: invoiceDetails?.useTax || 'No',
        wingStatus: invoiceDetails?.statusWing || '',
        buildUpArea: invoiceDetails?.built_up_area || '',
        noOfFlats: invoiceDetails?.no_of_flats || '',
        annualWaterCharge: invoiceDetails?.annual_water_charges || '',
        noOfInlets: invoiceDetails?.no_of_water_inlets || '',
        annualSecurityCharge: invoiceDetails?.annual_security_charges || '',
        totalHouseKeeping: invoiceDetails?.total_house_keeping || '',
        totalAnnualLiftMaintenance: invoiceDetails?.annual_lift_maintenance || '',
        electricityCharge: invoiceDetails?.electricity_charges || '',
        constructionCost: invoiceDetails?.construction_cost || '',
        sinkingFund: invoiceDetails?.sinking_fund || '',
        insuranceCharge: invoiceDetails?.annual_insurance_charges || '',
        leaseRent: invoiceDetails?.annual_lease_rent || '',
        nonAgriculturalTax: invoiceDetails?.annual_agricultural_tax || '',
        repairFund: invoiceDetails?.repairs_maintenance || '',
        otherFunds: invoiceDetails?.other_funds || '',
        serviceCharges: invoiceDetails?.service_charges || '',
        // nocPercentage: invoiceDetails?.noc_percentage || 0,
        // nocHead: invoiceDetails?.noc_head || [],
        // nocPeriod: invoiceDetails?.noc_period || 'day'
      }}
      innerRef={(f) => (formRef.current = f)}
      onSubmit={values =>
        dispatch(updateMasterHeadDetails({ ...values, wingId, societyId: loginData?.default_community?.community_id }))
      }
      enableReinitialize
    //validationSchema={InvoiceDetailsValidation}
    >
      {
        ({ values, setFieldValue }) => {
          return (
            <Form>{console.log(values)}
              <div className="w-1/2 mx-1 mt-4">
               
                <TextInputFM
                  label="Total Annual Property Tax (Rs.)	"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='annualPropertyTax'
                  type='decimal'
                  maxLength={15}
                />
                <div className="flex w-full mb-4 -mt-3">
                  <div className="w-1/2"></div>
                  <div className="w-1/2 ml-9">
                    <Checkbox
                      text="Use total Annual Property Tax"
                      className="text-xs"
                      initialchecked={values.useAnnualPropertyTax === "Yes"}
                      onChange={(e) => setFieldValue('useAnnualPropertyTax', e.target.checked ? 'Yes' : 'No')}
                    />
                  </div>
                </div>
                <div className="text-[#AAAAAA] text-[13px] mb-3 text-left whitespace-nowrap">
                  Note : You can uncheck the checkbox, if you want to enter Flat wise
                  property tax in the Flat management section.
                </div>
                <div className="flex">
                  <div className="basis-full">
                    <TextInputFM
                      label={`Total ${areaTypeOptions?.find(item => item.value === loginData?.default_community?.builtup_option)?.label || ""}`}
                      placeholder="Enter Area"
                      className="w-44"
                      name='buildUpArea'
                      type='decimal'
                      maxLength={15}
                    />
                  </div>
                  <span className="ps-2.5 w-12 mt-2 text-xs text-nowrap">
                    {loginData?.default_community?.const_unit}
                  </span>
                </div>
                <TextInputFM
                  label="Total Number of Flats"
                  placeholder="Enter Number Of Flats"
                  className="w-56"
                  name='noOfFlats'
                  type='number'
                  maxLength={5}
                  disabled={true}
                />
                <TextInputFM
                  label="Annual Water Charges (Rs.)"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='annualWaterCharge'
                  type='decimal'
                  maxLength={15}
                />
                <TextInputFM
                  label="Number of Water Inlets"
                  placeholder="Enter Number "
                  className="w-56"
                  name='noOfInlets'
                  type='number'
                  maxLength={5}
                />
                <TextInputFM
                  label="Annual Security Charges (Rs.)"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='annualSecurityCharge'
                  type='decimal'
                  maxLength={15}
                />
                <TextInputFM
                  label="Annual Maintenance Charges (Rs.)	"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='totalHouseKeeping'
                  type='decimal'
                  maxLength={15}
                />
                <TextInputFM
                  label="Annual Lift Charges (Rs.)	"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='totalAnnualLiftMaintenance'
                  type='decimal'
                  maxLength={15}
                />
                <TextInputFM
                  label="Common Electricity Charges (Rs.)	"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='electricityCharge'
                  type='decimal'
                  maxLength={15}
                />
                <TextInputFM
                  label="Construction Cost Per Unit (Rs.)"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='constructionCost'
                  type='decimal'
                  maxLength={15}
                />
                <TextInputFM
                  label="Sinking Fund (%)	"
                  placeholder="Enter Rate"
                  className="w-56"
                  name='sinkingFund'
                  type='decimal'
                  maxLength={10}
                />
                <TextInputFM
                  label="Repair Fund (%)	"
                  placeholder="Enter Rate"
                  className="w-56"
                  name='repairFund'
                  type='decimal'
                  maxLength={15}
                />
                <TextInputFM
                  label="Other Funds (%)	"
                  placeholder="Enter Rate"
                  className="w-56"
                  name='otherFunds'
                  type='decimal'
                  maxLength={15}
                />
                <TextInputFM
                  label="Annual Insurance Charges (Rs.)	"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='insuranceCharge'
                  type='decimal'
                  maxLength={15}
                />
                <TextInputFM
                  label="Annual Lease Rent (Rs.)"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='leaseRent'
                  type='decimal'
                  maxLength={15}
                />
                <TextInputFM
                  label="Annual Non Agricultural Tax (Rs.)	"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='nonAgriculturalTax'
                  type='decimal'
                  maxLength={15}
                />
                <TextInputFM
                  label="Annual Other Charges (Rs.)	"
                  placeholder="Enter Amount"
                  className="w-56"
                  name='serviceCharges'
                  type='decimal'
                  maxLength={15}
                />
               
              </div>
            </Form>
          )
        }
      }
    </Formik>
  );
};

export default InvoiceDetailsForm;
