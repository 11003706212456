import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import { DeleteIcon, EditIcon, SortIcon, ViewIcon } from "../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../utils/helpers/universalFunctions";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { CircularProgress, Tooltip } from "@mui/material";
import {
  EDIT_OCCUPANT_ROLES_ENDPOINT,
  VIEW_OCCUPANT_ROLES_ENDPOINT,
} from "../../Routing/routes";
import DeleteModal from "../Modal/DeleteModal";
import ChangeStatusModal from "../Modal/ChangeStatusModal";
import { useSelector } from "react-redux";
import {
  deleteEmployeeOccupantAndFamilyRole,
  updateRoleAndRightsStatus,
} from "../../redux/actions/SetupRecordsActions/ManageRolesAndRightsActions/ManageRolesAndRightsAction";
import { useDispatch } from "react-redux";
import Dropdown2 from "../Dropdown/dropdown2";
import Button from "../Button/Button";
import { getPermission } from "../../utils/helpers/getPermissions";
import * as permissions from "../../Routing/permissions";

function ActionComponent({ id, setSelected }) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { loginData } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    let data = {
      ids: [id],
      societyId: loginData?.default_community?.community_id,
      flag: "occupant",
      onSuccessCallback: () => {
        setShowDeleteModal(false);
        setSelected([]);
      },
    };
    dispatch(deleteEmployeeOccupantAndFamilyRole(data));
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="View" arrow>
        <ViewIcon
          className="fill-[#555] hover:fill-red-650 me-2.5"
          onClick={() => {
            navigate(`${VIEW_OCCUPANT_ROLES_ENDPOINT}/${id}`);
          }}
        />
      </Tooltip>
      <Tooltip title="Edit" arrow>
        <EditIcon
          className="fill-[#555] hover:fill-red-650 me-2.5"
          onClick={() => {
            navigate(`${EDIT_OCCUPANT_ROLES_ENDPOINT}/${id}`);
          }}
        />
      </Tooltip>
      {getPermission(loginData, [permissions.DELETE_MEMBER_TITLE]) && (
        <Tooltip title="Delete" arrow>
          <DeleteIcon
            className="fill-[#555] hover:fill-red-650 me-3"
            onClick={() => handleDeleteClick()}
          />
        </Tooltip>
      )}
      {/* Delete Confirmation Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
      />
    </Box>
  );
}

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const ManageOccupantsRolesTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
}) => {
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([]);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState({
    id: "",
    open: false,
  });
  const dispatch = useDispatch();
  const [action, setAction] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { updateLoader } = useSelector(
    (state) => state.manageRoleAndRightsReducer
  );

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked
      ? data.map((item) => item.occupantRoleId)
      : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    // searchParams.delete("page")
    navigate({
      pathname: `${""}`,
      // search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      // searchParams.delete("pageLimit")
      navigate({
        pathname: `${""}`,
        // search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      });
    }
  };

  const handleChangeStatus = (id, status) => {
    let data = {
      id,
      flag: "occupant",
      status: status === "Active" ? "I" : "A",
      onRequestEndCallback: () => {
        setShowChangeStatusModal(false);
      },
    };
    dispatch(updateRoleAndRightsStatus(data));
  };

  const handleApply = () => {
    if (action === "Delete") {
      let data = {
        ids: selected,
        societyId: loginData?.default_community?.community_id,
        flag: "occupant",
        onSuccessCallback: () => {
          setShowDeleteModal(false);
          setSelected([]);
        },
      };
      dispatch(deleteEmployeeOccupantAndFamilyRole(data));
    }
  };

  const isSelected = (id) => selected.includes(id);

  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        {!!selected?.length && (
          <div className="flex justify-end items-center border-b border-[#CCC] gap-2 p-2">
            <div className="flex items-center gap-2">
              <Dropdown2
                options={[{ value: "Delete", label: "Delete" }]}
                placeholder="Choose Action"
                className="text-[11px] ms-4"
                width="160px"
                height="32px"
                onSelect={(selectedItem) => {
                  setAction(selectedItem.value);
                }}
              />
              <Button
                label={
                  <span className="w-8 inline-block">
                    {updateLoader ? (
                      <CircularProgress sx={{ color: "white" }} size={17} />
                    ) : (
                      "Apply"
                    )}
                  </span>
                }
                className="font-semibold px-4 h-8"
                type="button"
                onClick={handleApply}
              />
            </div>
          </div>
        )}
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox &&
                getPermission(loginData, [permissions.DELETE_MEMBER_TITLE]) && (
                  <TableCellStyled
                    size="small"
                    sx={{ pl: "0 !important", pr: 2, width: 36 }}
                  >
                    <Checkbox
                      size="small"
                      indeterminate={false}
                      checked={
                        data?.length > 0 && selected?.length === data?.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all",
                      }}
                      sx={{
                        p: 0,
                        color: "#AAAAA",
                        "&.Mui-checked": {
                          color: "#CF001C",
                        },
                      }}
                      disabled={loading || !data}
                    />
                  </TableCellStyled>
                )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(data, getComparator(order, orderBy))?.map(
                    (row, rowIndex) => {
                      const isRowSelected = isSelected(row?.id);

                      return (
                        <TableRowStyled
                          key={row?.occupantRoleId}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {!!checkbox &&
                            getPermission(loginData, [
                              permissions.DELETE_MEMBER_TITLE,
                            ]) && (
                              <TableCellStyled
                                size="small"
                                sx={{ pl: "0 !important", zIndex: 0 }}
                                onClick={(event) =>
                                  handleClick(event, row?.occupantRoleId)
                                }
                              >
                                <Checkbox
                                  checked={selected?.includes(
                                    row?.occupantRoleId
                                  )}
                                  onChange={(e) =>
                                    handleClick(e, row?.occupantRoleId)
                                  }
                                  size="small"
                                  color="primary"
                                  inputProps={{
                                    "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                  }}
                                  sx={{
                                    p: 0,
                                    color: "#AAAAA",
                                    "&.Mui-checked": {
                                      color: "#CF001C",
                                    },
                                  }}
                                />
                              </TableCellStyled>
                            )}

                          {/* Serial Number */}
                          <TableCellStyled
                            size="small"
                            align="left"
                            onClick={(event) => handleClick(event)}
                          >
                            {row?.sNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.role}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <div
                              onClick={() =>
                                setShowChangeStatusModal({
                                  id: row.occupantRoleId,
                                  open: true,
                                })
                              }
                              className={`underline font-semibold ${
                                row.status === "Active"
                                  ? "text-green-600"
                                  : row.status === "Inactive"
                                  ? "text-red-500"
                                  : "text-yellow-500"
                              }`}
                            >
                              {row.status}
                            </div>
                            {getPermission(loginData, [
                              permissions.STATUS_MEMBER_TITLE,
                            ]) && (
                              <ChangeStatusModal
                                isOpen={
                                  showChangeStatusModal.open &&
                                  row.occupantRoleId ===
                                    showChangeStatusModal.id
                                }
                                onCancel={() => setShowChangeStatusModal(false)}
                                onConfirm={() => {
                                  handleChangeStatus(
                                    row.occupantRoleId,
                                    row?.status
                                  );
                                }}
                                text={`Do you want to change status to ${
                                  row?.status === "Active"
                                    ? "Inactive"
                                    : "Active"
                                } ?`}
                                loading={updateLoader}
                              />
                            )}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <ActionComponent
                              id={row.occupantRoleId}
                              setSelected={setSelected}
                            />
                          </TableCellStyled>
                        </TableRowStyled>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

ManageOccupantsRolesTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default ManageOccupantsRolesTable;
