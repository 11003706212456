import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
// import { getChequeDepositSlipReport } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import parse from "html-react-parser";
import {
  getinterestCalculationtReport,
  getInterestCalculationReport,
} from "../../../../redux/actions/ReportActions/FinanceReportActions";
import moment from "moment";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const demoData = [
  {
    "Flat No.	": "DN-0005/2023-24	",
    "Member Name	": "F - 0301	",
    "Invoice No.	": "Prem Kumar Bhawsar	",
    "Invoice Type	": "50.00",
    "Receipt No.	 ": "4234234",
    "Payment Mode	": "50.00	",
    "Chq/Tran Number		": "50.00		",
    "Chq/Tran Date	": "14.00	",
    "Bank Name ": "50.00",
    "Branch Name	": "faf",
    "Amount (Rs.)	": "faf",
    "Payment Date	": "08-03-2024	",
    Status: "Verified",
    Reason: "fads",
    "Payment Due/Excess": "123",
  },
];
const PrintInterestCalculationReport = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [headCells, setHeadCells] = useState([]);

  const { loginData } = useSelector((state) => state.loginReducer);
  const { interestCalculationtReport, loading } = useSelector(
    (state) => state.financeReportReducer
  );

  const getTableData = () => {
    dispatch(
      getInterestCalculationReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("subscriber_name") || "",
        billNo: searchParams.get("billNo") || "",
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        export: 0,
      })
    );
  };

  useEffect(() => {
    getTableData();

    const afterprint = (e) => {
      e.preventDefault();
    };

    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    };
  }, []);

  useEffect(() => {
    if (!loading && interestCalculationtReport?.data?.length > 0) {
      setTimeout(function () {
        window.print();
      }, 2000);
    }
  }, [dispatch]);

  useEffect(() => {
    if (interestCalculationtReport?.data?.length)
      setHeadCells(
        Object.entries(interestCalculationtReport.data.slice(-1)[0])?.map(
          (item) => ({
            label: item[0],
            id: item[0].replaceAll(" ", "_"),
            width: "130px",
          })
        )
      );
  }, [interestCalculationtReport]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        // <>{parse(chequeDepositSlipReport)}</>
        <div className="m-1">
          <table className="table-auto  w-full mx-auto border-collapse border  text-center border-black break-words ">
            <caption className="caption-top  mt-4 text-center mb-4">
              <h1
                style={{
                  textDecoration: "underline",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {loginData?.default_community?.community_name}
              </h1>
              <div className="text-[11px] ">
                (REG No.{loginData?.default_community?.regn_no} Date
                {loginData?.default_community?.date_of_regn})
              </div>
              <div className="text-[13px] ">
                {loginData?.default_community?.office_address}
              </div>
              {/* <div className="font-extrabold text-lg mb-3  text-start">
                Receipt Report From: {searchParams.get("startDate") || "-"} To:{" "}
                {searchParams.get("endDate") || "-"}
              </div> */}
            </caption>
            <thead className="">
              <tr className="bg-[#dcdcdc] font-semibold">
                {headCells?.length &&
                  headCells?.map(
                    (item, index) =>
                      index != headCells?.length - 1 && (
                        <td
                          className="text-[13px] p-2 font-bold border border-black"
                          key={item?.id}
                          width={item?.width}
                        >
                          {item?.label}
                        </td>
                      )
                  )}
              </tr>
            </thead>
            <tbody>
              {interestCalculationtReport?.data?.length &&
                interestCalculationtReport?.data?.map((row, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    {headCells.length && (
                      <>
                        <tr className="text-[#222] text-[12px] ">
                          {headCells?.map((item, headIndex) => {
                            return (
                              item?.label != "child_data" && (
                                <td className="p-2 border border-black align-top">
                                  {!headIndex
                                    ? rowIndex + 1
                                    : row?.[item?.label]}
                                </td>
                              )
                            );
                          })}
                        </tr>
                        {row?.["child_data"]?.length && (
                          <>
                            <tr className="bg-[#dcdcdc] font-semibold">
                              {Object.entries(row?.["child_data"]?.[0])?.map(
                                (item, index, arr) => (
                                  <th
                                    className="p-2 border border-black align-top text-[12px]"
                                    colSpan={index == arr.length - 1 && 2}
                                  >
                                    {item?.[0]}
                                  </th>
                                )
                              )}
                            </tr>

                            {row?.["child_data"]?.map((item, index) => (
                              <tr>
                                {Object.entries(item)?.map(
                                  (item2, index2, arr) => (
                                    <td
                                      className="p-2 border border-black align-top text-[12px]"
                                      colSpan={index2 == arr.length - 1 && 2}
                                    >
                                      {item2?.[1]}
                                    </td>
                                  )
                                )}
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
          {!interestCalculationtReport?.data?.length && !loading && (
            <div className="text-[11px] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PrintInterestCalculationReport;
