import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../api";
import axiosInstance from "../../../axiosInstance";
import { logOut } from "../../../../utils/helpers/logOut";
import { errorMsg } from "../../../../consts/responseMessages";
import axiosInstance2 from "../../../axiosInstance2";

export async function getDebitNoteInvoiceReportReq(action) {
    const BODY = {
        society_id: action.data?.societyId,
        invoice_type: action.data?.invoice,
        debit_wing_no: action.data?.wingId,
        debit_flat_no: action.data?.flatId,
        debit_from_date: action.data?.fromDate,
        debit_to_date: action.data?.toDate,
        invoiceType: action.data?.invoiceType,
        debit_gst_no: action.data?.gstNo,
        debit_member_name: action.data?.memberName,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        export: action.data?.export
    }
    return axiosInstance2
        .post(
            `${apiEndpoints.DEBIT_NOTE_INVOICE_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}



export async function getInvoiceReportReq(action) {
    const BODY =
    {
        society_id: action.data?.societyId,
        invoiceType: action.data?.invoice,
        invoice_type: action.data?.invoiceType,
        hdrwingno: action.data?.wingId,
        hdrflatno: action.data?.flatId,
        hdrfrom_date: action.data?.fromDate,
        hdrto_date: action.data?.toDate,
        searchby: action.data?.searchBy,
        gst_no: action.data?.gstNo,
        hdrmembername: action.data?.memberName,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        export: action.data?.export
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.INVOICE_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}



export async function getReceiptReportReq(action) {
    const BODY =
    {
        society_id: action.data?.societyId,
        receiptwingid: action.data?.wingId,
        receiptflatid: action.data?.flatId,
        hdrfrom_date: action.data?.fromDate,
        hdrto_date: action.data?.toDate,
        searchby: action.data?.searchBy,
        hdrmembername: action.data?.memberName,
        status: action.data?.status,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        invoice_type: action.data?.invoiceType,
        export: 0
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.RECEIPT_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function downloadReceiptReportReq(action) {
    const BODY =
    {
        society_id: action.data?.societyId,
        receiptwingid: action.data?.wingId,
        receiptflatid: action.data?.flatId,
        hdrfrom_date: action.data?.fromDate,
        hdrto_date: action.data?.toDate,
        searchby: action.data?.searchBy,
        hdrmembername: action.data?.memberName,
        status: action.data?.status,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        invoice_type: action.data?.invoiceType,
        export: action.data?.export
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.DOWNLOAD_RECEIPT_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}


export async function getSupplementaryBillReportReq(action) {
    const BODY =
    {
        society_id: action.data?.societyId,
        invoiceType: action.data?.invoice,
        hdrwingno: action.data?.wingId,
        hdrreceiptflatno: action.data?.flatId,
        hdrfrom_date: action.data?.fromDate,
        hdrto_date: action.data?.toDate,
        searchby: action.data?.searchBy,
        gst_no: action.data?.gstNo,
        hdrmembername: action.data?.memberName,
        page: action.data?.page,
        limit: action.data?.pageLimit
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.SUPPLEMENTARY_BILL_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function getDebitNoteReceiptReportReq(action) {
    const BODY =
    {
        society_id: action.data?.societyId,
        invoice_type: action.data?.invoiceType,
        hdrwingno: action.data?.wingId,
        hdrdebitreceiptflatno: action.data?.flatId,
        hdrfrom_date: action.data?.fromDate,
        hdrto_date: action.data?.toDate,
        searchby: action.data?.searchBy,
        status: action.data?.status,
        hdrmembername: action.data?.memberName,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        export: 0
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.DEBIT_NOTE_RECEIPT_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}
export async function downloadDebitNoteReceiptReportReq(action) {
    const BODY =
    {
        society_id: action.data?.societyId,
        invoice_type: action.data?.invoiceType,
        hdrwingno: action.data?.wingId,
        hdrdebitreceiptflatno: action.data?.flatId,
        hdrfrom_date: action.data?.fromDate,
        hdrto_date: action.data?.toDate,
        searchby: action.data?.searchBy,
        status: action.data?.status,
        hdrmembername: action.data?.memberName,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        export: action.data?.export
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.DOWNLOAD_DEBIT_NOTE_RECEIPT_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}



export async function getDeletedInvoiceReportReq(action) {
    const BODY =
    {
        society_id: action.data?.societyId,
        from_date: action.data?.fromDate,
        to_date: action.data?.toDate,
        entrystatus: action.data?.status,
        invoice_type: action.data?.invoiceType,
        searchby: action.data?.searchBy,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        export: action.data?.export
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.DELETED_INVOICE_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}



export async function getDeletedDebitNoteReportReq(action) {
    const BODY =
    {
        id: action.data?.societyId,
        invoice_no: action.data?.billNo,
        payment_status: action.data?.status,
        invoice_type: action.data?.invoiceType,
        debitor_type: action.data?.debitorType,
        subscriber_name: action.data?.memberName,
        wing_id: action.data?.wingId,
        flat_id: action.data?.flatId,
        search_by: action.data?.searchBy,
        from_date: action.data?.fromDate,
        to_date: action.data?.toDate,
        from_amount: action.data?.fromAmount,
        to_amount: action.data?.toAmount,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        export: 0
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.DELETED_DEBIT_NOTE_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function downloadDeletedDebitNoteReportReq(action) {
    const BODY =
    {
        id: action.data?.societyId,
        invoice_no: action.data?.billNo,
        payment_status: action.data?.status,
        invoice_type: action.data?.invoiceType,
        debitor_type: action.data?.debitorType,
        subscriber_name: action.data?.memberName,
        wing_id: action.data?.wingId,
        flat_id: action.data?.flatId,
        search_by: action.data?.searchBy,
        from_date: action.data?.fromDate,
        to_date: action.data?.toDate,
        from_amount: action.data?.fromAmount,
        to_amount: action.data?.toAmount,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        export: action.data?.export
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.DOWNLOAD_DELETED_DEBIT_NOTE_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}


export async function getInvoiceInterestReportReq(action) {
    const BODY =
    {
        id: action.data?.societyId,
        invoiceno: action.data?.billNo,
        invoice_type: action.data?.invoiceType,
        wingid: action.data?.wingId,
        billType: action.data?.billType,
        membername: action.data?.memberName,
        flatid: action.data?.flatId,
        searchby: action.data?.searchBy,
        from_date: action.data?.fromDate,
        to_date: action.data?.toDate,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        previous: action.data?.previous
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.INVOICE_INTEREST_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}



export async function getInterestCalculationReportReq(action) {
    const BODY =
    {
        id: action.data?.societyId,
        invoice_no: action.data?.billNo,
        wing_no: action.data?.wingId,
        member_name: action.data?.memberName,
        flat_no: action.data?.flatId,
        search_by: action.data?.searchBy,
        from_date: action.data?.toDate,
        to_date: action.data?.fromDate,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        export: action.data?.export

    }

    return axiosInstance2
        .post(
            `${apiEndpoints.GET_INTEREST_CALCULATION_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function getReconciliationReportReq(action) {
    const BODY =
    {
        id: action.data?.societyId,
        reco_date: action.data?.tillDate,
        show_differences_only: action.data?.showDifference,
        invoice_type: action.data?.invoiceType,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        export: action.data?.export
    }


    return axiosInstance2
        .post(
            `${apiEndpoints.GET_RECONCILIATION_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function getDebitNoteInterestCalculationReportReq(action) {
    const BODY =
    {
        id: action.data?.societyId,
        debit_wing_no: action.data?.wingId,
        debit_flat_no: action.data?.flatId,
        debit_from_date: action.data?.fromDate,
        debit_to_date: action.data?.toDate,
        invoice_type: action.data?.invoiceType,
        debit_gst_no: action.data?.gstNo,
        debit_member_name: action.data?.memberName,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        export: action.data?.export
    }
    return axiosInstance2
        .post(
            `${apiEndpoints.GET_DEBITNOTE_INTEREST_CALCULATION_REPORT_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function getInvoiceAgingReportReq(action) {
    const BODY =
    {
        id: action.data?.societyId,
        debit_wing_no: action.data?.wingId,
        debit_flat_no: action.data?.flatId,
        debit_from_date: action.data?.fromDate,
        debit_to_date: action.data?.toDate,
        bill_type: action.data?.invoiceType,
        debit_gst_no: action.data?.gstNo,
        debit_member_name: action.data?.memberName,
        page: action.data?.page,
        limit: action.data?.pageLimit,
        invoice_type: ""
    }
    return axiosInstance2
        .post(
            `${apiEndpoints.GET_INVOICE_AGING_REPORT}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function getInvoiceAuditReportReq(action) {
    const BODY =
    {
        id: action.data?.societyId,
        debit_wing_no: action.data?.wingId,
        debit_flat_no: action.data?.flatId,
        debit_from_date: action.data?.fromDate,
        debit_to_date: action.data?.toDate,
        bill_type: action.data?.invoiceType,
        debit_gst_no: action.data?.gstNo,
        debit_member_name: action.data?.memberName,
        show_type: action.data?.showType,
        // invoice,servicetax-audit,settings-audit,display-audit,invoice-audit,invoice-details-audit,payment-edit-audit,advance-edit-audit,credit-edit-audit
        page: action.data?.page,
        limit: action.data?.pageLimit,
    }
    return axiosInstance2
        .post(
            `${apiEndpoints.GET_INVOICE_AUDIT_REPORT}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function deleteFianceReportExcelReq(action) {
    const BODY =
    {
        file_name: action?.data?.download_url
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.DELETE_FINANCE_REPORT_EXCEL_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}
