import React from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Search from "../../../components/SearchBox/Search";
import Layout from "../../Layout/Layout";
import ContentManagementTemplateTable from "../../../components/TableComponent/ContentManagmentTemplateTable";
import { contentManagementList } from "../../../redux/actions/SetupRecordsActions/ContentManagementActions/ContentManagementAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import Loader from "../../../components/Loader/Loader";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "70px" },
  { id: "title", label: "Title", sort: false, width: "260px" },
  { id: "description", label: "Description", sort: true, width: "500px" },
  { id: "contactNo", label: "Action", sort: true, width: "100px" },
];

const ContentManagementTemplate = () => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);

  const society_id = loginData?.default_community?.community_id;
  useEffect(() => {
    dispatch(
      contentManagementList({
        society_id,
      })
    );
  }, []);
  const { data, loading, error } = useSelector((state) => state.contentReducer);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Content Management"></ListHeader>
        </div>

        {/* <div className="py-1 flex justify-between gap-2 my-1">
          <Search
            height="8"
            width="48"
            placeholder="Search transaction"
          />
        </div> */}
        <div className="ml-1">
          <ContentManagementTemplateTable
            data={data}
            columns={headCells}
            // checkbox={true}
            loading={loading}

            // pagination={data?.length}
            // totalCount={data?.total_count}
            // totalPages={data?.total_pages}
            // start={(data?.current_page - 1) * (searchParams.get("pageLimit") || 10) + 1}
            // end={(data?.current_page - 1) * (searchParams.get("pageLimit") || 10) + staffPersonnelList?.staff_list?.length}
            // currentPage={parseInt(data?.current_page)}
            // rowsPerPage={searchParams.get("pageLimit") || 10}
            // getTableData={getTableData}
          ></ContentManagementTemplateTable>
        </div>
        {!loading && !data?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ContentManagementTemplate;
