import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { toWords } from "number-to-words";
import { getManageAdvanceList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageAdvanceNoteAction";
import Loader from "../../../../components/Loader/Loader";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false, width: "40px" },
  { id: "invoiceNo", label: "Invoice No.", sort: true, width: "10%" },
  { id: "date", label: "Date", sort: true, width: "10%" },
  { id: "debitName", label: "Debit Name", sort: true, width: "11%" },
  { id: "creditName", label: "Credit Name", sort: true, width: "11%" },
  { id: "amount", label: "Amount", sort: true, width: "10%" },
  { id: "paidAmount", label: "Paid Amount", sort: true, width: "10%" },
  { id: "remarks", label: "Remarks", sort: true, width: "10%" },
  { id: "genDate", label: "Generation Date", sort: true, width: "11%" },
  { id: "status", label: "Status", sort: true, width: "11%" },
];

const PrintAdvanceNoteList = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams()

  const { loginData } = useSelector((state) => state.loginReducer);
  const {loading, advanceNoteList} = useSelector(state=>state.manageAdvanceNoteReducer)


  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: 'all',
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      searchBy: searchParams.get("searchBy") || '',
      startDate: searchParams.get("startDate") || '',
      endDate: searchParams.get("endDate") || '',
      status: searchParams.get("status") || '',
    }
    dispatch(getManageAdvanceList(data));
  }
  
  useEffect(()=>{      
    getTableData()

    const afterprint = (e) => {
      e.preventDefault()
    };
    
    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    }
  }, [])


  useEffect(() => {
    if(!loading && advanceNoteList){
      window.print()
    }
  }, [loading]);


  return loading ? (
    <Loader />
  ) : (
    <div  className="p-1">
      <table className="table-auto w-full mx-auto border-collapse border border-black">
        <thead>
          <tr>
            {
              headCells?.map((item)=>(
                <td className={`text-sm p-1 h-16 font-semibold text-[14px] border border-black w-[${item?.width}]`} key={item?.id}>
                  {item?.label}
                </td>
              ))
            }
          </tr>
        </thead>
        <tbody>
            {
              advanceNoteList?.list?.map((item, index)=>(
                <React.Fragment  key={index}>
                {
                  !!Object.keys(item)?.length &&
                  <tr className="text-[#222] text-sm">
                    <td className="p-1 border border-black align-top w-[45px]">
                      {(index+1)?.toString()?.padStart(2, 0)}
                    </td>
                    <td className="p-1 border border-black align-top capitalize">
                      {item?.invoice_no || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {validateDate(item.date) ? moment(item.date)?.format('DD-MM-YYYY') : '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item.name || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item.name || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.amount ? parseFloat(item?.amount)?.toFixed(2) :  '0.00'}
                    </td>
                    <td className="p-1 border border-black align-top capitalize">
                      {item?.paidAmount ? parseFloat(item?.paidAmount)?.toFixed(2) :  '0.00'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.remarks || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.genDate || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.row_status || '-'}
                    </td>
                  </tr>
                }
                </React.Fragment>
              ))
            }

        </tbody>
      </table>
      {!advanceNoteList?.list?.length && !loading && (
          <div className="text-sm my-10 text-center italic">
            No Records
          </div>
        )}
    </div>
  );
};

export default PrintAdvanceNoteList;
