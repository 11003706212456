import { useSelector } from "react-redux";
import { getPermission } from "../utils/helpers/getPermissions";
import ListHeaderBack from "../components/ListHeader/ListHeaderBack";
import Layout from "../pages/Layout/Layout";
import { useNavigate } from "react-router-dom";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const PermissionOnComponent = ({ permissionPaths, component }) => {
  const navigate = useNavigate();
  const { loginData } = useSelector((state) => state.loginReducer);
  return getPermission(loginData, permissionPaths) ? (
    <>{component}</>
  ) : (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack onClick={() => navigate(-1)}></ListHeaderBack>
        </div>
        <div className="py-11 p-2 text-center font-bold">
          <ReportProblemIcon className="text-red-500" sx={{ fontSize: 50 }} />
          <h2 className="text-[27px] mb-0"> Access Denied</h2>
          <div>You dont have permission to view this page</div>
          <div>
            <span className="font-bold">Error Code:</span> 403
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PermissionOnComponent;
