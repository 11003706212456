import React, { useEffect } from "react";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import Layout from "../../../Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSingleInvoiceList,
} from "../../../../redux/actions/FinanceActions/MyInvoiceActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ViewManageInvoiceTable from "./ViewManageInvoiceDetailsTabled";

export const headCells = [
  { id: "date", label: "Date", width: "100px" },
  { id: "receiptNo", label: "Receipt No.", width: "100px" },
  { id: "mode", label:"Payment Mode", width: "100px" },
  { id: "totalAmount", label: "Bill Amount",sort: true, width: "100px" },
  { id: "amountPaid", label: "Amount Paid", sort: true, width: "150px" },
  { id: "amountDue", label: "Amount Due", sort: true, width: "170px" },
  { id: "action", label: "Action", sort: true, width: "120px" },
];

export const ViewManageInvoiceDetailsTable = ({singleInvoice, loading}) => {

  return (
    <>
      <div>
        <div className="p-1 ml-2">
          <ViewManageInvoiceTable
            loading={loading}
            data={singleInvoice?.payments?.map((item, index) => ({
              date: item?.payment_date,
              totalAmount: item?.grandTotaFinal,
              amountPaid: item?.amount,
              amountDue: item.due,
              invid: item?.enc_invId,
              receiptid: item.id,
              invoiceid:item?.invoiceid,
              mode: item?.modeofpayment,
              receiptNo: item?.receipt_no,
              PostStatus: item?.PostStatus,
              paymentInvoiceId: item?.id,
              status: item?.status,
              transactionid: item?.transactionid,
            }))}
            columns={headCells}
            checkbox={false}
          ></ViewManageInvoiceTable>
        </div>
        {!singleInvoice?.payments?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </>
  );
};

function ViewManageInvoices() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { singleInvoice, loading } =
    useSelector((state) => state.myInvoiceReducer);

  useEffect(() => {
    dispatch(
      getSingleInvoiceList({
        invoiceNo: id,
      })
    );
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="View Invoice Details"
            onClick={() => {
              navigate(-1);
            }}
          ></ListHeaderBack>
        </div>
        <div className="gap-2 py-1 my-1">
          <table className="w-full p-2 table-auto">
            <tbody>
              <tr className="text-[#222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                  Invoice No :
                </td>
                <td className="py-2">
                  {singleInvoice?.invoice_no || ""}
                </td>
              </tr>
              <tr className="text-[#222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                  Flat No :
                </td>
                <td className="py-2">
                  {singleInvoice?.flat_no || "-"}
                </td>
              </tr>
              <tr className="text-[#222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                  Invoice Date :
                </td>
                <td className="py-2">
                  {singleInvoice?.invoice_date || ""}
                </td>
              </tr>
              <tr className="text-[#222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                  Status :
                </td>
                <td className="py-2">
                  {singleInvoice?.status || ""}
                </td>
              </tr>
              <tr className="text-[#222] text-sm">
                <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                  Remarks :
                </td>
                <td className="py-2">
                  {singleInvoice?.remarks || ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <ViewManageInvoiceDetailsTable singleInvoice={singleInvoice} loading={loading} />
        </div>
      </div>
    </Layout>
  );
}

export default ViewManageInvoices;
