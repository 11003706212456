import * as types from "../index.types"

export function getSocietyEmailReport(data) {
    return {
        type: types.GET_SOCIETY_EMAIL_REPORTS,
        data
    }
}
export function getMemberLedgerReport(data) {
    return {
        type: types.GET_MEMBER_LEDGER_REPORTS,
        data
    }
}

export function downloadSocietyEmailReport(data) {
    return {
        type: types.DOWNLOAD_SOCIETY_EMAIL_REPORTS,
        data
    };
}