import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/ReportsReqs/SocietyReportsReqs/SocietyReportsReqs'
import { notify } from '../../../utils/notification';

function* getSocietyEmailReports(action) {
    try {
        const result = yield call(req.getSocietyEmailReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_SOCIETY_EMAIL_REPORTS_SUCCESS, payload: result })
        } else {
            yield put({ type: types.GET_SOCIETY_EMAIL_REPORTS_FAILURE, message: result?.response })
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMemberLedgerReports(action) {
    try {
        const result = yield call(req.getMemberLedgerReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MEMBER_LEDGER_REPORTS_SUCCESS, payload: result.data })
        } else {
            yield put({ type: types.GET_MEMBER_LEDGER_REPORTS_FAILURE, message: result?.response })
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadSocietyEmailReports(action) {
    try {
        const result = yield call(req.downloadSocietyEmailReportReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield put({ type: types.DOWNLOAD_SOCIETY_EMAIL_REPORTS_SUCCESS });
            yield call(req.deleteSocietyReportExcelReq, action);
        } else {
            yield put({ type: types.DOWNLOAD_SOCIETY_EMAIL_REPORTS_FAILURE });
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* societyReportSaga() {
    yield takeLatest(types.GET_SOCIETY_EMAIL_REPORTS, getSocietyEmailReports);
    yield takeLatest(types.DOWNLOAD_SOCIETY_EMAIL_REPORTS, downloadSocietyEmailReports);
    yield takeLatest(types.GET_MEMBER_LEDGER_REPORTS, getMemberLedgerReports);

}