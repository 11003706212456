import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import parse from "html-react-parser";

import "../../../../../components/TableComponent/Table.css";
import { SortIcon } from "../../../../../assets";
import styled from "@emotion/styled";
import {
  stableSort,
  getComparator,
} from "../../../../../utils/helpers/universalFunctions";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../../components/Loader/Loader";
import ItemsPerPage from "../../../../../components/ItemsPerPage/ItemsPerPage";
import Pagination from "../../../../../components/Pagination/Pagination";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#000000",
    fontSize: "13px",
    padding: "12px 8px ",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    border: "1px solid #e6e6e6",
    fontSize: "13px",
    padding: "12px 8px",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const InvoiceDetailsTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
}) => {
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked
      ? data.map((item) => item.employeeRoleId)
      : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    // searchParams.delete("page")
    navigate({
      pathname: `${""}`,
      // search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      // searchParams.delete("pageLimit")
      navigate({
        pathname: `${""}`,
        // search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      });
    }
  };

  const isSelected = (id) => selected.includes(id);

  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table
          stickyHeader
          aria-label="sticky table "
          className=" !border-collapse"
        >
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled sx={{ border: "none" }} colSpan={3}>
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(data, getComparator(order, orderBy))?.map(
                    (row, rowIndex) => {
                      let values = {};
                      !Array.isArray(row) &&
                        Object.entries(row)?.[0]?.[1]?.date &&
                        Object.entries(row)?.[0]?.[1]?.date?.map(
                          (item, index, arr) => {
                            if (arr.indexOf(item) == index) {
                              values[item] = {
                                name: "",
                                date: "",
                                ip: "",
                                item_name: [],
                                old: [],
                                new: [],
                              };
                            }
                          }
                        );

                      Object.entries(row)?.[0]?.[1]?.date?.map(
                        (item, index) => {
                          let objects = Object.entries(row)[0][1];

                          values[item].name = objects?.name?.[index];
                          values[item].date = objects?.date?.[index];
                          values[item].ip = objects?.ip?.[index];
                          values[item]?.item_name?.push(
                            objects?.item_name?.[index]
                          );
                          values[item]?.old?.push(objects?.old?.[index]);
                          values[item]?.new?.push(objects?.new?.[index]);
                        }
                      );
                      return (
                        <>
                          {!Array.isArray(row) && (
                            <tr key={rowIndex} className="text-sm">
                              <th className="p-3  w-[33.33%]">
                                {Object.entries(row)[0][0]}
                              </th>
                              <th className="p-3   w-[33.33%]">Old Value</th>

                              <th className="p-3  w-[33.33%]">New Value</th>
                            </tr>
                          )}
                          {values &&
                            Object.entries(values)?.length > 0 &&
                            Object.entries(values)?.map((item, index) => {
                              return (
                                <>
                                  <tr key={index} className="text-sm">
                                    <td
                                      colSpan={3}
                                      className="bg-[#e6e6e6] p-3 text-end border border-[#e6e6e6]"
                                    >
                                      <p className="mb-1">
                                        <span>{item[1]?.name} </span>
                                        <span>{item[1]?.date}</span>
                                      </p>
                                      <p>
                                        <span>{item[1]?.ip} </span>
                                      </p>
                                    </td>
                                  </tr>
                                  {item[1]?.item_name?.length > 0 &&
                                    item[1]?.item_name?.map((item2, index2) => {
                                      return (
                                        <TableRow key={index2} role="checkbox">
                                          <TableCellStyled
                                            size="small"
                                            align="left"
                                          >
                                            {item[1]?.item_name[index2]}
                                          </TableCellStyled>
                                          <TableCellStyled
                                            size="small"
                                            align="left"
                                          >
                                            {parse(
                                              "<span>" +
                                                item[1]?.old[index2] +
                                                "</span>"
                                            )}
                                          </TableCellStyled>
                                          <TableCellStyled
                                            size="small"
                                            align="left"
                                          >
                                            {parse(
                                              "<span>" +
                                                item[1]?.new[index2] +
                                                "</span>"
                                            )}
                                          </TableCellStyled>
                                        </TableRow>
                                      );
                                    })}
                                </>
                              );
                            })}
                        </>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

export default InvoiceDetailsTable;
