import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import {getInvitedOccupantsAndTenantsList} from '../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/ViewInvitiesAction'
import { ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT, ARCHIVE_TENANTS_ENDPOINT } from "../../../../Routing/routes";
import ViewInvitiesTable from "./ViewInvitiesTable";
import * as permissions from '../../../../Routing/permissions'
import { getPermission } from "../../../../utils/helpers/getPermissions";

export const headCells = [
  { id: "sNo", label: "Sr. No.", width: "50px" },
  { id: "name", label: "Name", sort: true, width: "250px", capitalize: true},
  { id: "phoneNumber", label: "Phone Number", sort: true, width: "150px" },
  { id: "emailAddress", label: "Email Address", sort: true, width: "250px" },
  { id: "status", label: "Status", sort: true, width: "130px" },
  { id: "action", label: "Action" },
];

export const tabData = [
  {
    flag: "occupant", 
    label: 'Members', 
    archiveLink: ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT,
  }, 
  {
    flag: "tenant", 
    label: 'Tenants', 
    archiveLink: ARCHIVE_TENANTS_ENDPOINT,
    permission: permissions.VIEW_ARCHIVE_TENANTS_PERMISSION
  }];

  const StyledTab = styled(Tab)({
    textTransform: "none",
    fontSize: "15px",
    fontWeight: 500,
    fontFamily: "'Montserrat', sans-serif",

    "&.Mui-selected": {
      color: "#CF001C",
    }
  });

const ViewInvites = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [tabValue, setTabValue] = useState(0);
  const {loading, occupantsList, tenantsList} = useSelector(state=>state.viewInvitiesInfoReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const dispatch = useDispatch()

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      pageLimit: searchParams.get("pageLimit") || 10,
      flag: tabData[tabValue]?.flag
    }

    dispatch(getInvitedOccupantsAndTenantsList(data))
  }

  useEffect(()=>{
    getTableData()
  }, [tabValue, searchParams])



  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-3">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="View Invites">
            <div className="flex text-sm gap-3">
              {
                (tabData[tabValue]?.permission && getPermission(loginData, [tabData[tabValue]?.permission])) ?
                <>
                  <div className="border-r border-[#CCC]"></div>
                  <Link to={ tabData[tabValue]?.archiveLink} className="text-white">
                    <Button label={`Archive ${tabData[tabValue]?.label}`} className="h-8" />
                  </Link>
                </>
                :
                <>
                  <div className="border-r border-[#CCC]"></div>
                  <Link to={ tabData[tabValue]?.archiveLink} className="text-white">
                    <Button label={`Archive ${tabData[tabValue]?.label}`} className="h-8" />
                  </Link>
                </>
              }
            </div>
          </ListHeader>
        </div>

        <div className="">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            TabIndicatorProps={{
              style: {
              backgroundColor: "#CF001C",
              }
          }}
          >
            <StyledTab label="Occupants Invites" />
            <StyledTab label="Tenant Invites" />
          </Tabs>
        </div>

        <div className="">
          <TabPanel value={tabValue} index={0}>
            <ViewInvitiesTable
                loading={loading && !occupantsList?.occupants_list}
                data={occupantsList?.occupants_list?.map((item, index)=>({
                sNo: (((occupantsList?.current_page_no - 1) * occupantsList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
                name: item?.subscriber_name,
                phoneNumber: item?.subscriber_number,
                emailAddress: item?.subscriber_email,
                status: item?.status ==='A' ? 'Accepted' : 'Pending'
              }))}
              columns={headCells}
              checkbox={false}
              pagination={occupantsList?.occupants_list?.length}
              totalCount={occupantsList?.total_record}
              totalPages={occupantsList?.total_pages}
              start={(occupantsList?.current_page_no - 1) * occupantsList?.page_limit + 1}
              end={(occupantsList?.current_page_no - 1) * occupantsList?.page_limit + occupantsList?.occupants_list?.length}
              currentPage={occupantsList?.current_page_no}
              rowsPerPage={searchParams.get("pageLimit") || 10}
              getTableData={getTableData}

              flag={tabData[tabValue]?.flag}
            >
            </ViewInvitiesTable>
            {!occupantsList?.occupants_list?.length && !loading && (
              <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                No Records
              </div>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <ViewInvitiesTable
              loading={loading && !tenantsList?.tenants_list}
              data={tenantsList?.tenants_list?.map((item, index)=>({
                sNo: (((tenantsList?.current_page_no - 1) * tenantsList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
                name: item?.name,
                phoneNumber: item?.contact_no,
                emailAddress: item?.email,
                status: item?.status ==='A' ? 'Accepted' : 'Pending'
              }))}
              columns={headCells}
              checkbox={false}
              pagination={tenantsList?.tenants_list?.length}
              totalCount={tenantsList?.total_record}
              totalPages={tenantsList?.total_pages}
              start={(tenantsList?.current_page_no - 1) * tenantsList?.page_limit + 1}
              end={(tenantsList?.current_page_no - 1) * tenantsList?.page_limit + tenantsList?.tenants_list?.length}
              currentPage={tenantsList?.current_page_no}
              rowsPerPage={searchParams.get("pageLimit") || 10}
              getTableData={getTableData}
              flag={tabData[tabValue]?.flag}
            >
            </ViewInvitiesTable>
            {!tenantsList?.tenants_list?.length && !loading && (
              <div className="text-lg text-[#AAAAAA] mt-10 text-center italic">
                No Records
              </div>
            )}
          </TabPanel>
        </div>

      </div>
    </Layout>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default ViewInvites;
