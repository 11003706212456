import React, { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import UtilityReadingTable from "./UtilityReadingTable";
import {
  UTILITY_READING_ENDPOINT,
  WINGS_AND_FLATS_ENDPOINT,
} from "../../../Routing/routes";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import { UploadEx } from "../../../assets";
import UploadExcelModal from "../../../components/Modal/UploadExcelModal";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import {
  monthOptions,
  utilityYearOptions,
} from "../../../consts/DropDownOptions";
import {
  getHeadList,
  getUtilityReadingsDetails,
  getWingsAndFlatDetails,
  uploadUtilityReadingsExcel,
} from "../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { useDispatch, useSelector } from "react-redux";
import * as permissions from "../../../Routing/permissions";
import { getPermission } from "../../../utils/helpers/getPermissions";
import ButtonG from "../../../components/Button/ButtonG";
import { getInvoiceTypeList } from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { CircularProgress } from "@mui/material";

const UtilityReading = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { wingsData, uploadLoader } = useSelector(
    (state) => state.wingsReducer
  );
  const { headList } = useSelector((state) => state.headListReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);
  const { loading, updateLoader, utilityReadings } = useSelector(
    (state) => state.wingsReducer
  );

  const [filter, setFilter] = useState({
    wingId: id,
    headId: searchParams.get("headId") || "",
    month: searchParams.get("month") || "",
    year: searchParams.get("year") || "",
    invoiceId: 0,
  });
  const [file, setFile] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [saveClick, setSaveClick] = useState(undefined);

  const handleWingChange = (selectedItem) => {
    setFilter((prev) => {
      return { ...prev, wingId: selectedItem.value };
    });
  };

  const handleHeadChange = (selectedItem) => {
    setFilter((prev) => {
      return { ...prev, headId: selectedItem.value };
    });
  };

  const handleMonthChange = (selectedItem) => {
    setFilter((prev) => {
      return { ...prev, month: selectedItem.value };
    });
  };

  const handleYearChange = (selectedItem) => {
    setFilter((prev) => {
      return { ...prev, year: selectedItem.value };
    });
  };

  const onSubmit = () => {
    navigate({
      pathname: `${UTILITY_READING_ENDPOINT}/${filter.wingId}`,
      search: `?${createSearchParams([
        ...Object.entries(filter).filter(
          (item) => item[1] && item[0] !== "wingId"
        ),
      ])}`,
    });
  };

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const onUpload = () => {
    let data = {
      wingId: filter.wingId,
      societyId: loginData?.default_community?.community_id,
      ipAddress,
      file,
      sessionId: loginData?.session_id,
      onSuccessCallback: () => {
        const data = {
          ...filter,
          societyId: loginData?.default_community?.community_id,
        };
        dispatch(getUtilityReadingsDetails(data));
      },

      onRequestEndCallback: () => {
        setFile(null);
        setIsUploadModalOpen(false);
      },
    };
    dispatch(uploadUtilityReadingsExcel(data));
  };

  const handleClear = () => {
    navigate(`${UTILITY_READING_ENDPOINT}/${filter.wingId}`);
  };

  useEffect(() => {
    dispatch(getWingsAndFlatDetails());
    dispatch(getHeadList());
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
    const data = {
      wingId: id,
      headId: searchParams.get("headId") || "",
      month: searchParams.get("month") || "",
      year: searchParams.get("year") || new Date().getFullYear(),
      invoiceId: searchParams.get("invoiceId"),
      societyId: loginData?.default_community?.community_id,
    };
    setFilter({
      wingId: id,
      headId: searchParams.get("headId") || "",
      month: searchParams.get("month") || "",
      year: searchParams.get("year") || new Date().getFullYear(),
      invoiceId: searchParams.get("invoiceId"),
    });
    dispatch(getUtilityReadingsDetails(data));
  }, [searchParams, id]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 ">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            onClick={handleNavigation(WINGS_AND_FLATS_ENDPOINT)}
            title="Utility Reading"
          >
            {getPermission(loginData, [
              permissions.UPLOAD_UTILITY_READING_PERMISSION,
            ]) && (
              <button
                className="flex items-center h-8 px-2 text-xs font-semibold bg-white border rounded-lg text-red-650 border-red-650 whitespace-nowrap"
                onClick={handleOpenUploadModal}
              >
                <UploadEx className="mr-2 fill-red-650" /> Upload Modified/New
                Unit Excel
              </button>
            )}
            <span className="border-r border-[#CCCC]"></span>
            <div className="flex items-center justify-between gap-3">
              <Dropdown2
                options={
                  typeLists
                    ? typeLists?.map((item) => ({
                        label: item?.invoice_title,
                        value: item?.id,
                      }))
                    : []
                }
                placeholder="Invoice type"
                className="text-[11px] ms-4"
                width="172px"
                height="32px"
                onSelect={(selectedItem) => {
                  // handelSelectInvuoiceType(selectedItem);
                  setFilter((item) => {
                    return {
                      ...item,
                      invoiceId: selectedItem.value,
                      headId: "",
                    };
                  });
                }}
                value={
                  typeLists?.[0]?.id && {
                    value: filter?.invoiceId || typeLists?.[0]?.id,
                    label: typeLists
                      ? typeLists?.find(
                          (item) =>
                            item?.id ==
                            (filter?.invoiceId
                              ? filter?.invoiceId
                              : typeLists?.[0]?.id)
                        )?.invoice_title
                      : "",
                  }
                }
              />
            </div>
            <span className="border-r border-[#CCCC]"></span>
            {!!utilityReadings?.reading?.length && (
              <Button
                label={
                  <span className="inline-block w-8">
                    {updateLoader ? (
                      <CircularProgress sx={{ color: "white" }} size={17} />
                    ) : (
                      "Save"
                    )}
                  </span>
                }
                className="flex items-center h-8 px-4"
                onClick={saveClick}
              />
            )}
          </ListHeaderBack>
        </div>
        <div className="flex justify-between p-2 -mb-2">
          <div className="flex gap-4">
            <div className="flex items-center gap-2">
              <span className="text-xs">Wing</span>
              <Dropdown2
                options={
                  wingsData?.map((item) => ({
                    label: item.number,
                    value: item.wing_id,
                  })) || []
                }
                className="text-xs font-semibold"
                width="128px"
                height="32px"
                placeholder="Select"
                onSelect={handleWingChange}
                value={
                  {
                    label: wingsData?.filter(
                      (item) =>
                        item?.wing_id?.toString() === filter.wingId?.toString()
                    )[0]?.number,
                    value: filter.wingId,
                  } || {}
                }
              />
            </div>

            <div className="flex items-center gap-2">
              <span className="text-xs whitespace-nowrap">Head Name</span>
              <Dropdown2
                options={
                  typeLists?.[0]?.id
                    ? headList
                        ?.filter(
                          (item) =>
                            item.invoice_type ==
                            (filter?.invoiceId || typeLists?.[0]?.id)
                        )
                        ?.map((item) => ({
                          label: item.head_name,
                          value: item.head_id,
                        }))
                    : []
                }
                width="128px"
                height="32px"
                placeholder="Select"
                className="text-xs"
                onSelect={handleHeadChange}
                value={
                  filter.headId
                    ? {
                        label: headList?.filter(
                          (item) =>
                            item?.head_id?.toString() ==
                            filter.headId?.toString()
                        )[0]?.head_name,
                        value: filter.headId,
                      }
                    : []
                }
              />
            </div>
            <div className="flex items-center gap-2">
              <span className="text-xs">Month</span>
              <Dropdown2
                options={monthOptions}
                width="128px"
                height="32px"
                placeholder="Select"
                className="text-xs"
                onSelect={handleMonthChange}
                value={
                  filter.month && {
                    label: monthOptions?.filter(
                      (item) =>
                        item?.value?.toString() === filter.month?.toString()
                    )[0]?.label,
                    value: filter.month,
                  }
                }
              />
            </div>
            <div className="flex items-center gap-2">
              <span className="text-xs">Year</span>
              <Dropdown2
                options={utilityYearOptions || []}
                width="128px"
                height="32px"
                placeholder="Select"
                className="text-xs"
                onSelect={handleYearChange}
                value={
                  filter.year && {
                    label: utilityYearOptions?.filter(
                      (item) =>
                        item?.value?.toString() === filter.year?.toString()
                    )[0]?.label,
                    value: filter.year,
                  }
                }
              />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex items-center justify-end">
              <Button
                label="Search"
                className="flex items-center h-8 px-4"
                onClick={onSubmit}
              />
            </div>
            <span className="border-r border-[#CCCC]"></span>
            <ButtonG label="Clear" onClick={handleClear} />
          </div>
        </div>
        <div>
          <div className="p-2">
            <UtilityReadingTable
              wingId={filter?.wingId}
              societyId={loginData?.default_community?.community_id}
              filter={filter}
              setSaveClick={setSaveClick}
            />
          </div>
        </div>
      </div>
      {isUploadModalOpen && (
        <UploadExcelModal
          isOpen={isUploadModalOpen}
          onClose={handleCloseUploadModal}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          file={file}
          setFile={setFile}
          onUpload={onUpload}
          uploadLoader={uploadLoader}
          // downloadFormatLink={
          //   "https://firebasestorage.googleapis.com/v0/b/np-zg-dev-neo-bucket/o/excelSheetSampleAndFormat%2Futility_reading_excel_sheet_format.xlsx?alt=media"
          // }
          downloadSampleLink={
            "https://firebasestorage.googleapis.com/v0/b/np-zg-dev-neo-bucket/o/excelSheetSampleAndFormat%2Futility_reading_excel_sheet_sample.xlsx?alt=media"
          }
        />
      )}
    </Layout>
  );
};

export default UtilityReading;
