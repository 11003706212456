import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../../../Layout/Layout";
import { Arrow } from "../../../../assets";
import { AuditData } from "../../../../consts/MemberDetailsData";
import Loader from '../../../../components/Loader/Loader'
import { useDispatch, useSelector } from "react-redux";
import {getOccupantsAuditLogs}  from '../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction'
import moment from "moment";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const AccordionForBillAndPaymentDetails = ({ label, isOpen, toggleAccordion, data }) => {

  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
            border rounded flex justify-center items-center">
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold">{label}</div>
        </div>
      </div>
      {isOpen && (
        <>
          <div className="p-3 text-[#222222] text-sm">
            <div className="font-bold mb-4 uppercase">Last Bill Details</div>
            <div className="flex mb-3">
              <div className="flex w-1/2">
                <div className="w-1/2 font-semibold">Member Name</div>
                <div className="w-1/2">{data?.invoice?.subscriber_name || '-'}</div>
              </div>
            </div>
            <div className="flex mb-3">
              <div className="flex  w-1/2">
                <div className="w-1/2 font-semibold">Invoice Number</div>
                <div className="w-1/2">{data?.invoice?.invoice_no || '-'}</div>
              </div>
              <div className="flex  w-1/2">
                <div className="w-1/2 font-semibold">Invoice Date</div>
                <div className="w-1/2">{validateDate(data?.invoice?.invoice_date) ? moment(new Date(data?.invoice?.invoice_date))?.format('DD-MM-YYYY') : '-'}</div>
              </div>
            </div>
            <div className="flex mb-3">
              <div className="flex  w-1/2">
                <div className="w-1/2 font-semibold">Total</div>
                <div className="w-1/2">{data?.invoice?.grandtotal || '-'}</div>
              </div>
              <div className="flex  w-1/2">
                <div className="w-1/2 font-semibold">Amount Paid</div>
                <div className="w-1/2">{data?.invoice?.amount_paid || '-'}</div>
              </div>
            </div>
            <hr className="mt-4"/>
          </div>
          <div className="p-3 text-[#222222] text-sm">
            <div className="font-bold mb-4 uppercase">Last Payment Details</div>
            <div className="flex mb-3">
              <div className="flex w-1/2">
                <div className="w-1/2 font-semibold">Member Name</div>
                <div className="w-1/2">{data?.invoice?.subscriber_name || '-'}</div>
              </div>
            </div>
            <div className="flex mb-3">
              <div className="flex  w-1/2">
                <div className="w-1/2 font-semibold">Reciept Number</div>
                <div className="w-1/2">{data?.payment?.receipt_no || '-'}</div>
              </div>
              <div className="flex  w-1/2">
                <div className="w-1/2 font-semibold">Payment Mode</div>
                <div className="w-1/2">{data?.payment?.mode_of_payment || '-'}</div>
              </div>
            </div>
            <div className="flex mb-3">
              <div className="flex  w-1/2">
                <div className="w-1/2 font-semibold">Invoice Number</div>
                <div className="w-1/2">{data?.payment?.invoice_no || '-'}</div>
              </div>
              <div className="flex  w-1/2">
                <div className="w-1/2 font-semibold">Invoice Date</div>
                <div className="w-1/2">{validateDate(data?.payment?.invoice_date) ? moment(new Date(data?.payment?.invoice_date))?.format('DD-MM-YYYY') : '-'}</div>
              </div>
            </div>
            <div className="flex mb-3">
              <div className="flex  w-1/2">
                <div className="w-1/2 font-semibold">Payment Date</div>
                <div className="w-1/2">{data?.payment?.payment_date ? moment(new Date(data?.payment?.payment_date))?.format('DD MMM, YYYY hh:mm A') : '-'}</div>
              </div>
              <div className="flex  w-1/2">
                <div className="w-1/2 font-semibold">Status</div>
                <div className="w-1/2">{data?.payment?.status || '-'}</div>
              </div>
            </div>
            <hr className="mt-4"/>
          </div>
        </>
      )}
    </div>
  )
}

const AccordionSection = ({ label, isOpen, index, toggleAccordion, data }) => {

  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
            border rounded flex justify-center items-center">
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold">{label}</div>
        </div>
      </div>
      {isOpen && (
          data?.map((audit, i)=>{
            return (
              <>
                {
                  !!AuditData[index]?.values?.filter((item)=> (audit?.old_data?.[item.key]?.toString()) !== (audit?.new_data?.[item.key]?.toString()))?.length
                  &&
                  <div className="p-3 text-[#222222] text-sm" key={audit?.log_id}>
                    <div className="flex justify-end flex-col mb-4">
                      <div className="text-xs text-right text-[#888]">{`Modified By ${audit?.modified_by} On ${moment(new Date(audit?.modified_dateTime))?.format('MMM DD,YYYY hh:mm A')}`}</div>
                      <div className="text-xs text-right text-[#888]">{`Ip Address: ${audit?.IpAddress}. Session id - ${audit?.modified_sessionId}`}</div>
                    </div>
                    <div className="flex justify-between w-full text-sm p-2 font-bold">
                      <div className="w-1/2">{' '}</div>
                      <div className="w-1/2">Old Value</div>
                      <div className="w-1/2">New Value</div>
                    </div>
                    {AuditData?.[index]?.values.map((item, i0) => (
                      <React.Fragment key={i0}>
                        {
                          (audit?.old_data?.[item.key]?.toString() !== audit?.new_data?.[item.key]?.toString()  || label === 'Member Details')
                          &&
                          <div className="flex justify-between w-full text-sm p-2">
                            <div className="w-1/2 font-semibold">{item.label}</div>
                            <div className="w-1/2">
                              {
                                item.label==='Contact No' && audit?.old_data?.[item.key]?.toString()?
                                '+' + audit?.old_data?.['Country code']?.toString() + '-' + audit?.old_data?.[item.key]?.toString()
                                :
                                audit?.old_data?.[item.key]?.toString() || '-'
                              }
                            </div>
                            <div className="w-1/2">{audit?.new_data?.[item.key]?.toString() || '-'}</div>
                          </div>
                        }
                      </React.Fragment>
                    ))}
                    <hr className="mt-4"/>
                  </div>
                }
              </>
            )
          })
        
      )}
    </div>
  )
}

const OccupantsAuditLog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams()
  const {loading, occupantsAuditLogs} = useSelector(state=>state.occupantInfoReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(()=>{
    const data = {
      flatId: searchParams.get("flatId") || '',
      subscriberId: searchParams.get("subscriberId") || '',
      userAccId: searchParams.get("userAccId") || '',
    }
    dispatch(getOccupantsAuditLogs(data))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="flex gap-5 pb-2">
          <div className="flex w-1/2">
            <button
              onClick={handleNavigation(-1)}
              className="text-sm p-2 text-red-650 font-semibold flex gap-2 justify-center items-center"
            >
              <span>
                <Arrow />
              </span>
              Back
            </button>
            <span className="border-r border-[#CCCCCC]"></span>
            <h1 className="text-sm font-semibold p-2">Occupants Audit Logs</h1>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        {
          loading && searchParams.get("subscriberId") !== occupantsAuditLogs?.occupant_audit_log?.[0]?.subscriber_id?.toString() ?
          <Loader />
          :
          <div>
            <AccordionSection
              label={'Member Details'}
              key={0}
              index={0}
              data={occupantsAuditLogs?.['occupant_audit_log']}
              isOpen={0=== openAccordionIndex}
              toggleAccordion={() => toggleAccordion(0)}
            />
            <AccordionForBillAndPaymentDetails
              label={'Last Bill and Payment Details'}
              key={'bill_details'}
              data={{
                invoice: occupantsAuditLogs?.['last_invoice_data'],
                payment: occupantsAuditLogs?.['last_payment_data']
              }}
              isOpen={'bill_details'=== openAccordionIndex}
              toggleAccordion={() => toggleAccordion('bill_details')}
            />
            { 
                AuditData?.slice(1)?.map((item, index)=>(
                  <AccordionSection
                    label={item.label}
                    key={index+1}
                    index={index+1}
                    data={occupantsAuditLogs?.[item.key]}
                    isOpen={index+1 === openAccordionIndex}
                    toggleAccordion={() => toggleAccordion(index+1)}
                    desc={item.desc}
                  />
                ))
            }
          </div>
        }
      </div>
    </Layout>
  );
};

export default OccupantsAuditLog;
