import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/ClubReqs/BookAFacilityReqs'
import { notify } from '../../../utils/notification';
import { getFirebaseURL, uploadImageOnFirebase } from '../../../firebase/firebaseFunction';
import { facilityDocsPath } from '../../../firebase/getFirebasePath';

function* getFacilityList(action) {
    try {
        const result = yield call(req.getFacilityListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_FACILITY_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_FACILITY_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getTimeSlotList(action) {
    try {
        const result = yield call(req.getTimeSlotListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_TIME_SLOT_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_TIME_SLOT_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getBookingFrequencyList(action) {
    try {
        const result = yield call(req.getBookingFrequencyListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BOOKING_FREQUENCY_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_BOOKING_FREQUENCY_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getFacilityDetails(action) {
    try {
        const result = yield call(req.getFacilityDetailsReq, action);
        if (result.statusCode === 200) {
            if (!result.data.facility_image?.includes('https:'))
                result.data.facility_image_url = yield getFirebaseURL(result.data.facility_image)
            else
                result.data.facility_image_url = result.facility_image.facility_image
            yield put({ type: types.GET_FACILITY_DETAILS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_FACILITY_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateFacility(action) {
    try {
        if (typeof action.data.attachments !== 'string') {
            let path = facilityDocsPath({ societyId: action.data.societyId, fileName: action.data.attachments?.name });
            yield uploadImageOnFirebase(action.data.attachments, path)
            action.data.attachments = path;
        }
        const result = yield call(req.updateFacilityReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Facility updated successfully')
            yield put({ type: types.UPDATE_FACILITY_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.UPDATE_FACILITY_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getFacilityAuditLogst(action) {
    try {
        const result = yield call(req.getFacilityAuditLogstReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_FACILITY_AUDIT_LOGS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_FACILITY_AUDIT_LOGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteFacility(action) {
    try {
        const result = yield call(req.deleteFacilityReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Facility removed successfully');
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_FACILITY_SUCCESS, payload: action.data });
            const newList = yield call(req.getFacilityListReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_FACILITY_LIST_SUCCESS, payload: newList.data });
        } else {
            yield put({ type: types.DELETE_FACILITY_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}


function* updateTimeSlot(action) {
    try {
        const result = yield call(req.updateTimeSlotReq, action);
        if (result.statusCode === 200) {
            notify('success', `Time Slot ${action.data.type || " added "} successfully`)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.ADD_UPDATE_TIME_SLOT_SUCCESS, payload: action.data });
            const newList = yield call(req.getTimeSlotListReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_TIME_SLOT_LIST_SUCCESS, payload: newList.data });
        } else {
            yield put({ type: types.ADD_UPDATE_TIME_SLOT_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getRateChart(action) {
    try {
        const result = yield call(req.getRateChartReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_RATE_CHART_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_RATE_CHART_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateRateChart(action) {
    try {
        const result = yield call(req.updateRateChartReq, action);
        if (result.statusCode === 200) {
            notify('success', `Rate Chart ${action.data.type} successfully`)
            yield put({ type: types.ADD_UPDATE_RATE_CHART_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
            const newList = yield call(req.getRateChartReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_RATE_CHART_SUCCESS, payload: newList.data });
        } else {
            yield put({ type: types.ADD_UPDATE_RATE_CHART_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* checkAvailibility(action) {
    try {
        const result = yield call(req.checkAvailibilityReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.CHECK_BOOKING_AVAILIBILITY_SUCCESS, payload: { available: true, error: null } });
        } else {
            console.log(result)
            yield put({ type: types.CHECK_BOOKING_AVAILIBILITY_FAILURE, payload: { available: false, error: result?.response?.data?.message } });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAvailableTickets(action) {
    try {
        const result = yield call(req.getAvailableTicketsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_AVAILABLE_TICKETS_SUCCESS, payload: { data: result.data, error: null } });
        } else {
            yield put({ type: types.GET_AVAILABLE_TICKETS_FAILURE, payload: { available: false, error: result?.response?.data?.message } });
        }
    } catch (e) {
        console.log(e)
    }
}


function* changeFacilityStatus(action) {
    try {
        const result = yield call(req.changeFacilityStatusReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Status changed successfully')
            yield put({ type: types.CHANGE_FACILITY_STATUS_SUCCESS, payload: action.data });
        } else {
            yield put({ type: types.CHANGE_FACILITY_STATUS_FAILURE });
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* deleteTimeSlot(action) {
    try {
        const result = yield call(req.deleteTimeSlotReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Time Slot removed successfully');
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_TIME_SLOT_SUCCESS, payload: action.data });
            const newList = yield call(req.getTimeSlotListReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_TIME_SLOT_LIST_SUCCESS, payload: newList.data });
        } else {
            yield put({ type: types.DELETE_TIME_SLOT_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteRateChart(action) {
    try {
        const result = yield call(req.deleteRateChartReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Rate Chart  removed successfully');
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_RATE_CHART_SUCCESS, payload: action.data });
            const newList = yield call(req.getRateChartReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_RATE_CHART_SUCCESS, payload: newList.data });
        } else {
            yield put({ type: types.DELETE_RATE_CHART_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* sendMessageToAdministrator(action) {
    try {
        const result = yield call(req.sendMessageToAdministratorReq, action);
        if (result.statusCode === 200) {
            notify('success', `Message sent successfully`)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.SEND_MESSAGE_TO_ADMINISTRATOR_SUCCESS });
        } else {
            yield put({ type: types.SEND_MESSAGE_TO_ADMINISTRATOR_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getFacilityPaymentLink(action) {
    try {
        const result = yield call(req.getFacilityPaymentLinkReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_FACILTIY_ONLINE_PAYMENT_LINK_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_FACILTIY_ONLINE_PAYMENT_LINK_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addBooking(action) {
    try {
        const result = yield call(req.addBookingReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Booking made successfully')
            console.log(action)
            if (action.data.mode === 'Yes') {
                action.data = { ...action.data, invoiceId: result.data.booking_id, amount: result.data.total_amount }
                const resultLink = yield call(req.getFacilityPaymentLinkReq, action);
                yield put({ type: types.ADD_BOOKING_SUCCESS, payload: result.data });
                window.location.href = resultLink.payment_link
            }
            else {
                yield put({ type: types.ADD_BOOKING_SUCCESS, payload: null });
                action.data?.onSuccessCallback?.()
            }
        } else {
            yield put({ type: types.ADD_BOOKING_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* bookAFacilitySaga() {
    yield takeLatest(types.GET_FACILITY_LIST, getFacilityList);
    yield takeLatest(types.GET_TIME_SLOT_LIST, getTimeSlotList);
    yield takeLatest(types.GET_BOOKING_FREQUENCY_LIST, getBookingFrequencyList);
    yield takeLatest(types.GET_FACILITY_DETAILS, getFacilityDetails);
    yield takeLatest(types.UPDATE_FACILITY, updateFacility);
    yield takeLatest(types.GET_FACILITY_AUDIT_LOGS, getFacilityAuditLogst);
    yield takeLatest(types.DELETE_FACILITY, deleteFacility);
    yield takeLatest(types.ADD_UPDATE_TIME_SLOT, updateTimeSlot);
    yield takeLatest(types.GET_RATE_CHART, getRateChart);
    yield takeLatest(types.ADD_UPDATE_RATE_CHART, updateRateChart);
    yield takeLatest(types.CHECK_BOOKING_AVAILIBILITY, checkAvailibility);
    yield takeLatest(types.GET_AVAILABLE_TICKETS, getAvailableTickets);
    yield takeLatest(types.CHANGE_FACILITY_STATUS, changeFacilityStatus);
    yield takeLatest(types.DELETE_TIME_SLOT, deleteTimeSlot);
    yield takeLatest(types.DELETE_RATE_CHART, deleteRateChart);
    yield takeLatest(types.SEND_MESSAGE_TO_ADMINISTRATOR, sendMessageToAdministrator);
    yield takeLatest(types.GET_FACILTIY_ONLINE_PAYMENT_LINK, getFacilityPaymentLink);
    yield takeLatest(types.ADD_BOOKING, addBooking);

}