import * as type from "../../../actions/index.types";

export function invoiceDetailsReducer(
  state = {
    loading: false,
    updateLoader: false,
    invoiceDetails: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_MASTER_HEAD_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_MASTER_HEAD_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        invoiceDetails: action.payload,
      };
    case type.GET_MASTER_HEAD_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        invoiceDetails: null,
      };
         
    
case type.UPDATE_MASTER_HEAD_DETAILS:
    return {
      ...state,
      updateLoader: true
    }
  case type.UPDATE_MASTER_HEAD_DETAILS_SUCCESS:
    return {
        ...state,
        updateLoader: false
      }

  case type.UPDATE_MASTER_HEAD_DETAILS_FAILURE:
    return {
        ...state,
        updateLoader: false
      }


    default:
      return state;
  }
}
