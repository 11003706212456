import React, { useEffect } from "react";
import { DeleteIcon } from "../../../../assets";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { TextInput, TextInputFM } from "../../../../components/InputFields/TextInput";
import TextRadio from "../../../../components/InputFields/TextRadio";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getBookingGLMappingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
//import { getCreditDebitTariffHeaderList } from "../../../../redux/actions/FinanceActions/SettingActions/CreditDebitTariffHeaderAction";
import { FieldArray } from "formik";

const CreditDetailsBox = ({ values, setFieldValue,creditNoteCause,creditNoteSetting,loading }) => {
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  //const {loading, tariffHeaderList} = useSelector(state=>state.creditDebitTariffHeaderReducer)
  const { glMappingList} = useSelector(state=>state.viewBookingReducer)
  
  const handleRadio = (value, key, setFieldValue) => {
    setFieldValue(key, value)
  };

const timeOfDayOptions = [
  { name: "timeOfDay", value: "Y", label: "Service" },
  { name: "timeOfDay", value: "N", label: "Goods" },
];

useEffect(()=>{
  const data={
      societyId: loginData?.default_community?.community_id,
      page: 'all',
      searchText: '',
      pageLimit: ''
    }
    dispatch(getBookingGLMappingList(data))
    //dispatch(getCreditDebitTariffHeaderList(data))  
  }, [])

  return (
    <div>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
        <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
          <h1 className="items-center p-2 ml-1 -mb-2 text-base">
            Tariff Details
          </h1>
        </div>
        <div className="p-2 ">
          <div className="flex flex-wrap gap-2 p-2">
          {<FieldArray
            name="details"
            render={arrayHelpers => (
              <>
                {values.details && values.details.length > 0 && (
                  values.details.map((row, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="flex items-start gap-4" >
                        <div className="flex items-start justify-between gap-4">
                          <DropdownFM2
                            options={creditNoteCause?.map(item=>({
                              label: item.cause,
                              value: item.id,
                              debitaccId: item.GLmapping,
                              hsnCode: item.hsn_code,
                              serviceGood: item.servicegoods,
                              amount: item.amount,
                              gstApplicable: item.gstapplicable
                            }))}
                            loading={loading}
                            width={"176px"}
                            className="text-xs"
                            placeholder="Select Tariff"
                            onSelect={(selectedItem) => {
                              setFieldValue(`details.${index}.tariffHeaderId`, selectedItem.label)
                              setFieldValue(`details.${index}.debitAccountId`, selectedItem.debitaccId)
                              setFieldValue(`details.${index}.hsnCode`, selectedItem.hsnCode)
                              setFieldValue(`details.${index}.serviceGood`, selectedItem.serviceGood==='service' ? 'Y' : 'N')
                              setFieldValue(`details.${index}.amount`, selectedItem.amount)
                              setFieldValue(`details.${index}.gstApplicable`, selectedItem.gstApplicable)
                            }}
                            height="38px"
                            name={`details.${index}.tariffHeaderId`}
                            value={
                              values.details[index]?.tariffHeaderId ? 
                              {
                                label: values.details[index]?.tariffHeaderId,
                                value: creditNoteCause?.find(item=> item.cause === values.details[index]?.tariffHeaderId)?.id,
                              }
                              :null
                            }
                          />
                          <DropdownFM2
                            options={glMappingList?.map(item=> ({
                              label: item.name,
                              value: item.ac_code
                            }))}
                            width={"176px"}
                            className="text-xs"
                            placeholder="Select Debit Account"
                            onSelect={(selectedItem) => {
                              setFieldValue(`details.${index}.debitAccountId`, selectedItem.value)
                            }}
                            height="38px"
                            name={`details.${index}.debitAccountId`}
                            value={
                              values.details[index]?.debitAccountId ? 
                              {
                                label: glMappingList?.find(item=> item.ac_code===values.details[index]?.debitAccountId)?.name,
                                value: values.details[index]?.debitAccountId
                              }
                              :null
                            }
                          />
                          <TextInputFM
                            label=""
                            placeholder="Enter HSN Code"
                            className="w-28"
                            name={`details.${index}.hsnCode`}
                          />
                          <TextRadio
                            label=""
                            options={timeOfDayOptions}
                            onChange={(value)=>{handleRadio(value, `details.${index}.serviceGood`, setFieldValue)}}
                            className={"w-44 mt-3"}
                            name={`details.${index}.serviceGood`}
                            defaultSelected={values.details[index]?.serviceGood}
                          />
                          <TextInputFM
                            label=""
                            placeholder="Enter Amount"
                            className="w-48"
                            name={`details.${index}.amount`}
                            type='decimal'
                          />
                        </div>
                        <div className="flex mt-1">
                          {values.details?.length > 1 && (
                            <button
                              className="p-2 fill-red-650"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                              type="button"
                            >
                              <DeleteIcon />
                            </button>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })
              )}
              <div>
                <button
                  type="button"
                  className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-28 text-xs whitespace-nowrap h-9 p-2 mb-4 bg-[#FDF6F6] flex justify-center rounded-md"
                  onClick={() =>
                    arrayHelpers.push({
                      tariffHeaderId: '',
                      debitAccountId: '',
                      hsnCode: '',
                      serviceGood: 'Y',
                      amount: '',
                    })
                  }
                >
                  Add More
                </button>
              </div>
              </>
            )}></FieldArray>
              }
          </div>
          {
            values.gstApplicable ==='Y' &&
            <div className="flex p-2">
              <div className="w-1/2">
              </div>
              <div className="w-1/2">
                <TextInput
                  label="SGST"
                  placeholder=""
                  className={"w-48 mr-8"}
                  maxLength={300}
                  value={((values.details?.reduce((partialSum, item) => {
                    const old_total = parseFloat(item.amount) || 0 ;
                    return partialSum + old_total;
                  }, 0) || 0) * (parseFloat(creditNoteSetting?.sgst_rate|| 0)/100))?.toFixed(2)}
                  type='decimal'
                  disabled={true}
                />
                <TextInput
                  label="CGST"
                  placeholder=""
                  className={"w-48 mr-8"}
                  maxLength={300}
                  value={((values.details?.reduce((partialSum, item) => {
                    const old_total = parseFloat(item.amount) || 0 ;
                    return partialSum + old_total;
                  }, 0) || 0) * (parseFloat(creditNoteSetting?.cgst_rate || 0)/100))?.toFixed(2)}                
                  type='decimal'
                  disabled={true}
                />
              </div>
            </div>
          }
          <div className="flex p-2">
            <div className="w-1/2">
            </div>
            <div className="w-1/2">
              <TextInput
                label="Grand Total"
                placeholder=""
                className={"w-48 mr-8"}
                maxLength={300}
                name='total'
                type='decimal'
                value={
                  values.gstApplicable ==='N' ? 
                  (values.details?.reduce((partialSum, item) => {
                    const old_total = parseFloat(item.amount) || 0 ;
                    return partialSum + old_total;
                  }, 0) || 0)?.toFixed(2)
                : 
                  ((values.details?.reduce((partialSum, item) => {
                    const old_total = parseFloat(item.amount) || 0 ;
                    return partialSum + old_total;
                  }, 0) || 0) * ((100 + parseFloat(creditNoteSetting?.sgst_rate|| 0)+ parseFloat(creditNoteSetting?.cgst_rate || 0))/100))?.toFixed(2)
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditDetailsBox;
