import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import {
  AssignIcon,
  ClosedIcon,
  CommentIcon,
  EditIcon,
  PrinterIcon,
  SortIcon,
  ViewIcon,
} from "../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../utils/helpers/universalFunctions";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Tooltip } from "@mui/material";
import {
  ASSIGN_COMPLAINTS_ENDPOINT,
  COMPLAINT_DASHBOARD_ENDPOINT,
  EDIT_COMPLAINTS_ENDPOINT,
  POST_COMMENTS_ENDPOINT,
  PRINT_COMPLAINT_ENDPOINT,
  VIEW_COMPLAINTS_ENDPOINT,
} from "../../Routing/routes";
import { useDispatch } from "react-redux";
import ChangeStatusModal from "../Modal/ChangeStatusModal";
import { useSelector } from "react-redux";
import { addPostComment, changeComplaintStatus } from "../../redux/actions/DeskActions/DeskAction";
import * as permissions from "../../Routing/permissions";
import { getPermission } from "../../utils/helpers/getPermissions";
import { RatingComp2 } from "../Rating/Rating";
import ChangeComplaintStatusModal from "../Modal/ChangeComplaintStatusModal";

function ActionComponent({ id, status }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleChangeStatus = () => {
    let data = {
      complaintId: id,
      societyId: loginData?.default_community?.community_id,
      status: "I",
    };
    dispatch(changeComplaintStatus(data));
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="View" arrow>
        <ViewIcon
          className="fill-[#555] hover:fill-red-650 me-1.5"
          onClick={() => {
            navigate(`${VIEW_COMPLAINTS_ENDPOINT}/${id}`);
          }}
        />
      </Tooltip>
      {
        <Tooltip title="Edit" arrow>
          <EditIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={() => {
              navigate(`${EDIT_COMPLAINTS_ENDPOINT}/${id}`);
            }}
          />
        </Tooltip>
      }
      {getPermission(loginData, [permissions.PRINT_COMPLAINT_PERMISSION]) && (
        <Tooltip title="Print" arrow>
          <PrinterIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={() => {
              window.open(`${PRINT_COMPLAINT_ENDPOINT}/${id}`, "_blank");
            }}
          />
        </Tooltip>
      )}
      {!(
        status?.toLowerCase() === "on hold" ||
        status?.toLowerCase() === "closed"
      ) && (
          <>
            {getPermission(loginData, [
              permissions.ASSIGNMENT_OF_COMPLAINT_PERMISSION,
            ]) && (
                <Tooltip title="Assign" arrow>
                  <AssignIcon
                    className="fill-[#555] hover:fill-red-650 me-1.5"
                    onClick={() => {
                      navigate(`${ASSIGN_COMPLAINTS_ENDPOINT}/${id}`);
                    }}
                  />
                </Tooltip>
              )}
          </>
        )}
      {!(
        // status?.toLowerCase() === "in-progress" ||
        // status?.toLowerCase() === "on hold" ||  
        status?.toLowerCase() === "closed"
      ) && (
          <Tooltip title="Comment" arrow>
            <CommentIcon
              className="fill-[#555] hover:fill-red-650 me-1.5  w-[14px]"
              onClick={() => {
                navigate(`${POST_COMMENTS_ENDPOINT}/${id}`);
              }}
            />
          </Tooltip>
        )}
      {!(
        status?.toLowerCase() === "on hold" ||
        status?.toLowerCase() === "closed"
      ) && (
          <>
            {getPermission(loginData, [
              permissions.OPEN_CLOSE_COMPLAINT_PERMISSION,
            ]) && (
                <Tooltip title="Closed" arrow>
                  <ClosedIcon
                    className="fill-[#555] hover:fill-red-650 me-1.5  w-[14px]"
                    onClick={handleChangeStatus}
                  />
                </Tooltip>
              )}
          </>
        )}
    </Box>
  );
}

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const ComplaintDashboardTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  getTableData,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [review, setReview] = useState([]);

  const [showChangeStatusModal, setShowChangeStatusModal] = useState({
    id: "",
    open: false,
  });
  const [selected, setSelected] = useState([]);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { updateLoader } = useSelector((state) => state.occupantInfoReducer);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.id) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    searchParams.delete("page");
    navigate({
      pathname: `${COMPLAINT_DASHBOARD_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["page", newPage],
      ])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit");
      searchParams.delete("page");
      navigate({
        pathname: `${COMPLAINT_DASHBOARD_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["pageLimit", value],
        ])}`,
      });
    }
  };

  const handleChangeStatus = (id, values) => {
    let data = {
      complaintId: id,
      societyId: loginData?.default_community?.community_id,
      ...values,
      onSuccessCallback: () => {
        setShowChangeStatusModal(false);
      },
    };
    for (let i in values.attachments) {
      if (values.attachments[i].file == "") {
        values.attachments.splice(i);
      }
    }
    dispatch(addPostComment(data));
  };

  const isSelected = (id) => selected.includes(id);

  const tableRef = useRef(null);
  const [startX, setStartX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - tableRef.current.offsetLeft);
    setScrollX(tableRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - tableRef.current.offsetLeft;
    const scrollDiff = x - startX;
    tableRef.current.scrollLeft = scrollX - scrollDiff;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  useEffect(() => {
    if (isDragging) {
      tableRef.current?.addEventListener("mousemove", handleMouseMove);
      tableRef.current?.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      tableRef.current?.removeEventListener("mousemove", handleMouseMove);
      tableRef.current?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  return (
    <Box sx={{ width: "100%", height: "67%" }} className="table-component">
      <TableContainer
        className="border-t border-[#CCC] table-container complaintDashboardTable"
        onMouseDown={handleMouseDown}
        sx={{ minHeight: "100%" }}
        ref={tableRef}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCell
                  size="small"
                  sx={{ py: 1.5, pl: 0, pr: 2, zIndex: 0, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  size="small"
                  key={column.id}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    py: 1.5,
                    pl: 0,
                    pr: 1,
                    fontWeight: 600,
                    zIndex: 0,
                    color: "#AAAAAA",
                    wordWrap: "break-word",
                    fontSize: "13px",
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!data?.length &&
              stableSort(data, getComparator(order, orderBy))?.map(
                (row, rowIndex) => {
                  const isRowSelected = isSelected(row.id);

                  return (
                    <TableRowStyled
                      key={row.id}
                      role="checkbox"
                      aria-checked={isRowSelected}
                      tabIndex={-1}
                      selected={isRowSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      {!!checkbox && (
                        <TableCell
                          size="small"
                          sx={{
                            borderBottom: "none",
                            fontSize: "13px",
                            py: 1.5,
                            pl: 0,
                            pr: 1,
                            zIndex: 0,
                          }}
                          onClick={(event) => handleClick(event, row.id)}
                        >
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={isRowSelected}
                            inputProps={{
                              "aria-labelledby": `generic-table-checkbox-${row.id}`,
                            }}
                            sx={{
                              p: 0,
                              color: "#AAAAA",
                              "&.Mui-checked": {
                                color: "#CF001C",
                              },
                            }}
                          />
                        </TableCell>
                      )}
                      {/* Serial Number */}
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "50px",
                        }}
                      >
                        {row.sNo}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "60px",
                        }}
                      >
                        {row.ticketNo}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "150px",
                        }}
                      >
                        {row.flatNo}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "100px",
                        }}
                      >
                        {row.subject}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "100px",
                        }}
                      >
                        {row.category}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "70px",
                        }}
                      >
                        {row.level}
                      </TableCell>

                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "70px",
                        }}
                      >
                        {row.assigned_type}
                      </TableCell>

                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "120px",
                        }}
                      >
                        {row.loggedBy}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "120px",
                        }}
                      >
                        {row.loggedOn}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "120px",
                        }}
                      >
                        <div>
                          <span
                            onClick={
                              getPermission(loginData, [
                                permissions.OPEN_CLOSE_COMPLAINT_PERMISSION,
                              ])
                                ? () =>
                                  setShowChangeStatusModal({
                                    id: row.id,
                                    open: true,
                                  })
                                : undefined
                            }
                            className="underline"
                          >
                            {row?.status}
                          </span>
                          <ChangeComplaintStatusModal
                            isOpen={
                              showChangeStatusModal.open &&
                              row.id === showChangeStatusModal.id
                            }
                            status={row?.status}
                            id={row?.id}
                            showChangeStatusModal={showChangeStatusModal}
                            onClose={() => setShowChangeStatusModal(false)}
                            onConfirm={(id, value) => {
                              handleChangeStatus(row.id, value);
                            }}
                            loading={updateLoader}
                          />
                        </div>
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "120px",
                        }}
                      >
                        {row.aging}
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                        }}
                        align="left"
                      >
                        {row?.id == review?.[row?.id - 100]?.id &&
                          row?.review_rating !=
                          review?.[row?.id - 100]?.rating ? (
                          "Updating"
                        ) : (
                          <RatingComp2
                            onChangeReview={setReview}
                            getTableData={getTableData}
                            row={row}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          borderBottom: "none",
                          fontSize: "13px",
                          py: 1.5,
                          pl: 0,
                          pr: 1,
                          wordBreak: "break-word",
                          width: "130px",
                        }}
                      >
                        <ActionComponent status={row.status} id={row.id} />
                      </TableCell>
                    </TableRowStyled>
                  );
                }
              )}
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

ComplaintDashboardTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default ComplaintDashboardTable;
