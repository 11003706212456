import * as type from "../../../actions/index.types";

export function invoiceSettingReducer(
  state = {
    loading: false,
    updateLoader: false,
    gstAndNOCDetails: null,
    error: null,
    typeLists: [],
  },
  action
) {
  switch (action.type) {
    case type.GET_INVOICE_HEAD_SETTING:
      return {
        ...state,
        loading: true,
        error: null,
        invoiceHeadSetting: null,
        glCode: null,
      };
    case type.GET_INVOICE_HEAD_SETTING_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        invoiceHeadSetting: action.payload,
        glCode: action.glCode,
      };
    case type.GET_INVOICE_HEAD_SETTING_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        invoiceHeadSetting: null,
        glCode: null,
      };

    case type.UPDATE_INVOICE_HEAD_SETTING:
      return {
        ...state,
        updateLoader: true,
      };
    case type.UPDATE_INVOICE_HEAD_SETTING_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };

    case type.UPDATE_INVOICE_HEAD_SETTING_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };

    case type.GET_GL_ACCOUNT_LIST:
      return {
        ...state,
        loading: true,
        glCodeList: null,
      };
    case type.GET_GL_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        glCodeList: action.payload,
      };

    case type.GET_GL_ACCOUNT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        glCodeList: null,
      };
    case type.ADD_MORE_HEAD:
      return {
        ...state,
        updateLoader: true,
      };
    case type.ADD_MORE_HEAD_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };

    case type.ADD_MORE_HEAD_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };
    case type.EDIT_MORE_HEAD_DETAILS:
      return {
        ...state,
        updateLoader: true,
      };
    case type.EDIT_MORE_HEAD_DETAILS_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };

    case type.EDIT_MORE_HEAD_DETAILS_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };
    case type.DELETE_MORE_HEAD_DETAILS:
      return {
        ...state,
        deleteLoader: true,
      };
    case type.DELETE_MORE_HEAD_DETAILS_SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };

    case type.DELETE_MORE_HEAD_DETAILS_FAILURE:
      return {
        ...state,
        deleteLoader: false,
      };
    case type.GET_MORE_HEAD_DETAILS:
      return {
        ...state,
        loading: true,
        headDeails: null,
      };
    case type.GET_MORE_HEAD_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        headDeails: action.payload,
      };

    case type.GET_MORE_HEAD_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        headDeails: null,
      };
    case type.DELETE_UTILITY_SLAB:
      return {
        ...state,
        updateLoader: true,
      };
    case type.DELETE_UTILITY_SLAB_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };

    case type.DELETE_UTILITY_SLAB_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };
    case type.ADD_UTILITY_SLAB:
      return {
        ...state,
        addLoader: true,
      };
    case type.ADD_UTILITY_SLAB_SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case type.ADD_UTILITY_SLAB_FAILURE:
      return {
        ...state,
        addLoader: false,
      };
    case type.GET_INVOICE_FUNCTIONAL_SETTING:
      return {
        ...state,
        loading: true,
        error: null,
        invoiceSettings: null,
      };
    case type.GET_INVOICE_FUNCTIONAL_SETTING_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        invoiceSettings: action.payload,
      };
    case type.GET_INVOICE_FUNCTIONAL_SETTING_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        invoiceSettings: [],
      };
    case type.SAVE_INVOICE_FUNCTIONAL_SETTING:
      return {
        ...state,
        updateLoader: true,
      };
    case type.SAVE_INVOICE_FUNCTIONAL_SETTING_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.SAVE_INVOICE_FUNCTIONAL_SETTING_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };
    case type.GET_INVOICE_SERIES_LIST:
      return {
        ...state,
        loading: true,
        error: null,
        serierLists: [],
      };
    case type.GET_INVOICE_SERIES_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        serierLists: action.payload,
      };
    case type.GET_INVOICE_SERIES_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        serierLists: [],
      };
    case type.DELETE_INVOICE_SERIES:
      return {
        ...state,
        updateLoader: true,
      };
    case type.DELETE_INVOICE_SERIES_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.DELETE_INVOICE_SERIES_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };
    case type.ADD_INVOICE_SERIES:
      return {
        ...state,
        addLoader: true,
      };
    case type.ADD_INVOICE_SERIES_SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case type.ADD_INVOICE_SERIES_FAILURE:
      return {
        ...state,
        addLoader: false,
      };
    case type.GET_TAXABLE_FORMAT_LIST:
      return {
        ...state,
        loading: true,
        error: null,
        taxableLists: null,
      };
    case type.GET_TAXABLE_FORMAT_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        taxableLists: action.payload,
      };
    case type.GET_TAXABLE_FORMAT_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        taxableLists: [],
      };
    case type.DELETE_TAXABLE_MAIN_HEAD:
      return {
        ...state,
        updateLoader: true,
      };
    case type.DELETE_TAXABLE_MAIN_HEAD_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.DELETE_TAXABLE_MAIN_HEAD_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };
    case type.ADD_TAXABLE_MAIN_HEAD:
      return {
        ...state,
        addLoader: true,
      };
    case type.ADD_TAXABLE_MAIN_HEAD_SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case type.ADD_TAXABLE_MAIN_HEAD_FAILURE:
      return {
        ...state,
        addLoader: false,
      };
    case type.ACTION_TAXABLE_SUB_HEAD:
      return {
        ...state,
        addLoader: true,
      };
    case type.ACTION_TAXABLE_SUB_HEAD_SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case type.ACTION_TAXABLE_SUB_HEAD_FAILURE:
      return {
        ...state,
        addLoader: false,
      };
    case type.ADD_TAXABLE_SUB_HEAD:
      return {
        ...state,
        addLoader: true,
      };
    case type.ADD_TAXABLE_SUB_HEAD_SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case type.ADD_TAXABLE_SUB_HEAD_FAILURE:
      return {
        ...state,
        addLoader: false,
      };
    case type.CHANGE_ORDER_TAXABLE_HEAD:
      return {
        ...state,
        updateLoader: true,
      };
    case type.CHANGE_ORDER_TAXABLE_HEAD_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case type.CHANGE_ORDER_TAXABLE_HEAD_FAILURE:
      return {
        ...state,
        updateLoader: false,
      };
    case type.GET_INVOICE_TYPE_LIST:
      return {
        ...state,
        loading: true,
        error: null,
        typeLists: [],
      };
    case type.GET_INVOICE_TYPE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        typeLists: action.payload,
      };
    case type.GET_INVOICE_TYPE_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        typeLists: [],
      };
    case type.ADD_INVOICE_TYPE:
      return {
        ...state,
        addLoader: true,
      };
    case type.ADD_INVOICE_TYPE_SUCCESS:
      return {
        ...state,
        addLoader: false,
      };
    case type.ADD_INVOICE_TYPE_FAILURE:
      return {
        ...state,
        addLoader: false,
      };
    case type.DELETE_INVOICE_TYPE:
      return {
        ...state,
        deleteLoader: true,
      };
    case type.DELETE_INVOICE_TYPE_SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };
    case type.DELETE_INVOICE_TYPE_FAILURE:
      return {
        ...state,
        deleteLoader: false,
      };
    case type.GET_INVOICE_HEAD_LIST:
      return {
        ...state,
        headlist: [],
      };
    case type.GET_INVOICE_HEAD_LIST_SUCCESS:
      return {
        ...state,
        headlist: action.payload,
      };
    case type.GET_INVOICE_HEAD_LIST_FAILURE:
      return {
        ...state,
        headlist: [],
      };
    case type.GET_BANK_DETAIL_LIST:
      return {
        ...state,
        bankList: [],
      };
    case type.GET_BANK_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        bankList: action.payload,
      };
    case type.GET_BANK_DETAIL_LIST_FAILURE:
      return {
        ...state,
        bankList: [],
      };
    case type.GET_CREDIT_NOTE_SETTINGS_DETAIL:
      return {
        ...state,
        loading: true,
        settingList: [],
      };
    case type.GET_CREDIT_NOTE_SETTINGS_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        settingList: action.payload,
      };
    case type.GET_CREDIT_NOTE_SETTINGS_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        settingList: [],
      };
    case type.EDIT_TAXABLE_MAIN_HEAD:
      return {
        ...state,
        editLoader: true,
      };
    case type.EDIT_TAXABLE_MAIN_HEAD_SUCCESS:
      return {
        ...state,
        editLoader: false,
      };
    case type.EDIT_TAXABLE_MAIN_HEAD_FAILURE:
      return {
        ...state,
        editLoader: false,
      };
    case type.GET_MEMBER_LEDGER_LIST:
      return {
        ...state,
        loading: true,
        ledgerList: []
      };
    case type.GET_MEMBER_LEDGER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ledgerList:action.payload
      };
    case type.GET_MEMBER_LEDGER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        ledgerList:[]
      };
    default:
      return state;
  }
}
