import React, { useEffect } from "react";
import Dropdown2, { DropdownFM2 } from "../../../../../components/Dropdown/dropdown2";
import { Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getFDMaturityDueReminderDetails, getMemberListAccordingToRole, updateFDMaturityDueReminderDetails } from "../../../../../redux/actions/FinanceActions/SettingActions/DueRemindersAction";
import Button from "../../../../../components/Button/Button";
import Loader from "../../../../../components/Loader/Loader";
import { FDMaturityDueReminderValidation } from "../../../../../validationSchema/financeSchema/settingSchema";
import { getRoleList, getRoleWiseList } from "../../../../../redux/actions/ClubActions/AddFacilityAction";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";
import { MultiSelectFM } from "../../../../../components/MultiSelect/MultiSelect";
import { getOccupantsRolesList } from "../../../../../redux/actions/SetupRecordsActions/ManageRolesAndRightsActions/ManageRolesAndRightsAction";

const UpdateReminder = () => {
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, fdMaturityDueReminderData, memberListAccRole} = useSelector(state=>state.dueReminderReducer)
  const {employeeRoleList, managingCommitteeRoleList, employeeList, managingCommitteeList} = useSelector(state=>state.addFacilityReducer)
  const {occupantsRoleList} = useSelector(state=>state.manageRoleAndRightsReducer)

  const getListAccordingToRole = (key, id)=>{
    let data={
      roleType: key,
      roleTypeId: id,
      societyId: loginData?.default_community?.community_id,
    }
    dispatch(getRoleWiseList(data))
  }


  const handleSubmit = (values, {resetForm})=>{
    dispatch(updateFDMaturityDueReminderDetails({...values, societyId: loginData?.default_community?.community_id}))
  }
  
  useEffect(()=>{
    let data={
      societyId: loginData?.default_community?.community_id,
    }
    dispatch(getFDMaturityDueReminderDetails(data))
    dispatch(getOccupantsRolesList(data))
    dispatch(getRoleList({...data, roleType:'Employees'}))
    dispatch(getRoleList({...data, roleType:'Managing Staff'}))
  }, [])

  useEffect(()=>{
    let data={
      societyId: loginData?.default_community?.community_id,
    }
    
    if(fdMaturityDueReminderData){
      if(fdMaturityDueReminderData?.employee_type){
        dispatch(getRoleWiseList({...data, roleType:'Employees',roleTypeId: fdMaturityDueReminderData?.employee_type}))
      }
      if(fdMaturityDueReminderData?.managing_committees){
        dispatch(getRoleWiseList({...data, roleType:'Managing Staff',roleTypeId: fdMaturityDueReminderData?.managing_committees}))
      }
      if(fdMaturityDueReminderData?.member_type){
        dispatch(getMemberListAccordingToRole({...data, roleId:fdMaturityDueReminderData?.member_type}))
      }
    }

  }, [fdMaturityDueReminderData])

  return (
    <div>
      <Formik
          initialValues={{  
            noOfDaysBeforeDue: fdMaturityDueReminderData?.day_prior || '',
            employeeTypeId: fdMaturityDueReminderData?.employee_type || '',
            employeeIds: fdMaturityDueReminderData?.employee ? fdMaturityDueReminderData?.employee?.split(',')?.filter(item=> item) : [],
            memberTypeId: fdMaturityDueReminderData?.member_type || '',
            memberIds: fdMaturityDueReminderData?.member ? fdMaturityDueReminderData?.member?.split(',')?.filter(item=> item) : [],
            managingCommitteeId: fdMaturityDueReminderData?.managing_committees || '',
            managingCommitteeMemberIds: fdMaturityDueReminderData?.mc_member ? fdMaturityDueReminderData?.mc_member?.split(',')?.filter(item=> item) : [],
          }}
          enableReinitialize
          validationSchema={FDMaturityDueReminderValidation}
          onSubmit={handleSubmit}
        >
          {
            ({values, errors, setFieldValue})=>(
              <Form>
                <div className="flex py-1.5 justify-end border-b border-[#CCC]">
                  <Button
                    type='submit'
                    label={
                      <span className="w-16 inline-block">
                        {
                        updateLoader ?
                          <CircularProgress sx={{color: "white"}} size={17}/>
                          :
                          <>Save</>
                        }
                      </span>
                      }
                      disabled={loading || updateLoader}
                  />
                </div>
                {
                  loading ?
                  <Loader/>
                  :
                  <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
                    <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
                      <h1 className="text-base items-center p-2 -mb-2 ml-1">
                        Update Reminder
                      </h1>
                    </div>
                    <div className=" p-2">
                      <div className="flex flex-col mt-2">
                        <div className="w-1/2">
                          <div className="flex justify-between gap-6 items-center">
                            <span className="text-sm">FD Maturity Reminder (Days)</span>
                            <TextInputFM
                              name={`noOfDaysBeforeDue`}
                              placeholder="Enter No Of Days"
                              type='number'
                              className='w-60'
                              label=''
                            />
                          </div>
                          <div className="text-[13px] font-semibold mt-4">Choose Members To Send FD Maturity Email And SMS Reminder</div>
                          <div className="flex justify-between mt-4 z-10">
                            <span className="text-sm mt-2">Employee Type</span>
                            <DropdownFM2
                              options={employeeRoleList?.map((item)=>({
                                label: item.title,
                                value: item?.id
                              }))|| []}
                              placeholder="Select Employee Type"
                              className=""
                              width="242px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue('employeeTypeId', selectedItem.value)
                                setFieldValue('employeeIds', [])
                                getListAccordingToRole('Employees', selectedItem.value)
                              }}
                              value={
                                values.employeeTypeId ? 
                                {
                                  label: employeeRoleList?.find(item=> item.id === parseInt(values.employeeTypeId))?.title,
                                  value: parseInt(values.employeeTypeId)
                                }
                                :null
                              }
                              name={"employeeTypeId"}
                            />
                          </div>
                          <div className="flex justify-between z-10">
                            <span className="text-sm mt-2">Employee</span>
                            <MultiSelectFM
                              name='employeeIds'
                              options={
                                employeeList?.map((item) => ({
                                  label: item?.full_name,
                                  value: item.bridge_table_id,
                                })) || []
                              }
                              width="242px"
                              height={"fit-content"}
                              placeholder="Select Employee"
                              className="text-xs"
                              onSelect={(selectedItem) => {
                                setFieldValue(
                                  "employeeIds",
                                  selectedItem.map((item) => item.value)
                                );
                              }}
                              isMulti={true}
                              value={values?.employeeIds?.map((item) => ({
                                label: employeeList?.find(
                                  (person) => person.bridge_table_id === parseInt(item)
                                )?.full_name,
                                value: parseInt(item),
                              }))}
                            />
                          </div>
                          <div className="flex justify-between z-10">
                            <span className="text-sm">Member Type</span>
                            <DropdownFM2
                              options={occupantsRoleList?.map((item)=>({
                                label: item.title,
                                value: item?.occupant_role_id
                              }))|| []}
                              placeholder="Select Meember Type"
                              className=""
                              width="242px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue('memberTypeId', selectedItem.value)
                                setFieldValue('memberIds', [])
                                dispatch(getMemberListAccordingToRole({societyId: loginData?.default_community?.community_id, roleId: selectedItem.value}))
                              }}
                              value={
                                values.memberTypeId ? 
                                {
                                  label: occupantsRoleList?.find(item=> item.occupant_role_id === parseInt(values.memberTypeId))?.title,
                                  value: parseInt(values.memberTypeId)
                                }
                                :null
                              }
                              name={"memberTypeId"}
                            />
                          </div>
                          <div className="flex justify-between z-10">
                            <span className="text-sm">Member</span>
                            <MultiSelectFM
                              name='memberIds'
                              options={
                                memberListAccRole?.map((item) => ({
                                  label: item?.subscriber_name,
                                  value: item.id,
                                })) || []
                              }
                              width="242px"
                              height={"fit-content"}
                              placeholder="Select Member"
                              className="text-xs"
                              onSelect={(selectedItem) => {
                                setFieldValue(
                                  "memberIds",
                                  selectedItem.map((item) => item.value)
                                );
                              }}
                              isMulti={true}
                              value={values?.memberIds?.map((item) => ({
                                label: memberListAccRole?.find(
                                  (person) => person.id === parseInt(item)
                                )?.subscriber_name,
                                value: parseInt(item),
                              }))}
                            />
                          </div>
                          <div className="flex justify-between z-10">
                            <span className="text-sm mt-2">Managing Committees Type</span>
                            <DropdownFM2
                            options={managingCommitteeRoleList?.map((item)=>({
                              label: item.title,
                              value: item?.id
                            }))|| []}
                            placeholder="Select managing Committee"
                            className=""
                            width="242px"
                            height="36px"
                            onSelect={(selectedItem) => {
                              setFieldValue('managingCommitteeId', selectedItem.value)
                              setFieldValue('managingCommitteeMemberIds', [])
                              getListAccordingToRole('Managing Staff', selectedItem.value)
                            }}
                            value={
                              values.managingCommitteeId ? 
                              {
                                label: managingCommitteeRoleList?.find(item=> item.id === parseInt(values.managingCommitteeId))?.title,
                                value: parseInt(values.managingCommitteeId)
                              }
                              :null
                            }
                            name={"managingCommitteeId"}
                          />
                          </div>
                          <div className="flex justify-between z-10">
                            <span className="text-sm mt-2">Managing Committee Member</span>
                            <MultiSelectFM
                              name='managingCommitteeMemberIds'
                              options={
                                managingCommitteeList?.map((item) => ({
                                  label: item?.full_name,
                                  value: item.subscriber_id,
                                })) || []
                              }
                              width="242px"
                              height={"fit-content"}
                              placeholder="Search Member"
                              className="text-xs"
                              onSelect={(selectedItem) => {
                                setFieldValue(
                                  "managingCommitteeMemberIds",
                                  selectedItem.map((item) => item.value)
                                );
                              }}
                              isMulti={true}
                              value={values.managingCommitteeMemberIds ? values?.managingCommitteeMemberIds?.map((item) => ({
                                label: managingCommitteeList?.find(
                                  (person) => person.subscriber_id === parseInt(item)
                                )?.full_name,
                                value: parseInt(item),
                              })) : null}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </Form>
            )}
            </Formik>
    </div>
  );
};

export default UpdateReminder;
