import React from "react";
import {
  MAKE_PAYMENT_ENDPOINT,
  MANAGE_DEBIT_CURRENT_ENDPOINT,
  MANAGE_INVOICE_CURRENT_ENDPOINT,
} from "../../../../../Routing/routes";
import Layout from "../../../../Layout/Layout";
import { Navigate, useLocation } from "react-router-dom";

const PaymentSuccess = () => {
  const data = useLocation().state;
  if (data)
    return (
      <Layout>
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-white p-8 border w-full max-w-md">
              <h2 className="text-2xl font-bold text-green-600 mb-4">
                Payment Successful!
              </h2>
              {data?.invoice_no && (
                <div className="mb-4">
                  <span className="font-semibold">Invoice Number:</span>
                  <span className="ml-2 text-gray-700">{data?.invoice_no}</span>
                </div>
              )}

              {data?.payments[0]?.transactionid && (
                <div className="mb-4">
                  <span className="font-semibold">Transaction ID:</span>
                  <span className="ml-2 text-gray-700">
                    {data?.payments[0]?.transactionid}
                  </span>
                </div>
              )}
              {data?.grandtotal && (
                <div className="mb-4">
                  <span className="font-semibold">Total Amount:</span>
                  <span className="ml-2 text-gray-700">
                    {Math.round(data?.grandtotal)}
                  </span>
                </div>
              )}
              {data?.payments[0]?.grandtotal && (
                <div className="mb-4">
                  <span className="font-semibold">Total Paid:</span>
                  <span className="ml-2 text-gray-700">
                    {data?.payments[0]?.grandtotal}
                  </span>
                </div>
              )}
              {data?.payments[0]?.receipt_no && (
                <div className="mb-4">
                  <span className="font-semibold">Receipt Number:</span>
                  <span className="ml-2 text-gray-700">
                    {data?.payments[0]?.receipt_no}
                  </span>
                </div>
              )}

              <button
                onClick={() =>
                  (window.location.href = MANAGE_DEBIT_CURRENT_ENDPOINT)
                }
                className="w-full bg-gray-500 text-white py-2 rounded hover:bg-gray-700 transition duration-300"
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </Layout>
    );
  else return <Navigate to={MANAGE_DEBIT_CURRENT_ENDPOINT} />;
};

export default PaymentSuccess;
