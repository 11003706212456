import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CREDIT_DEBIT_NOTE_CAUSE_ENDPOINT } from "../../../Routing/routes";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import ButtonG from "../../../components/Button/ButtonG";
import Button from "../../../components/Button/Button";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import TextRadio from "../../../components/InputFields/TextRadio";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addCreditDebitTariffHeaders,
  getGLMappingList,
} from "../../../redux/actions/FinanceActions/SettingActions/CreditDebitTariffHeaderAction";
import { Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { AddTariffHeaderValidation } from "../../../validationSchema/financeSchema/settingSchema";

const AddCause = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { updateLoader, glMappingList } = useSelector(
    (state) => state.creditDebitTariffHeaderReducer
  );

  const handleCancelClick = () => {
    navigate(CREDIT_DEBIT_NOTE_CAUSE_ENDPOINT);
  };

  const handleRadioChange = (value, key, setFieldValue) => {
    setFieldValue(key, value);
  };

  const typeOptions = [
    { name: "type", value: "debitnote", label: "Debit Note" },
    { name: "type", value: "creditnote", label: "Credit Note" },
  ];

  const gstOptions = [
    { name: "gst", value: "Y", label: "Yes" },
    { name: "gst", value: "N", label: "No" },
  ];

  const serviceOptions = [
    { name: "service", value: "service", label: "Service" },
    { name: "service", value: "good", label: "Goods" },
  ];

  useEffect(() => {
    dispatch(
      getGLMappingList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 mb-5">
        <Formik
          initialValues={{
            orderNo: "",
            cause: "",
            glMappingId: "",
            amount: "",
            hsnCode: "",
            type: "debitnote",
            gst: "N",
            service: "service",
          }}
          onSubmit={(values, { resetForm }) =>
            dispatch(
              addCreditDebitTariffHeaders({
                ...values,
                societyId: loginData?.default_community?.community_id,
                onSuccessCallback: () => {
                  resetForm();
                },
              })
            )
          }
          enableReinitialize
          validationSchema={AddTariffHeaderValidation}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Add Credit/Debit Note Tariff Header">
                    <ButtonG
                      label="Cancel"
                      onClick={handleCancelClick}
                      type="button"
                    />
                    <Button
                      label={
                        <span className="w-12 inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      disabled={updateLoader}
                      className="px-5"
                      type="submit"
                    />
                  </ListHeader>
                </div>
                <div className="flex justify-between p-2">
                  <div className="flex flex-col w-1/2 pr-5">
                    <TextInputFM
                      label="Order No."
                      placeholder="Enter Order No."
                      className="w-56"
                      name="orderNo"
                      type="number"
                      maxLength={15}
                    />
                    <TextAreaFM
                      label="Tariff Head"
                      placeholder="Enter Tariff"
                      className="w-56"
                      name="cause"
                      row={4}
                    />
                    <div className="flex justify-between">
                      <span className="text-sm mt-2">GL Mapping</span>
                      <DropdownFM2
                        options={
                          glMappingList?.map((item) => ({
                            label: item.name,
                            value: item.ac_code,
                          })) || []
                        }
                        width="224px"
                        height="38px"
                        placeholder="Select"
                        className="text-xs"
                        onSelect={(selectedItem) => {
                          setFieldValue("glMappingId", selectedItem.value);
                        }}
                        name="glMappingId"
                        {...(values.glMappingId === "" && { value: null })}
                      />
                    </div>
                    <TextInputFM
                      label="Amount"
                      placeholder="Enter Amount"
                      className="w-56"
                      name="amount"
                      type="decimal"
                      maxLength={15}
                    />
                    <TextInputFM
                      label="HSN Code"
                      placeholder="Enter Code"
                      className="w-56"
                      name="hsnCode"
                      type="decimal"
                      maxLength={15}
                    />

                    <div className="my-2">
                      <TextRadio
                        label="Type"
                        options={typeOptions}
                        defaultSelected={values.type}
                        onChange={(value) =>
                          handleRadioChange(value, "type", setFieldValue)
                        }
                        className={"w-56 mb-1.5"}
                      />
                    </div>
                    <div className="my-2">
                      <TextRadio
                        label="GST Applicable"
                        options={gstOptions}
                        defaultSelected={values.gst}
                        onChange={(value) =>
                          handleRadioChange(value, "gst", setFieldValue)
                        }
                        className={"w-56 mb-1.5"}
                      />
                    </div>
                    <div className="my-2">
                      <TextRadio
                        label="Service/Goods"
                        options={serviceOptions}
                        defaultSelected={values.service}
                        onChange={(value) =>
                          handleRadioChange(value, "service", setFieldValue)
                        }
                        className={"w-56 mb-1.5"}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default AddCause;
