import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getReceiptDetails } from '../../../redux/actions/FinanceActions/BillingReportsAction';
import Layout from '../../Layout/Layout';
import { useSelector } from 'react-redux';
import ListHeaderBack from '../../../components/ListHeader/ListHeaderBack';
import moment from 'moment';
import { toWords } from 'number-to-words'
import Loader from '../../../components/Loader/Loader';
import { getFlatDetails } from '../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction';
import { validateDate } from '../../../utils/helpers/universalFunctions';

const invoiceDetails = [
    { "key": "propertytax", "label": "Property Tax" },
    { "key": "watercharges", "label": "Water Charges" },
    { "key": "electriccharges", "label": "Common Electricity Charges" },
    { "key": "repairnmaintenance", "label": "Repairs & Maintenance Fund" },
    { "key": "liftmaintenance", "label": "Expenses on Lift Maintenance" },
    { "key": "sinkingfund", "label": "Sinking Fund Contribution" },
    { "key": "housekeeping", "label": "Service Charges- House Keeping" },
    { "key": "officecharges", "label": "Service Charges- Office & Genral Ch" },
    { "key": "printingcharges", "label": "Service Charges- Printing & Stationery" },
    { "key": "parkingcharges", "label": "Parking Charges" },
    { "key": "defaultedcharges", "label": "Interest on the defaulted Charges" },
    { "key": "loancharges", "label": "Repayment of Loan and Interest" },
    { "key": "nonoccupancycharges", "label": "Non Occupancy Charges" },
    { "key": "insurancecharge", "label": "Insurance Charges" },
    { "key": "leaserent", "label": "Lease Rent" },
    { "key": "agriculturaltax", "label": "Non Agricultural Tax" },
    { "key": "otherchargesfn", "label": "Other Charges- Function Ch." },
    { "key": "othercharges", "label": "Other Charges-" },
    { "key": "repairfund", "label": "Major Repair Fund" },
    { "key": "paintingfund", "label": "Painting Fund" },
    { "key": "servicetax", "label": "SGST" },
    { "key": "Sb_Cess", "label": "CGST" },
    { "key": "Kk_Cess", "label": "IGST" },
    { "key": "servicecharge", "label": "Service Charge" },
    { "key": "securitycharges", "label": "Security Charges" },
    { "key": "type", "label": "Invoice" },
    { "key": "watertax", "label": "Water Tax" },
    { "key": "othertax", "label": "Other Tax" },
    { "key": "debitnote", "label": "Debit Note" },
    { "key": "chequereturncharges", "label": "Cheque Return Charges" },
    { "key": "other1", "label": "Other1" },
    { "key": "other2", "label": "Other2" },
    { "key": "other3", "label": "Other3" },
    { "key": "other4", "label": "Other4" },
    { "key": "roundoff", "label": "roundoff" }
]

const arrears = [
    { "key": "Principal Arrears", "label": "Principal Arrears" },
    { "key": "Current Period Interest", "label": "Current Period Interest" },
    { "key": "Debitnote Arrears", "label": "Debitnote Arrears" },
    { "key": "Interest Arrears", "label": "Interest Arrears" },
    { "key": "Past Advances & Dues", "label": "Past Advances & Dues" },
]

function PrintReceiptTransactions() {
    const { id } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { loginData } = useSelector(state => state.loginReducer)
    const { loading, receiptDetails } = useSelector(state => state.billingReportsReducer)
    const { flatsData } = useSelector(state => state.wingsReducer)

    useEffect(() => {
        dispatch(getReceiptDetails({
            societyId: loginData?.default_community?.community_id,
            id
        }))
    }, [])

    useEffect(() => {
        if (receiptDetails) {
            dispatch(getFlatDetails({ societyId: loginData?.default_community?.community_id, wingId: receiptDetails?.societyInvoice?.wing_no }))
        }
    }, [receiptDetails])

    useEffect(() => {
        if (!loading && receiptDetails) {
            window.print()
        }
    }, [loading]);

    return (
        <div className="mx-5 my-8">
            {
                loading && !flatsData ?
                    <Loader />
                    :
                    <>
                        <div className="text-center text-sm">
                            <div className="underline text-xl font-bold">{loginData?.default_community?.community_name}</div>
                            <div>(REGN. NO. {loginData?.default_community?.regn_no}   Dated -{moment(loginData?.default_community?.date_of_regn)?.format('DD-MM-YYYY')})</div>
                            <div>{loginData?.default_community?.office_address}</div>
                            <div>GST Registration No. : {loginData?.default_community?.gst_registration_no}</div>
                        </div>
                        <div>
                            <div className="font-bold text-center mt-8">Receipt</div>
                            <div className="flex justify-between">
                                <div><span className="font-bold">Receipt Number: </span>{receiptDetails?.paymentDetail?.receipt_no}</div>
                                <div><span className="font-bold">Receipt Date: </span>{moment(receiptDetails?.paymentDetail?.date, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')}</div>
                            </div>
                            <div className="flex justify-between">
                                <div><span className="font-bold">Invoice Reference Number: </span>{receiptDetails?.societyInvoice?.invoice_no}</div>
                                <div><span className="font-bold">Invoice Date: </span>{moment(receiptDetails?.societyInvoice?.invoice_date)?.format('DD-MM-YYYY')}</div>
                            </div>
                            <div className="flex justify-between">
                                <div><span className="font-bold">Member Name: </span>{flatsData?.manage_flat_details?.find((detail) => detail.subscriber_id == receiptDetails?.paymentDetail?.member)?.subscriber_name}</div>
                                <div><span className="font-bold">Bill Period: </span>{receiptDetails?.societyInvoice?.bill_period}</div>
                            </div>
                            {receiptDetails?.societyInvoice?.flat_address &&
                                <div className="flex justify-between">
                                    <div className=' w-1/2'><span className="font-bold">Member Address: </span>{receiptDetails?.societyInvoice?.flat_address}</div>
                                    <div><span className="font-bold"> </span> </div>
                                </div>
                            }
                            <div className="flex justify-between">
                                <div><span className="font-bold">Unit : Wing No. -  </span>{receiptDetails?.societyInvoice?.wing_no}, Flat No. - {receiptDetails?.societyInvoice?.flat_no}, Area -  {receiptDetails?.societyInvoice?.flatarea}</div>
                                <div><span className="font-bold">Payment Due Date : </span>{moment(receiptDetails?.societyInvoice?.payment_duedate)?.format('DD-MM-YYYY')}</div>
                            </div>
                            {receiptDetails?.societyInvoice?.parkingno !== "" &&
                                <div className="flex justify-between">
                                    <div><span className="font-bold">Parking No. -  </span>{receiptDetails?.societyInvoice?.parkingno}</div>
                                    <div><span className="font-bold"> </span> </div>
                                </div>
                            }
                            {receiptDetails?.societyInvoice?.gst_registration_no !== "" &&
                                <div className="flex justify-between">
                                    <div><span className="font-bold">GST Registration No. -  </span>{receiptDetails?.societyInvoice?.gst_registration_no}</div>
                                    <div><span className="font-bold"> </span> </div>
                                </div>
                            }
                            <table className='w-full mt-2'>
                                <tbody className='border '>
                                    <tr>
                                        <td colspan="2">
                                            <table className='w-full border border-black border-collapse table-fixed'>
                                                <tbody className='text-sm'>
                                                    <tr style={{ background: '#e6e6e6' }}>
                                                        <th className='px-2 border border-black' align="left">Item</th>
                                                        <th className='px-2 border border-black' align="left">Bill Amount (Rs.)</th>
                                                        <th className='px-2 border border-black' align="left">Amount Paid (Rs.)</th>
                                                    </tr>
                                                    {
                                                        invoiceDetails?.map((item) => (
                                                            !!parseFloat(receiptDetails?.societyInvoice?.[item?.key]) ?
                                                                <tr>
                                                                    <td className='px-2 border border-black'>{item?.label}</td>
                                                                    <td className='px-2 border border-black'>{(receiptDetails?.societyInvoice?.[item?.key] == "0") ? "0.00" : Number(receiptDetails?.societyInvoice?.[item?.key]).toFixed(2)}</td>
                                                                    <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.[item?.key]}</td>
                                                                </tr>
                                                                :
                                                                <></>
                                                        ))
                                                    }
                                                    {/* <tr>
                                                        <td className='px-2 border border-black'>&nbsp;</td>
                                                        <td className='px-2 border border-black'>&nbsp;</td>
                                                    </tr> */}
                                                    <tr>
                                                        <td className='px-2 border border-black'><b>Sub Total <b></b></b></td>
                                                        <td className='px-2 border border-black'><b>{receiptDetails?.societyInvoice?.subtotal}<b></b></b></td>
                                                        <td className='px-2 border border-black'><b>{receiptDetails?.paymentDetail?.subtotal}<b></b></b></td>
                                                    </tr>
                                                    {receiptDetails?.paymentDetail.modeofpayment !== 'Online' &&
                                                        <>
                                                            {arrears?.map((item) => (
                                                                <tr>
                                                                    <td className='px-2 border border-black'>{item?.label}</td>
                                                                    <td className='px-2 border border-black'>{receiptDetails?.billamount_arrears.find((arrear) => arrear.head == item.label).amount || "0.00"}</td>
                                                                    <td className='px-2 border border-black'>{receiptDetails?.amount_paid_arrears.find((arrear) => arrear.head == item.label)?.amount || "0.00"}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    }
                                                    {receiptDetails?.paymentDetail.advance !== "0" && receiptDetails?.paymentDetail.advance !== null &&
                                                        <tr>
                                                            <td className='px-2 border border-black'>Advance</td>
                                                            <td className='px-2 border border-black'>
                                                                {Number(receiptDetails?.societyInvoice?.advance) < 0
                                                                    ? receiptDetails?.societyInvoice?.advance.startsWith("-") && `(${receiptDetails?.societyInvoice?.advance.split("-")[1]})`
                                                                    : (receiptDetails?.societyInvoice?.advance && Number(receiptDetails?.societyInvoice?.advance) !== 0) ? Number(receiptDetails?.societyInvoice?.advance).toFixed(2) || "0.00" : "0.00"
                                                                }
                                                            </td>
                                                            <td className='px-2 border border-black'>
                                                                {Number(receiptDetails?.paymentDetail?.advance) < 0
                                                                    ? receiptDetails?.paymentDetail?.advance.toString().startsWith("-") && `(${receiptDetails?.paymentDetail?.advance.toString().split("-")[1]})`
                                                                    : (receiptDetails?.paymentDetail?.advance && Number(receiptDetails?.paymentDetail?.advance) !== 0) ? Number(receiptDetails?.paymentDetail?.advance).toFixed(2) || "0.00" : "0.00"
                                                                }
                                                            </td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <td className='px-2 border border-black'><strong>Grand Total</strong></td>
                                                        <td className='px-2 border border-black'><strong>{
                                                            // receiptDetails?.societyInvoice?.grandtotal
                                                            Number(receiptDetails?.societyInvoice?.grandtotal) < 0
                                                                ? receiptDetails?.societyInvoice?.grandtotal.toString().startsWith("-") && `(${receiptDetails?.societyInvoice?.grandtotal.toString().split("-")[1]})`
                                                                : (receiptDetails?.societyInvoice?.grandtotal && Number(receiptDetails?.societyInvoice?.grandtotal) !== 0) ? Number(receiptDetails?.societyInvoice?.grandtotal).toFixed(2) || "0.00" : "0.00"
                                                        }</strong></td>
                                                        <td className='px-2 border border-black'><strong>{
                                                            Number(receiptDetails?.paymentDetail?.grandtotal) < 0
                                                                ? receiptDetails?.paymentDetail?.grandtotal.toString().startsWith("-") && `(${receiptDetails?.paymentDetail?.grandtotal.toString().split("-")[1]})`
                                                                : (receiptDetails?.paymentDetail?.grandtotal && Number(receiptDetails?.paymentDetail?.grandtotal) !== 0) ? Number(receiptDetails?.paymentDetail?.grandtotal).toFixed(2) || "0.00" : "0.00"
                                                        }</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" className="capitalize px-2 border border-black"><strong>Amount in words - {receiptDetails?.paymentDetail?.grandtotal ? toWords(parseFloat(receiptDetails?.paymentDetail?.grandtotal)) : ''} Rupees  only</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                            {receiptDetails?.paymentDetail?.paymentType !== "Adjustment" &&
                                <>
                                    <div className="font-semibold mt-5">Payment Details</div>
                                    {/* {receiptDetails?.paymentDetail.modeofpayment !== "CASH" && */}
                                        <div className="text-sm mt-3">
                                            <span className="font-semibold mb-3">Reciept No.: {receiptDetails?.paymentDetail.receipt_no}</span>
                                            <table className="w-full table-fixed">
                                                <thead>
                                                    <tr className="font-bold text-left">
                                                        <th className="px-2  border border-black">Mode of Payment</th>
                                                        {
                                                            (receiptDetails?.paymentDetail.modeofpayment === 'NEFT' || receiptDetails?.paymentDetail.modeofpayment === 'Cheque') &&
                                                            <th className="px-2  border border-black">{receiptDetails?.paymentDetail.modeofpayment === 'NEFT' || receiptDetails?.paymentDetail.modeofpayment === 'Online' ? 'Transaction Number' : 'Cheque Number'}</th>
                                                        }
                                                        {
                                                            (receiptDetails?.paymentDetail.modeofpayment === 'Online') &&
                                                            <th className="px-2  border border-black">{receiptDetails?.paymentDetail.modeofpayment === 'Online' && 'Transaction Id'}</th>
                                                        }
                                                        {
                                                            (receiptDetails?.paymentDetail.modeofpayment === 'NEFT' || receiptDetails?.paymentDetail.modeofpayment === 'Cheque') &&
                                                            <th className="px-2  border border-black">Bank Name</th>
                                                        }
                                                        <th className="px-2  border border-black">Amount</th>
                                                        {
                                                            (receiptDetails?.paymentDetail.modeofpayment === 'NEFT' || receiptDetails?.paymentDetail.modeofpayment === 'Cheque') &&
                                                            <th className="px-2  border border-black">{receiptDetails?.paymentDetail.modeofpayment === 'NEFT' ? 'Transaction Date' : 'Cheque Date'}</th>
                                                        }
                                                        <th className="px-2  border border-black">Payment Date</th>
                                                        <th className="px-2  border border-black">Amount Due</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="px-2  border border-black">{receiptDetails?.paymentDetail.modeofpayment}</td>
                                                        {
                                                            (receiptDetails?.paymentDetail.modeofpayment === 'NEFT' || receiptDetails?.paymentDetail.modeofpayment === 'Cheque') &&
                                                            <td className="px-2  border border-black">{receiptDetails?.paymentDetail.modeofpayment === 'NEFT' ? receiptDetails?.paymentDetail?.transaction_number : receiptDetails?.paymentDetail.cheque_number}</td>
                                                        }
                                                        {
                                                            (receiptDetails?.paymentDetail.modeofpayment === 'Online') &&
                                                            <td className="px-2  border border-black">{receiptDetails?.paymentDetail?.transactionid || '-'}</td>
                                                        }
                                                        {
                                                            (receiptDetails?.paymentDetail.modeofpayment === 'NEFT' || receiptDetails?.paymentDetail.modeofpayment === 'Cheque') &&
                                                            <td className="px-2  border border-black">{receiptDetails?.paymentDetail?.bank_name || '-'}</td>
                                                        }
                                                        <td className="px-2  border border-black">{
                                                            // parseFloat(receiptDetails?.paymentDetail.grandtotal)?.toFixed(2)
                                                            Number(receiptDetails?.paymentDetail.grandtotal) < 0 ?
                                                                receiptDetails?.paymentDetail.grandtotal.toString().startsWith("-") && `(${receiptDetails?.paymentDetail.grandtotal.toString().split("-")[1]})`
                                                                : Number(receiptDetails?.paymentDetail.grandtotal).toFixed(2) || "0.00"
                                                        }</td>
                                                        {
                                                            (receiptDetails?.paymentDetail.modeofpayment === 'NEFT' || receiptDetails?.paymentDetail.modeofpayment === 'Cheque') &&
                                                            <td className="px-2  border border-black">{validateDate(receiptDetails?.paymentDetail.payment_date) ? moment(receiptDetails?.paymentDetail.payment_date)?.format('DD-MM-YYYY') : '-'}</td>
                                                        }
                                                        <td className="px-2  border border-black">{moment(receiptDetails?.paymentDetail.payment_date)?.format('DD-MM-YYYY')}</td>
                                                        <td className="px-2  border border-black">{receiptDetails?.adjustment_details?.dueamount > 0 ? (receiptDetails?.adjustment_details?.dueamount).toFixed(2) : '0.00'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    {/* } */}
                                </>
                            }
                            {receiptDetails?.paymentDetail?.paymentType === "Adjustment" &&
                                <>
                                    <div className="font-semibold mt-5">Adjustment Details</div>
                                    <div className="text-sm mt-3">
                                        {/* <span className="font-semibold mb-3">Reciept No.: {receiptDetails?.paymentDetail.receipt_no}</span> */}
                                        <table className="w-full table-fixed">
                                            <thead>
                                                <tr className="font-bold text-left">
                                                    <th className="px-2  border border-black">Debit Account</th>
                                                    <th className="px-2  border border-black">Credit Account</th>
                                                    <th className="px-2  border border-black">Amount</th>
                                                    <th className="px-2  border border-black">Adjustment Date</th>
                                                    <th className="px-2  border border-black">Amount Due</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="px-2  border border-black">{receiptDetails?.adjustment_details.debit_ac}</td>
                                                    <td className="px-2  border border-black">{receiptDetails?.adjustment_details.credit_ac}</td>
                                                    <td className="px-2  border border-black">{
                                                        Number(receiptDetails?.adjustment_details?.amount) < 0 ?
                                                            receiptDetails?.adjustment_details?.amount.toString().startsWith("-") && `(${receiptDetails?.adjustment_details?.amount.toString().split("-")[1]})`
                                                            : Number(receiptDetails?.adjustment_details?.amount).toFixed(2) || "0.00"
                                                    }</td>
                                                    <td className="px-2  border border-black">{moment(receiptDetails?.paymentDetail.adjustment_date)?.format('DD-MM-YYYY')}</td>
                                                    <td className="px-2  border border-black">{
                                                        Number(receiptDetails?.adjustment_details?.dueamount) < 0 ?
                                                            receiptDetails?.adjustment_details?.dueamount.toString().startsWith("-") && `(${receiptDetails?.adjustment_details?.dueamount.toString().split("-")[1]})`
                                                            : Number(receiptDetails?.adjustment_details?.dueamount).toFixed(2) || "0.00"
                                                    }</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                            <div className="my-5">
                                This is a system generated receipt and does not require a signature.In case of any discrepency in the receipt, immediately inform the issuing authority.
                                <div className="my-3">
                                    Generated By:
                                    <span className="font-semibold">{loginData?.default_community?.community_name} </span>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div >
    )
}

export default PrintReceiptTransactions;