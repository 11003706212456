import React from "react";
import moment from "moment";
import Loader from "../../../../components/Loader/Loader";

const VerifyAndPostStructure = ({ invoiceDetails, loginData, loading }) => {
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <table className="w-full border-collapse border-2 border-[#222]">
            <thead>
              <tr>
                <td colSpan="4" className="border-b-2 border-[#222]">
                  <h2 className="underline text-center text-gray-700 text-2xl font-bold my-4">
                    {loginData?.default_community?.community_name}
                  </h2>
                  <div className="text-center font-normal w-full">
                    (REGN. NO. {loginData?.default_community?.regn_no} Dated -
                    {moment(loginData?.default_community?.date_of_regn)?.format(
                      "DD-MM-YYYY"
                    )}
                    )
                  </div>
                  <div className="text-center font-normal w-full text-gray-700 text-sm">
                    {loginData?.default_community?.office_address}
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  colSpan="1"
                  className="border-b-2 border-r-2 border-[#222] p-1"
                  height="30"
                >
                  Invoice Number : {invoiceDetails?.data?.invoice_no}
                </td>
                <td
                  colSpan="2"
                  className="text-right border-b-2 border-r-2 border-[#222] p-1"
                >
                  Invoice Date :{" "}
                  {moment(invoiceDetails?.data?.invoice_date).format(
                    "DD-MM-YYYY"
                  ) || "-"}
                </td>
              </tr>
              <tr height="30">
                <td
                  colSpan="1"
                  className="border-b-2 border-r-2 border-[#222] p-1"
                >
                  Member Name: {invoiceDetails?.data?.membername}
                </td>
                <td
                  colSpan="2"
                  className="border-b-2 border-r-2 border-[#222] text-right p-1"
                >
                  {/* Bill Period: {invoiceDetails?.data?.invoice_period} */}
                </td>
              </tr>
              <tr height="30">
                <td
                  colSpan="1"
                  className="border-b-2 border-r-2 border-[#222] p-1"
                >
                  Unit: Wing No. - {invoiceDetails?.data?.wing_no} ,Flat No. -{" "}
                  {invoiceDetails?.data?.flat_no}, Area -{" "}
                  {invoiceDetails?.data?.flatarea}
                </td>
                <td
                  colSpan="2"
                  className="border-b-2 border-r-2 border-[#222] text-right p-1"
                >
                  Payment Due Date:{" "}
                  {moment(invoiceDetails?.data?.payment_duedate).format(
                    "DD-MM-YYYY"
                  ) || "-"}
                </td>
              </tr>
              <tr height="30">
                <td
                  colSpan="4"
                  className="border-b-2 border-[#222] text-right p-1"
                >
                  &nbsp;
                </td>
              </tr>
              <tr height="30">
                <th
                  width="33%"
                  className="border-b-2 border-r-2 border-[#222] text-left p-1"
                >
                  Item
                </th>
                <th
                  width="27%"
                  colSpan="4"
                  className="border-b-2 border-r-2 border-[#222] text-left p-1"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {invoiceDetails?.data?.invoice_items.length &&
                invoiceDetails?.data?.invoice_items.map((item, index) => {
                  return (
                    <tr className="border-b-2 border-[#222]" height="30">
                      <td
                        className={`border-b-2 border-r-2 border-[#222] p-1 `}
                      >
                        {item?.name}
                      </td>
                      <td
                        className={`border-b-2 border-r-2 border-[#222] p-1 `}
                      >
                        {Number(item?.amount).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}

              <tr className="border-b-2 border-[#222]" height="30">
                <td
                  className={`border-b-2 border-r-2 border-[#222] p-1 font-semibold`}
                >
                  Sub Total
                </td>
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {Math.round(invoiceDetails?.data?.subtotal).toFixed(2)}
                </td>
              </tr>
              <tr className="border-b-2 border-[#222]" height="30">
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  Principal Arrears
                </td>
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {Number(invoiceDetails?.data?.principal_arrear).toFixed(2)}
                </td>
              </tr>
              <tr className="border-b-2 border-[#222]" height="30">
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  Interest Arrears
                </td>
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {Number(invoiceDetails?.data?.interest_arrear).toFixed(2)}
                </td>
              </tr>
              <tr className="border-b-2 border-[#222]" height="30">
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  Interest
                </td>
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {Number(invoiceDetails?.data?.interest).toFixed(2)}
                </td>
              </tr>
              <tr className="border-b-2 border-[#222]" height="30">
                <td
                  className={`border-b-2 border-r-2 border-[#222] p-1 font-semibold`}
                >
                  Grand Total
                </td>
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {Math.round(invoiceDetails?.data?.grandtotal).toFixed(2)}
                </td>
              </tr>

              <tr className="border-b-2 border-[#222]" height="30">
                <td
                  className={`border-b-2 border-r-2 border-[#222] p-1 font-semibold`}
                >
                  Paid
                </td>
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {Math.round(invoiceDetails?.data?.paid).toFixed(2)}
                </td>
              </tr>
              <tr className="border-b-2 border-[#222]" height="30">
                <td
                  className={`border-b-2 border-r-2 border-[#222] p-1 font-semibold`}
                >
                  Amount Due
                </td>
                <td
                  className={`border-b-2 border-r-2 border-[#222] p-1 font-bold`}
                >
                  {Math.round(invoiceDetails?.data?.due).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default VerifyAndPostStructure;
