import React from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import DocumentsIssuedTable from "./DocumentsIssuedTable";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { ISSUE_DOCUMENT_ENDPOINT } from "../../../Routing/routes";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "50px" },
  { id: "memberFullName", label: "Member Full Name", sort: false, width: "100px" },
  { id: "wingNo", label: "Wing No.", sort: true, width: "90px" },
  { id: "flatNo.", label: "Flat Number", sort: true, width: "100px" },
  { id: "title", label: "Title", sort: true, width: "100px" },
  { id: "issueDate", label: "Issue Date", sort: true, width: "90px"},
  { id: "action", label: "Action", sort: true, width: "90px" },
];

const rows = [
    {
        id:"1",
        sNo: "01",
        memberFullName: "Himanshi Mehta",
        wingNo: "P-Block",
        flatNo: "TPP001",
        title: "NOC for Transfer",
        issueDate: "23-02-2024",
    },
    {
        id:"2",
        sNo: "02",
        memberFullName: "Riya Mehta",
        wingNo: "C-Block",
        flatNo: "TPP002",
        title: "NOC for Passport",
        issueDate: "23-02-2024",
    },
];

const DocumentsIssued = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => () => {
      navigate(path);
    };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="Documents Issued">
            <Button label="Issue Document" onClick={handleNavigation(ISSUE_DOCUMENT_ENDPOINT)} />
        </ListHeader>
        <div className="ml-1">
          <DocumentsIssuedTable
            data={rows}
            columns={headCells}
            checkbox={false}
            pagination={true}
          ></DocumentsIssuedTable>
        </div>
        {!rows?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default DocumentsIssued;
