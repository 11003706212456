import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../../components/TableComponent/Table";
import { useDispatch, useSelector } from "react-redux";
import { getComplaintAgingList } from "../../../redux/actions/DeskActions/DeskAction";
const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false },
  { id: "category", label: "Category", sort: false, width: "180px" },
  { id: "oneWeek", label: "Upto One week", sort: false },
  { id: "oneTwoWeek", label: "1-2 weeks", sort: false },
  { id: "twoToOneMonth", label: "2 weeks to 1 month", sort: false },
  { id: "oneToThreeMonth", label: "1-3 months", sort: false },
  { id: "threeToSixMonth", label: "3-6 months", sort: false },
  { id: "sixToTwelveMonth", label: "6-12 months", sort: false },
  { id: "above12Months", label: "Above 12 months", sort: false },
];

function ComplaintAging() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { complaintAgingList, loading } = useSelector(
    (state) => state.deskReducers
  );

  useEffect(() => {
    dispatch(
      getComplaintAgingList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div>
          <ListHeaderBack
            title="Complaints Aging"
            onClick={() => {
              navigate(-1);
            }}
          ></ListHeaderBack>
        </div>
        <div className="gap-2 px-2">
          <GenericTable
            loading={loading}
            data={complaintAgingList?.map((item) => ({
              id: item.id,
              category: item.complaint_category,
              oneWeek: item.one_week,
              oneTwoWeek: item.one_two_week,
              twoToOneMonth: item.two_week_to_month,
              oneToThreeMonth: item.one_three_month,
              threeToSixMonth: item.three_six_month,
              sixToTwelveMonth: item.six_twelve_month,
              above12Months: item.one_year,
            }))}
            columns={headCells}
            checkbox={false}
            pagination={false}
          />
          {!complaintAgingList?.length && (
            <div className="text-sm text-[#AAAAAA] my-4 text-center">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default ComplaintAging;
