import React, { useEffect, useState } from "react";
import Layout from "../../../../Layout/Layout";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Search from "../../../../../components/SearchBox/Search";
import Button from "../../../../../components/Button/Button";
import ListHeader from "../../../../../components/ListHeader/ListHeader";
import {
  ADD_AGENCY_ENDPOINT,
  ADD_MAINTENANCE_STAFF_FORM_ENDPOINT,
  AGENCY_ENDPOINT,
  MAINTENANCE_STAFF_LIST_ENDPOINT,
} from "../../../../../Routing/routes";
import MaintenanceStaffTable from "./MaintenanceStaffTable";
import { useDispatch, useSelector } from "react-redux";
import {deleteMaintenanceStaffOrAgency, getAgencyList, getMaintenanceStaffList} from '../../../../../redux/actions/SetupRecordsActions/StaffManagementActions/MaintenanceAgencyAction'
import Dropdown2 from "../../../../../components/Dropdown/dropdown2";
import { CircularProgress } from "@mui/material";
import { getPermission } from "../../../../../utils/helpers/getPermissions";
import * as permissions from '../../../../../Routing/permissions'
import ButtonG from "../../../../../components/Button/ButtonG";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "80px" },
  { id: "name", label: "Name Of Staff", sort: false, width: "190px" },
  { id: "agencyName", label: "Agency Name", sort: false, width: "190px" },
  {
    id: "responsibiltiy",
    label: "Responsibility",
    sort: false,
    width: "190px",
  },
  { id: "title", label: "Title", sort: false, width: "190px" },
  { id: "action", label: "Action" },
];


const MaintenanceStaff = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, maintenanceStaffList} = useSelector(state=>state.maintenanceStaffReducer)
  const {agencyList} = useSelector(state=>state.agencyReducer)
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)
  const [selected, setSelected] = useState([]);
  const [action, setAction] = useState('');


  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete('page')
      searchParams.delete("search")
      navigate({
        pathname: `${MAINTENANCE_STAFF_LIST_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text?.trim()]])}`,
      })
    }
  }

  
  const handelSelectAgency = (selectedItem)=>{
    if(selectedItem.value?.toString() !== searchParams.get("agencyId")){
        searchParams.delete("agencyId")
        navigate({
          pathname: `${MAINTENANCE_STAFF_LIST_ENDPOINT}`,
          search: `?${createSearchParams([...searchParams.entries(), ["agencyId", selectedItem.value]])}`,
        })
    }
  }

  const handleClear = () => {
    navigate(MAINTENANCE_STAFF_LIST_ENDPOINT);
  };


  const handleApply = ()=>{
    if(action === 'Delete'){
      let data={
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        searchText: searchParams.get("search") || '',
        pageLimit: searchParams.get("pageLimit") || 10,
        agencyId: searchParams.get("agencyId") || '',
        ids: selected,
        flag: 'staff',
        sessionId: loginData?.session_id, 
        ipAddress ,
        onSuccessCallback: ()=>{
          setSelected([]);
        }
      }
      dispatch(deleteMaintenanceStaffOrAgency(data))
    }
  }

  useEffect(()=>{
    dispatch(getAgencyList({societyId: loginData?.default_community?.community_id}))
  }, [])

  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
      agencyId: searchParams.get("agencyId") || '',
    }

    dispatch(getMaintenanceStaffList(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Maintenance Staff">
            <div className="flex text-sm gap-3">
              {
                getPermission(loginData, [permissions.VIEW_AGENCY_PERMISSION]) &&
                <Link to={AGENCY_ENDPOINT}>
                  <Button label="Agency" className="h-8" />
                </Link>
              }
              {
                getPermission(loginData, [permissions.ADD_AGENCY_PERMISSION]) &&
                <Link to={ADD_AGENCY_ENDPOINT}>
                  <Button label="Add Agency" className="h-8" />
                </Link>
              }
              <div className="border-r border-[#CCC]"></div>
              {
                getPermission(loginData, [permissions.ADD_STAFF_PERMISSION]) &&
                <Link
                  to={ADD_MAINTENANCE_STAFF_FORM_ENDPOINT}
                  className="text-white"
                >
                  <Button label="Add Maintenance Staff" className="h-8" />
                </Link>
              }
            </div>
          </ListHeader>
        </div>

        <div className="py-1 flex justify-between gap-2 my-1">
          <div className="flex">
            <Search height="8" onclick={handleSearch} value={searchParams.get("search")} placeholder="Search staff name.." />
            <div className="ms-3 flex items-center">
              <span className="mr-4 text-[13px]">Agency</span>
              <Dropdown2
                options={agencyList?.list?.map((item)=>({
                  value: item?.agency_id,
                  label: item?.agency_name
                })) || []}
                placeholder="Select Agency"
                className="text-[11px] ms-4"
                width="180px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectAgency(selectedItem)
                }}
                value={
                  searchParams.get("agencyId") && {
                    value: searchParams.get("agencyId"),
                    label: agencyList?.list?.find((item) => item.agency_id?.toString() === searchParams.get("agencyId"))?.agency_name
                  }
                }
              />
            </div>
            <ButtonG label="Clear" className="ml-3 h-8" onClick={handleClear} />
          </div>
          <div className="flex">
            {
              !!selected?.length &&
              <>
                <Dropdown2
                  options={[
                    { value: "Delete", label: "Delete" }
                  ]}
                  placeholder="Choose Action"
                  className="text-[11px]"
                  width="160px"
                  height="32px"
                  onSelect={(selectedItem) => {setAction(selectedItem.value)}}
                />
                <div className="ms-3">
                  <Button label={
                      <span className="w-8 inline-block">
                        {
                          updateLoader ?
                            <CircularProgress sx={{color: "white"}} size={17}/>
                            :
                            'Apply'
                          }
                      </span>
                    } 
                    className="font-semibold px-4 h-8" type='button' onClick={handleApply} disabled={!action} />
                </div>
              </>
            }
          </div>
        </div>
        <div className="ml-1">
          <MaintenanceStaffTable
            loading={loading}
            data={maintenanceStaffList?.list?.map((item, index)=>({
              sNo: (((maintenanceStaffList?.current_page_no - 1) * maintenanceStaffList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
              staffId: item?.agency_staff_id,
              name: item?.name || '-',
              agencyName: item?.agency_name  || '-',
              responsibility: item?.responsibility || '-',
              title: item?.title || '-'
            }))}
            columns={headCells}
            checkbox={true}
            pagination={maintenanceStaffList?.list?.length}
            totalCount={maintenanceStaffList?.total_record}
            totalPages={maintenanceStaffList?.total_pages}
            start={(maintenanceStaffList?.current_page_no - 1) * maintenanceStaffList?.page_limit + 1}
            end={(maintenanceStaffList?.current_page_no - 1) * maintenanceStaffList?.page_limit + maintenanceStaffList?.list?.length}
            currentPage={maintenanceStaffList?.current_page_no}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}

            selected={selected}
            setSelected={setSelected}
          ></MaintenanceStaffTable>
        </div>

        {!maintenanceStaffList?.list?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default MaintenanceStaff;
