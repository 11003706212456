import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import { MANAGE_DEBIT_CURRENT_ENDPOINT } from "../../../../Routing/routes";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ButtonG from "../../../../components/Button/ButtonG";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { societyStaffStatusOptions } from "../../../../consts/DropDownOptions";
import Loader from "../../../../components/Loader/Loader";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import EditDebitDetailBox from "./EditDebitDetailBox";
import { Form, Formik } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getWingsAndFlatDetails,
  getFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  getDebiteNoteTarrifList,
  getDebiteNoteFlatWingWiseList,
  getDebiteNoteArrearDetails,
  addDebiteNote,
  editSingleDebitNoteDetails,
  editSingleDebitNoteSave,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import { getNonMemberList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageAdvanceNoteAction";
import { getBookingGLMappingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
import { getTenantsDetailsList } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import { AddDebitNoteValidation } from "../../../../validationSchema/financeSchema/billingManagementSchema";
import { TextInput } from "../../../../components/InputFields/TextInput";
import { TextArea } from "../../../../components/InputFields/TextArea";
import { object } from "yup";
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

const DebitNoteMemberTypeOptions = [
  { value: "Member", label: "Members" },
  { value: "Vendor", label: "Non Member" },
  { value: "Tenant", label: "Tenants" },
];
const BillingType = [
  { value: "debit_note", label: "Debit Note" },
  { value: "supplementary", label: "Supplementary", isDisabled: false },
];
const headCells = [
  { label: "Tariff Header" },
  { label: "Credit A/C" },
  { label: "Amount (Rs.)" },
  { label: "Description" },
  { label: "From Date" },
  { label: "To Date" },
];

const EditDebitNote = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { wingsData } = useSelector((state) => state.wingsReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const {
    updateLoader,
    loading,
    manageDebitNoteTarrifList,
    manageDebitNoteFlatWingsWise,
    loadingManageDebitNoteFlatWingsWise,
    manageDebitNoteArrearDetails,
    editSingleDebitNoteDetail,
  } = useSelector((state) => state.manageDebitNoteReducer);
  const { glMappingList } = useSelector((state) => state.viewBookingReducer);
  const { nonMemberList } = useSelector(
    (state) => state.manageAdvanceNoteReducer
  );
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);

  useEffect(() => {
    const societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails());
    dispatch(editSingleDebitNoteDetails({ societyId, invid: [id] }));
    // dispatch(getBookingGLMappingList({ societyId }));
    dispatch(getInvoiceTypeList({ societyId }));
    dispatch(getNonMemberList({ societyId }));
    dispatch(
      getTenantsDetailsList({
        societyId: loginData?.default_community?.community_id,
        pageLimit: 10,
        page: 1,
      })
    );
    dispatch(getDebiteNoteArrearDetails());
    setTenantListData([]);
  }, []);

  // useEffect(() => {
  //   if (
  //     Object.entries(editSingleDebitNoteDetail)?.[0]?.[1].issued_type ==
  //       "member" &&
  //     Object.entries(editSingleDebitNoteDetail)?.[0]?.[1].wing_id
  //   ) {
  //     dispatch(
  //       getDebiteNoteFlatWingWiseList({
  //         id: loginData?.default_community?.community_id,
  //         wing_no: Object.entries(editSingleDebitNoteDetail)?.[0]?.[1].wing_id,
  //       })
  //     );
  //   }
  // }, [editSingleDebitNoteDetail]);

  const { tenantsList } = useSelector((state) => state.tenantInfoReducer);
  const [tenantListData, setTenantListData] = useState([]);

  useEffect(() => {
    if (!(tenantListData.length > 0)) {
      if (tenantsList?.page_limit > 10) {
        setTenantListData(tenantsList?.tenants_list);
      } else if (tenantsList?.total_pages > 1) {
        dispatch(
          getTenantsDetailsList({
            societyId: loginData?.default_community?.community_id,
            pageLimit:
              tenantsList.total_pages > 1
                ? 10 * tenantsList?.total_pages
                : 10 * 2,
            page: 1,
          })
        );
      } else if (tenantsList?.total_pages <= 1) {
        setTenantListData(tenantsList?.tenants_list);
      }
    }
  }, [tenantsList?.page_limit]);

  const getArrears = (values) => {
    if (
      values.invoiceDate &&
      values.dueDate &&
      values.issuedTo &&
      // values.billType &&
      ((values.flatId && values.flatId != "0") ||
        (values.tenantId && values.tenantId != "0") ||
        (values.nonMemberId && values.nonMemberId != "0"))
    ) {
      dispatch(
        getDebiteNoteArrearDetails({
          id: loginData?.default_community?.community_id,
          invoice_date: values.invoiceDate,
          due_date: values.dueDate,
          // bill_type: values.billType,
          issue_type: values.issuedTo,
          flat_id: values.issuedTo == "member" ? values.flatId : "",
          debitor_id:
            values.issuedTo == "tenant"
              ? values.tenantId
              : values.issuedTo == "vendor"
              ? values.nonMemberId
              : "",
          invoiceType:
            editSingleDebitNoteDetail?.debitnote_header?.invoice_type,
        })
      );
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    values.data = editSingleDebitNoteDetail;
    values.glAccountList = glMappingList;
    values.wingsData = wingsData;
    values.flatsData = manageDebitNoteFlatWingsWise;

    values.societyId = loginData?.default_community?.community_id;
    values.id = id;

    // values.lastdebitnote = manageDebitNoteArrearDetails?.debitnoteid;
    values.cgst_head = editSingleDebitNoteDetail?.debitnote_header?.cgst_head;
    values.sgst_head = editSingleDebitNoteDetail?.debitnote_header?.sgst_head;
    values.igst_head = editSingleDebitNoteDetail?.debitnote_header?.igst_head;
    values.roundoff = editSingleDebitNoteDetail?.debitnote_header?.roundoff;
    values.cgst_rate = editSingleDebitNoteDetail?.debitnote_header?.cgst_rate;
    values.sgst_rate = editSingleDebitNoteDetail?.debitnote_header?.sgst_rate;
    values.igst_rate = editSingleDebitNoteDetail?.debitnote_header?.igst_rate;

    if (manageDebitNoteArrearDetails) {
      values.interest = Math.round(manageDebitNoteArrearDetails?.interest);
      values.principal_arrear = Math.round(
        manageDebitNoteArrearDetails?.principal_arrear
      );
      values.interest_arrear = Math.round(
        manageDebitNoteArrearDetails?.interest_arrear
      );
    }

    dispatch(
      editSingleDebitNoteSave({
        ...values,
        onSuccessCallback: () => navigate(MANAGE_DEBIT_CURRENT_ENDPOINT),
      })
    );
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            invoiceDate: Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
              ?.invoice_date,
            dueDate: Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
              ?.due_date,
            issuedTo: Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
              ?.issued_type
              ? Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]?.issued_type
              : "",
            // billType:
            //   Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]?.bill_type ==
            //   "Supplementary"
            //     ? "supplementary"
            //     : "debit_note",
            wingId:
              Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
                ?.issued_type == "member"
                ? Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]?.wing_id
                  ? Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]?.wing_id
                  : ""
                : "0",
            flatId:
              Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
                ?.issued_type == "member"
                ? Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]?.flat_id
                  ? Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]?.flat_id
                  : ""
                : "0",
            tenantId:
              Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
                ?.issued_type == "tenant"
                ? Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
                    ?.debitor_id
                  ? Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
                      ?.debitor_id
                  : ""
                : "0",
            nonMemberId:
              Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
                ?.issued_type == "nonMember"
                ? Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
                    ?.debitor_id
                  ? Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
                      ?.debitor_id
                  : ""
                : "0",
            debitAccount: Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]
              ?.debit_code
              ? Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]?.debit_code
              : "",
            sendSMS: "N",
            sendEmail: "N",
            is_draft: 0,
            details: editSingleDebitNoteDetail
              ? Object.entries(editSingleDebitNoteDetail)?.[0]?.[1]?.[
                  "head_detail"
                ]
              : [],
            cgst_amount:
              (Object.entries(editSingleDebitNoteDetail)[0]?.[1]?.[
                "head_detail"
              ].reduce((prevVal, item) => {
                return item?.gstapplicable == "Y"
                  ? prevVal + Number(item?.amount)
                  : prevVal;
              }, 0) *
                parseInt(
                  editSingleDebitNoteDetail?.debitnote_header?.cgst_rate &&
                    editSingleDebitNoteDetail?.debitnote_header?.cgst_head
                    ? Number(
                        editSingleDebitNoteDetail?.debitnote_header?.cgst_rate
                      )
                    : 0
                )) /
              100,
            sgst_amount:
              (Object.entries(editSingleDebitNoteDetail)[0]?.[1]?.[
                "head_detail"
              ].reduce((prevVal, item) => {
                return item?.gstapplicable == "Y"
                  ? prevVal + Number(item?.amount)
                  : prevVal;
              }, 0) *
                parseInt(
                  editSingleDebitNoteDetail?.debitnote_header?.sgst_rate &&
                    editSingleDebitNoteDetail?.debitnote_header?.sgst_head
                    ? Number(
                        editSingleDebitNoteDetail?.debitnote_header?.sgst_rate
                      )
                    : 0
                )) /
              100,
            igst_amount:
              (Object.entries(editSingleDebitNoteDetail)[0]?.[1][
                "head_detail"
              ].reduce((prevVal, item) => {
                return item?.gstapplicable == "Y"
                  ? prevVal + Number(item?.amount)
                  : prevVal;
              }, 0) *
                parseInt(
                  editSingleDebitNoteDetail?.debitnote_header?.igst_rate &&
                    editSingleDebitNoteDetail?.debitnote_header?.igst_head
                    ? Number(
                        editSingleDebitNoteDetail?.debitnote_header?.igst_rate
                      )
                    : 0
                )) /
              100,
            subTotal:
              Object.entries(editSingleDebitNoteDetail)[0]?.[1][
                "head_detail"
              ].reduce((prevVal, item) => {
                return prevVal + Number(item?.amount);
              }, 0) +
              (Object.entries(editSingleDebitNoteDetail)[0]?.[1][
                "head_detail"
              ].reduce((prevVal, item) => {
                return item?.gstapplicable == "Y"
                  ? prevVal + Number(item?.amount)
                  : prevVal;
              }, 0) *
                parseInt(
                  (editSingleDebitNoteDetail?.debitnote_header?.cgst_rate &&
                  editSingleDebitNoteDetail?.debitnote_header?.cgst_head
                    ? Number(
                        editSingleDebitNoteDetail?.debitnote_header?.cgst_rate
                      )
                    : 0) +
                    (editSingleDebitNoteDetail?.debitnote_header?.sgst_rate &&
                    editSingleDebitNoteDetail?.debitnote_header?.sgst_head
                      ? Number(
                          editSingleDebitNoteDetail?.debitnote_header?.sgst_rate
                        )
                      : 0) +
                    (editSingleDebitNoteDetail?.debitnote_header?.igst_rate &&
                    editSingleDebitNoteDetail?.debitnote_header?.igst_head
                      ? Number(
                          editSingleDebitNoteDetail?.debitnote_header?.igst_rate
                        )
                      : 0)
                )) /
                100,
            interest: Object.entries(editSingleDebitNoteDetail)[0]?.[1]
              ?.interest,
            principal_arrear: Object.entries(editSingleDebitNoteDetail)[0]?.[1]
              ?.principal_arrear,
            interest_arrear: Object.entries(editSingleDebitNoteDetail)[0]?.[1]
              ?.interest_arrear,

            roundoff_amount:
              editSingleDebitNoteDetail?.debitnote_header?.roundoff,
            cgst_head: editSingleDebitNoteDetail?.debitnote_header?.cgst_head,
            sgst_head: editSingleDebitNoteDetail?.debitnote_header?.sgst_head,
            igst_head: editSingleDebitNoteDetail?.debitnote_header?.igst_head,
          }}
          enableReinitialize
          validationSchema={AddDebitNoteValidation}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Edit Debit Note">
                    <div className="flex gap-2">
                      <ButtonG
                        label="Cancel"
                        onClick={() => {
                          navigate(-1);
                        }}
                        className="h-8"
                        type="button"
                      />
                      <Button
                        label="Save"
                        className="px-5 h-8"
                        type="submit"
                        // onClick={""}
                      />
                      {/* <Button
                        label="Save As Draft"
                        className="px-5 h-8"
                        type="submit"
                        onClick={() => {
                          setFieldValue("is_draft", 1);
                        }}
                      /> */}
                    </div>
                  </ListHeader>
                </div>

                {loading || updateLoader ? (
                  <Loader />
                ) : (
                  <>
                    <div className="flex gap-4 p-2">
                      <div className="flex flex-row justify-between w-full gap-7">
                        <div className="flex flex-col w-1/2">
                          <div className="flex flex-row justify-between mb-1">
                            <span className="mt-2 text-sm">Invoice Type</span>
                            <div className="flex gap-3 ">
                              <DropdownFM2
                                options={
                                  typeLists
                                    ?.filter(
                                      (item) =>
                                        item?.id ==
                                        editSingleDebitNoteDetail
                                          ?.debitnote_header?.invoice_type
                                    )
                                    ?.map((item) => ({
                                      label: item?.invoice_title,
                                      value: item?.id,
                                    })) || []
                                }
                                placeholder="Select Invoice Type"
                                className=""
                                width="225px"
                                height="36px"
                                value={
                                  editSingleDebitNoteDetail?.debitnote_header
                                    ?.invoice_type && {
                                    label:
                                      typeLists?.find(
                                        (item) =>
                                          item.id ===
                                          editSingleDebitNoteDetail
                                            ?.debitnote_header?.invoice_type
                                      )?.invoice_title || "",
                                    value:
                                      editSingleDebitNoteDetail
                                        ?.debitnote_header?.invoice_type,
                                  }
                                }
                                name={"invoiceType"}
                              />
                            </div>
                          </div>
                          <div className="flex flex-row justify-between mb-1">
                            <span className="text-sm mt-2">
                              Debit Note Date
                            </span>
                            <div className="flex gap-3">
                              <DatePickerComponentFM
                                className="w-56 h-9  justify-between"
                                onDateChange={(selectedDate) => {
                                  if (
                                    new Date(values.dueDate).getTime() <
                                    new Date(selectedDate).getTime()
                                  ) {
                                    setFieldValue("dueDate", null);
                                  }
                                  setFieldValue(
                                    "invoiceDate",
                                    moment(selectedDate).format("YYYY-MM-DD")
                                  );
                                  getArrears({
                                    ...values,
                                    invoiceDate:
                                      moment(selectedDate).format("YYYY-MM-DD"),
                                  });
                                }}
                                name="invoiceDate"
                                defaultValue={
                                  values.invoiceDate
                                    ? new Date(values.invoiceDate)
                                    : null
                                }
                                minDate={
                                  values?.invoiceDate &&
                                  new Date(
                                    new Date(values?.invoiceDate).getFullYear(),
                                    new Date(values?.invoiceDate).getMonth(),
                                    1
                                  )
                                }
                                maxDate={
                                  values?.invoiceDate &&
                                  new Date(
                                    new Date(values?.invoiceDate).getFullYear(),
                                    new Date(values?.invoiceDate).getMonth() +
                                      1,
                                    0
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="flex flex-row justify-between  mb-1">
                            <span className="text-sm mt-2">
                              Payment Due Date
                            </span>
                            <div className="flex gap-3">
                              <DatePickerComponentFM
                                className="w-56 h-9 justify-between"
                                onDateChange={(selectedDate) => {
                                  setFieldValue(
                                    "dueDate",
                                    moment(selectedDate).format("YYYY-MM-DD")
                                  );
                                  getArrears({
                                    ...values,
                                    dueDate:
                                      moment(selectedDate).format("YYYY-MM-DD"),
                                  });
                                }}
                                name="dueDate"
                                minDate={new Date(values.invoiceDate)}
                                defaultValue={
                                  values.dueDate
                                    ? new Date(values.dueDate)
                                    : null
                                }
                                maxDate={
                                  values?.dueDate &&
                                  new Date(
                                    new Date(values?.dueDate).getFullYear(),
                                    new Date(values?.dueDate).getMonth() + 1,
                                    0
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="flex justify-between items-center gap-3 mb-1">
                            <div className="text-sm">Issued To</div>
                            <div className="flex gap-6">
                              <DropdownFM2
                                options={[
                                  DebitNoteMemberTypeOptions?.find(
                                    (item) =>
                                      item.value.toLowerCase() ==
                                      values?.issuedTo
                                  ),
                                ]}
                                placeholder="Select Issued To"
                                className="text-[11px] ms-4"
                                width="224px"
                                height="36px"
                                // onSelect={(selectedItem) => {
                                //   setFieldValue("issuedTo", selectedItem.value);
                                //   setFieldValue("debitAccount", "");
                                //   setFieldValue(
                                //     "wingId",
                                //     selectedItem.value == "Member" ? "" : "0"
                                //   );
                                //   setFieldValue(
                                //     "flatId",
                                //     selectedItem.value == "Member" ? "" : "0"
                                //   );
                                //   setFieldValue(
                                //     "tenantId",
                                //     selectedItem.value == "Tenant" ? "" : "0"
                                //   );
                                //   setFieldValue(
                                //     "nonMemberId",
                                //     selectedItem.value == "Vendor" ? "" : "0"
                                //   );
                                //   getArrears({
                                //     ...values,
                                //     issuedTo: selectedItem.value,
                                //   });
                                // }}
                                value={DebitNoteMemberTypeOptions?.find(
                                  (item) =>
                                    item.value.toLowerCase() == values?.issuedTo
                                )}
                                name="issuedTo"
                              />
                            </div>
                          </div>
                          {/* <div className="flex justify-between items-center gap-3 mb-1">
                            <div className="text-sm">Bill Type</div>
                            <div className="flex gap-6">
                              <DropdownFM2
                                options={BillingType}
                                placeholder="Select Bill Type"
                                className="text-[11px] ms-4"
                                width="224px"
                                height="36px"
                                onSelect={(selectedItem) => {
                                  setFieldValue("billType", selectedItem.value);
                                  getArrears({
                                    ...values,
                                    billType: selectedItem.value,
                                  });
                                }}
                                value={
                                  BillingType && {
                                    label: BillingType.find(
                                      (item) => item.value == values.billType
                                    )?.label,
                                    value: values.billType,
                                  }
                                }
                                name="billType"
                              />
                            </div>
                          </div> */}

                          <div className="flex justify-between gap-3 mb-1">
                            <div className="text-sm ">
                              {values.issuedTo == "Tenant"
                                ? "Tenant "
                                : values.issuedTo == "Vendor"
                                ? "Vendor "
                                : "Member "}
                              Details
                            </div>
                            <div>
                              {values.issuedTo == "member" && (
                                <div className="flex gap-2">
                                  <DropdownFM2
                                    options={
                                      values.issuedTo == "member" && [
                                        {
                                          label: Object.entries(
                                            editSingleDebitNoteDetail
                                          )?.[0]?.[1]?.wing_no,
                                          value: Object.entries(
                                            editSingleDebitNoteDetail
                                          )?.[0]?.[1]?.wing_id,
                                        },
                                      ]
                                    }
                                    placeholder="Wing"
                                    className="text-[11px] ms-4"
                                    width="108px"
                                    height="36px"
                                    // onSelect={(selectedItem) => {
                                    //   if (selectedItem.value != values.wingId) {
                                    //     setFieldValue("flatId", "");
                                    //     dispatch(
                                    //       getDebiteNoteFlatWingWiseList({
                                    //         id: loginData?.default_community
                                    //           ?.community_id,
                                    //         wing_no: selectedItem.value,
                                    //       })
                                    //     );
                                    //   }
                                    //   setFieldValue(
                                    //     "wingId",
                                    //     selectedItem.value
                                    //   );
                                    // }}
                                    name="wingId"
                                    value={
                                      values.issuedTo == "member" && {
                                        label: Object.entries(
                                          editSingleDebitNoteDetail
                                        )?.[0]?.[1]?.wing_no,
                                        value: Object.entries(
                                          editSingleDebitNoteDetail
                                        )?.[0]?.[1]?.wing_id,
                                      }
                                    }
                                    // disabled={!(values.issuedTo == "Member")}
                                  />
                                  <DropdownFM2
                                    options={
                                      values.wingId && [
                                        {
                                          label: Object.entries(
                                            editSingleDebitNoteDetail
                                          )?.[0]?.[1]?.flat_no,
                                          value: Object.entries(
                                            editSingleDebitNoteDetail
                                          )?.[0]?.[1]?.flat_id,
                                        },
                                      ]
                                    }
                                    loading={
                                      loadingManageDebitNoteFlatWingsWise
                                    }
                                    placeholder="Flat Unit"
                                    className="text-[11px] ms-4"
                                    width="108px"
                                    height="36px"
                                    // onSelect={(selectedItem) => {
                                    //   // setFieldValue(
                                    //   //   "debitAccount",
                                    //   //   manageDebitNoteFlatWingsWise.find(
                                    //   //     (item) => item?.id == selectedItem.value
                                    //   //   )?.codeId
                                    //   // );
                                    //   setFieldValue(
                                    //     "flatId",
                                    //     selectedItem.value
                                    //   );
                                    //   if (values.flatId != selectedItem.value)
                                    //     getArrears({
                                    //       ...values,
                                    //       flatId: selectedItem.value,
                                    //     });
                                    // }}
                                    name="flatId"
                                    // {...(values.flatId === "" && { value: null })}
                                    value={
                                      values.issuedTo == "member" &&
                                      values.flatId && {
                                        label: Object.entries(
                                          editSingleDebitNoteDetail
                                        )?.[0]?.[1]?.flat_no,
                                        value: Object.entries(
                                          editSingleDebitNoteDetail
                                        )?.[0]?.[1]?.flat_id,
                                      }
                                    }
                                    // disabled={!(values.issuedTo == "Member")}
                                  />
                                </div>
                              )}
                              {values.issuedTo == "tenant" && (
                                <div className="flex gap-6">
                                  <DropdownFM2
                                    options={
                                      values.issuedTo == "tenant"
                                        ? [
                                            {
                                              label: tenantListData?.find(
                                                (item) =>
                                                  item?.tenant_id ==
                                                  values.tenantId
                                              )?.tenant_name,
                                              value: values.tenantId,
                                            },
                                          ]
                                        : []
                                    }
                                    loading={!tenantListData.length > 0}
                                    placeholder="Select Tenant Name"
                                    className="text-[11px] ms-4"
                                    width="224px"
                                    height="36px"
                                    // onSelect={(selectedItem) => {
                                    //   setFieldValue(
                                    //     "tenantId",
                                    //     selectedItem.value
                                    //   );
                                    //   // setFieldValue("debitAccount", "");
                                    //   if (values.tenantId != selectedItem.value)
                                    //     getArrears({
                                    //       ...values,
                                    //       tenantId: selectedItem.value,
                                    //     });
                                    // }}
                                    value={
                                      values.tenantId &&
                                      values.issuedTo == "tenant"
                                        ? {
                                            label: tenantListData?.find(
                                              (item) =>
                                                item?.tenant_id ==
                                                values.tenantId
                                            )?.tenant_name,
                                            value: values.tenantId,
                                          }
                                        : []
                                    }
                                    name="tenantId"
                                    // disabled={!(values.issuedTo == "Tenant")}
                                  />
                                </div>
                              )}

                              {values.issuedTo == "vendor" && (
                                <div className="flex gap-6">
                                  <DropdownFM2
                                    options={
                                      values.issuedTo == "vendor" &&
                                      nonMemberList
                                        ? {
                                            label: nonMemberList?.find(
                                              (item) =>
                                                item.id == values.nonMemberId
                                            )?.vendorname,
                                            value: values.nonMemberId,
                                          }
                                        : []
                                    }
                                    placeholder="Select Non-Member Name"
                                    className="text-[11px] ms-4"
                                    width="224px"
                                    height="36px"
                                    // onSelect={(selectedItem) => {
                                    //   setFieldValue(
                                    //     "nonMemberId",
                                    //     selectedItem.value
                                    //   );
                                    //   // setFieldValue(
                                    //   //   "debitAccount",
                                    //   //   nonMemberList?.find(
                                    //   //     (item) => item?.id == selectedItem.value
                                    //   //   )?.glcode
                                    //   // );
                                    //   if (
                                    //     values.nonMemberId != selectedItem.value
                                    //   )
                                    //     getArrears({
                                    //       ...values,
                                    //       nonMemberId: selectedItem.value,
                                    //     });
                                    // }}
                                    value={
                                      values?.nonMemberId &&
                                      values.issuedTo == "vendor"
                                        ? {
                                            label: nonMemberList?.find(
                                              (item) =>
                                                item.id == values.nonMemberId
                                            )?.vendorname,
                                            value: values.nonMemberId,
                                          }
                                        : []
                                    }
                                    name="nonMemberId"
                                    disabled={!(values.issuedTo == "vendor")}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex justify-between items-center gap-3 mb-1">
                            <div className="text-sm">Debit A/C</div>
                            {/* <div className="flex gap-6">
                              <TextInputFM
                                name="debitAccount"
                                label=""
                                placeholder="Debit A/C"
                                className="w-[224px]"
                                disabled={true}
                              />
                            </div> */}

                            <DropdownFM2
                              options={
                                Object.entries(
                                  editSingleDebitNoteDetail
                                )?.[0]?.[1]?.debit_code
                                  ? [
                                      {
                                        label: Object.entries(
                                          editSingleDebitNoteDetail
                                        )?.[0]?.[1]?.debit_glname,
                                        value: Object.entries(
                                          editSingleDebitNoteDetail
                                        )?.[0]?.[1]?.debit_code,
                                      },
                                    ]
                                  : []
                              }
                              placeholder="Debit A/C"
                              className="text-[11px] ms-4"
                              width="224px"
                              height="36px"
                              // onSelect={(selectedItem) => {
                              //   setFieldValue(
                              //     "debitAccount",
                              //     selectedItem.value
                              //   );
                              // }}
                              name="debitAccount"
                              value={
                                Object.entries(
                                  editSingleDebitNoteDetail
                                )?.[0]?.[1]?.debit_code
                                  ? {
                                      label: Object.entries(
                                        editSingleDebitNoteDetail
                                      )?.[0]?.[1]?.debit_glname,
                                      value: Object.entries(
                                        editSingleDebitNoteDetail
                                      )?.[0]?.[1]?.debit_code,
                                    }
                                  : []
                              }
                            />
                          </div>

                          <div className="flex justify-between items-center gap-3 mb-1">
                            <div className="text-sm">Send SMS And Email</div>
                            <div className="py-2 text-sm">
                              <Checkbox
                                text={"SMS"}
                                className={"w-28"}
                                initialchecked={values.sendSMS === "Y"}
                                onChange={(e) => {
                                  setFieldValue(
                                    "sendSMS",
                                    e.target.checked ? "Y" : "N"
                                  );
                                }}
                              />
                              <Checkbox
                                text={"Email"}
                                className={"w-28"}
                                initialchecked={values.sendEmail === "Y"}
                                onChange={(e) => {
                                  setFieldValue(
                                    "sendEmail",
                                    e.target.checked ? "Y" : "N"
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <EditDebitDetailBox
                      values={values}
                      manageDebitNoteTarrifList={editSingleDebitNoteDetail}
                      manageDebitNoteArrearDetails={
                        manageDebitNoteArrearDetails
                      }
                      setFieldValue={setFieldValue}
                      columns={headCells}
                    />
                    {/* <div className="border-b border-[#CCC] -mt-2"></div> */}
                    <div className="w-full">
                      <BillSection
                        manageDebitNoteArrearDetails={
                          !!manageDebitNoteArrearDetails
                            ? manageDebitNoteArrearDetails
                            : {
                                interest: values?.interest,
                                interest_arrear: values?.interest_arrear,
                                principal_arrear: values?.principal_arrear,
                              }
                        }
                        manageDebitNoteTarrifList={editSingleDebitNoteDetail}
                        values={values}
                      />
                    </div>
                  </>
                )}
              </>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
export const BillSection = ({
  values,
  manageDebitNoteArrearDetails = null,
  manageDebitNoteTarrifList = null,
}) => {
  return (
    <div className="flex justify-between mt-4">
      <table className="border-separate">
        <tbody>
          <tr>
            <td className="text-sm w-56 pt-2.5 align-top ">CGST</td>
            <td>
              <TextInput
                label=""
                placeholder="SGST"
                className="w-52"
                type="number"
                name="sgst_amount"
                value={values.cgst_amount}
                // maxLength={15}
                disabled={true}
              />
            </td>
            <td className="text-sm w-56 pt-2.5 align-top ps-3">SGST</td>
            <td>
              <TextInput
                label=""
                placeholder="CGST"
                className="w-52"
                type="number"
                name="cgst_amount"
                value={values.sgst_amount}
                // maxLength={15}
                disabled={true}
              />
            </td>
          </tr>
          <tr>
            <td className="text-sm w-56 pt-2.5 align-top ">IGST</td>
            <td>
              <TextInput
                label=""
                placeholder="IGST"
                className="w-52"
                type="number"
                name="igst_amount"
                value={values.igst_amount}
                // maxLength={15}
                disabled={true}
              />
            </td>
          </tr>

          <tr>
            <td className="text-sm w-56 pt-2.5 pb-2.5 align-top font-semibold ">
              Sub Total
            </td>
            <td className="text-sm">{values.subTotal}</td>

            <td className="text-sm w-56 pt-2.5 align-top font-semibold ps-3">
              Round Off
            </td>
            <td className="text-sm">
              <TextInput
                name="interest"
                label=""
                placeholder="Enter Interest"
                className="w-52"
                type="number"
                value={
                  manageDebitNoteTarrifList?.debitnote_header?.roundoff == 1 &&
                  values.subTotal
                    ? (Math.round(values.subTotal) - values.subTotal).toFixed(2)
                    : 0
                }
                disabled={true}
              />
            </td>
          </tr>
          <tr>
            <td className="text-sm w-56 pt-2.5 align-top">Interest</td>
            <td>
              <TextInput
                name="interest"
                label=""
                placeholder="Enter Interest"
                className="w-52"
                type="number"
                value={
                  manageDebitNoteArrearDetails
                    ? Math.round(manageDebitNoteArrearDetails?.interest)
                    : 0
                }
                disabled={true}
              />
            </td>

            <td className="text-sm w-56 pt-2.5 ps-3 align-top">
              Interest Arrears
            </td>
            <td>
              <TextInput
                name="interest_arrear"
                label=""
                placeholder="Enter Interest Arrears"
                className="w-52"
                type="number"
                value={
                  manageDebitNoteArrearDetails
                    ? Math.round(manageDebitNoteArrearDetails?.interest_arrear)
                    : 0
                }
                disabled={true}
              />
            </td>
          </tr>
          <tr>
            <td className="text-sm w-56 pt-2.5 pb-2.5 align-top ">
              Principle Arrears
            </td>
            <td>
              <TextInput
                name="principal_arrear"
                label=""
                placeholder="Enter Principle Arrears"
                className="w-52"
                type="number"
                value={
                  manageDebitNoteArrearDetails
                    ? Math.round(manageDebitNoteArrearDetails?.principal_arrear)
                    : 0
                }
                disabled={true}
              />
            </td>

            <td className="text-sm w-56 pt-2.5 align-top font-semibold ps-3">
              Grand Total
            </td>
            <td className="text-sm">
              {values.subTotal
                ? (
                    values.subTotal +
                    (manageDebitNoteArrearDetails
                      ? Math.round(manageDebitNoteArrearDetails?.interest) +
                        Math.round(
                          manageDebitNoteArrearDetails?.principal_arrear
                        ) +
                        Math.round(
                          manageDebitNoteArrearDetails?.interest_arrear
                        )
                      : 0) +
                    (manageDebitNoteTarrifList?.debitnote_header?.roundoff ==
                      1 && values.subTotal
                      ? Math.round(values.subTotal) - values.subTotal
                      : 0)
                  ).toFixed(2)
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default EditDebitNote;
