import React from 'react'
import ListHeaderBack from '../../../components/ListHeader/ListHeaderBack'
import Layout from '../../Layout/Layout'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


function ViewContentManagment() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { data } = useSelector((state) => state.contentReducer);
    console.log(data)
    const SingleData = data ? data.find(item => item.id === parseInt(id)) : null;
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 ">
        <div className="border-b border-[#CCC] ">
          <ListHeaderBack title="Content Management" onClick={()=>{navigate(-1)}}>
          </ListHeaderBack>
        </div>
        {SingleData ? (
          <div className="py-1 gap-2 my-1 border border-red-650">
            <table className="table-auto w-full">
              <tbody>
                <tr className='text-[#222222] text-sm'>
                <td  className="text-center bg-red-650  text-white font-bold align-top" colSpan="2">View Content</td>
                </tr>
                <tr className="text-[#222222] text-sm">
                  <td className="px-2.5 w-40 py-2 font-bold align-top">Title:</td>
                  <td className="py-2">{SingleData.title || '-'}</td>
                </tr>
                <tr className="text-[#222222] text-sm">
                  <td className="px-2.5 w-40 py-2 font-bold align-top">Description:</td>
                  <td className="py-2">
                  <div dangerouslySetInnerHTML={{ __html: SingleData.content }} />
                  </td>
                </tr>
                {/* <tr>
                <td className="py-2" colSpan="2">
                  <div dangerouslySetInnerHTML={{ __html: SingleData.content }} />
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Record Found
          </div>
        )}
      </div>
    </Layout>
  )
}

export default ViewContentManagment