import React, { useEffect } from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { financialYears2 } from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  downloadCSVOpeningBalanceReports,
  downloadPDFOpeningBalanceReports,
  getOpeningBalanceReports,
} from "../../../../redux/actions/ReportActions/OldFinancialReportsActions";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import OpeningBalanceTable from "./OpeningBalanceTable";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../../assets";
import { ACCOUNT_REPORTS_ENDPOINT } from "../../../../Routing/routes";
import ButtonG from "../../../../components/Button/ButtonG";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";

export const headCells = [
  {
    id: "id",
    label: "Sr. No.",
    width: "50px",
  },
  { id: "headOfAccount", label: "Head Of Account", width: "90px" },
  { id: "daybook", label: "Daybook", width: "90px" },
  { id: "flat", label: "Flat", width: "90px" },
  { id: "debit", label: "Debit", width: "90px" },
  { id: "credit", label: "Credit", width: "90px" },
];

const OpeningBalance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, openingBalance } = useSelector(
    (state) => state.oldFinancialReportsReducers
  );

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };

  const handleSelectYear = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("year")) {
      searchParams.delete("year");
      navigate({
        pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["year", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleDownloadCSV = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Opening Balance.xlsx` });
    };
    const data = {
      society_id: loginData?.default_community?.zipgrid_socsrno,
      // societyId: loginData?.default_community?.community_id,
      fin_year: searchParams.get("year"),
      page: searchParams.get("page") || 1,
      limit: searchParams.get("pageLimit") || 10,
      onSucessCallback,
    };
    dispatch(downloadCSVOpeningBalanceReports(data));
  };

  const handleDownloadPDF = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Opening Balance.pdf` });
    };
    const data = {
      society_id: loginData?.default_community?.community_id,
      // societyId: loginData?.default_community?.community_id,
      fin_year: searchParams.get("year"),
      page: searchParams.get("page") || 1,
      limit: searchParams.get("pageLimit") || 10,
      onSucessCallback,
    };
    dispatch(downloadPDFOpeningBalanceReports(data));
  };

  const handleClear = () => {
    navigate({
      pathname: `${ACCOUNT_REPORTS_ENDPOINT}`,
      search: `?${createSearchParams([
        ["tab", 1],
        [
          "year",
          financialYears2[0].value.split("-")[0] +
            financialYears2[0].value.split("-")[1].slice(2),
        ],
      ])}`,
    });
  };

  useEffect(() => {
    dispatch(
      getOpeningBalanceReports({
        society_id: loginData?.default_community?.zipgrid_socsrno,
        // societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
        fin_year: searchParams.get("year"),
      })
    );
  }, [searchParams, dispatch]);

  return (
    <div className="mt-1">
      <div>
        <ListHeader leftContent="Opening Balance">
          {/* <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("search")}
          /> */}
          <span className="border-r border-[#CCC]"></span>
          <Dropdown2
            options={financialYears2}
            placeholder="Choose Financial Year"
            className=""
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              const valueParts = selectedItem.value.split("-");
              const selectedValue = `${valueParts[0]}${valueParts[1].slice(2)}`;
              handleSelectYear({ ...selectedItem, value: selectedValue });
            }}
            value={
              searchParams.get("year") && {
                value: searchParams.get("year"),
                label: financialYears2?.find((item) => {
                  const valueParts = item.value.split("-");
                  const selectedValue = `${valueParts[0]}${valueParts[1].slice(
                    2
                  )}`;
                  return selectedValue === searchParams.get("year");
                })?.label,
              }
            }
          />
          <span className="border-r border-[#CCC]"></span>
          {/* <ButtonIco
            icon={DownloadExt}
            children="Download PDF"
            className="h-8"
            type="button"
            onClick={() => {
              handleDownloadPDF();
            }}
          /> */}
          <ButtonIco
            icon={DownloadExt}
            children="Download Excel"
            className="h-8"
            type="button"
            onClick={() => {
              handleDownloadCSV();
            }}
          />
          <span className="border-r border-[#CCC]"></span>
          <ButtonG label="Clear" onClick={handleClear} />
        </ListHeader>
      </div>
      <div className="ml-1">
        <OpeningBalanceTable
          loading={loading}
          data={
            openingBalance?.list?.map((item) => ({
              sNo: item?.opbrefaccno,
              headOfAccount: item?.account_head,
              daybook: item?.opbdaybook,
              flat: item?.opbrefflatno,
              debit: item?.opbdebit,
              credit: item?.opbcredit,
            })) || []
          }
          columns={headCells}
          checkbox={false}
          pagination={
            openingBalance?.list?.length ? openingBalance?.list?.length : 0
          }
          totalCount={openingBalance?.total_count}
          totalPages={openingBalance?.total_pages}
          start={
            (openingBalance?.current_page - 1) *
              (parseInt(openingBalance?.page_limit) || 10) +
            1
          }
          end={
            (openingBalance?.current_page - 1) *
              (parseInt(openingBalance?.page_limit) || 10) +
            openingBalance?.list?.length
          }
          currentPage={parseInt(openingBalance?.current_page)}
          rowsPerPage={searchParams.get("pageLimit") || 10}
        ></OpeningBalanceTable>
      </div>
      {!openingBalance?.list?.length && !loading && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )}
    </div>
  );
};

export default OpeningBalance;
