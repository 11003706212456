import * as type from "../../actions/index.types";

export function billingReportsReducer(
  state = {
    loading: false,
    printLoader: false,
    updateLoader: false,
    debitNotesReportList: null,
    deletedReceiptList: null,
    receiptDetails: null,
    incompleteTransactionDetails: null,
    chequeDepositSlipReport: null,
    countedInvoiceReport: null,
    receiptList: null,
    incompleteTransaction: null,
    memberDueSummary: null,
    memberBillDueReport: null,
    openingBalanceData: [],
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_DEBIT_NOTES_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_DEBIT_NOTES_REPORT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        debitNotesReportList: action.payload,
      };
    case type.GET_DEBIT_NOTES_REPORT_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        debitNotesReportList: null,
      };
    
    case type.GET_DELETED_RECEIPT:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_DELETED_RECEIPT_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        deletedReceiptList: action.payload,
    };
    case type.GET_DELETED_RECEIPT_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        deletedReceiptList: null,
    };

    case type.GET_RECIEPT_DETAILS:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_RECIEPT_DETAILS_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        receiptDetails: action.payload,
    };
    case type.GET_RECIEPT_DETAILS_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        receiptDetails: null,
    };

    case type.GET_INCOMPLETE_TRANSACTION_DETAILS:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_INCOMPLETE_TRANSACTION_DETAILS_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        incompleteTransactionDetails: action.payload,
    };
    case type.GET_INCOMPLETE_TRANSACTION_DETAILS_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        incompleteTransactionDetails: null,
    };

    case type.GET_CHEQUE_DEPOSIT_SLIP_REPORT:
        return {
            ...state,
            printLoader: true,
            error: null,
        };
    case type.GET_CHEQUE_DEPOSIT_SLIP_REPORT_SUCCESS:
        return {
            ...state,
            error: null,
            printLoader: false,
            chequeDepositSlipReport: action.payload,
        };
    case type.GET_CHEQUE_DEPOSIT_SLIP_REPORT_FAILURE:
        return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            printLoader: false,
            chequeDepositSlipReport: null,
        };
    
    case type.GET_COUNTED_INVOICE_REPORT:
        return {
            ...state,
            printLoader: true,
            error: null,
        };
    case type.GET_COUNTED_INVOICE_REPORT_SUCCESS:
        return {
            ...state,
            error: null,
            printLoader: false,
            countedInvoiceReport: action.payload,
        };
    case type.GET_COUNTED_INVOICE_REPORT_FAILURE:
        return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            printLoader: false,
            countedInvoiceReport: null,
        };

    case type.GET_RECEIPTS_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_RECEIPTS_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        receiptList: action.payload,
      };
    case type.GET_RECEIPTS_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        receiptList: null,
      };
    
    case type.GET_INCOMPLETE_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_INCOMPLETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        incompleteTransaction: action.payload,
      };
    case type.GET_INCOMPLETE_TRANSACTION_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        incompleteTransaction: null,
      };

    case type.GET_MEMBER_DUE_SUMMARY:
        return {
            ...state,
            printLoader: true,
            error: null,
        };
    case type.GET_MEMBER_DUE_SUMMARY_SUCCESS:
        return {
            ...state,
            error: null,
            printLoader: false,
            memberDueSummary: action.payload,
        };
    case type.GET_MEMBER_DUE_SUMMARY_FAILURE:
        return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            printLoader: false,
            memberDueSummary: null,
        };
    
    case type.GET_MEMBER_BILL_DUE_REPORT:
        return {
            ...state,
            printLoader: true,
            error: null,
        };
    case type.GET_MEMBER_BILL_DUE_REPORT_SUCCESS:
        return {
            ...state,
            error: null,
            printLoader: false,
            memberBillDueReport: action.payload,
        };
    case type.GET_MEMBER_BILL_DUE_REPORT_FAILURE:
        return {
            ...state,
            error: action?.message || "Somthing went wrong.",
            printLoader: false,
            memberBillDueReport: null,
        };
      
    case type.GET_OPENING_BALANCE_DATA:
      return {
        ...state,
        loading: true,
        error: null,
        openingBalanceData : [],
      };
    case type.GET_OPENING_BALANCE_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        openingBalanceData: action.payload,
      };
    case type.GET_OPENING_BALANCE_DATA_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        openingBalanceData: [],
      };
        
    default:
      return state;
  }
}
