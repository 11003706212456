import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import Search from "../../../components/SearchBox/Search";
import ListHeader from '../../../components/ListHeader/ListHeader';
import ButtonIco from '../../../components/Button/ButtonPrint';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Dropdown2 from '../../../components/Dropdown/dropdown2';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { MEMBER_LEDGER_REPORT_ENDPOINT } from '../../../Routing/routes';
import { getFlatDetails, getWingsAndFlatDetails } from '../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction';
import RangeDatePicker from '../../../components/DatePicker/RangeDatePicker';
import moment from 'moment';
import ButtonG from '../../../components/Button/ButtonG';
import Button from '../../../components/Button/Button';
import { getMemberLedgerReport } from '../../../redux/actions/ReportActions/SocietyReportActions';
import { downloadExcel, downloadFileFromURL } from '../../../utils/helpers/downloadMedia';
import MemberLedgerReportsTable from './MemberLedgerReportsTable';
import {
    getInvoiceTypeList,memberLedgerList
} from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { financialYears } from "../../../consts/DropDownOptions";
import Loader from "../../../components/Loader/Loader";

// export const headCells = [
//     { id: "sNo", label: "S No.", width: "70px" },
//     { id: "email", label: "Email", width: "180px" },
//     { id: "subject", label: "Subject", sort: true, width: "140px" },
//     { id: "flat", label: "Flat", sort: true, width: "100px" },
//     { id: "amountDue", label: "Date(From-To)", sort: true, width: "170px" },
//     { id: "status", label: "Status", sort: true, width: "170px" },
// ];
export const status = [
    { value: "proforma" , label: "Proforma"},
    { value: "account" , label: "Account"},
]

const MemberLedgerReports = () => {
    const dispatch = useDispatch();
    // const [headCells, setHeadCells] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { loginData } = useSelector((state) => state.loginReducer);
    const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
    const { societyEmailReport, loading, reportList } = useSelector((state) => state.societyReportReducer)
    const navigate = useNavigate();
    const { typeLists,ledgerList } = useSelector(
        (state) => state.invoiceSettingReducer
    );
   
    const handelSelectInvoiceType = (selectedItem) => {
        searchParams.delete("typeid");
        navigate({
            pathname: `${MEMBER_LEDGER_REPORT_ENDPOINT}`,
            search: `?${createSearchParams([
                ...searchParams.entries(),
                ["typeid", selectedItem.value],
            ])}`,
        });
    };
    const handelSelectFinancialYear = (selectedItem) => {
        searchParams.delete("finYear");
        navigate({
            pathname: `${MEMBER_LEDGER_REPORT_ENDPOINT}`,
            search: `?${createSearchParams([
                ...searchParams.entries(),
                ["finYear", selectedItem.value],
            ])}`,
        });
    };
    const handelSelectMemberLedger = (selectedItem) => {
        searchParams.delete("glCode");
        searchParams.delete("wingId");
        searchParams.delete("flatId");
        navigate({
            pathname: `${MEMBER_LEDGER_REPORT_ENDPOINT}`,
            search: `?${createSearchParams([
                ...searchParams.entries(),
                ["glCode", selectedItem.value],
            ])}`,
        });
    };
    const handleSelectWing = (selectedItem) => {
        if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
            dispatch(
                getFlatDetails({
                    societyId: loginData?.default_community?.community_id,
                    wingId: selectedItem?.value,
                })
            );
            searchParams.delete("wingId");
            searchParams.delete("flatId");
            searchParams.delete("glCode");
            navigate({
                pathname: `${MEMBER_LEDGER_REPORT_ENDPOINT}`,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["wingId", selectedItem.value],
                ])}`,
            });
        }
    };

    const handleSelectFlat = (selectedItem) => {
        if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
            searchParams.delete("flatId");
            searchParams.delete("glCode");
            navigate({
                pathname: `${MEMBER_LEDGER_REPORT_ENDPOINT}`,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["flatId", selectedItem.value],
                ])}`,
            });
        }
    };

    const handelSelectType = (selectedItem) => {
        searchParams.delete("type");

        navigate({
            pathname: `${MEMBER_LEDGER_REPORT_ENDPOINT}`,
            search: `?${createSearchParams([
                ...searchParams.entries(),
                ["type", selectedItem.value],
            ])}`,
        });
    };

    const handleStartDate = (selectedDate) => {
        if (
            moment(selectedDate).format("YYYY-MM-DD") !==
            searchParams.get("startDate")
        ) {
            searchParams.delete("startDate");
            navigate({
                pathname: `${MEMBER_LEDGER_REPORT_ENDPOINT}`,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
                ])}`,
            });
        }
    };

    const handleEndDate = (selectedDate) => {
        if (
            moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
        ) {
            searchParams.delete("endDate");
            navigate({
                pathname: `${MEMBER_LEDGER_REPORT_ENDPOINT}`,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
                ])}`,
            });
        }
    };

    const handleClear = () => {
        navigate(`${MEMBER_LEDGER_REPORT_ENDPOINT}`);
    };
    const handleSubmit = () => {
         dispatch(
            getMemberLedgerReport({
                societyId: loginData?.default_community?.community_id,
                wingId: searchParams.get("wingId") || "",
                flatId: searchParams.get("flatId") || "",
                startDate: searchParams.get("startDate") || "",
                endDate: searchParams.get("endDate") || "",
                type: searchParams.get("type") || "",
                invoiceType: searchParams.get("typeid") || "",
                glCode: searchParams.get("glCode") || "",
                financialYear: searchParams.get("finYear") || "",
            })
        )
    };


    useEffect(() => {
        let societyId = loginData?.default_community?.community_id;
        dispatch(getWingsAndFlatDetails({ societyId }));
        dispatch(getInvoiceTypeList({ societyId }))
        dispatch(memberLedgerList({ societyId }))
        if (searchParams.get("wingId")) {
            dispatch(
                getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
            );
        }
    }, []);

    const handleDownloadExcel = () => {
        // dispatch(
        //     downloadSocietyReportExcel({
        //         societyId: loginData?.default_community?.community_id,
        //         compsrchwing: searchParams.get("wingId") || "",
        //         compflatid: searchParams.get("flatId") || "",
        //         search_from_date: searchParams.get("startDate") || "",
        //         search_to_date: searchParams.get("endDate") || "",
        //         status1: searchParams.get("status") || "",
        //         page: searchParams.get("page") || 0,
        //         limit: searchParams.get("pageLimit") || 10,
        //         export: 1,
        //         onSuccessCallback: (url) => {
        //             downloadExcel({ url, fileName: "sample.csv" });
        //         },
        //     })
        // );
    };
    // {
    //     "compflatid": "",
    //     "compsrchwing": "",
    //     "export": 0,
    //     "id": 4176,
    //     "limit": 10,
    //     "page": 0,
    //     "search_content": "",
    //     "search_from_date": "",
    //     "search_to_date": "",
    //     "status1": "",
    // }
    const getTableData = () => {
        // dispatch(
        //     getSocietyEmailReport({
        //         societyId: loginData?.default_community?.community_id,
        //         search_content: searchParams.get("searchBy") || "",
        //         compsrchwing: searchParams.get("wingId") || "",
        //         compflatid: searchParams.get("flatId") || "",
        //         search_from_date: searchParams.get("startDate") || "",
        //         search_to_date: searchParams.get("endDate") || "",
        //         status1: searchParams.get("status") || "",
        //         page: searchParams.get("page") || 0,
        //         limit: searchParams.get("pageLimit") || 10,
        //         export: 0,
        //     })
        // )
    }
    // useEffect(() => {
    //     getTableData();
    // }, [searchParams]);

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Member Ledger Report">
                        <div className="flex gap-3 text-sm">
                            {/* <Search height="8" width="48" placeholder="Search by Email"
                                onclick={handleSearch}
                                value={searchParams.get("search")}
                            /> */}
                            <div className="border-r border-[#CCC]"></div>
                            {/* {downLoader ?
                            <ButtonIco className="justify-center w-32 " children={<CircularProgress sx={{ color: "#d32f2f" }} size={17} />} />
                            :
                        } */}
                            <ButtonIco icon={faDownload} children='Download Excel'
                                onClick={handleDownloadExcel}
                            />
                        </div>
                    </ListHeader>
                </div>
                <div className="w-full">
                    <div className="flex gap-2 py-1 my-1 justify-evenly">
                        <div className="flex items-center justify-between gap-3">
                            {/* <div className="text-sm">Financial Year</div> */}
                            <Dropdown2
                                options={financialYears}
                                width="172px"
                                height="32px"
                                name={"onBehalfOf"}
                                placeholder="Select Year"
                                onSelect={(selectedItem) => {
                                    handelSelectFinancialYear(selectedItem)
                                    //setYear(selectedItem.value);
                                }}
                                value={
                                    searchParams.get("finYear") && {
                                        value: searchParams.get("finYear"),
                                        label: searchParams.get("finYear"),
                                    }
                                }
                            />
                            {/* <div className="text-sm">Invoice Type</div> */}
                            <Dropdown2
                                options={
                                    typeLists
                                        ? typeLists?.map(
                                            (item) => ({
                                                label: `${item?.daybook} - ${item?.invoice_title}`,
                                                value: item?.id,
                                            })
                                        )
                                        : []
                                }
                                placeholder="Invoice type"
                                className="text-[11px] ms-4"
                                width="172px"
                                height="32px"
                                onSelect={(selectedItem) => {
                                    handelSelectInvoiceType(selectedItem);
                                }}
                                value={
                                    searchParams.get("typeid") && {
                                        value: searchParams.get("typeid"),
                                        label: typeLists ? `${typeLists?.find((item) => item?.id === parseInt(searchParams.get("typeid")))?.daybook} - ${typeLists?.find((item) => item?.id === parseInt(searchParams.get("typeid")))?.invoice_title}` : "",
                                    }
                                }
                            />
                            <Dropdown2
                                options={status}
                                width="172px"
                                height="32px"
                                name={"onBehalfOf"}
                                placeholder="Select Entry Type"
                                onSelect={(selectedItem) => {
                                    handelSelectType(selectedItem)
                                    //setYear(selectedItem.value);
                                }}
                                value={
                                    searchParams.get("type") && {
                                        value: searchParams.get("type"),
                                        label: status?.find((item) => item.value === searchParams.get("type")).label ,
                                    }
                                }
                            />
                            <div className="flex items-center justify-between gap-3">
                                {/* <div className="text-sm">Search By</div> */}
                                <RangeDatePicker
                                    className={"w-[190px] h-8"}
                                    defaultStartValue={
                                        searchParams.get("startDate")
                                            ? new Date(searchParams.get("startDate"))
                                            : ""
                                    }
                                    defaultEndValue={
                                        searchParams.get("endDate")
                                            ? new Date(searchParams.get("endDate"))
                                            : ""
                                    }
                                    onStartDateChange={handleStartDate}
                                    onEndDateChange={handleEndDate}
                                />
                               
                            </div>

                        </div>
                    </div>
                    <div className="flex gap-2 py-1 my-1 justify-evenly">
                        <div className="flex items-center justify-between gap-3">
                            {/* <div className="text-sm">Flat No.</div> */}
                            <Dropdown2
                                options={
                                    wingsData?.map((item) => ({
                                        value: item?.wing_id,
                                        label: item?.number,
                                    })) || []
                                }
                                placeholder="Select Wing"
                                className="text-[11px] ms-4"
                                width="120px"
                                height="32px"
                                onSelect={(selectedItem) => {
                                    handleSelectWing(selectedItem);
                                }}
                                value={
                                    searchParams.get("wingId") && {
                                        value: searchParams.get("wingId"),
                                        label: wingsData?.find(
                                            (item) =>
                                                item.wing_id?.toString() === searchParams.get("wingId")
                                        )?.number,
                                    }
                                }
                            />
                            <Dropdown2
                                options={
                                    (searchParams.get("wingId") &&
                                        flatsData?.manage_flat_details?.map((item) => ({
                                            value: item?.flat_id,
                                            label: item?.flat_no,
                                        }))) ||
                                    []
                                }
                                placeholder="Select Flat"
                                className="text-[11px] ms-4"
                                width="120px"
                                height="32px"
                                onSelect={(selectedItem) => {
                                    handleSelectFlat(selectedItem);
                                }}
                                value={
                                    searchParams.get("flatId") && {
                                        value: searchParams.get("flatId"),
                                        label: flatsData?.manage_flat_details?.find(
                                            (item) =>
                                                item.flat_id?.toString() === searchParams.get("flatId")
                                        )?.flat_no,
                                    }
                                }
                            />
                        </div>
                        <div>OR</div>
                        <div className="flex items-center justify-between gap-3">
                            {/* <div className="text-sm">Member Ledger</div> */}
                            <Dropdown2
                                options={
                                    ledgerList
                                        ? ledgerList?.map(
                                            (item) => ({
                                                label: item?.member_name,
                                                value: item?.gl_code,
                                            })
                                        )
                                        : []
                                }
                                placeholder="Member Gl Account"
                                className="text-[11px] ms-4"
                                width="250px"
                                height="32px"
                                onSelect={(selectedItem) => {
                                    handelSelectMemberLedger(selectedItem);
                                }}
                                value={
                                    searchParams.get("glCode") && {
                                        value: searchParams.get("glCode"),
                                        label: ledgerList?.find((item) => item?.gl_code === searchParams.get("glCode"))?.member_name || "",
                                    }
                                }
                            />
                        </div>  
                        <div className="flex items-center justify-between gap-3">
                            <Button label="Submit" className="h-8" onClick={handleSubmit} />      
                            <ButtonG label="Clear" className="h-8" onClick={handleClear} />
                        </div>
                              
                    </div>
                </div>
                 {loading ?  <Loader /> :                
                    <MemberLedgerReportsTable
                        loading={loading}
                        data={reportList}
                    />
                }
                {!reportList?.length && !loading && (
                    <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                        No Records
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default MemberLedgerReports;