import { call, put, takeLatest } from 'redux-saga/effects'
import { notify } from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/SetupRecordsReqs/Occupants&TenantsReqs/OccupantsInfoReqs'
import { deleteImageFromFirebase, getFirebaseURL, uploadImageOnFirebase } from '../../../../firebase/firebaseFunction';
import { flatsDocsPath, societyOccupantDocsPath, societyOccupantProfilePath } from '../../../../firebase/getFirebasePath';

function* getTitleList(action) {
    try {
        const result = yield call(req.getTitleListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_TITLE_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_TITLE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getOccupantDetailsList(action) {
    try {
        const result = yield call(req.getOccupantsListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_OCCUPANTS_DETAILS_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_OCCUPANTS_DETAILS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadOccupantDetailsList(action) {
    try {
        const result = yield call(req.downloadOccupantsExcelSheetReq, action);
        if (result instanceof ArrayBuffer) {
            yield put({ type: types.DOWNLOAD_OCCUPANTS_DETAILS_LIST_SUCCESS});
            action.data?.onSucessCallback(result)
        } else {
            yield put({ type: types.DOWNLOAD_OCCUPANTS_DETAILS_LIST_FAILURE});
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* sendEmailToOccupant(action) {
    try {
        const result = yield call(req.sendEmailToOccupantsReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.SEND_EMAIL_TO_OCCUPANT_SUCCESS });
        } else {
            notify('Unable to send Email.')
            yield put({ type: types.SEND_EMAIL_TO_OCCUPANT_FAILURE });
        }
        action.data?.onRequestEndCallback()
    } catch (e) {
    }
}

function* changeOccupantStatus(action) {
    action.data?.onRequestEndCallback()
    try {
        const result = yield call(req.changeOccupantStatusReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.CHANGE_OCCUPANT_STATUS_SUCCESS, payload: action.data });
        } else {
            yield put({ type: types.CHANGE_OCCUPANT_STATUS_FAILURE });
        }
    } catch (e) {
    }
}

function* changeOccupantStatusInBulk(action) {
    try {
        const result = yield call(req.changeOccupantStatusInBulkReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.CHANGE_OCCUPANT_STATUS_IN_BULK_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback()
        } else {
            yield put({ type: types.CHANGE_OCCUPANT_STATUS_IN_BULK_FAILURE });
        }
    } catch (e) {
    }
}

function* getMemberPrivacyList(action) {
    try {
        const result = yield call(req.getMemberPrivacyListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_MEMBERS_PRIVACY_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_MEMBERS_PRIVACY_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateMemberPrivacy(action) {
    try {
        const result = yield call(req.updateMemberPrivacyReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_MEMBERS_PRIVACY_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback()
        } else {
            yield put({ type: types.UPDATE_MEMBERS_PRIVACY_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addOccupants(action) {
    try {
        if (typeof action.data.profilePic !== 'string') {
            let path = societyOccupantProfilePath({ societyId: action.data.societyId, flatId: action.data.flatId, fileName: action.data.profilePic?.name });
            yield uploadImageOnFirebase(action.data.profilePic, path)
            action.data.profilePic = path;
        }
        if (typeof action.data.adharFile !== 'string') {
            let path = societyOccupantDocsPath({ societyId: action.data.societyId, flatId: action.data.flatId, fileName: action.data.adharFile?.name });
            yield uploadImageOnFirebase(action.data.adharFile, path)
            action.data.adharFile = path;
        }

        const result = yield call(req.addOccupantsReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_OCCUPANTS_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.ADD_OCCUPANTS_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteOccupants(action) {
    try {
        const result = yield call(req.deleteOccupantsReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_OCCUPANT_SUCCESS, payload: action.data });

            if (action.data.fetchData) {
                const newOccupantsList = yield call(req.getOccupantsListReq, action)
                if (result.statusCode === 200)
                    yield put({ type: types.GET_OCCUPANTS_DETAILS_LIST_SUCCESS, payload: newOccupantsList.data });
            }
        }
    } catch (e) {
        console.log(e)
    }
}

function* getOccupantsAuditList(action) {
    try {
        const result = yield call(req.getOccupantsAuditLogsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_OCCUPANTS_AUDIT_LOGS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_OCCUPANTS_AUDIT_LOGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getExistingOccupantsList(action) {
    try {
        const result = yield call(req.getExistingOccupantsListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_EXISTING_OCCUPANTS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_EXISTING_OCCUPANTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addExistingOccupantsList(action) {
    try {
        const result = yield call(req.addExistingOccupantsListReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_EXISTING_OCCUPANTS_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback()
        } else {
            yield put({ type: types.ADD_EXISTING_OCCUPANTS_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getArchivedOccupants(action) {
    try {
        const result = yield call(req.getArchivedOccupantsListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ARCHIVE_OCCUPANTS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_ARCHIVE_OCCUPANTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getOccupantDeatils(action) {
    try {
        const result = yield call(req.getOccupantDetailsReq, action);
        if (result.statusCode === 200) {
            result.data.user_detail.aadhar_file_url = yield getFirebaseURL(result?.data?.user_detail?.aadhar_file)
            result.data.user_detail.profile_image_url = yield getFirebaseURL(result?.data?.user_detail?.profile_image)

            for (let flat of result.data?.flat_data) {
                flat.flat_docs.rent_agreement = yield getFirebaseURL(flat?.flat_docs?.rent_agreement)
                flat.flat_docs.noc = yield getFirebaseURL(flat?.flat_docs?.noc)
                let rentAgreementDoc = yield getFirebaseURL(flat?.rent_details?.split(',')?.[2])
                flat.rent_details = flat?.rent_details?.split(',')?.[0] + ',' + flat?.rent_details?.split(',')?.[1] + ',' + rentAgreementDoc

                for (let staff of flat?.staff_data) {
                    if (!staff.profile_image?.includes('https:'))
                        staff.profile_image = yield getFirebaseURL(staff?.profile_image)
                    if (!staff.add_proof_doc?.includes('https:'))
                        staff.add_proof_doc = yield getFirebaseURL(staff?.add_proof_doc)
                    if (!staff.photo_id?.includes('https:'))
                        staff.photo_id = yield getFirebaseURL(staff?.photo_id)
                    if (!staff.other_document?.includes('https:'))
                        staff.other_document = yield getFirebaseURL(staff?.other_document)
                }
            }

            yield put({ type: types.GET_OCCUPANTS_DETAILS_SUCCESS, payload: { ...result.data, ...action.data } });
        } else {
            yield put({ type: types.GET_OCCUPANTS_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateOccupantDetails(action) {
    try {
        if (typeof action.data.profilePic !== 'string') {
            let path = societyOccupantProfilePath({ societyId: action.data.societyId, flatId: action.data.flats?.[0]?.flatId, fileName: action.data.profilePic?.name });
            yield uploadImageOnFirebase(action.data.profilePic, path)
            action.data.profilePic = path;
        }
        if (typeof action.data.adharFile !== 'string') {
            let path = societyOccupantDocsPath({ societyId: action.data.societyId, flatId: action.data.flats?.[0]?.flatId, fileName: action.data.adharFile?.name });
            yield uploadImageOnFirebase(action.data.adharFile, path)
            action.data.adharFile = path;
        }
        const result = yield call(req.editOccupantDetailsReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            action.data.onSuccessCallback?.()
            yield put({ type: types.UPDATE_OCCUPANTS_DETAILS_SUCCESS, payload: action.data });
        } else {
            yield put({ type: types.UPDATE_OCCUPANTS_DETAILS_FAILURE });
        }
        action.data?.onEndCallack?.()
    } catch (e) {
        console.log(e)
    }
}

function* getMortgageDetails(action) {
    try {

        const result = yield call(req.getMortgageRegisterDetailsReq, action);
        if (result.statusCode === 200) {
            result.data.cancel_letter_doc_url = yield getFirebaseURL(result?.data?.cancel_letter_doc)
            result.data.society_noc_doc_url = yield getFirebaseURL(result?.data?.society_noc_doc)
            result.data.charge_letter_url = yield getFirebaseURL(result?.data?.charge_letter_doc)

            yield put({ type: types.GET_MORTGAGE_DETAILS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_MORTGAGE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addMortgageDetails(action) {
    try {
        if (typeof action.data.letterOfLien !== 'string') {
            let path = societyOccupantProfilePath({ societyId: action.data.societyId, flatId: action.data?.flatId, fileName: action.data.letterOfLien?.name });
            yield uploadImageOnFirebase(action.data.letterOfLien, path)
            action.data.letterOfLien = path;
        }
        if (typeof action.data.cancellationLetter !== 'string') {
            let path = societyOccupantProfilePath({ societyId: action.data.societyId, flatId: action.data?.flatId, fileName: action.data.cancellationLetter?.name });
            yield uploadImageOnFirebase(action.data.cancellationLetter, path)
            action.data.cancellationLetter = path;
        }
        if (typeof action.data.societyNoc !== 'string') {
            let path = societyOccupantProfilePath({ societyId: action.data.societyId, flatId: action.data?.flatId, fileName: action.data.societyNoc?.name });
            yield uploadImageOnFirebase(action.data.societyNoc, path)
            action.data.societyNoc = path;
        }
        const result = yield call(req.addMortgageRegisterDetailsReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            action.data?.onSuccessCallback?.()
            yield put({ type: types.ADD_MORTGAGE_DETAILS_SUCCESS, payload: action.data });
        } else {
            yield put({ type: types.ADD_MORTGAGE_DETAILS_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}


function* addFamilyMember(action) {
    try {
        const result = yield call(req.addFamilyMemberReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_FAMILY_MEMBER_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.ADD_FAMILY_MEMBER_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteFamilyMember(action) {
    try {
        const result = yield call(req.deleteFamilyMemberReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_FAMILY_MEMBER_SUCCESS, payload: action.data });
            const newOccupantsDetail = yield call(req.getOccupantDetailsReq, action)
            if (result.statusCode === 200)
                yield put({ type: types.GET_OCCUPANTS_DETAILS_SUCCESS, payload: newOccupantsDetail.data });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getOccupantsStaffList(action) {
    try {
        const result = yield call(req.getOccupantsStaffListReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.GET_OCCUPANTS_STAFF_DETAILS_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback()
        } else {
            yield put({ type: types.GET_OCCUPANTS_STAFF_DETAILS_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateFamilyMember(action) {
    try {
        const result = yield call(req.updateFamilyMemberReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_FAMILY_MEMBER_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.UPDATE_FAMILY_MEMBER_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addFamilyMemberThroughExcel(action) {
    try {
        const result = yield call(req.addFamilyMemberThroughExcelReq, action);
        if (result.statusCode === 200) {
            if (result?.data?.message)
                notify('error', result?.data?.message)
            else
                notify('success', result?.message)
            yield put({ type: types.ADD_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS, payload: { error: result?.data?.message } });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.ADD_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE });
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* updateFamilyMemberThroughExcel(action) {
    try {
        const result = yield call(req.updateFamilyMemberThroughExcelReq, action);
        if (result.statusCode === 200) {
            if (result?.data?.message)
                notify('error', result?.data?.message)
            else
                notify('success', result?.message)
            yield put({ type: types.UPDATE_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS, payload: { error: result?.data?.message } });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.UPDATE_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE });
        }
        action.data?.onRequestEndCallback?.()
    } catch (e) {
        console.log(e)
    }
}

function* downloadExcelSampleOfFamilyMember(action) {
    try {
        const result = yield call(req.downloadExcelSampleOfFamilyMemberReq, action);
        if (result instanceof ArrayBuffer) {
            action.data?.onSucessCallback(result)
        } else {
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* getFlatDocuments(action) {
    try {
        const result = yield call(req.getFlatDocumentsReq, action);
        if (result.statusCode === 200) {
            result.data.sale_deed_url = yield getFirebaseURL(result?.data?.sale_deed)
            result.data.noc_url = yield getFirebaseURL(result?.data?.noc)
            result.data.rent_agreement_doc_url = yield getFirebaseURL(result?.data?.rent_agreement)

            for (let doc of result.data?.other_docs) {
                doc.file_url = yield getFirebaseURL(doc?.file)
            }

            yield put({ type: types.GET_FLAT_DOCUMENTS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_FLAT_DOCUMENTS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateFlatDocuments(action) {
    try {
        const deleteItem = action.data.documents?.find(item => item.status === 'D')
        if (deleteItem) {
            yield deleteImageFromFirebase(deleteItem.doc)
        } else {
            if (typeof action.data.saleDeed !== 'string') {
                let path = flatsDocsPath({ societyId: action.data.societyId, flatId: action.data?.flatId, fileName: action.data.saleDeed?.name });
                yield uploadImageOnFirebase(action.data.saleDeed, path)
                action.data.saleDeed = path;
            }
            if (typeof action.data.noc !== 'string') {
                let path = flatsDocsPath({ societyId: action.data.societyId, flatId: action.data?.flatId, fileName: action.data.noc?.name });
                yield uploadImageOnFirebase(action.data.noc, path)
                action.data.noc = path;
            }
            if (typeof action.data.rentAgreement !== 'string') {
                let path = flatsDocsPath({ societyId: action.data.societyId, flatId: action.data?.flatId, fileName: action.data.rentAgreement?.name });
                yield uploadImageOnFirebase(action.data.rentAgreement, path)
                action.data.rentAgreement = path;
            }

            for (let doc of action.data?.documents) {
                if (typeof doc.doc !== 'string') {
                    let path = flatsDocsPath({ societyId: action.data.societyId, flatId: action.data?.flatId, fileName: doc.doc?.name });
                    yield uploadImageOnFirebase(doc.doc, path)
                    doc.doc = path;
                }
            }
        }

        const result = yield call(req.updateFlatDocumentsReq, action);
        if (result.statusCode === 200) {
            if (deleteItem)
                notify('success', 'Data deleted Successfully')
            else
                notify('success', result?.message)
            yield put({ type: types.UPDATE_FAMILY_MEMBER_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.UPDATE_FAMILY_MEMBER_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getNomineeList(action) {
    try {
        const result = yield call(req.getNomineeListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.VIEW_NOMINEE_DETAILS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.VIEW_NOMINEE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addUpdateNominee(action) {
    try {
        const result = yield call(req.addUpdateNomineeReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_UPDATE_NOMINEE_DETAILS_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback()
        } else {
            yield put({ type: types.ADD_UPDATE_NOMINEE_DETAILS_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteNominee(action) {
    try {
        const result = yield call(req.deleteNomineeReq, action);
        if (result.statusCode === 200) {

            notify('success', result.message);
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_NOMINEE_DETAILS_SUCCESS, payload: action.data });

            const newdata = yield call(req.getNomineeListReq, action)
            if (result.statusCode === 200)
                yield put({ type: types.VIEW_NOMINEE_DETAILS_SUCCESS, payload: newdata.data });
        } else
            yield put({ type: types.DELETE_NOMINEE_DETAILS_FAILURE });
    } catch (e) {
        console.log(e)
    }
}

function* getVehicleDetails(action) {
    try {
        const result = yield call(req.getVehicleDetailsListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_VEHICLE_DETAILS_SUCCESS, payload: result.data });
            action.data?.onSuccessCallback()
        } else {
            yield put({ type: types.GET_VEHICLE_DETAILS_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateVehicleDetails(action) {
    try {
        const result = yield call(req.updateVehicleDetailsReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.UPDATE_VEHICLE_DETAILS_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.UPDATE_VEHICLE_DETAILS_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteVehicleDetails(action) {
    try {
        const result = yield call(req.deleteVehicleReq, action);
        if (result.statusCode === 200) {
            notify('success', result.message);
            action.data?.onSuccessCallback?.()
            yield put({ type: types.DELETE_VEHICLE_DETAILS_SUCCESS, payload: action.data });
        } else {
            yield put({ type: types.DELETE_VEHICLE_DETAILS_FAILURE });

        }
    } catch (e) {
        console.log(e)
    }
}

export default function* occupantsInfoSaga() {
    yield takeLatest(types.GET_TITLE_LIST, getTitleList);
    yield takeLatest(types.GET_OCCUPANTS_DETAILS_LIST, getOccupantDetailsList);
    yield takeLatest(types.DOWNLOAD_OCCUPANTS_DETAILS_LIST, downloadOccupantDetailsList);
    yield takeLatest(types.SEND_EMAIL_TO_OCCUPANT, sendEmailToOccupant);
    yield takeLatest(types.CHANGE_OCCUPANT_STATUS, changeOccupantStatus);
    yield takeLatest(types.CHANGE_OCCUPANT_STATUS_IN_BULK, changeOccupantStatusInBulk);
    yield takeLatest(types.GET_MEMBERS_PRIVACY, getMemberPrivacyList);
    yield takeLatest(types.UPDATE_MEMBERS_PRIVACY, updateMemberPrivacy);
    yield takeLatest(types.ADD_OCCUPANTS, addOccupants);
    yield takeLatest(types.DELETE_OCCUPANT, deleteOccupants);
    yield takeLatest(types.GET_OCCUPANTS_AUDIT_LOGS, getOccupantsAuditList);
    yield takeLatest(types.GET_EXISTING_OCCUPANTS, getExistingOccupantsList);
    yield takeLatest(types.ADD_EXISTING_OCCUPANTS, addExistingOccupantsList);
    yield takeLatest(types.GET_ARCHIVE_OCCUPANTS, getArchivedOccupants)
    yield takeLatest(types.GET_OCCUPANTS_DETAILS, getOccupantDeatils);
    yield takeLatest(types.UPDATE_OCCUPANTS_DETAILS, updateOccupantDetails);
    yield takeLatest(types.GET_MORTGAGE_DETAILS, getMortgageDetails);
    yield takeLatest(types.ADD_MORTGAGE_DETAILS, addMortgageDetails);
    yield takeLatest(types.ADD_FAMILY_MEMBER, addFamilyMember);
    yield takeLatest(types.GET_OCCUPANTS_STAFF_DETAILS, getOccupantsStaffList);
    yield takeLatest(types.DELETE_FAMILY_MEMBER, deleteFamilyMember);
    yield takeLatest(types.UPDATE_FAMILY_MEMBER, updateFamilyMember);
    yield takeLatest(types.ADD_FAMILY_MEMBER_THROUGH_EXCEL, addFamilyMemberThroughExcel);
    yield takeLatest(types.UPDATE_FAMILY_MEMBER_THROUGH_EXCEL, updateFamilyMemberThroughExcel);
    yield takeLatest(types.DOWNLOAD_FAMILY_MEMBER_EXCEL_SAMPLE, downloadExcelSampleOfFamilyMember);
    yield takeLatest(types.GET_FLAT_DOCUMENTS, getFlatDocuments);
    yield takeLatest(types.UPDATE_FLAT_DOCUMENTS, updateFlatDocuments);
    yield takeLatest(types.VIEW_NOMINEE_DETAILS, getNomineeList);
    yield takeLatest(types.ADD_UPDATE_NOMINEE_DETAILS, addUpdateNominee);
    yield takeLatest(types.DELETE_NOMINEE_DETAILS, deleteNominee);
    yield takeLatest(types.UPDATE_VEHICLE_DETAILS, updateVehicleDetails);
    yield takeLatest(types.DELETE_VEHICLE_DETAILS, deleteVehicleDetails);
    yield takeLatest(types.GET_VEHICLE_DETAILS, getVehicleDetails);
}