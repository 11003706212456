import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import {
  MANAGE_DEBIT_CURRENT_ENDPOINT,
  MANAGE_DEBIT_DRAFT_ENDPOINT,
} from "../../../../Routing/routes";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ButtonG from "../../../../components/Button/ButtonG";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { societyStaffStatusOptions } from "../../../../consts/DropDownOptions";
import Loader from "../../../../components/Loader/Loader";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import Checkbox from "../../../../components/Checkbox/Checkbox";
// import EditDebitDetailBox from "./EditDebitDetailBox";
import EditDebitDraftDetailBox from "./EditDebitDraftDetailBox";
import { Form, Formik } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getWingsAndFlatDetails,
  getFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  getDebiteNoteTarrifList,
  getDebiteNoteFlatWingWiseList,
  getDebiteNoteArrearDetails,
  addDebiteNote,
  editSingleDebitNoteDetails,
  editSingleDebitNoteSave,
  editSingleDebitNoteDraftSave,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import { getNonMemberList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageAdvanceNoteAction";
import { getBookingGLMappingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
import { getTenantsDetailsList } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import {
  AddDebitNoteValidation,
  AddMultiDebitNoteValidation,
} from "../../../../validationSchema/financeSchema/billingManagementSchema";
import { TextInput } from "../../../../components/InputFields/TextInput";
import { TextArea } from "../../../../components/InputFields/TextArea";
import { object } from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

const Accordion = ({
  label,
  isOpen,
  toggleAccordion,
  children,
  totalAmount,
}) => {
  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center w-full">
          <div>
            <button
              className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
              border rounded flex justify-center items-center"
            >
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
          </div>
          <div className="text-[#000000] grow w-full text-sm font-semibold justify-between flex">
            <span className="">{label}</span>
            {!!totalAmount && (
              <span className="">Amount: {totalAmount.toFixed(2)}</span>
            )}
          </div>
        </div>
      </div>
      {isOpen && <>{children}</>}
    </div>
  );
};

const DebitNoteMemberTypeOptions = [
  { value: "Member", label: "Members" },
  { value: "Vendor", label: "Non Member" },
  { value: "Tenant", label: "Tenants" },
];
const BillingType = [
  { value: "debitnote", label: "Debit Note" },
  { value: "supplementary", label: "Supplementary", isDisabled: false },
];
const headCells = [
  { label: "Tariff Header" },
  { label: "Credit A/C" },
  { label: "Amount (Rs.)" },
  { label: "Description" },
  { label: "From Date" },
  { label: "To Date" },
];

const EditDebitNoteDraft = () => {
  const data = useLocation().state;
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { wingsData } = useSelector((state) => state.wingsReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const {
    updateLoader,
    loading,
    manageDebitNoteFlatWingsWise,
    loadingManageDebitNoteFlatWingsWise,
    manageDebitNoteArrearDetails,
    editSingleDebitNoteDetail,
  } = useSelector((state) => state.manageDebitNoteReducer);
  const { glMappingList } = useSelector((state) => state.viewBookingReducer);
  const { nonMemberList } = useSelector(
    (state) => state.manageAdvanceNoteReducer
  );

  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);

  // const [debitNoteDraft, setDebitNoteDraft] = useState();
  const [enableReinitialize, setEnableReinitialize] = useState(true);

  useEffect(() => {
    const societyId = loginData?.default_community?.community_id;
    // dispatch(getWingsAndFlatDetails());
    dispatch(getInvoiceTypeList({ societyId }));

    // dispatch(getDebiteNoteTarrifList({ societyId }));
    if (data?.invid?.length) {
      dispatch(editSingleDebitNoteDetails({ societyId, invid: data?.invid }));
    } else if (id) {
      dispatch(editSingleDebitNoteDetails({ societyId, invid: [id] }));
    }
    dispatch(getBookingGLMappingList({ societyId }));
    dispatch(getNonMemberList({ societyId }));
    dispatch(
      getTenantsDetailsList({
        societyId: loginData?.default_community?.community_id,
        pageLimit: 10,
        page: 1,
      })
    );
    dispatch(getDebiteNoteArrearDetails());
    setTenantListData([]);
    // setDebitNoteDraft();
  }, []);

  const [flatWingWiseListId, setFlatWingWiseListId] = useState([]);
  const [flatWingWiseList, setFlatWingWiseList] = useState({});

  useEffect(() => {
    if (
      Object.entries(editSingleDebitNoteDetail)?.filter(
        (item) => item?.[1].issued_type == "member" && item?.[1].wing_id
      ).length > 0
    ) {
      let data = Object.entries(editSingleDebitNoteDetail)
        ?.filter(
          (item) => item?.[1].issued_type == "member" && item?.[1].wing_id
        )
        .map((item) => item?.[1].wing_id);
      setFlatWingWiseListId(data);
    }
    // setDebitNoteDraft(editSingleDebitNoteDetail);
  }, [editSingleDebitNoteDetail]);

  useEffect(() => {
    if (flatWingWiseListId.length > 0) {
      dispatch(
        getDebiteNoteFlatWingWiseList({
          id: loginData?.default_community?.community_id,
          wing_no: flatWingWiseListId.slice(-1),
        })
      );
    }
  }, [flatWingWiseListId]);

  useEffect(() => {
    let data = flatWingWiseList;
    data[
      manageDebitNoteFlatWingsWise?.filter(
        (item) => item.main_member && item.gl_codeid > 0
      )[0]?.wing_no
    ] = manageDebitNoteFlatWingsWise?.filter(
      (item) => item.main_member && item.gl_codeid > 0
    );

    setFlatWingWiseList(data);
    setFlatWingWiseListId((item) => item.slice(0, -1));
  }, [manageDebitNoteFlatWingsWise]);

  const [arrearDetailsListId, setArrearDetailsListId] = useState(null);
  const [arrearDetailsList, setArrearDetailsList] = useState({});

  useEffect(() => {
    if (arrearDetailsListId) {
      let data = arrearDetailsList;
      data[arrearDetailsListId] = manageDebitNoteArrearDetails;

      setArrearDetailsList(data);
      setArrearDetailsListId(null);
    }
  }, [manageDebitNoteArrearDetails]);

  const { tenantsList } = useSelector((state) => state.tenantInfoReducer);
  const [tenantListData, setTenantListData] = useState([]);

  useEffect(() => {
    if (!(tenantListData.length > 0)) {
      if (tenantsList?.page_limit > 10) {
        setTenantListData(tenantsList?.tenants_list);
      } else if (tenantsList?.total_pages > 1) {
        dispatch(
          getTenantsDetailsList({
            societyId: loginData?.default_community?.community_id,
            pageLimit:
              tenantsList.total_pages > 1
                ? 10 * tenantsList?.total_pages
                : 10 * 2,
            page: 1,
          })
        );
      } else if (tenantsList?.total_pages <= 1) {
        setTenantListData(tenantsList?.tenants_list);
      }
    }
  }, [tenantsList?.page_limit]);

  const getArrears = (values, index = 0) => {
    if (
      values.invoice_date &&
      values.due_date &&
      values.issued_type &&
      values.bill_type &&
      ((values.flat_id && values.flat_id != "0") ||
        (values.debitor_id && values.debitor_id != "0") ||
        (values.debitor_id && values.debitor_id != "0"))
    ) {
      setArrearDetailsListId(index);
      dispatch(
        getDebiteNoteArrearDetails({
          id: loginData?.default_community?.community_id,
          invoice_date: values.invoice_date,
          due_date: values.due_date,
          bill_type: values.bill_type,
          issue_type: values.issued_type,
          flat_id: values.issued_type == "member" ? values.flat_id : "",
          debitor_id:
            values.issued_type == "tenant"
              ? values.debitor_id
              : values.issued_type == "vendor"
              ? values.debitor_id
              : "",
          invoiceType:
            editSingleDebitNoteDetail?.debitnote_header?.invoice_type,
        })
      );
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    let data = { mainData: {} };
    values.debitNoteIds.map((item, index) => {
      data.mainData[item] = values.draftDebitNoteDetails[index];

      data.mainData[item].send_email =
        values.draftDebitNoteDetails[index].send_email == "Y" ? 1 : 0;
      data.mainData[item].send_sms =
        values.draftDebitNoteDetails[index].send_sms == "Y" ? 1 : 0;

      data.mainData[item].head_detail.map((item2, index1) => {
        let igst_rate =
          item2?.gstapplicable == "Y"
            ? Number(values.debitnoteHeader?.igst_rate) &&
              values.debitnoteHeader?.igst_head
              ? Number(values.debitnoteHeader?.igst_rate)
              : 0
            : 0;
        let tax_rate =
          item2?.gstapplicable == "Y"
            ? (Number(values.debitnoteHeader?.cgst_rate) &&
              values.debitnoteHeader?.cgst_head
                ? Number(values.debitnoteHeader?.cgst_rate)
                : 0) +
              (Number(values.debitnoteHeader?.sgst_rate) &&
              values.debitnoteHeader?.sgst_head
                ? Number(values.debitnoteHeader?.sgst_rate)
                : 0)
            : 0;
        data.mainData[item].head_detail[index1].igst_amount =
          (Number(item2.amount) * igst_rate) / 100;
        data.mainData[item].head_detail[index1].tax_amount =
          (Number(item2.amount) * tax_rate) / 100;
      });

      data.mainData[item].sub_total =
        Number(
          data.mainData[item].head_detail.reduce((prevVal, newVal) => {
            return prevVal + Number(newVal?.amount);
          }, 0)
        ) +
        Number(data.mainData[item].igst_amount) +
        Number(data.mainData[item].cgst_amount) +
        Number(data.mainData[item].sgst_amount);
      // data.mainData[item].roundoff_amount =
      //   values.debitnoteHeader?.roundoff == 1
      //     ? Math.round(data.mainData[item].sub_total) -
      //       data.mainData[item].sub_total
      //     : 0;
      data.mainData[item].interest = arrearDetailsList?.[
        values.draftDebitNoteDetails[index].id
      ]?.interest
        ? arrearDetailsList[values.draftDebitNoteDetails[index].id]?.interest
        : data.mainData[item].interest;
      data.mainData[item].principal_arrear = arrearDetailsList?.[
        values.draftDebitNoteDetails[index].id
      ]?.principal_arrear
        ? arrearDetailsList[values.draftDebitNoteDetails[index].id]
            ?.principal_arrear
        : data.mainData[item].principal_arrear;
      data.mainData[item].interest_arrear = arrearDetailsList?.[
        values.draftDebitNoteDetails[index].id
      ]?.interest_arrear
        ? arrearDetailsList[values.draftDebitNoteDetails[index].id]
            ?.interest_arrear
        : data.mainData[item].interest_arrear;

      data.mainData[item].grand_total =
        Number(data.mainData[item].sub_total) +
        Number(data.mainData[item].interest) +
        Number(data.mainData[item].interest_arrear) +
        Number(data.mainData[item].principal_arrear) +
        (values?.debitnoteHeader?.roundoff == 1
          ? Number(data.mainData[item]?.roundoff_amount)
          : 0);
    });

    dispatch(
      editSingleDebitNoteDraftSave({
        mainData: data?.mainData,
        societyId: loginData?.default_community?.community_id,
        debitnoteHeader: values?.debitnoteHeader,
        is_draft: values?.is_draft,
        onSuccessCallback: () => navigate(MANAGE_DEBIT_DRAFT_ENDPOINT),
      })
    );
  };

  const [openAccordionIndex, setOpenAccordionIndex] = useState([]);
  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) =>
      prevIndex.includes(index)
        ? prevIndex.filter((item) => item != index)
        : [...prevIndex, index]
    );
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            debitNoteIds: Object.entries(editSingleDebitNoteDetail)
              .filter((item) => item?.[0] != "debitnote_header")
              .map((item, index) => item?.[0]),
            draftDebitNoteDetails: Object.entries(editSingleDebitNoteDetail)
              .filter((item) => item?.[0] != "debitnote_header")
              .map((item, index) => item?.[1]),
            debitnoteHeader: editSingleDebitNoteDetail?.debitnote_header,
            is_draft: 0,
          }}
          enableReinitialize={enableReinitialize}
          validationSchema={AddMultiDebitNoteValidation}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Edit Debit Note">
                    <div className="flex gap-2">
                      <ButtonG
                        label="Cancel"
                        onClick={() => {
                          navigate(-1);
                        }}
                        className="h-8"
                        type="button"
                      />
                      <Button
                        label="Save"
                        className="px-5 h-8"
                        type="submit"
                        onClick={() => {
                          setFieldValue("is_draft", 0);
                        }}
                      />
                      <Button
                        label="Post"
                        className="px-5 h-8"
                        type="submit"
                        onClick={() => {
                          setFieldValue("is_draft", 1);
                        }}
                      />
                    </div>
                  </ListHeader>
                </div>

                {loading || updateLoader ? (
                  <Loader />
                ) : (
                  <>
                    {values?.draftDebitNoteDetails.map((data, index) => {
                      return (
                        <Accordion
                          key={index}
                          label={data?.member_name}
                          totalAmount={
                            Number(
                              data?.head_detail?.reduce((prevVal, newVal) => {
                                return Number(prevVal) + Number(newVal?.amount);
                              }, 0)
                            ) +
                            Number(data?.cgst_amount) +
                            Number(data?.sgst_amount) +
                            Number(data?.igst_amount) +
                            (arrearDetailsList?.[data?.id]
                              ? Number(
                                  arrearDetailsList?.[data?.id]
                                    ?.principal_arrear
                                ) +
                                Number(
                                  arrearDetailsList?.[data?.id]?.interest_arrear
                                ) +
                                Number(arrearDetailsList?.[data?.id]?.interest)
                              : Number(data?.principal_arrear) +
                                Number(data?.interest_arrear) +
                                Number(data?.interest)) +
                            Number(data?.roundoff_amount)
                          }
                          isOpen={openAccordionIndex.includes(index)}
                          toggleAccordion={() => {
                            setEnableReinitialize(false);
                            toggleAccordion(index);
                          }}
                        >
                          <>
                            <div className="flex gap-4 p-2">
                              <div className="flex flex-row justify-between w-full gap-7">
                                <div className="flex flex-col w-1/2">
                                  <div className="flex flex-row justify-between mb-1">
                                    <span className="mt-2 text-sm">
                                      Invoice Type
                                    </span>
                                    <div className="flex gap-3 ">
                                      <DropdownFM2
                                        options={
                                          typeLists
                                            ?.filter(
                                              (item) =>
                                                item?.id ==
                                                editSingleDebitNoteDetail
                                                  ?.debitnote_header
                                                  ?.invoice_type
                                            )
                                            ?.map((item) => ({
                                              label: item?.invoice_title,
                                              value: item?.id,
                                            })) || []
                                        }
                                        placeholder="Select Invoice Type"
                                        className=""
                                        width="225px"
                                        height="36px"
                                        value={
                                          editSingleDebitNoteDetail
                                            ?.debitnote_header
                                            ?.invoice_type && {
                                            label:
                                              typeLists?.find(
                                                (item) =>
                                                  item.id ===
                                                  editSingleDebitNoteDetail
                                                    ?.debitnote_header
                                                    ?.invoice_type
                                              )?.invoice_title || "",
                                            value:
                                              editSingleDebitNoteDetail
                                                ?.debitnote_header
                                                ?.invoice_type,
                                          }
                                        }
                                        name={"invoiceType"}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-row justify-between mb-1">
                                    <span className="text-sm mt-2">
                                      Debit Note Date
                                    </span>
                                    <div className="flex gap-3">
                                      <DatePickerComponentFM
                                        className="w-56 h-9  justify-between"
                                        onDateChange={(selectedDate) => {
                                          if (
                                            new Date(
                                              values.draftDebitNoteDetails[
                                                index
                                              ]?.due_date
                                            ).getTime() <
                                            new Date(selectedDate).getTime()
                                          ) {
                                            setFieldValue(
                                              `draftDebitNoteDetails.${index}.due_date`,
                                              null
                                            );
                                          }
                                          setFieldValue(
                                            `draftDebitNoteDetails.${index}.invoice_date`,
                                            moment(selectedDate).format(
                                              "YYYY-MM-DD"
                                            )
                                          );

                                          getArrears(
                                            {
                                              ...data,
                                              invoice_date:
                                                moment(selectedDate).format(
                                                  "YYYY-MM-DD"
                                                ),
                                            },
                                            data?.id
                                          );
                                        }}
                                        name={`draftDebitNoteDetails.${index}.invoice_date`}
                                        defaultValue={
                                          values.draftDebitNoteDetails?.[index]
                                            ?.invoice_date
                                            ? new Date(
                                                values.draftDebitNoteDetails?.[
                                                  index
                                                ]?.invoice_date
                                              )
                                            : null
                                        }
                                        minDate={
                                          values.draftDebitNoteDetails?.[index]
                                            ?.invoice_date &&
                                          new Date(
                                            new Date(
                                              values.draftDebitNoteDetails?.[
                                                index
                                              ]?.invoice_date
                                            ).getFullYear(),
                                            new Date(
                                              values.draftDebitNoteDetails?.[
                                                index
                                              ]?.invoice_date
                                            ).getMonth(),
                                            1
                                          )
                                        }
                                        maxDate={
                                          values.draftDebitNoteDetails?.[index]
                                            ?.invoice_date &&
                                          new Date(
                                            new Date(
                                              values.draftDebitNoteDetails?.[
                                                index
                                              ]?.invoice_date
                                            ).getFullYear(),
                                            new Date(
                                              values.draftDebitNoteDetails?.[
                                                index
                                              ]?.invoice_date
                                            ).getMonth() + 1,
                                            0
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-row justify-between  mb-1">
                                    <span className="text-sm mt-2">
                                      Payment Due Date
                                    </span>
                                    <div className="flex gap-3">
                                      <DatePickerComponentFM
                                        className="w-56 h-9 justify-between"
                                        onDateChange={(selectedDate) => {
                                          setFieldValue(
                                            `draftDebitNoteDetails.${index}.due_date`,
                                            moment(selectedDate).format(
                                              "YYYY-MM-DD"
                                            )
                                          );
                                          if (
                                            values?.draftDebitNoteDetails?.[
                                              index
                                            ]?.due_date !=
                                            moment(selectedDate).format(
                                              "YYYY-MM-DD"
                                            )
                                          )
                                            getArrears(
                                              {
                                                ...data,
                                                due_date:
                                                  moment(selectedDate).format(
                                                    "YYYY-MM-DD"
                                                  ),
                                              },
                                              data?.id
                                            );
                                        }}
                                        name={`draftDebitNoteDetails.${index}.due_date`}
                                        minDate={
                                          new Date(
                                            values?.draftDebitNoteDetails?.[
                                              index
                                            ]?.invoice_date
                                          )
                                        }
                                        defaultValue={
                                          values?.draftDebitNoteDetails?.[index]
                                            ?.due_date
                                            ? new Date(
                                                values?.draftDebitNoteDetails?.[
                                                  index
                                                ]?.due_date
                                              )
                                            : null
                                        }
                                        maxDate={
                                          values.draftDebitNoteDetails?.[index]
                                            ?.due_date &&
                                          new Date(
                                            new Date(
                                              values.draftDebitNoteDetails?.[
                                                index
                                              ]?.due_date
                                            ).getFullYear(),
                                            new Date(
                                              values.draftDebitNoteDetails?.[
                                                index
                                              ]?.due_date
                                            ).getMonth() + 1,
                                            0
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="flex justify-between items-center gap-3 mb-1">
                                    <div className="text-sm">Issued To</div>
                                    <div className="flex gap-6">
                                      <DropdownFM2
                                        options={[
                                          DebitNoteMemberTypeOptions?.find(
                                            (item) =>
                                              item.value.toLowerCase() ==
                                              data?.issued_type
                                          ),
                                        ]}
                                        placeholder="Select Issued To"
                                        className="text-[11px] ms-4"
                                        width="224px"
                                        height="36px"
                                        // onSelect={(selectedItem) => {
                                        //   setFieldValue(
                                        //     `draftDebitNoteDetails.${index}.issued_type`,
                                        //     selectedItem.value.toLocaleLowerCase()
                                        //   );
                                        //   setFieldValue(
                                        //     `draftDebitNoteDetails.${index}.debit_code`,
                                        //     ""
                                        //   );
                                        //   setFieldValue(
                                        //     `draftDebitNoteDetails.${index}.wing_id`,
                                        //     selectedItem.value == "Member"
                                        //       ? ""
                                        //       : "0"
                                        //   );
                                        //   setFieldValue(
                                        //     `draftDebitNoteDetails.${index}.flat_id`,
                                        //     selectedItem.value == "Member"
                                        //       ? ""
                                        //       : "0"
                                        //   );
                                        //   setFieldValue(
                                        //     `draftDebitNoteDetails.${index}.debitor_id`,
                                        //     selectedItem.value == "Tenant" ||
                                        //       selectedItem.value == "Vendor"
                                        //       ? ""
                                        //       : "0"
                                        //   );

                                        //   getArrears(
                                        //     {
                                        //       ...data,
                                        //       issued_type: selectedItem.value,
                                        //     },
                                        //     data?.id
                                        //   );
                                        // }}
                                        value={
                                          DebitNoteMemberTypeOptions &&
                                          DebitNoteMemberTypeOptions?.find(
                                            (item) =>
                                              item.value.toLowerCase() ==
                                              data?.issued_type
                                          )
                                        }
                                        name={`draftDebitNoteDetails.${index}.issued_type`}
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="flex justify-between items-center gap-3 mb-1">
                                    <div className="text-sm">Bill Type</div>
                                    <div className="flex gap-6">
                                      <DropdownFM2
                                        options={BillingType}
                                        placeholder="Select Bill Type"
                                        className="text-[11px] ms-4"
                                        width="224px"
                                        height="36px"
                                        onSelect={(selectedItem) => {
                                          setFieldValue(
                                            `draftDebitNoteDetails.${index}.bill_type`,
                                            selectedItem.value
                                          );
                                          getArrears(
                                            {
                                              ...data,
                                              bill_type: selectedItem.value,
                                            },
                                            data?.id
                                          );
                                        }}
                                        value={
                                          BillingType && {
                                            label: data?.bill_type,
                                            value: BillingType.find(
                                              (item) =>
                                                item.label == data?.bill_type
                                            )?.value,
                                          }
                                        }
                                        name={`draftDebitNoteDetails.${index}.bill_type`}
                                      />
                                    </div>
                                  </div> */}

                                  <div className="flex justify-between gap-3 mb-1">
                                    <div className="text-sm ">
                                      {data?.issued_type == "tenant"
                                        ? "Tenant "
                                        : data?.issued_type == "vendor"
                                        ? "Vendor "
                                        : "Member "}
                                      Details
                                    </div>
                                    <div>
                                      {values.draftDebitNoteDetails[index]
                                        ?.issued_type == "member" && (
                                        <div className="flex gap-2">
                                          <DropdownFM2
                                            options={
                                              data.issued_type == "member" && [
                                                {
                                                  label: data?.wing_no,
                                                  value: data?.wing_id,
                                                },
                                              ]
                                            }
                                            placeholder="Wing"
                                            className="text-[11px] ms-4"
                                            width="108px"
                                            height="36px"
                                            name={`draftDebitNoteDetails.${index}.wing_id`}
                                            value={
                                              data.issued_type == "member" && {
                                                label: data?.wing_no,
                                                value: data?.wing_id,
                                              }
                                            }
                                            // disabled={
                                            //   !(
                                            //     values.draftDebitNoteDetails[
                                            //       index
                                            //     ].issued_type == "member"
                                            //   )
                                            // }
                                          />
                                          <DropdownFM2
                                            options={
                                              data.wing_id && [
                                                {
                                                  label: data.flat_no,
                                                  value: data.flat_id,
                                                },
                                              ]
                                            }
                                            // loading={
                                            //   loadingManageDebitNoteFlatWingsWise
                                            // }
                                            placeholder="Flat Unit"
                                            className="text-[11px] ms-4"
                                            width="108px"
                                            height="36px"
                                            name={`draftDebitNoteDetails.${index}.flat_id`}
                                            // {...(values.flatId === "" && { value: null })}
                                            value={
                                              data.issued_type == "member" &&
                                              data.flat_id && {
                                                label: data.flat_no,
                                                value: data.flat_id,
                                              }
                                            }
                                            disabled={
                                              !(
                                                values.draftDebitNoteDetails[
                                                  index
                                                ].issued_type == "member"
                                              )
                                            }
                                          />
                                        </div>
                                      )}
                                      {data?.issued_type == "tenant" && (
                                        <div className="flex gap-6">
                                          <DropdownFM2
                                            options={
                                              data.issued_type == "tenant"
                                                ? {
                                                    label: tenantListData?.find(
                                                      (item) =>
                                                        item?.tenant_id ==
                                                        data.debitor_id
                                                    )?.tenant_name,
                                                    value: data.debitor_id,
                                                  }
                                                : []
                                            }
                                            loading={!tenantListData.length > 0}
                                            placeholder="Select Tenant Name"
                                            className="text-[11px] ms-4"
                                            width="224px"
                                            height="36px"
                                            value={
                                              data.debitor_id &&
                                              data.issued_type == "tenant"
                                                ? {
                                                    label: tenantListData?.find(
                                                      (item) =>
                                                        item?.tenant_id ==
                                                        data.debitor_id
                                                    )?.tenant_name,
                                                    value: data.debitor_id,
                                                  }
                                                : []
                                            }
                                            name={`draftDebitNoteDetails.${index}.debitor_id`}
                                            disabled={
                                              !(
                                                values.draftDebitNoteDetails[
                                                  index
                                                ].issued_type == "tenant"
                                              )
                                            }
                                          />
                                        </div>
                                      )}

                                      {data?.issued_type == "vendor" && (
                                        <div className="flex gap-6">
                                          <DropdownFM2
                                            options={
                                              data?.issued_type == "vendor"
                                                ? [
                                                    {
                                                      label:
                                                        nonMemberList?.find(
                                                          (item) =>
                                                            item?.id ==
                                                            data.debitor_id
                                                        )?.vendorname,
                                                      value: data.debitor_id,
                                                    },
                                                  ]
                                                : []
                                            }
                                            placeholder="Select Non-Member Name"
                                            className="text-[11px] ms-4"
                                            width="224px"
                                            height="36px"
                                            // onSelect={(selectedItem) => {
                                            //   setFieldValue(
                                            //     `draftDebitNoteDetails.${index}.debitor_id`,
                                            //     selectedItem.value
                                            //   );
                                            //   // setFieldValue(
                                            //   //   "debitAccount",
                                            //   //   nonMemberList?.find(
                                            //   //     (item) => item?.id == selectedItem.value
                                            //   //   )?.glcode
                                            //   // );
                                            //   if (
                                            //     values.draftDebitNoteDetails[
                                            //       index
                                            //     ].debitor_id !=
                                            //     selectedItem.value
                                            //   )
                                            //     getArrears(
                                            //       {
                                            //         ...data,
                                            //         debitor_id:
                                            //           selectedItem.value,
                                            //       },
                                            //       data?.id
                                            //     );
                                            // }}
                                            value={
                                              data.debitor_id
                                                ? {
                                                    label: nonMemberList?.find(
                                                      (item) =>
                                                        item?.id ==
                                                        data.debitor_id
                                                    )?.vendorname,
                                                    value: data.debitor_id,
                                                  }
                                                : []
                                            }
                                            name={`draftDebitNoteDetails.${index}.debitor_id`}
                                            disabled={
                                              !(
                                                values.draftDebitNoteDetails[
                                                  index
                                                ].issued_type == "vendor"
                                              )
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex justify-between items-center gap-3 mb-1">
                                    <div className="text-sm">Debit A/C</div>

                                    <DropdownFM2
                                      options={
                                        data.debit_code && [
                                          {
                                            label: data?.debit_glname,
                                            value: data?.debit_code,
                                          },
                                        ]
                                      }
                                      placeholder="Debit A/C"
                                      className="text-[11px] ms-4"
                                      width="224px"
                                      height="36px"
                                      // onSelect={(selectedItem) => {
                                      //   setFieldValue(
                                      //     `draftDebitNoteDetails.${index}.debit_code`,
                                      //     selectedItem.value
                                      //   );
                                      // }}
                                      name={`draftDebitNoteDetails.${index}.debit_code`}
                                      value={
                                        data.debit_code && {
                                          label: data?.debit_glname,
                                          value: data?.debit_code,
                                        }
                                      }
                                    />
                                  </div>

                                  <div className="flex justify-between items-center gap-3 mb-1">
                                    <div className="text-sm">
                                      Send SMS And Email
                                    </div>
                                    <div className="py-2 text-sm">
                                      <Checkbox
                                        text={"SMS"}
                                        className={"w-28"}
                                        initialchecked={
                                          values.draftDebitNoteDetails[index]
                                            ?.send_sms === "Y"
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `draftDebitNoteDetails.${index}.send_sms`,
                                            e.target.checked ? "Y" : "N"
                                          );
                                        }}
                                      />
                                      <Checkbox
                                        text={"Email"}
                                        className={"w-28"}
                                        initialchecked={
                                          values.draftDebitNoteDetails[index]
                                            ?.send_email === "Y"
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `draftDebitNoteDetails.${index}.send_email`,
                                            e.target.checked ? "Y" : "N"
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <EditDebitDraftDetailBox
                              setEnableReinitialize={setEnableReinitialize}
                              values={values}
                              manageDebitNoteTarrifList={
                                editSingleDebitNoteDetail
                              }
                              manageDebitNoteArrearDetails={
                                manageDebitNoteArrearDetails
                              }
                              setFieldValue={setFieldValue}
                              columns={headCells}
                              index={index}
                            />
                            {/* <div className="border-b border-[#CCC] -mt-2"></div> */}
                            <div className="w-full">
                              <BillSection
                                manageDebitNoteArrearDetails={
                                  !!arrearDetailsList?.[
                                    values.draftDebitNoteDetails[index]?.id
                                  ]
                                    ? arrearDetailsList[
                                        values.draftDebitNoteDetails[index]?.id
                                      ]
                                    : {
                                        interest:
                                          values.draftDebitNoteDetails[index]
                                            ?.interest,
                                        interest_arrear:
                                          values.draftDebitNoteDetails[index]
                                            ?.interest_arrear,
                                        principal_arrear:
                                          values.draftDebitNoteDetails[index]
                                            ?.principal_arrear,
                                      }
                                }
                                manageDebitNoteTarrifList={
                                  editSingleDebitNoteDetail
                                }
                                values={values}
                                index={index}
                              />
                            </div>
                          </>
                        </Accordion>
                      );
                    })}
                  </>
                )}
              </>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
export const BillSection = ({
  values,
  manageDebitNoteArrearDetails = null,
  index = 0,
}) => {
  return (
    <div className="flex justify-between mt-4 p-2">
      <table className="border-separate">
        <tbody>
          <tr>
            <td className="text-sm w-56 pt-2.5 align-top ">CGST</td>
            <td>
              <TextInput
                label=""
                placeholder="SGST"
                className="w-52"
                type="number"
                name="sgst_amount"
                value={values?.draftDebitNoteDetails[index].cgst_amount}
                // maxLength={15}
                disabled={true}
              />
            </td>
            <td className="text-sm w-56 pt-2.5 align-top ps-3">SGST</td>
            <td>
              <TextInput
                label=""
                placeholder="CGST"
                className="w-52"
                type="number"
                name="cgst_amount"
                value={values?.draftDebitNoteDetails[index].sgst_amount}
                // maxLength={15}
                disabled={true}
              />
            </td>
          </tr>
          <tr>
            <td className="text-sm w-56 pt-2.5 align-top ">IGST</td>
            <td>
              <TextInput
                label=""
                placeholder="IGST"
                className="w-52"
                type="number"
                name="igst_amount"
                value={values?.draftDebitNoteDetails[index].igst_amount}
                // maxLength={15}
                disabled={true}
              />
            </td>
          </tr>

          <tr>
            <td className="text-sm w-56 pt-2.5 pb-2.5 align-top font-semibold ">
              Sub Total
            </td>
            <td className="text-sm">
              {(
                Number(
                  values.draftDebitNoteDetails[index]?.head_detail.reduce(
                    (prevVal, item) => {
                      return prevVal + Number(item?.amount);
                    },
                    0
                  )
                ) +
                (Number(values?.draftDebitNoteDetails[index].cgst_amount) +
                  Number(values?.draftDebitNoteDetails[index].sgst_amount) +
                  Number(values?.draftDebitNoteDetails[index].igst_amount))
              ).toFixed(2)}
            </td>

            <td className="text-sm w-56 pt-2.5 align-top font-semibold ps-3">
              Round Off
            </td>
            <td className="text-sm">
              {values?.debitnoteHeader?.roundoff == 1 &&
              values.draftDebitNoteDetails[index]?.roundoff_amount
                ? Number(
                    values.draftDebitNoteDetails[index]?.roundoff_amount
                  ).toFixed(2)
                : 0}
            </td>
          </tr>
          <tr>
            <td className="text-sm w-56 pt-2.5 align-top">Interest</td>
            <td>
              <TextInput
                name="interest"
                label=""
                placeholder="Enter Interest"
                className="w-52"
                type="number"
                value={
                  manageDebitNoteArrearDetails
                    ? Math.round(manageDebitNoteArrearDetails?.interest)
                    : 0
                }
                disabled={true}
              />
            </td>

            <td className="text-sm w-56 pt-2.5 ps-3 align-top">
              Interest Arrears
            </td>
            <td>
              <TextInput
                name="interest_arrear"
                label=""
                placeholder="Enter Interest Arrears"
                className="w-52"
                type="number"
                value={
                  manageDebitNoteArrearDetails
                    ? Math.round(manageDebitNoteArrearDetails?.interest_arrear)
                    : 0
                }
                disabled={true}
              />
            </td>
          </tr>
          <tr>
            <td className="text-sm w-56 pt-2.5 pb-2.5 align-top ">
              Principle Arrears
            </td>
            <td>
              <TextInput
                name="principal_arrear"
                label=""
                placeholder="Enter Principle Arrears"
                className="w-52"
                type="number"
                value={
                  manageDebitNoteArrearDetails
                    ? Math.round(manageDebitNoteArrearDetails?.principal_arrear)
                    : 0
                }
                disabled={true}
              />
            </td>

            <td className="text-sm w-56 pt-2.5 align-top font-semibold ps-3">
              Grand Total
            </td>
            <td className="text-sm">
              {Number(
                values.draftDebitNoteDetails[index]?.head_detail.reduce(
                  (prevVal, item) => {
                    return prevVal + Number(item?.amount);
                  },
                  0
                )
              ) +
                (Number(values?.draftDebitNoteDetails[index].cgst_amount) +
                  Number(values?.draftDebitNoteDetails[index].sgst_amount) +
                  Number(values?.draftDebitNoteDetails[index].igst_amount) +
                  (manageDebitNoteArrearDetails
                    ? Number(manageDebitNoteArrearDetails?.interest) +
                      Number(manageDebitNoteArrearDetails?.principal_arrear) +
                      Number(manageDebitNoteArrearDetails?.interest_arrear)
                    : 0)) +
                (values?.debitnoteHeader?.roundoff == 1 &&
                values.draftDebitNoteDetails[index]?.roundoff_amount
                  ? Number(values.draftDebitNoteDetails[index]?.roundoff_amount)
                  : 0)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default EditDebitNoteDraft;
