import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { notify } from "../../../../utils/notification";
import * as types from "../../../actions/index.types";
import * as req from "../../../../axios/request/SetupRecordsReqs/ContentManagementReqs/ContentManagementReq";

function* contentManagementList(action) {
  try {
    const result = yield call(req.contentManagementListReq, action);
    if (result.success) {
      yield put({
        type: types.GET_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS,
        payload: result.data,
      });
    } else {
      yield put({
        type: types.GET_CONTENT_MANAGEMENT_TEMPLATE_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* auditLogContentManagement(action) {
  try {
    const result = yield call(req.auditLogContentManagementReq, action);

    if (result.success) {
      yield put({
        type: types.AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS,
        payload: result.data,
      });
      notify("success", result.message);
    } else {
      yield put({
        type: types.AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* updateContentManagement(action) {
  try {
    const result = yield call(req.updateContentManagementReq, action);
    console.log("action", action);
    console.log("API Update result:", result);
    if (result.success) {
      yield put({
        type: types.UPDATE_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS,
        payload: result.message,
      });
      action.data.onSucessBackCall();
      notify("success", result.message);
    } else {
      yield put({
        type: types.UPDATE_CONTENT_MANAGEMENT_TEMPLATE_FAILURE,
        message: result?.response?.data?.message,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* ContentSaga() {
  yield takeLatest(
    types.GET_CONTENT_MANAGEMENT_TEMPLATE,
    contentManagementList
  );
  yield takeLatest(
    types.UPDATE_CONTENT_MANAGEMENT_TEMPLATE,
    updateContentManagement
  );
  yield takeLatest(
    types.AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE,
    auditLogContentManagement
  );
}
