import * as type from "../../actions/index.types";

export function downloadVisitorVehicleLogExcelReducer(state = {
    loading: false,
    downLoader: false,
    error: null
}, action) {
    switch (action.type) {
        case type.DOWNLOAD_VISITOR_VEHICLE_LOG:
            return {
                ...state,
                loading: true,
                downLoader: true,
            }
        case type.DOWNLOAD_VISITOR_VEHICLE_LOG_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                downLoader: false,
            }
        case type.DOWNLOAD_VISITOR_VEHICLE_LOG_FAILURE:
            return {
                ...state,
                error: action?.message || 'Somthing went wrong.',
                loading: false,
                downLoader: false,
            }

        default:
            return state
    }
}