import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../Layout/Layout";
import ButtonG from "../../../components/Button/ButtonG";
import Button from "../../../components/Button/Button";
import TaxableSubHeadList from "../../../components/TableComponent/TaxablesubHeadTable";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import {
  getTaxableFormatList,
  actionTaxableSubHead,
  changeOrderTaxableHead
} from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import Loader from "../../../components/Loader/Loader";
import AddTaxableSubHeadModal from "../../../components/Modal/AddTaxableSubHeadModal";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";

const TaxableFormatSubHeadList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginData } = useSelector((state) => state.loginReducer);
  const [subHeadTaxableList, setsubHeadTaxableList] = useState([]);
  const [addModal, setModal] = useState(false);
  const [deleteId, setdeleteId] = useState();
  const [activeId, setactiveId] = useState();
  const { id } = useParams();
  const { typeId } = useParams();
  const { loading, updateLoader, taxableLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const handleNavigation = (path) => () => {
    navigate(path);
  };
  const handleOrderChange = (headIds) => {
    dispatch(
      changeOrderTaxableHead({
        societyId: loginData?.default_community?.community_id,
        headType: 'sub',
        headIds: headIds
      })
    )
  };
  useEffect(() => {
    dispatch(
      getTaxableFormatList({
        societyId: loginData?.default_community?.community_id,
        typeId : typeId
        // onSuccessCallback: () =>{
        //   setTaxableList(taxableLists); 
        // }
      })
    );
  }, [loginData]);
  useEffect(() => {
    if (taxableLists) {
      setsubHeadTaxableList(taxableLists.taxable_list.find((item) => parseInt(item.id) === parseInt(id)));
    }
  }, [taxableLists]);
  const handleDelete = (headId) => {
    setdeleteId(headId)
    dispatch(
      actionTaxableSubHead({
        societyId: loginData?.default_community?.community_id,
        subId: headId,
        action: 'delete',
        onSuccessCallback: () => {
          dispatch(
            getTaxableFormatList({
              societyId: loginData?.default_community?.community_id,
              typeId : typeId
            })
          );
          setdeleteId("")
        },
      })
    );
  };
  const handleAction = (headId, status) => {
    setactiveId(headId)
    dispatch(
      actionTaxableSubHead({
        societyId: loginData?.default_community?.community_id,
        subId: headId,
        status: status,
        action: 'active',
        onSuccessCallback: () => {
          dispatch(
            getTaxableFormatList({
              societyId: loginData?.default_community?.community_id,
              typeId :typeId
            })
          );
          setactiveId("")
        },
      })
    );
  };
  
  return (
    <Layout>
      <div className="flex flex-col w-full gap-2">
        <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
          <div className="w-full  pb-2 border-b border-[#CCCC]">
            <ListHeaderBack
              title="Sub Head Setup"
              onClick={handleNavigation(-1)}
            >
              <div className="border-r border-[#CCC] w-full h-full"></div>
              {getPermission(loginData, [permissions.ADD_SUB_HEAD_GROUP_SETTING]) && <Button
                label="Add Sub-Head"
                className="h-8 w-52"
                onClick={() => setModal(true)}
              />}
            </ListHeaderBack>
          </div>
          <div>
            {loading ? <Loader /> : subHeadTaxableList && Object.entries(subHeadTaxableList).length > 0 ? <TaxableSubHeadList
              list={subHeadTaxableList}
              taxableList={subHeadTaxableList}
              setsubHeadTaxableList={setsubHeadTaxableList}
              handleDelete={handleDelete}
              handleAction={handleAction}
              deleteId={deleteId}
              activeId={activeId}
              updateLoader={updateLoader}
              handleOrderChange={handleOrderChange}
              loginData={loginData}
            //columns={headCells}
            >
              {/* <ActionComponent /> */}
            </TaxableSubHeadList> : <></>}
          </div>

          <AddTaxableSubHeadModal
            isOpen={addModal}
            onClose={() => setModal(false)}
            id={id}
            headList={taxableLists ? taxableLists.head_list : []}
            mainHead={subHeadTaxableList}
            typeId={typeId}
          />
        </div>
      </div>
    </Layout>
  )
}

export default TaxableFormatSubHeadList;