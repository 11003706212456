import React, { useState } from "react";
import PropTypes from "prop-types";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { red } from '@mui/material/colors';
import FormLabel from '@mui/material/FormLabel';
import {TextInputFM}  from "../InputFields/TextInput";
import {NumberInputFM}  from "../InputFields/NumberInput";
// import TextField from '@mui/material/TextField';
import Button from "../Button/Button";
import  CheckboxFM  from "../Checkbox/Checkbox";
import {DropdownFM2} from "../Dropdown/dropdown2";
import { EDIT_MORE_HEADS_ENDPOINT } from "../../Routing/routes";
import { CircularProgress } from "@mui/material";
import { ErrorMessage } from "formik";
import { getPermission } from "../../utils/helpers/getPermissions";
import * as permissions from "../../Routing/permissions";


const InvoiceHeadSettingGrid = ({
    loading,
    data,
    glCode,
    setHeadDeatail,
    handleNavigation,
    handleDeleteHead,
    deletehead,
    typeId,
    setFieldValue,
    loginData,
}) => {
    const gstHeads = ["servicetax", "Sb_Cess", "debinote", "Kk_Cess","roundoff"];
    const arrersHeads = ["Principal Arrears", "Debitnote Arrears", "Interest Arrears", "Past Advances & Dues"];

    return (
        <div className="grid gap-2 p-5 2xl:grid-cols-2 lg:grid-cols-2">
            {data ? data?.map((item, index) => (
                <div>
                <div className="border rounded-lg border-[#CCCCCC] my-1">
                    <div className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b">
                        <div className="flex items-center w-full">
                            <div className="text-[#000000] text-sm font-semibold grow  flex justify-between ">
                                <div><span>{item.invoice_head}</span></div>
                                <div className="flex items-center ">
                                {item.id === "roundoff" ? <></> :<CheckboxFM
                                        text="Active"
                                        className="-mt-3 text-xs"
                                        initialchecked={
                                            item?.active === "Yes"
                                                ? true
                                                : false
                                        }
                                        name={`headDetail.${index}.active`}
                                        onChange={(e) => {
                                            setFieldValue(`headDetail.${index}.active`,e.target.checked ? "Yes" : "No")
                                            if(e.target.checked){
                                                setFieldValue(`headDetail.${index}.order`,'')
                                            }
                                        }}
                                    />}
                                    {item.head_type==="more" && getPermission(loginData, [permissions.EDIT_MORE_INVOICE_HEAD]) && <Button label="Edit" className="ml-2" onClick={
                                        handleNavigation(`${EDIT_MORE_HEADS_ENDPOINT}/${typeId}/${item.id}`)
                                    } />}
                                     {item.head_type==="more" && getPermission(loginData, [permissions.DELETE_MORE_INVOICE_HEAD]) && <Button label="Delete" className="ml-2" type="button" onClick={()=>handleDeleteHead(item.id)} />}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    { item?.active === "Yes" && <table className="w-full p-1 text-sm" aria-label="table">
                        <tbody>
                        {item.id === "roundoff" ? <></> :<tr className="border-b-2">
                                <td className="px-2 py-1">
                                    Order :
                                </td>
                                <td className="px-2 py-1">
                                    <NumberInputFM
                                        placeholder="Enter Order"
                                        className="w-[300px]"
                                        value={item?.order}
                                        type="decimal"
                                        name={`headDetail.${index}.order`}
                                        onChange={ (e) => {
                                            setFieldValue(`headDetail.${index}.order`,parseInt(e.target.value))
                                        }}
                                    />
                                    
                                     <div className={`text-red-500 text-xs italic h-auto text-wrap break-words`} id={item.id}></div> 
                                </td>
                            </tr>}
                            <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    Tariff Name :
                                </td>
                                <td className="px-2 py-1">
                                    <TextInputFM
                                        placeholder="Enter Tariff Name"
                                        className="w-[300px]"
                                        value={item?.tariff_header|| ""}
                                        type="text"
                                        name={`headDetail.${index}.tariff_header`}
                                    />
                                </td>
                            </tr >
                            { arrersHeads.includes(item.invoice_head) && item.head_type === "arrear" ? <></> : <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    G/L Accounts :
                                </td>
                                <td className="px-2 py-1">
                                    <DropdownFM2
                                        options={glCode?.map((detail) => {
                                            return {
                                                value: detail.ac_code,
                                                label: detail.name
                                            }
                                        }) || []}
                                        value={item?.gl_code !== '' ? {
                                            label: item?.gl_name,
                                            value: item?.gl_code,
                                        } : null}
                                        name={`headDetail.main_head.${index}.gl_code`}
                                        onSelect={(e) => {
                                            setFieldValue(`headDetail.${index}.gl_code`,e.value)
                                            setFieldValue(`headDetail.${index}.gl_name`,e.label)              
                                        }}
                                        placeholder="Select Gl Account"
                                        width="300px"
                                        height="36px"
                                    />
                                </td>
                            </tr>}
                            {gstHeads.includes(item.id) || (arrersHeads.includes(item.invoice_head) && item.head_type === "arrear")  ? <></> : <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    HSN code :
                                </td>
                                <td className="px-2 py-1">
                                    <TextInputFM
                                        placeholder="Enter HSN code"
                                        className="w-[300px]"
                                        value={item?.hsn_code || ""}
                                        type="decimal"
                                        name={`headDetail.${index}.hsn_code`}

                                    />
                                </td>
                            </tr>}
                            {gstHeads.includes(item.id) || (arrersHeads.includes(item.invoice_head) && item.head_type === "arrear")  ? <></> : <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    <CheckboxFM
                                        text="GST"
                                        className="m-3"
                                        initialchecked={
                                            item?.gst_applicable === "Yes" ? true : false
                                        }
                                        arrear_head
                                        onChange={(e) => {
                                            setFieldValue(`headDetail.${index}.gst_applicable`,e.target.checked ? "Yes" : "No")
                                            //data.main_head[item.id].gst_applicable = e.target.checked ? "Yes" : "No";
                                            //handleChange(data)
                                        }}
                                    />
                                </td>
                                <td className="px-2 py-1">
                                    <CheckboxFM
                                        text="Exemption Not Applicable"
                                        className="m-3"
                                        initialchecked={
                                            item?.excemption === "Y" ? true : false
                                        }
                                        name={`headDetail.${index}.excemption`}
                                        onChange={(e) => {
                                            setFieldValue(`headDetail.${index}.excemption`,e.target.checked ? "Y" : "N")
                                            // data.main_head[item.id].excemption = e.target.checked ? "Y" : "N";
                                            // handleChange(data)
                                        }}
                                    />
                                </td>
                            </tr>}
                            {gstHeads.includes(item.id) || (arrersHeads.includes(item.invoice_head) && item.head_type === "arrear")  ? <></> : <tr className="border-b-2">
                               
                                <td className="px-2 py-1 text-center" colSpan={2}>
                                    <FormControl className="m-3" >
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name={`headDetail.${index}.servicegood`}
                                            value={item?.servicegood || 'Y'}
                                            onChange={(e) => {
                                                setFieldValue(`headDetail.${index}.servicegood`,e.target.value)
                                                // data.main_head[item.id].servicegood = e.target.value;
                                                // handleChange(data)
                                            }}
                                        >
                                            <FormControlLabel value="Y" className="text-sm" control={<Radio sx={{ color: "#CF001C", '&.Mui-checked': { color: "#CF001C", }, }} />} label="Service" />
                                            <FormControlLabel value="N" className="text-sm" control={<Radio sx={{ color: "#CF001C", '&.Mui-checked': { color: "#CF001C", }, }} />} label="Good" />
                                        </RadioGroup>
                                    </FormControl>
                                </td>

                            </tr>}
                        </tbody>
                    </table>}
                    {/* <div className="w-full p-5">col 1</div> */}
                </div></div>
            )) : <></>}
        </div>
    )
}
InvoiceHeadSettingGrid.propTypes = {
    data: PropTypes.array.isRequired,
};

export default InvoiceHeadSettingGrid;
