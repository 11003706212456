import React, { useEffect } from "react";
import { DeleteIcon } from "../../../../assets";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import {
  TextInput,
  TextInputFM,
} from "../../../../components/InputFields/TextInput";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import TextRadio from "../../../../components/InputFields/TextRadio";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getBookingGLMappingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
import { getCreditDebitTariffHeaderList } from "../../../../redux/actions/FinanceActions/SettingActions/CreditDebitTariffHeaderAction";
import { FieldArray, ErrorMessage } from "formik";

const DebitDraftDetailBox = ({
  setEnableReinitialize = () => {},
  values,
  setFieldValue,
  manageDebitNoteTarrifList = null,
  columns = null,
  index = 0,
}) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);

  const { glMappingList } = useSelector((state) => state.viewBookingReducer);

  const handleRadio = (value, key, setFieldValue) => {
    setFieldValue(key, value);
  };

  const timeOfDayOptions = [
    { name: "timeOfDay", value: "Y", label: "Service" },
    { name: "timeOfDay", value: "N", label: "Goods" },
  ];

  const subTotal = (values, setFieldValue, index) => {
    const total = Number(
      values.draftDebitNoteDetails[index]?.head_detail.reduce(
        (prevVal, item) => {
          return prevVal + Number(item?.amount);
        },
        0
      )
    );
    const total2 = Number(
      values.draftDebitNoteDetails[index]?.head_detail.reduce(
        (prevVal, item) => {
          return item?.gstapplicable == "Y"
            ? prevVal + Number(item?.amount)
            : prevVal;
        },
        0
      )
    );
    const cgst =
      values?.debitnoteHeader?.cgst_rate && values?.debitnoteHeader?.cgst_head
        ? ((total2 * Number(values?.debitnoteHeader?.cgst_rate)) / 100).toFixed(
            2
          )
        : 0;
    const sgst =
      values?.debitnoteHeader?.sgst_rate && values?.debitnoteHeader?.sgst_head
        ? ((total2 * Number(values?.debitnoteHeader?.sgst_rate)) / 100).toFixed(
            2
          )
        : 0;
    const igst =
      values?.debitnoteHeader?.igst_rate && values?.debitnoteHeader?.igst_head
        ? ((total2 * Number(values?.debitnoteHeader?.igst_rate)) / 100).toFixed(
            2
          )
        : 0;
    // setFieldValue("interest", Number(cgst) + Number(sgst));
    setFieldValue(`draftDebitNoteDetails.${index}.sgst_amount`, cgst);
    setFieldValue(`draftDebitNoteDetails.${index}.cgst_amount`, sgst);
    setFieldValue(`draftDebitNoteDetails.${index}.igst_amount`, igst);

    setFieldValue(
      `draftDebitNoteDetails.${index}.sub_total`,
      Number(total.toFixed(2)) + (Number(cgst) + Number(sgst) + Number(igst))
    );
    if (values?.debitnoteHeader?.roundoff == 1)
      setFieldValue(
        `draftDebitNoteDetails.${index}.roundoff_amount`,
        Math.round(
          Number(total) + (Number(cgst) + Number(sgst) + Number(igst))
        ) -
          (Number(total) + (Number(cgst) + Number(sgst) + Number(igst)))
      );

    // if (
    //   manageDebitNoteTarrifList?.debitsetting?.cgst_rate != "" &&
    //   manageDebitNoteTarrifList?.debitsetting?.cgst_rate != ""
    // ) {
    // }

    // setFieldValue("interest", igst);
    // setFieldValue("subTotal", Number(total.toFixed(2)) + igst);
    //   if (manageDebitNoteTarrifList?.debitsetting?.igst_rate !== "") {
    // }
  };

  return (
    <div className="p-2 ">
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3 ">
        <div className=" border-b border-[#CCC] pb-2 bg-slate-100 rounded-lg">
          <h1 className="text-base items-center p-2 -mb-2 ml-1">
          Tariff Details
          </h1>
        </div>
        <div className=" p-2">
          <div className="p-2 flex flex-wrap gap-2">
            {
              <>
                <div className="text-sm flex items-start gap-4">
                  <div className=" flex justify-between items-start gap-4">
                    <div style={{ width: "121px" }} className="py-2 font-bold">
                    Tariff Header
                    </div>
                    <div style={{ width: "121px" }} className=" py-2 font-bold">
                      Credit A/C
                    </div>
                    <div style={{ width: "129px" }} className="py-2 font-bold">
                      Amount(Rs.)
                    </div>
                    <div style={{ width: "158px" }} className="py-2 font-bold">
                      Description
                    </div>
                    <div style={{ width: "137px" }} className="py-2 font-bold">
                      From Date
                    </div>
                    <div style={{ width: "130px" }} className="py-2 font-bold">
                      To Date
                    </div>
                  </div>
                  <div className=" py-2 font-bold">
                    {values.details?.length > 1 ? "Action" : ""}
                  </div>
                </div>
                <FieldArray
                  name={`draftDebitNoteDetails.${index}.head_detail`}
                  render={(arrayHelpers) => (
                    <>
                      {values.draftDebitNoteDetails[index]?.head_detail &&
                        values.draftDebitNoteDetails[index]?.head_detail
                          .length > 0 &&
                        values.draftDebitNoteDetails[index]?.head_detail.map(
                          (row, index1) => {
                            return (
                              <React.Fragment key={index1}>
                                <div className="flex items-start gap-4">
                                  <div className="flex justify-between items-start gap-4">
                                    <div
                                      style={{ width: "150px" }}
                                      className="text-sm py-2"
                                    >
                                      {row?.cause}
                                    </div>

                                    <div
                                      style={{ width: "150px" }}
                                      className="text-sm py-2"
                                    >
                                      {row?.gl_name}
                                    </div>
                                    <div>
                                      <TextInput
                                        label=""
                                        placeholder="Enter Amount"
                                        className="w-30"
                                        name={`draftDebitNoteDetails.${index}.head_detail.${index1}.amount`}
                                        value={
                                          values.draftDebitNoteDetails[index]
                                            .head_detail[index1]?.amount
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `draftDebitNoteDetails.${index}.head_detail.${index1}.amount`,
                                            e.target.value
                                          );
                                          values.draftDebitNoteDetails[
                                            index
                                          ].head_detail[index1].amount =
                                            e.target.value;
                                          subTotal(
                                            values,
                                            setFieldValue,
                                            index
                                          );
                                        }}
                                        type="decimal"
                                      />
                                      <div
                                        className={`text-red-500 text-xs italic h-4 text-wrap break-words `}
                                      >
                                        <ErrorMessage
                                          name={`draftDebitNoteDetails.${index}.head_detail.${index1}.amount`}
                                        />
                                      </div>
                                    </div>

                                    <div style={{ width: "158px" }}>
                                      <TextInputFM
                                        label=""
                                        placeholder="Enter Description"
                                        className="w-40"
                                        name={`draftDebitNoteDetails.${index}.head_detail.${index1}.description`}
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      <DatePickerComponentFM
                                        className="h-9 w-42"
                                        name={`draftDebitNoteDetails.${index}.head_detail.${index1}.from_date`}
                                        placeholder="From Date"
                                        onDateChange={(selectedDate) => {
                                          if (
                                            new Date(
                                              values?.draftDebitNoteDetails[
                                                index
                                              ].head_detail[index1].to_date
                                            ).getTime() <
                                            new Date(selectedDate).getTime()
                                          ) {
                                            setFieldValue(
                                              `draftDebitNoteDetails.${index}.head_detail.${index1}.to_date`,
                                              null
                                            );
                                          }
                                          setFieldValue(
                                            `draftDebitNoteDetails.${index}.head_detail.${index1}.from_date`,
                                            moment(selectedDate).format(
                                              "YYYY-MM-DD"
                                            )
                                          );
                                        }}
                                        // minDate={new Date()}
                                        defaultValue={
                                          values?.draftDebitNoteDetails[index]
                                            .head_detail[index1].from_date
                                            ? new Date(
                                                values?.draftDebitNoteDetails[
                                                  index
                                                ].head_detail[index1].from_date
                                              )
                                            : null
                                        }
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      <DatePickerComponentFM
                                        className="h-9 w-42"
                                        name={`draftDebitNoteDetails.${index}.head_detail.${index1}.to_date`}
                                        onDateChange={(selectedDate) => {
                                          setFieldValue(
                                            `draftDebitNoteDetails.${index}.head_detail.${index1}.to_date`,
                                            moment(selectedDate).format(
                                              "YYYY-MM-DD"
                                            )
                                          );
                                        }}
                                        minDate={
                                          new Date(
                                            values?.draftDebitNoteDetails[
                                              index
                                            ].head_detail[index1].from_date
                                          )
                                        }
                                        defaultValue={
                                          values?.draftDebitNoteDetails[index]
                                            .head_detail[index1].to_date
                                            ? new Date(
                                                values?.draftDebitNoteDetails[
                                                  index
                                                ].head_detail[index1].to_date
                                              )
                                            : null
                                        }
                                      />
                                    </div>
                                    {/* <TextInputFM
                                  label=""
                                  placeholder="Enter Amount"
                                  className="w-48"
                                  name={`details.${index}.amount`}
                                  type="decimal"
                                /> */}
                                  </div>
                                  <div className="flex mt-1">
                                    <div className="w-[26px]">
                                      {values?.draftDebitNoteDetails[index]
                                        .head_detail.length > 1 && (
                                        <button
                                          className="fill-red-650 p-2"
                                          onClick={() => {
                                            setEnableReinitialize(false);
                                            arrayHelpers.remove(index1);
                                            values?.draftDebitNoteDetails[
                                              index
                                            ].head_detail.splice(index1, 1);
                                            subTotal(
                                              values,
                                              setFieldValue,
                                              index
                                            );
                                          }}
                                          type="button"
                                        >
                                          <DeleteIcon />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          }
                        )}
                      {/* <div>
                      <button
                        type="button"
                        className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-28 text-xs whitespace-nowrap h-9 p-2 mb-4 bg-[#FDF6F6] flex justify-center rounded-md"
                        onClick={() =>
                          arrayHelpers.push({
                            tariffHeaderId: "",
                            debitAccountId: "",
                            hsnCode: "",
                            serviceGood: "Y",
                            amount: "",
                          })
                        }
                      >
                        Add More
                      </button>
                    </div> */}
                    </>
                  )}
                ></FieldArray>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebitDraftDetailBox;
