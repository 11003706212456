import React, { useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedComplaintTicketDetails } from "../../../redux/actions/DeskActions/DeskAction";
import Loader from "../../../components/Loader/Loader";

function ViewComplaint() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, complaintLoggedTicket, complaintStatusList } = useSelector(
    (state) => state.deskReducers
  );
  const contentRef = useRef();

  useEffect(() => {
    if (complaintLoggedTicket?.description && contentRef) {
      contentRef.current.innerHTML = complaintLoggedTicket?.description
    }
  }, [complaintLoggedTicket?.description]);

  useEffect(() => {
    dispatch(getLoggedComplaintTicketDetails({ ticketId: id }));
  }, []);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="View Complaints"
            onClick={() => {
              navigate(-1);
            }}
          ></ListHeaderBack>
        </div>

        <div className="py-1 gap-2 my-1">
          {loading && id !== complaintLoggedTicket?.id ? (
            <Loader />
          ) : (
            <table className="table-auto w-full p-2">
              <tbody>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Ticket ID:
                  </td>
                  <td className="py-2">
                    {complaintLoggedTicket?.ticket || ""}
                  </td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Creation Date:
                  </td>
                  <td className="py-2">
                    {complaintLoggedTicket?.creation_date.split(" ")[0] || ""}
                  </td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Creation Time:
                  </td>
                  <td className="py-2">
                    {complaintLoggedTicket?.creation_date.slice(11) || ""}
                  </td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Status:
                  </td>
                  <td className="py-2">
                    {complaintLoggedTicket?.status || ""}
                  </td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Nature:
                  </td>
                  <td className="py-2">{complaintLoggedTicket?.nature}</td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Logged By:
                  </td>
                  <td className="py-2">
                    {complaintLoggedTicket?.full_name || ""}
                  </td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Flat No.:
                  </td>
                  <td className="py-2">
                    {complaintLoggedTicket?.wing_flat || ""}
                  </td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Category:
                  </td>
                  <td className="py-2">
                    {complaintLoggedTicket?.complaint_category || ""}
                  </td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Serviced By:
                  </td>
                  <td className="py-2">
                    {complaintLoggedTicket?.serviced_by || ""}
                  </td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Assigned Type:
                  </td>
                  <td className="py-2">
                    {complaintLoggedTicket?.assigned_type || ""}
                  </td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Subject:
                  </td>
                  <td className="py-2">
                    {complaintLoggedTicket?.subject || ""}
                  </td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Description:
                  </td>
                  <td className="py-2">
                    <div className="vc-description" ref={contentRef}>
                      {complaintLoggedTicket?.description || ""}
                    </div>
                  </td>
                </tr>
                {complaintLoggedTicket?.status === "Closed" &&
                  <tr className="text-[#222] text-sm">
                    <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                      Closed By:
                    </td>
                    <td className="py-2">
                      {complaintLoggedTicket?.user_name || ""}
                    </td>
                  </tr>
                }
                {complaintLoggedTicket?.status === "Closed" &&
                  <tr className="text-[#222] text-sm">
                    <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                      Closed On:
                    </td>
                    <td className="py-2">
                      {complaintLoggedTicket?.last_update_on && complaintLoggedTicket?.last_update_on !==
                        "0000-00-00 00:00:00"
                        ? new Date(
                          complaintLoggedTicket?.last_update_on
                        ).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "UTC"
                        })
                        : "-"}
                    </td>
                  </tr>
                }
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Attachments:
                  </td>
                  <td>
                    {complaintLoggedTicket?.attachments.length > 0 ?
                      complaintLoggedTicket?.attachments.map((attach, index) =>
                        <div
                          className="whitespace-nowrap border-dashed border-b border-[#CCC]"
                        >
                          <div className="flex ">
                            <div className="grid grid-col-6 gap-3 w-full py-2">
                              <span>
                                {/* {index + 1 + ". "} */}
                                <Link
                                  target="_blank"
                                  className="ms-1 underline text-xs text-blue-500"
                                  to={
                                    attach?.attachment_Url
                                  }
                                >
                                  View Uploaded Doc
                                </Link>
                              </span>
                            </div>

                            <div className="">
                              <div className="grid grid-col-6 gap-3 w-full">
                                <div className="py-2 text-[#AAA] whitespace-nowrap">
                                  <div className="flex gap-3">
                                    <p> Status: </p>
                                    <p className="font-bold">
                                      {complaintStatusList?.filter((item) => item.value === attach.status).map((item) => {
                                        return item.key
                                      })}
                                    </p>
                                  </div>
                                  <div className="flex gap-3">
                                    <p> Posted On: </p>
                                    <p className=" text-[#AAA]">
                                      {attach.created_at && attach.created_at !==
                                        "0000-00-00 00:00:00"
                                        ? new Date(
                                          attach.created_at
                                        ).toLocaleString("en-GB", {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "numeric",
                                          hour: "numeric",
                                          minute: "numeric",
                                          timeZone: "Asia/Kolkata"
                                        })
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <span>-</span>
                      )}
                  </td>
                </tr>
                <tr className="text-[#222] text-sm">
                  <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                    Comment:
                  </td>
                  <td className="py-4">
                    <div className="flex">
                      {complaintLoggedTicket?.comments &&
                        complaintLoggedTicket?.comments?.length > 0 ? (
                        <div className="grid grid-col-6 gap-3 w-full">
                          {complaintLoggedTicket?.comments?.map(
                            (comment, index) => (
                              <div
                                key={index}
                                className="py-2 border-dashed border-b border-[#CCC]"
                              >
                                {/* <div className="flex gap-3">
                              <p> User Name: </p>
                              <p className="font-bold"> {comment.comment_by} </p>
                              </div> */}

                                <div className="flex gap-3">
                                  <p> - </p>
                                  <p className=" text-[#AAA]">
                                    {" "}
                                    {comment.comments || "-"}{" "}
                                  </p>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        "-"
                      )}

                      <div className="">
                        <div className="grid grid-col-6 gap-3 w-full">
                          {complaintLoggedTicket?.comments?.map(
                            (comment, index) => (
                              <div
                                key={index}
                                className="py-2 text-[#AAA] whitespace-nowrap border-dashed border-b border-[#CCC]"
                              >
                                <div className="flex gap-3">
                                  <p> Status: </p>
                                  <p className="font-bold">
                                    {complaintStatusList?.filter((item) => item.value === comment.status_update).map((item) => {
                                      return item.key
                                    })}
                                  </p>
                                </div>
                                <div className="flex gap-3">
                                  <p> Posted By: </p>
                                  <p className="font-bold">
                                    {" "}
                                    {comment.comment_by}{" "}
                                  </p>
                                </div>

                                <div className="flex gap-3">
                                  <p> Posted On: </p>
                                  <p className=" text-[#AAA]">
                                    {comment.add_comment_date && comment.add_comment_date !==
                                      "0000-00-00 00:00:00"
                                      ? new Date(
                                        comment.add_comment_date
                                      ).toLocaleString("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        timeZone: "UTC"
                                      })
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default ViewComplaint;
