import React from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Search from "../../../components/SearchBox/Search";
import GenericTable from "../../../components/TableComponent/Table";
import Layout from "../../Layout/Layout";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "70px" },
  { id: "date", label: "Sender Name", sort: false, width: "100px" },
  { id: "unitNumber", label: "Review", sort: true, width: "80px" },
  { id: "type", label: "Date & Time", sort: true, width: "100px" },
  { id: "amount", label: "Status", sort: true, width: "110px" },
  { id: "contactNo", label: "Action", sort: true, width: "90px" },
];

const rows = [];

const Reviews = () => {
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Reviews">
          </ListHeader>
        </div>

        <div className="py-1 flex justify-between gap-2 my-1">
          <Search
            height="8"
            width="48"
            placeholder="Search transaction"
          />
        </div>
        <div className="ml-1">
          <GenericTable
            data={rows}
            columns={headCells}
            checkbox={false}
            pagination={rows.length}
            totalCount={rows.length}
            totalPages={2}
            start={1}
            end={10}
            currentPage={1}
            getTableData={(rowsPerPage, page) => {
              console.log(rowsPerPage, page);
            }}
          ></GenericTable>
        </div>

        {!rows.length && (
          <div className="text-lg text-[#AAAAAA] mt-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Reviews;
