import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance2 from '../../../axiosInstance2'
import { logOut } from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';


export async function getManageCreditListReq(action) {
    const BODY = {
        'id': action.data?.societyId,
        'wing_id': action.data?.wingId,
        'flat_id': action.data?.flatId,
        'page': isNaN(parseInt(action.data?.page) - 1) ? 'all' : parseInt(action.data?.page) - 1,
        'limit': action.data?.pageLimit,
        "invoice_no": action.data.searchText,
        "typeid": action.data?.InvoiceType,
    }

    return axiosInstance2.post(apiEndpoints.GET_CREDIT_NOTE_LIST_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getCreditNoteDetailsReq(action) {
    const BODY = {
        'id': action.data?.societyId,
        'drcrid': action.data?.creditNoteId
    }

    return axiosInstance2.post(apiEndpoints.GET_CREDIT_NOTE_DETAILS_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function addCreditNoteReq(action) {
    const BODY = {
        "pageid": action.data.societyId,
        'typeid': action.data?.invoiceType,
        "debitor_type": action.data.memberType,
        "Creditnote_date": action.data.date,
        "Hard_copy_voucher": action.data.voucher,
        "remarks": action.data.remarks,
        ...(action.data.wingId && { "wing_id": action.data.wingId }),
        ...(action.data.flatId && { "flat_id": action.data.flatId }),
        ...(action.data.memberId && { "member_id": action.data.memberId }),
        "member_glcode": action.data.memberGLCode,
        "member_glacc": action.data.memberGLName,
        "cause": action.data.details?.map(item => item.tariffHeaderId),
        "debitaccount": action.data.details?.map(item => item.debitAccountId),
        "hsncode": action.data.details?.map(item => item.hsnCode),
        "service": action.data.details?.map(item => item.serviceGood),
        "amount": action.data.details?.map(item => item.amount),
        "gstapplicable": action.data.details?.map(item => item.gstApplicable),

        ...(action.data.sgstGLCode && { "sgst_glcode": action.data.sgstGLCode }),
        ...(action.data.cgstGLCode && { "cgst_glcode": action.data.cgstGLCode }),
        ...(parseFloat(action.data.sgstAmount) && { "sgst_amount": action.data.sgstAmount }),
        ...(parseFloat(action.data.cgstAmount) && { "cgst_amount": action.data.cgstAmount }),

        "grandtotal": action.data.grandTotal,

        "send_email": action.data.sendEmail,
        "send_sms": action.data.sendSMS,
    }


    return axiosInstance2.post(apiEndpoints.ADD_CREDIT_NOTE_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateCreditNoteReq(action) {
    const BODY = {
        "pageid": action.data.societyId,
        "debitor_type": action.data.debitorType,
        "Hard_copy_voucher": action.data.voucher,
        "membergl": action.data.memberGlCode,
        "memberid": action.data.memberId,
        "selectid": action.data.memberId || '',
        "loginId": action.data.loginId,
        "loginType": action.data.loginType,

        ...(action.data.wingId && { "wing_id": action.data.wingId }),
        ...(action.data.flatId && { "flat_id": action.data.flatId }),
        ...(action.data.memberId && { "member_id": action.data.memberId }),
        ...(action.data.memberId && { "selectid": action.data.memberId }),

        "remarks": action.data.remarks,
        "journalid": action.data.journalid,
        "send_email": action.data.sendEmail,
        "send_sms": action.data.sendSMS,
        "drcrid": action.data.creditNoteId,
        "registerId": action.data.registerid,
        "Creditnote_date": action.data.date,
        "cause": action.data.details?.map(item => item.tariffHeaderId),
        "gstapplicable": action.data.details?.map(item => item.gstApplicable),
        "debitaccount": action.data.details?.map(item => item.debitAccountId),
        "hsncode": action.data.details?.map(item => item.hsnCode),
        "amount": action.data.details?.map(item => item.amount),
        "service": action.data.details?.map(item => item.serviceGood),
    }


    return axiosInstance2.post(apiEndpoints.UPDATE_CREDIT_NOTE_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteCreditNoteReq(action) {
    const BODY = {
        'drcrid': action.data?.creditNoteId,
        'pageid': action.data?.societyId,
        'journalid': action.data?.jounralId
    }
    return axiosInstance2.post(apiEndpoints.DELETE_CREDIT_NOTE_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function printCreditNoteInvoiceReq(action) {
    const BODY = {
        "drcrid": [Number(action.data.creditNoteId)],
        // "drcrid": Number(action.data.creditNoteId),
        'id': action.data?.societyId,
    }


    return axiosInstance2.post(apiEndpoints.PRINT_CREDIT_NOTE_INVOICE_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getFlatWiseInvoiceListReq(action) {
    const BODY = {
        'id': action.data?.societyId,
        'wing_no': action.data?.wingId

        //add glcode of member
    }
    return axiosInstance2.post(apiEndpoints.GET_FLAT_WISE_INVOICE_LIST_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function downloadCreditNoteExcelSampleReq(action) {

    return axiosInstance2.post(`${apiEndpoints.DOWNLOAD_CREDIT_NOTE_EXCEL_SAMPLE_API}`, {}, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function uploadCreditNoteExcelReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('typeid', action.data?.invoiceType);
    bodyFormData.append('excel', action.data?.file);


    return axiosInstance2.post(apiEndpoints.UPLOAD_CREDIT_NOTE_EXCEL_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function saveCreditNoteExcelReq(action) {
    let { uploadingExcelData } = action.data;
    uploadingExcelData = uploadingExcelData?.filter(item => item.selected)

    const BODY = {
        "pageid": action.data.societyId,
        "typeid": action.data.invoiceType,
        "creditnote_type": uploadingExcelData?.map(item => item.type),
        "wing_id": uploadingExcelData?.map(item => item.wingid),
        "flat_id": uploadingExcelData?.map(item => item.flatid),
        "member_id": uploadingExcelData?.map(item => item.member_id),
        "member_glcode": uploadingExcelData?.map(item => item.member_glcode),
        "member_glacc": uploadingExcelData?.map(item => item.member_glacc),
        "billdate": uploadingExcelData?.map(item => item.date),
        "voucher": uploadingExcelData?.map(item => item.voucher_no),
        "gst_applicable": uploadingExcelData?.map(item => item.gst_applicable),
        "causename": uploadingExcelData?.map(item => item.cause),
        "creditaccount": uploadingExcelData?.map(item => item.credit_ac),
        "debitaccount": uploadingExcelData?.map(item => item.acredit_ac_name),
        "amount": uploadingExcelData?.map(item => item.amount),
        "hsncode": uploadingExcelData?.map(item => item.hsn_code),
        "service": uploadingExcelData?.map(item => item.service),
        "headgstapplicable": uploadingExcelData?.map(item => item.headgstapplicable),
        "invid": uploadingExcelData?.map((item, index) => index),
        "invoice": uploadingExcelData?.map(item => 'Yes'),
        "invoiceref": uploadingExcelData?.map(item => null),
        "sgst_glcode": uploadingExcelData?.map(item => item.sgst_glcode),
        "sgst_amount": uploadingExcelData?.map(item => item.sgst_amount),
        "cgst_glcode": uploadingExcelData?.map(item => item.cgst_glcode),
        "cgst_amount": uploadingExcelData?.map(item => item.cgst_amount),
        "grand_total": uploadingExcelData?.map(item => item.grand_total),
        "remark": uploadingExcelData?.map(item => item.remark),
    }


    return axiosInstance2.post(apiEndpoints.SAVE_CREDIT_NOTE_EXCEL_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if ((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)) {
                logOut()
            } else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}