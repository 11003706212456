import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import Search from "../../../components/SearchBox/Search";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import AcknowledgmentListNoticeTable from "./AcnowledgeListTable";
import { useSelector, useDispatch } from "react-redux";
import { getNoticeCircularAcknowledgementList } from "../../../redux/actions/SocialAndCommunicationActions/NoticeAndCircularAction";
import moment from "moment";
import {
  ARCHIVE_NOTICE_AND_CIRCULARS_ENDPOINT,
  NOTICE_AND_CIRCULARS_ENDPOINT,
  ACKNOWLEDGMENT_LIST_NOTICE_AND_CIRCULARS_ENDPOINT,
} from "../../../Routing/routes";

export const headCells = [
  { id: "sNo", label: "Sr. No.", sort: true, width: "80px" },
  { id: "full_name", label: "Member Details", sort: true, width: "300px" },
  { id: "email", label: "Email Id", sort: true, width: "200px" },
];

const AcknowledgementListNotice = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, noticeCircularAcknowledgementList } = useSelector(
    (state) => state.noticeAndCircularReducer
  );

  const goback = () => {
    navigate(NOTICE_AND_CIRCULARS_ENDPOINT);
  };

  const getTableData = () => {
    const data = {
      id: id,
    };
    dispatch(getNoticeCircularAcknowledgementList(data));
  };

  useEffect(() => {
    getTableData();
  }, [searchParams]);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack
          title="Acknowledgement - Notice and Circulars"
          onClick={goback}
        >
          {/* <span className="border-r border-[#CCC]"></span> */}
          {/* <Search
            width={36}
            height="8"
            onclick={handleSearch}
            value={searchParams.get("filter_text")}
          /> */}
        </ListHeaderBack>

        <div>
          <AcknowledgmentListNoticeTable
            loading={loading}
            data={noticeCircularAcknowledgementList?.map((item, index) => ({
              sNo: (index + 1)?.toString()?.padStart(2, "0"),
              full_name: item?.full_name,
              email: item?.email,
            }))}
            columns={headCells}
            checkbox={false}
            pagination={true}
          ></AcknowledgmentListNoticeTable>
        </div>
        {!noticeCircularAcknowledgementList?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AcknowledgementListNotice;
