import * as types from '../../index.types';

export function getDebitNoteCurrent(data) {
    return {
        type: types.GET_DEBIT_NOTE_CURRENT,
        data,
    };
}

export function getDebitNoteActionList(data) {
    return {
        type: types.GET_DEBIT_NOTE_ACTION_LIST,
        data,
    };
}

export function bulkVerfiyPostDebitNoteUpdate(data) {
    return {
        type: types.BULK_VERIFY_POST_DEBIT_NOTE_UPDATE,
        data,
    };
}

export function resendDebitNoteCurrentEmailSms(data) {
    return {
        type: types.RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS,
        data,
    };
}

export function bulkMakePaymentDebitNoteSave(data) {
    return {
        type: types.BULK_MAKE_PAYMENT_DEBIT_NOTE_SAVE,
        data,
    };
}

export function getDebitNoteReceiptPrint(data) {
    return {
        type: types.GET_DEBIT_NOTE_RECEIPT_PRINT,
        data
    }
}

export function resendDebitNoteReceiptEmailSms(data) {
    return {
        type: types.RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS,
        data,
    };
}

export function deleteDebitNoteReceipt(data) {
    return {
        type: types.DELETE_DEBIT_NOTE_RECEIPT,
        data,
    };
}


export function getSingleDebitNoteList(data) {
    return {
        type: types.GET_SINGLE_DEBIT_NOTE_LIST,
        data,
    }
}

export function rejectSingleReceiptDebitNote(data) {
    return {
        type: types.REJECT_SINGLE_RECEIPT_DEBIT_NOTE,
        data,
    }
}

export function editSingleDebitNoteDetails(data) {
    return {
        type: types.EDIT_SINGLE_DEBIT_NOTE_DETAILS,
        data,
    }
}

export function editSingleDebitNoteSave(data) {
    return {
        type: types.EDIT_SINGLE_DEBIT_NOTE_SAVE,
        data,
    }
}

export function getAdjustmentPaymentDetails(data) {
    return {
        type: types.ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE,
        data,
    }
}
export function getAdjustmentPaymentSave(data) {
    return {
        type: types.ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE,
        data,
    };
}
export function getMakePaymentDetails(data) {
    return {
        type: types.MAKE_PAYMENT_DETAILS_DEBIT_NOTE,
        data,
    }
}
export function getMakePaymentSave(data) {
    return {
        type: types.MAKE_PAYMENT_SAVE_DEBIT_NOTE,
        data,
    }
}
export function sendDebitNoteCurrentEmailSms(data) {
    return {
        type: types.SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS,
        data,
    }
}
export function deleteDebitNoteCurrent(data) {
    return {
        type: types.DELETE_DEBIT_NOTE_CURRENT,
        data,
    }
}

export function uploadDebitNoteCurrentExcel(data) {
    return {
        type: types.UPLOAD_DEBIT_NOTE_CURRENT_EXCEL,
        data,
    }
}

export function downloadDebitNoteCurrentExcel(data) {
    return {
        type: types.DOWNLOAD_DEBIT_NOTE_CURRENT_EXCEL,
        data,
    }
}

export function uploadDebitNoteCurrentPayment(data) {
    return {
        type: types.UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT,
        data,
    }
}

export function downloadDebitNoteCurrentPayment(data) {
    return {
        type: types.DOWNLOAD_DEBIT_NOTE_CURRENT_PAYMENT,
        data,
    }
}

export function getDebitNotePrevious(data) {
    return {
        type: types.GET_DEBIT_NOTE_PREVIOUS,
        data,
    };
}

export function getDebitNoteDraft(data) {
    return {
        type: types.GET_DEBIT_NOTE_DRAFT,
        data,
    };
}
export function editSingleDebitNoteDraftSave(data) {
    return {
        type: types.EDIT_SINGLE_DEBIT_NOTE_DRAFT_SAVE,
        data,
    }
}

export function getDebiteNoteInvoicePrint(data) {
    return {
        type: types.GET_DEBIT_NOTE_INVOICE_PRINT,
        data,
    }
}

// export function getSingleDebitNote(data) {
//     return {
//         type: types.SINGLE_DEBIT_NOTE_LIST,
//         data,
//     }
// }

// export function getDebitNoteActionsList(data) {
//     return {
//         type: types.GET_DEBIT_NOTE_ACTIONS_LIST,
//         data,
//     }
// }

export function getDebiteNoteTarrifList(data) {
    return {
        type: types.GET_DEBIT_NOTE_TARRIF_LIST,
        data,
    }
}
export function getDebiteNoteFlatWingWiseList(data) {
    return {
        type: types.GET_DEBIT_NOTE_FLAT_WING_WISE_LIST,
        data,
    }
}

export function getDebiteNoteArrearDetails(data) {
    return {
        type: types.GET_DEBIT_NOTE_ARREAR_DETAILS,
        data,
    }
}
export function addDebiteNote(data) {
    return {
        type: types.ADD_DEBIT_NOTE,
        data,
    }
}
export function addMultipleDebiteNote(data) {
    return {
        type: types.ADD_MULTIPLE_DEBIT_NOTE,
        data,
    }
}

