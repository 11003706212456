export function  percentage(percentage, totalValue){
  return (percentage * totalValue) / 100;
}
export default function invoiceCalculation (invoicedata, index) {
  if (invoicedata.invoice[index]) {
    let subtotal = 0;
    let grandtotal = 0;
    let principal_arrear = !isNaN(invoicedata.invoice[index].principal_arrear) ? invoicedata.invoice[index].principal_arrear : 0;
    let interest_arrear = !isNaN(invoicedata.invoice[index].interest_arrear) ? invoicedata.invoice[index].interest_arrear : 0;
    let interest = !isNaN(invoicedata.invoice[index].interest) ? invoicedata.invoice[index].interest : 0;
    let sgstrate = parseFloat(invoicedata?.debitnote_header?.sgst_rate) || 0;
    let cgstrate = parseFloat(invoicedata?.debitnote_header?.cgst_rate) || 0;
    let igstrate = parseFloat(invoicedata?.debitnote_header?.igst_rate) || 0;
    let sgstGl = invoicedata?.debitnote_header?.sgst_head || null;
    let cgstGl = invoicedata?.debitnote_header?.cgst_head || null;
    let igstGl = invoicedata?.debitnote_header?.igst_head || null;
    let roundOffActive = invoicedata?.debitnote_header?.roundoff || 0;
    let roundOffGl = invoicedata?.debitnote_header?.roundoff_head || null;
    let interestGstActive = invoicedata?.debitnote_header?.interest_gst || 0;
    let interestGl = invoicedata?.debitnote_header?.debitinterest || null;
    let sgstamount = 0;
    let cgstamount = 0;
    let igstamount = 0;
    if(invoicedata?.debitnote_header?.gst_applicable === 1){
      if(invoicedata.invoice[index]?.head_details){
        if (!isNaN(sgstrate) && sgstGl!=null){ 
          invoicedata.invoice[index].head_details.forEach((value,key)=>{
            if (value?.gstapplicable === "Y") {
              sgstamount += !isNaN(value.amount) ? percentage(sgstrate, value.amount) : 0;
              invoicedata.invoice[index].head_details[key].tax_amount = !isNaN(value.amount) ? percentage(sgstrate, value.amount) : 0;
            }
            if(interestGstActive===1){
              sgstamount += !isNaN(value.amount) ? percentage(sgstrate, interest) : 0;
              //invoicedata.invoice[index].head_details[key].tax_amount = !isNaN(value.amount) ? percentage(sgstrate, value.amount) : 0;
            }
          })
        }
        if (!isNaN(cgstrate) && cgstGl!=null){ 
          invoicedata.invoice[index].head_details.forEach((value,key)=>{
            if (value?.gstapplicable === "Y") {
              cgstamount += !isNaN(value.amount) ? percentage(cgstrate, value.amount) : 0;
              invoicedata.invoice[index].head_details[key].tax_amount += !isNaN(value.amount) ? percentage(cgstrate, value.amount) : 0;
            }
            if(interestGstActive===1){
              cgstamount += !isNaN(value.amount) ? percentage(cgstrate, interest) : 0;
              //invoicedata.invoice[index].head_details[key].tax_amount = !isNaN(value.amount) ? percentage(sgstrate, value.amount) : 0;
            }
          })
        }
        if (!isNaN(igstrate) && igstGl!=null){ 
          invoicedata.invoice[index].head_details.forEach((value,key)=>{
            if (value?.gstapplicable === "Y") {
              igstamount += !isNaN(value.amount) ? percentage(igstrate, value.amount) : 0;
              invoicedata.invoice[index].head_details[key].tax_amount += !isNaN(value.amount) ? percentage(igstrate, value.amount) : 0;
            }
            if(interestGstActive===1){
              igstamount += !isNaN(value.amount) ? percentage(igstrate, interest) : 0;
              //invoicedata.invoice[index].head_details[key].tax_amount = !isNaN(value.amount) ? percentage(sgstrate, value.amount) : 0;
            }
          })
        }
      }
    }
    invoicedata.invoice[index].sgst_amount = sgstamount.toFixed(2);
    invoicedata.invoice[index].cgst_amount = cgstamount.toFixed(2);
    invoicedata.invoice[index].igst_amount = igstamount.toFixed(2);
    if (invoicedata.invoice[index].head_details?.length > 0) {
      invoicedata.invoice[index].head_details?.forEach((key) => {
        subtotal += !isNaN(key?.amount) ? parseFloat(key?.amount) : 0;
      });
    }
    subtotal += sgstamount + cgstamount + igstamount;
    subtotal = !isNaN(subtotal) ? subtotal.toFixed(2) : 0;
    if (roundOffActive !== 0 && roundOffGl != null) {
      //if(roundoff[])
      var subtotal_withpoint = subtotal;
      subtotal = Math.round(subtotal);
      var roundoff_value = subtotal - subtotal_withpoint;
      invoicedata.invoice[index].roundoff_amount = roundoff_value.toFixed(2);
    }
    invoicedata.invoice[index].subtotal = subtotal;
    grandtotal += parseFloat(subtotal) + principal_arrear + interest_arrear + interest;
    
    invoicedata.invoice[index].grandtotal = grandtotal;
    console.log(invoicedata)
    return invoicedata;
  }
}