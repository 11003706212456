import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/ClubReqs/ViewBookingsReqs'
import { notify } from '../../../utils/notification';

function* getBookingList(action) {
    try {
        const result = yield call(req.getBookingListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BOOKING_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_BOOKING_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getBookingStatusList(action) {
    try {
        const result = yield call(req.getBookingStatusListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BOOKING_STATUS_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_BOOKING_STATUS_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getBookingDetails(action) {
    try {
        const result = yield call(req.getBookingDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BOOKING_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_BOOKING_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* makeBookingAdjustment(action) {
    try {
        const result = yield call(req.makeBookingAdjustmentReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.MAKE_BOOKING_ADJUSTMENT_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()
        }else{}
            yield put({ type: types.MAKE_BOOKING_ADJUSTMENT_FAILURE});  
    } catch (e) {
        console.log(e)
    }
}

function* getFacilityReport(action) {
    try {
        const result = yield call(req.getFacilityReportReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_FACILITY_REPORT_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_FACILITY_REPORT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getFacilityAuditLogs(action) {
    try {
        const result = yield call(req.getFacilityAuditLogsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_ALL_FACILITY_AUDIT_LOG_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_ALL_FACILITY_AUDIT_LOG_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* changeBookingStatus(action) {
    try {
        const result = yield call(req.changeBookingStatusReq, action);
        if (result.statusCode === 200) {
            if(action.data.status === 'D')
                notify('success', 'Booking deleted successfully')
            else if(action.data.status === 'C')
                notify('success', 'Booking cancelled successfully')
            else if(action.data.status === 'A')
                notify('success', 'Booking approved successfully')

            yield put({ type: types.CHANGE_BOOKING_STATUS_SUCCESS, payload: action.data});  
            action.data?.onSuccessCallback?.()

            if(action.data?.status==='D'){
                action.data.status = ""
                const newList = yield call(req.getBookingListReq, action)
                if (newList.statusCode === 200)
                    yield put({ type: types.GET_BOOKING_LIST_SUCCESS, payload: newList.data});  
            }
        }else{}
        yield put({ type: types.CHANGE_BOOKING_STATUS_FAILURE});  
    } catch (e) {
        console.log(e)
    }
}

function* downloadFacilityReportExcelSheet(action) {
    try {
        const result = yield call(req.downloadFacilityReportExcelSheetReq, action);
        if (result instanceof ArrayBuffer) {
            yield put({ type: types.DOWNLOAD_FACILITY_REPORT_EXCEL_SUCCESS});  
            action.data?.onSucessCallback(result)
        }else{
            yield put({ type: types.DOWNLOAD_FACILITY_REPORT_EXCEL_FAILURE});  
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}


function* getBookingGLMappingList(action) {
    try {
        const result = yield call(req.getBookingGLMappingListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BOOKING_GL_MAPPING_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_BOOKING_GL_MAPPING_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* getBookingBankList(action) {
    try {
        const result = yield call(req.getBookingBankListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_BOOKING_BANK_ACCOUNT_LIST_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_BOOKING_BANK_ACCOUNT_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


function* getBookingInvoice(action) {
    try {
        const result = yield call(req.getBookingInvoiceReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_BOOKING_INVOICE_SUCCESS, payload: result});  
        } else {
            yield put({ type: types.GET_BOOKING_INVOICE_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* makeBookingPayment(action) {
    try {
        const result = yield call(req.makeBookingPaymentReq, action);
        if (result.success === true) {
            yield put({ type: types.MAKE_BOOKING_PAYMENT_SUCCESS, payload: result}); 
            action.data?.onSuccessCallback?.(result)
        } else {
            yield put({ type: types.MAKE_BOOKING_PAYMENT_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

export default function* viewBookingsSaga() {
    yield takeLatest(types.GET_BOOKING_LIST, getBookingList);
    yield takeLatest(types.GET_BOOKING_STATUS_LIST, getBookingStatusList);
    yield takeLatest(types.GET_BOOKING_DETAILS, getBookingDetails);
    yield takeLatest(types.MAKE_BOOKING_ADJUSTMENT, makeBookingAdjustment);
    yield takeLatest(types.GET_FACILITY_REPORT, getFacilityReport);
    yield takeLatest(types.GET_ALL_FACILITY_AUDIT_LOG, getFacilityAuditLogs);
    yield takeLatest(types.CHANGE_BOOKING_STATUS, changeBookingStatus);
    yield takeLatest(types.DOWNLOAD_FACILITY_REPORT_EXCEL, downloadFacilityReportExcelSheet);
    yield takeLatest(types.GET_BOOKING_GL_MAPPING_LIST, getBookingGLMappingList);
    yield takeLatest(types.GET_BOOKING_BANK_ACCOUNT_LIST, getBookingBankList);
    yield takeLatest(types.GET_BOOKING_INVOICE, getBookingInvoice);
    yield takeLatest(types.MAKE_BOOKING_PAYMENT, makeBookingPayment);
}