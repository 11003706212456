import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { downloadChequeDepositSlipReport, getChequeDepositSlipReport } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import parse from 'html-react-parser';
import { DownloadExt, PrinterIcon } from "../../../assets";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { downloadFileFromURL } from "../../../utils/helpers/downloadMedia";
import { CircularProgress } from "@mui/material";

export const headingsTenants = [
  { id: 'sNo', label: 'S No.', width: '45px' },
  { id: 'wingFlat', label: 'Wing & Flat No.', sort: true, width: '100px' },
  { id: 'memberName', label: 'Member Name', sort: true, width: '80px' },
  { id: 'chequeNo', label: 'Cheque No/DD.No', sort: true, width: '90px' },
  { id: 'date', label: 'Date', sort: true, width: '100px' },
  { id: 'bankName', label: 'Bank Name', sort: true, width: '100px' },
  { id: 'branch', label: 'Branch', sort: true, width: '100px' },
  { id: 'amount', label: 'Amount', sort: true, width: '80px' },
];

const PrintChequeDepositSlip = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams()

  const { loginData } = useSelector((state) => state.loginReducer);
  const { printLoader, chequeDepositSlipReport } = useSelector(
    (state) => state.billingReportsReducer
  );
  const { downLoader } = useSelector((state) => state.downloadBillingReportsReducer)
  
  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      bankId: searchParams.get("bankId") || '',
      accountNo: searchParams.get("accountNo") || '',
      branchName: searchParams.get("branchName") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      memberName: searchParams.get("memberName") || '',
      dateType: searchParams.get("dateType") || '',
      fromDate: searchParams.get("fromDate") || '',
      toDate: searchParams.get("toDate") || '',
      export: 0,
    }
    dispatch(getChequeDepositSlipReport(data));
  }

  const handleDownloadExcel = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      bankId: searchParams.get("bankId") || '',
      accountNo: searchParams.get("accountNo") || '',
      branchName: searchParams.get("branchName") || '',
      wingId: searchParams.get("wingId") || '',
      flatId: searchParams.get("flatId") || '',
      memberName: searchParams.get("memberName") || '',
      dateType: searchParams.get("dateType") || '',
      fromDate: searchParams.get("fromDate") || '',
      toDate: searchParams.get("toDate") || '',
      export: 1,
      onSuccessCallback: (url) => {
        downloadFileFromURL({ url, fileName: "sample.csv" });
      },
    }
    dispatch(downloadChequeDepositSlipReport(data));
  };

  useEffect(() => {
    getTableData()

    const afterprint = (e) => {
      e.preventDefault()
    };

    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    }
  }, [])



  useEffect(() => {
    if (!printLoader && chequeDepositSlipReport) {
      setTimeout(function () { window.print(); }, 1000);
    }
  }, [printLoader]);


  return (
    <div>
      {
        printLoader || !chequeDepositSlipReport ?
          <Loader />
          :
          <>
            <div className="flex justify-end p-2 drop-shadow-sm gap-2 noprint">
              <button
                type="button"
                onClick={() => window.print()}
                className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
              >
                <PrinterIcon className="mr-2 fill-red-650" /> Print
              </button>
              <div className="border-r border-[#CCC]"></div>
              {downLoader ?
                <ButtonIco className=" w-32 justify-center" children={<CircularProgress sx={{ color: "#d32f2f" }} size={17} />} />
                :
                <ButtonIco icon={DownloadExt} children='Download Excel' onClick={handleDownloadExcel} />
              }
            </div>
            {typeof chequeDepositSlipReport === 'string' && chequeDepositSlipReport.trim() !== '' ?
              <>
                {parse(chequeDepositSlipReport)}
              </>
              :
              <div className="text-lg text-[#AAAAAA] mt-10 text-center italic">
                No Records
              </div>
            }
          </>
      }
    </div>
  );
};

export default PrintChequeDepositSlip;


{/*<div  className="p-1">
      <table className="table-auto w-full mx-auto border-collapse border border-black">
        <caption className="caption-top mb-10 mt-4">
          <div className=" font-extrabold text-lg mb-3 text-red-600">Cheque Deposit Slip/Pay Slip</div>
          {/* <div className="text-[15px] text-left"> <span className="font-bold">Bank Name:</span> Juhi Sahu</div>
          <div className="text-[15px] text-left"><span className="font-bold">Bank Address:</span> 25- B Ne</div>
          <div className="text-[15px] text-left"><span className="font-bold">Account No.:</span> 359486986496</div>
          <div className="text-[15px] text-left"><span className="font-bold">Account Holder Name:</span> Haunted</div>         
        </caption>
        <thead>
          <tr>
            {
              headingsTenants?.map((item)=>(
                <td className={`text-sm p-1 h-16 font-semibold text-[14px] border border-black w-[${item?.width}]`} key={item?.id}>
                  {item?.label}
                </td>
              ))
            }
          </tr>
        </thead>
        <tbody>
            {
              chequeDepositSlipReport?.map((item, index)=>(
                <React.Fragment  key={index}>
                {
                  !!Object.keys(item)?.length &&
                  <tr className="text-[#222] text-sm">
                    <td className="p-1 border border-black align-top w-[45px]">
                      {(index+1)?.toString()?.padStart(2, 0)}
                    </td>
                    <td className="p-1 border border-black align-top capitalize">
                      {item?.wing_no + ' - ' + item?.flat_no || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.memberName || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.cheque_number || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {validateDate(item?.payment_date) ? moment(new Date(item?.payment_date))?.format('DD-MM-YYYY') : '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.bank_name || '-'}
                    </td>
                    <td className="p-1 border border-black align-top capitalize">
                      {item?.branch_name || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.amount || '-'}
                    </td>
                  </tr>
                }
                </React.Fragment>
              ))
            }
            {
              !!chequeDepositSlipReport?.length &&
              <tr className="text-[#222] text-sm font-semibold">
                <td className="p-1 border border-black align-top"></td>
                <td className="p-1 border border-black align-top"></td>
                <td className="p-1 border border-black align-top"></td>
                <td className="p-1 border border-black align-top"></td>
                <td className="p-1 border border-black align-top"></td>
                <td className="p-1 border border-black align-top"></td>
                <td className="p-1 border border-black align-top">Total</td>
                <td className="p-1 border border-black align-top">
                  {chequeDepositSlipReport?.reduce((total, next) => total+ (parseFloat(next?.amount)), 0)}
                </td>
              </tr>
            }
        </tbody>
      </table>
      {
        !!chequeDepositSlipReport?.length && 
        <div className="my-10"> 
          <span className="font-semibold">Amount in words: </span>
          <span className="capitalize">{toWords(5500)}</span>
        </div>
      }
      {!chequeDepositSlipReport?.length && !printLoader && (
          <div className="text-sm my-10 text-center italic">
            No Records
          </div>
        )}
    </div>

*/}