import { call, put, takeLatest } from 'redux-saga/effects'
import {notify} from '../../../../utils/notification'
import * as types from '../../../actions/index.types'
import * as req from '../../../../axios/request/FinanceReqs/SettingReqs/InvoiceDetailsReqs'

function* getMasterHeadDetails(action) {
    try {
        const result = yield call(req.getMasterHeadDetailsReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MASTER_HEAD_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.GET_MASTER_HEAD_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* updateMasterHeadDetails(action) {

    try {
        const result = yield call(req.updateMasterHeadDetailssReq, action);
        if (result.success === true) {
            notify('success', result.message)
            yield put({ type: types.UPDATE_MASTER_HEAD_DETAILS_SUCCESS, payload: result.data});  
        } else {
            yield put({ type: types.UPDATE_MASTER_HEAD_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* invoiceDetailsSaga() {
    yield takeLatest(types.GET_MASTER_HEAD_DETAILS, getMasterHeadDetails);
    yield takeLatest(types.UPDATE_MASTER_HEAD_DETAILS, updateMasterHeadDetails);
}