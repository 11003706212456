import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  return (
    <footer className="bg-gray-100 border-t py-4 w-[1280px] mx-auto relative bottom-0 mt-auto">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between">
        <div className="flex items-center space-x-2 mb-4 lg:mb-0">
          <a className="text-red-650 text-sm" href="https://zipgrid.com/about">
            About Us
          </a>
          <span className="border-r-2 border-red-650"></span>
          <button
            className="text-red-650 text-sm"
            href="https://zipgrid.com/terms"
          >
            Terms Of Usage
          </button>
          <span className="border-r-2 border-red-650"></span>
          <button
            className="text-red-650 text-sm"
            href="https://zipgrid.com/privacy-policy"
          >
            Privacy Policy
          </button>
          <span className="border-r-2 border-red-650"></span>
          <button
            className="text-red-650 text-sm"
            href="https://zipgrid.com/terms"
          >
            Terms And Conditions
          </button>
        </div>
        <p className="text-center lg:text-right">
          &copy; {new Date().getFullYear()}{" "}
          <button className="text-blue-500">zipgrid.com</button>. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
