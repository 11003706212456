import React, { useEffect, useState } from "react";
import TextRadio from "../../../../../components/InputFields/TextRadio";
import ChequeDetailsForm, { EditChequeDetailsForm } from "./ChequeDetailsForm";
import NEFTDetailsForm, { EditNEFTDetailsForm } from "./NeftDetailsForm";
import CashDetailsForm, { EditCashDetailsForm } from "./CashDetailsForm";
import { makeBookingPayment } from "../../../../../redux/actions/ClubActions/ViewBookingsAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MAKE_PAYMENT_SUCCESS_DEBIT_NOTE_ENDPOINT } from "../../../../../Routing/routes";
import Loader from "../../../../../components/Loader/Loader";
import { getMakePaymentSave } from "../../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import { getPermission } from "../../../../../utils/helpers/getPermissions";
import * as permissions from "../../../../../Routing/permissions";

export const PaymentOptions = ({
  invoiceDetails,
  loginData,
  loading,
  updateLoader,
  invid,
  data,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState("cheque");
  // const [paymentSuccess, setpaymentSuccess] = useState();

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
  };

  const handleSubmit = (values) => {
    let sendRequest = {
      invid: parseInt(invid),
      ...{
        amount: values?.amount || "",
        bankid: values?.bankId || "",
        date: values?.date || "",
        transaction_number: values?.transaction_number || "",
        cheque_number: values?.cheque_number || "",
        bankname: values?.bankname || "",
        branchname: values?.branchname || "",
        paymentdate: values?.paymentdate || "",
        remark: values?.remark || "",
        cleardate: values?.cleardate || "",
        modeofpayment: values?.modeofpayment || "",
      },
      loginType: loginData?.user_type,
      societyId: loginData?.default_community?.community_id,
      loginId:
        loginData?.default_community?.tenant_id ||
        loginData?.default_community?.subscriber_id ||
        loginData?.default_community?.emp_id ||
        loginData?.user_id,
      onSuccessCallback: (result) => {
        navigate(MAKE_PAYMENT_SUCCESS_DEBIT_NOTE_ENDPOINT, {
          state: result.data,
        });
        console.log("Payment Successfull", result);
      },
    };
    dispatch(getMakePaymentSave(sendRequest));
  };

  useEffect(() => {
    if (paymentMethod === "onlinePayment" && data?.paymentLink) {
      window.location.href = data.paymentLink;
    }
  }, [paymentMethod, data]);

  const paymentOptions = [
    { name: "paymentDetails", value: "cheque", label: "Cheque" },
    { name: "paymentDetails", value: "neft", label: "NEFT" },
    { name: "paymentDetails", value: "cash", label: "Cash" },
    { name: "paymentDetails", value: "onlinePayment", label: "Online Payment" },
  ];
  console.log("sdasdasdas");
  return (
    <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
      <div className=" border-b border-[#CCC] rounded-tl-lg rounded-tr-lg pb-2 bg-[#FDF2F3] flex justify-between">
        <h1 className="items-center p-2 -mb-2 text-base font-semibold">
          Enter Payment Details
        </h1>
        <div className="flex items-center mr-40">
          <TextRadio
            label=""
            options={paymentOptions}
            defaultSelected="cheque"
            onChange={handlePaymentMethodChange}
            className={"w-52 whitespace-nowrap"}
          />
        </div>
      </div>
      {(loginData?.user_type == "Society"
        ? true
        : !getPermission(loginData, [
            permissions.HIDE_CHEQUE_PAYMENT_DEBIT_NOTE,
          ])) &&
        paymentMethod === "cheque" && (
          <ChequeDetailsForm
            invoiceDetails={invoiceDetails}
            handleSubmit={handleSubmit}
            invid={invid}
            updateLoader={updateLoader}
            loginData={loginData}
          />
        )}
      {(loginData?.user_type == "Society"
        ? true
        : !getPermission(loginData, [
            permissions.HIDE_NEFT_PAYMENT_DEBIT_NOTE,
          ])) &&
        paymentMethod === "neft" && (
          <NEFTDetailsForm
            invoiceDetails={invoiceDetails}
            handleSubmit={handleSubmit}
            invid={invid}
            updateLoader={updateLoader}
            loginData={loginData}
          />
        )}
      {(loginData?.user_type == "Society"
        ? true
        : !getPermission(loginData, [
            permissions.HIDE_CASH_PAYMENT_DEBIT_NOTE,
          ])) &&
        paymentMethod === "cash" && (
          <CashDetailsForm
            invoiceDetails={invoiceDetails}
            handleSubmit={handleSubmit}
            invid={invid}
            updateLoader={updateLoader}
            loginData={loginData}
          />
        )}
    </div>
  );
};

export const EditPaymentOptions = ({
  invoiceDetails,
  loginData,
  loading,
  updateLoader,
  invid,
  data,
  repaymentMethod,
}) => {
  const [paymentMethod, setPaymentMethod] = useState("CASH");

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    let sendRequest = {
      invid: parseInt([invid]),
      payid: parseInt(data?.paymentInvoiceId),
      resonType: repaymentMethod,
      ...{
        amount: values?.amount || "",
        bankid: values?.bankId || "",
        date: values?.date || "",
        transaction_number: values?.transaction_number || "",
        cheque_number: values?.cheque_number || "",
        bankname: values?.bankname || "",
        branchname: values?.branchname || "",
        paymentdate: values?.paymentdate || "",
        remark: values?.remark || "",
        cleardate: values?.cleardate || "",
        modeofpayment: values?.modeofpayment || "",
      },
      loginType: loginData?.user_type,
      societyId: loginData?.default_community?.community_id,
      loginId:
        loginData?.default_community?.tenant_id ||
        loginData?.default_community?.subscriber_id ||
        loginData?.default_community?.emp_id ||
        loginData?.user_id,
      onSuccessCallback: (result) => {
        navigate(MAKE_PAYMENT_SUCCESS_DEBIT_NOTE_ENDPOINT, {
          state: result.data,
        });
        console.log("Payment Successfull", result);
      },
    };
    console.log(sendRequest);
    dispatch(getMakePaymentSave(sendRequest));
  };

  // useEffect(() => {
  //   if (paymentMethod === "onlinePayment") {
  //     dispatch(getFacilityPaymentLink({
  //       societyId: loginData?.default_community?.community_id,
  //       amount: invoiceDetails?.data?.invoicedetail?.grandtotal?.amount2,
  //       invoiceId: invid,
  //     }));
  //   }
  // }, [paymentMethod, invoiceDetails, dispatch, loginData?.default_community?.community_id, invid]);

  useEffect(() => {
    if (paymentMethod === "onlinePayment" && data?.paymentLink) {
      window.location.href = data.paymentLink;
    }
  }, [paymentMethod, data]);

  useEffect(() => {
    if (invoiceDetails?.data?.modeofpayment)
      setPaymentMethod((invoiceDetails?.data?.modeofpayment).toUpperCase());
  }, [invoiceDetails]);

  const paymentOptions = [
    { name: "paymentDetails", value: "CHEQUE", label: "Cheque" },
    { name: "paymentDetails", value: "NEFT", label: "NEFT" },
    { name: "paymentDetails", value: "CASH", label: "Cash" },
    { name: "paymentDetails", value: "onlinePayment", label: "Online Payment" },
  ];

  return (
    <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg mt-3">
      <div className=" border-b border-[#CCC] rounded-tl-lg rounded-tr-lg pb-2 bg-[#FDF2F3] flex justify-between">
        <h1 className="items-center p-2 -mb-2 text-base font-semibold">
          Enter Payment Details
        </h1>
        <div className="flex items-center mr-40">
          <TextRadio
            label=""
            options={paymentOptions}
            defaultSelected={paymentMethod}
            onChange={handlePaymentMethodChange}
            className={"w-52 whitespace-nowrap"}
          />
        </div>
      </div>
      {(loginData?.user_type == "Society"
        ? true
        : !getPermission(loginData, [
            permissions.HIDE_CHEQUE_PAYMENT_DEBIT_NOTE,
          ])) &&
        paymentMethod === "CHEQUE" && (
          <EditChequeDetailsForm
            invoiceDetails={invoiceDetails}
            handleSubmit={handleSubmit}
            invid={invid}
            updateLoader={updateLoader}
            loginData={loginData}
          />
        )}
      {(loginData?.user_type == "Society"
        ? true
        : !getPermission(loginData, [
            permissions.HIDE_NEFT_PAYMENT_DEBIT_NOTE,
          ])) &&
        paymentMethod === "NEFT" && (
          <EditNEFTDetailsForm
            invoiceDetails={invoiceDetails}
            handleSubmit={handleSubmit}
            invid={invid}
            updateLoader={updateLoader}
            loginData={loginData}
          />
        )}
      {(loginData?.user_type == "Society"
        ? true
        : !getPermission(loginData, [
            permissions.HIDE_CASH_PAYMENT_DEBIT_NOTE,
          ])) &&
        paymentMethod === "CASH" && (
          <EditCashDetailsForm
            invoiceDetails={invoiceDetails}
            handleSubmit={handleSubmit}
            invid={invid}
            updateLoader={updateLoader}
            loginData={loginData}
          />
        )}
    </div>
  );
};

export default PaymentOptions;
