import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
import parse from "html-react-parser";
import { getDebitNoteInterestCalculationReport } from "../../../../redux/actions/ReportActions/FinanceReportActions";

const PrintDebitNoteInterestCalculationReport = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [headCells, setHeadCells] = useState([]);

  const { loginData } = useSelector((state) => state.loginReducer);
  const { debitNoteInterestCalculationtReport, loading } = useSelector(
    (state) => state.financeReportReducer
  );

  const getTableData = () => {
    dispatch(
      getDebitNoteInterestCalculationReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("subscriber_name") || "",
        invoiceType: searchParams.get("invoiceType") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        export: 0,
      })
    );
  };

  useEffect(() => {
    getTableData();

    const afterprint = (e) => {
      e.preventDefault();
    };

    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    };
  }, []);

  useEffect(() => {
    if (!loading && debitNoteInterestCalculationtReport?.data?.length > 0) {
      setTimeout(function () {
        window.print();
      }, 2000);
    }
  }, [dispatch]);

  useEffect(() => {
    if (debitNoteInterestCalculationtReport?.data?.length)
      setHeadCells(
        Object.entries(
          debitNoteInterestCalculationtReport.data.slice(-1)[0]
        )?.map((item) => ({
          label: item[0],
          id: item[0].replaceAll(" ", "_"),
          width: "130px",
        }))
      );
  }, [debitNoteInterestCalculationtReport]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        // <>{parse(chequeDepositSlipReport)}</>
        <div className="m-1">
          {headCells?.length > 0 && (
            <table className="table-auto  w-full mx-auto border-collapse border  text-center border-black break-all ">
              <caption className="caption-top  mt-4 text-center mb-4">
                <h1
                  style={{
                    textDecoration: "underline",
                    fontSize: "22px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {loginData?.default_community?.community_name}
                </h1>
                <div className="text-[11px] ">
                  (REG No.{loginData?.default_community?.regn_no} Date
                  {loginData?.default_community?.date_of_regn})
                </div>
                <div className="text-[13px] ">
                  {loginData?.default_community?.office_address}
                </div>
                {/* <div className="font-extrabold text-lg mb-3  text-start">
                Receipt Report From: {searchParams.get("startDate") || "-"} To:{" "}
                {searchParams.get("endDate") || "-"}
              </div> */}
              </caption>
              <thead className="">
                <tr className="bg-[#dcdcdc] font-semibold">
                  {headCells?.length &&
                    headCells?.map(
                      (item, index) =>
                        index != headCells?.length - 1 && (
                          <td
                            className="text-[13px] p-2 font-bold border border-black"
                            key={item?.id}
                            width={item?.width}
                          >
                            {item?.label}
                          </td>
                        )
                    )}
                </tr>
              </thead>
              <tbody>
                {debitNoteInterestCalculationtReport?.data?.length &&
                  debitNoteInterestCalculationtReport?.data?.map(
                    (row, rowIndex) => (
                      <React.Fragment key={rowIndex}>
                        {headCells.length && (
                          <>
                            <tr className="text-[#222] text-[12px] ">
                              {headCells?.map((item, headIndex) => {
                                return (
                                  item?.label != "Child Data" && (
                                    <td className="p-2 border border-black align-top">
                                      {!headIndex
                                        ? rowIndex + 1
                                        : row?.[item?.label]}
                                    </td>
                                  )
                                );
                              })}
                            </tr>
                            {row?.["Child Data"] && (
                              <>
                                <tr className="text-[#222] text-[12px] ">
                                  <td colspan={headCells.length}>
                                    {parse(row?.["Child Data"])}
                                  </td>
                                </tr>
                                <tr className="bg-[#dcdcdc] text-[14px] font-semibold">
                                  <td
                                    colspan={headCells.length - 3}
                                    className="text-end"
                                  >
                                    Total Interest Amount(Rounded Off)
                                  </td>
                                  <td colspan={3}>
                                    {row?.["Calculated Interest"]}
                                  </td>
                                </tr>
                              </>
                            )}
                          </>
                        )}
                      </React.Fragment>
                    )
                  )}
              </tbody>
            </table>
          )}
          {!debitNoteInterestCalculationtReport?.data?.length && !loading && (
            <div className="text-[11px] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PrintDebitNoteInterestCalculationReport;
