import React from "react";
import { DatePickerComponentFM } from "../../../../../components/DatePicker/DatePicker";
import { TextAreaFM } from "../../../../../components/InputFields/TextArea";
import { TextInputFM } from "../../../../../components/InputFields/TextInput";
import Button from "../../../../../components/Button/Button";
import { Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { CashSchema } from "../../../../../validationSchema/financeSchema/billingManagementSchema";

export const CashDetailsForm = ({
  invoiceDetails,
  updateLoader,
  loginData,
  handleSubmit: pHandleSubmit,
}) => {
  const handleSubmit = (values) => {
    pHandleSubmit(values);
  };

  return (
    <div className="flex flex-col justify-between w-full gap-5 mt-2">
      <Formik
        initialValues={{
          amount: Math.round(invoiceDetails?.data?.due) || "",
          paymentdate: "",
          cleardate: "",
          remark: "",
          modeofpayment: "CASH",
        }}
        enableReinitialize
        validationSchema={CashSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="pl-2 font-semibold">
                <span>Cash Details</span>
              </div>
              <div className="w-[50%] p-2">
                <div className="flex items-center justify-between">
                  <div className="-mt-4 text-sm">Amount</div>
                  <div className="">
                    <TextInputFM
                      label=""
                      placeholder="Enter Amount"
                      className="w-56"
                      name="amount"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Payment Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM
                      className="w-56 h-9"
                      name={`paymentdate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `cleardate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                        formik.setFieldValue(
                          `paymentdate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={
                        formik.values.paymentdate
                          ? new Date(formik.values.paymentdate)
                          : null
                      }
                      minDate={new Date(invoiceDetails?.data?.invoice_date)}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Payment Clear Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM
                      className="w-56 h-9"
                      name={`cleardate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `cleardate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={
                        formik.values.cleardate
                          ? new Date(formik.values.cleardate)
                          : null
                      }
                      readOnly={true}
                    />
                  </div>
                </div>
                <div>
                  <TextAreaFM
                    label="Remarks"
                    placeholder="Enter Remarks"
                    className="w-56 h-20"
                    name="remark"
                    maxLength={300}
                  />
                </div>
                <div className="ml-[250px] mb-2">
                  <Button
                    label={
                      <span className="inline-block w-16">
                        {updateLoader ? (
                          <CircularProgress sx={{ color: "white" }} size={17} />
                        ) : (
                          "Submit"
                        )}
                      </span>
                    }
                    className="h-8 px-5"
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export const EditCashDetailsForm = ({
  invoiceDetails,
  updateLoader,
  loginData,
  handleSubmit: pHandleSubmit,
}) => {
  const handleSubmit = (values) => {
    pHandleSubmit(values);
  };

  return (
    <div className="flex flex-col justify-between w-full gap-5 mt-2">
      <Formik
        initialValues={{
          amount:
            Math.round(invoiceDetails?.data?.payments?.[0]?.grandtotal) || "",
          paymentdate: invoiceDetails?.data?.payment_date || "",
          cleardate: invoiceDetails?.data?.clear_date || "",
          remark: invoiceDetails?.data?.remarks || "",
          modeofpayment: "CASH",
        }}
        enableReinitialize
        validationSchema={CashSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="pl-2 font-semibold">
                <span>Cash Details</span>
              </div>
              <div className="w-[50%] p-2">
                <div className="flex items-center justify-between">
                  <div className="-mt-4 text-sm">Amount</div>
                  <div className="">
                    <TextInputFM
                      label=""
                      placeholder="Enter Amount"
                      className="w-56"
                      name="amount"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Payment Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM
                      className="w-56 h-9"
                      name={`paymentdate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `paymentdate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={
                        formik.values.paymentdate
                          ? new Date(formik.values.paymentdate)
                          : null
                      }
                      minDate={new Date(invoiceDetails?.data?.invoice_date)}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-sm">Payment Clear Date</span>
                  <div className="flex gap-3">
                    <DatePickerComponentFM
                      className="w-56 h-9"
                      name={`cleardate`}
                      onDateChange={(selectedDate) => {
                        formik.setFieldValue(
                          `cleardate`,
                          moment(selectedDate).format("YYYY-MM-DD")
                        );
                      }}
                      defaultValue={
                        formik.values.cleardate
                          ? new Date(formik.values.cleardate)
                          : null
                      }
                    />
                  </div>
                </div>
                <div>
                  <TextAreaFM
                    label="Remarks"
                    placeholder="Enter Remarks"
                    className="w-56 h-20"
                    name={`remark`}
                    maxLength={300}
                  />
                </div>
                <div className="ml-[250px] mb-2">
                  <Button
                    label={
                      <span className="inline-block w-16">
                        {updateLoader ? (
                          <CircularProgress sx={{ color: "white" }} size={17} />
                        ) : (
                          "Submit"
                        )}
                      </span>
                    }
                    className="h-8 px-5"
                    type="submit"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CashDetailsForm;
