import React, { useEffect, useRef, useState } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import Dropdown2, { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import {
  BookingFrequencyTypeOptions,
  TimeSlotSelectedDaysOptions,
  makeBookingYearOptionsForCalander,
  monthOptions,
} from "../../../consts/DropDownOptions";
import { DatePickerComponentFM } from "../../../components/DatePicker/DatePicker";
import BookingCalender from "../../../components/BookingCalender/BookingCalender";
import { getBookingList } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import { ApproveIcon, ClosedIcon, CrossIcon, RightIcon } from "../../../assets";
import {
  checkAvailibility,
  clearcheckAvailibility,
  cleargetAvailableTickets,
  getAvailableTickets,
  getFacilityList,
  getRateChart,
  getTimeSlotList,
} from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { ErrorMessage, Form, Formik } from "formik";
import { MakeBookingValidation } from "../../../validationSchema/ClubSchema/BookAFacilitySchema";
import Loader from "../../../components/Loader/Loader";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import { MAKE_PAYMENT_BOOKINGS_ENDPOINT } from "../../../Routing/routes";
import { notify } from "../../../utils/notification";
import { TimeSelectorFM2 } from "../../../components/TimeSelector/TimeSelector";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";
import {
  addHoursInTimeString,
  addHoursMinutesInTimeString,
  getDateObjectFromTimeString,
} from "../../../utils/helpers/universalFunctions";
import TextRadio from "../../../components/InputFields/TextRadio";
import RateChartModal from "../../../components/Modal/RateChartModal";

const slotTypeRadioOptions = [
  { name: "slotType", value: "timeRange", label: "Select Time Slot" },
  { name: "slotType", value: "hours", label: "Custom" },
];

const MakeBooking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [values, setValues] = useState({});
  const { loginData } = useSelector((state) => state.loginReducer);
  const {
    loading,
    loadingRateChart,
    loadingTimeSlot,
    availabilityError,
    availabilityData,
    availableTicketsData,
    rateChart,
    facilityList,
    timeSlotList,
  } = useSelector((state) => state.bookAFacilityReducer);
  const [modal, setModal] = useState(false);
  const [availableDays, setAvailableDays] = useState([]);
  const [advanceBooking, setAdvanceBooking] = useState(0);
  const [calendarData, setCalendarData] = useState("");

  const handleValidate = () => {
    if (
      values.slotId &&
      values.frequency &&
      values.fromTime &&
      values.toTime &&
      (values.fromDate || values.dateOfBooking) &&
      parseInt(values.noOfBooking)
    )
      dispatch(
        checkAvailibility({
          ...values,
          societyId: loginData?.default_community?.community_id,
          facilityId: id,
        })
      );
  };

  const handleTicketCount = () => {
    if (
      values.slotId &&
      values.frequency &&
      values.fromTime &&
      values.toTime &&
      (values.fromDate || values.dateOfBooking)
    )
      dispatch(
        getAvailableTickets({
          ...values,
          societyId: loginData?.default_community?.community_id,
          facilityId: id,
        })
      );
  };

  const handleTimeSlot = () => {
    if (
      values.frequency &&
      values.slotType == 'timeRange' &&
      (values.fromDate || values.dateOfBooking)
    ) {
      dispatch(
        getTimeSlotList({
          ...values,
          facilityId: id,
        })
      );
    } else if (values.slotType == 'hours') dispatch(getTimeSlotList({ facilityId: id }));
  };

  const handleSubmit = (values, { resetForm }) => {
    // if(values.slotHours && values.slotHours<= (calculateHourDifference(values.fromTime, values.toTime))){
    //   notify('warning', `Allowed Time is ${values.slotName}. and You are booking for ${calculateHourDifference(values.fromTime, values.toTime)?.toFixed(1)} Hours `);

    // }else
    if (
      availabilityData !== null &&
      !availabilityError &&
      !!values.noOfBooking?.length > 0 &&
      !!parseInt(values.noOfBooking)
    ) {
      if (availabilityData?.booking_left < parseInt(values.noOfBooking))
        notify(
          "warning",
          `Only ${availabilityData?.booking_left} Booking Left. Please Enter Correct value.`
        );
      else
        navigate(
          {
            pathname: `${MAKE_PAYMENT_BOOKINGS_ENDPOINT}/${id}`,
          },
          {
            state: {
              ...values,
              facilityId: id,
              facilityName: facilityList?.facilty_list?.find(
                (item) => item.id?.toString() === id
              )?.facilities_name,
            },
          }
        );
    }
  };

  useEffect(() => {
    dispatch(getRateChart({ facilityId: id }));
    dispatch(
      getFacilityList({ societyId: loginData?.default_community?.community_id })
    );
    dispatch(getTimeSlotList({ facilityId: id }));

    return () => {
      dispatch(cleargetAvailableTickets());
      dispatch(clearcheckAvailibility());
    };
  }, []);

  useEffect(() => {
    const unavilDate = facilityList?.facilty_list?.find((item) => item.id?.toString() === id)?.unavailableDates;

    if (unavilDate) {
      const dateArray = unavilDate.split(',').map(date => {
        const [day, month, year] = date.split('/');
        return new Date(`${year}-${month}-${day}`);
      });

      setAvailableDays(dateArray)
    }
    const calendarFrequency = facilityList?.facilty_list?.find((item) => item.id?.toString() === id).calendar
    if (calendarFrequency) {
      setCalendarData(facilityList?.facilty_list?.find((item) => item.id?.toString() === id).calendar)
    }
    const advance = facilityList?.facilty_list?.find((item) => item.id?.toString() === id)?.advance_booking_window;
    if (advance) {
      setAdvanceBooking(advance)
    }
  }, [facilityList])

  useEffect(() => {
    handleValidate();
  }, [values]);

  useEffect(() => {
    handleTicketCount();
  }, [values])

  useEffect(() => {
    handleTimeSlot();
  }, [values.frequency, values.slotType, values.fromDate, values.dateOfBooking]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            frequency: "",
            dateOfBooking: "",
            fromDate: "",
            toDate: "",
            weekDays: [],
            applicableDays: "",
            slotType: "timeRange",
            slotName: "",
            slotId: "",
            slotHours: "",
            fromTime: "",
            toTime: "",
            noOfBooking: "",
            userGuidance: "",
          }}
          enableReinitialize
          validationSchema={MakeBookingValidation}
          onSubmit={handleSubmit}
          innerRef={(formikActions) =>
            formikActions ? setValues(formikActions.values) : setValues({})
          }
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <div className="border-b border-[#CCC]">
                <ListHeaderBack
                  title={`Make Booking ${
                    !loading && facilityList?.facilty_list
                      ? `- ${
                          facilityList?.facilty_list?.find(
                            (item) => item.id?.toString() === id
                          )?.facilities_name
                        }`
                      : ""
                  }`}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ButtonG
                    label="Cancel"
                    type="button"
                    className="h-8"
                    onClick={() => navigate(-1)}
                  />
                  <Button label="Proceed" type="submit" className="h-8" />
                </ListHeaderBack>
              </div>
              {loading || loadingRateChart ? (
                <Loader />
              ) : (
                <div className="w-full flex">
                  <div className="w-1/2 min-h-[500px] border-r border-[#CCC]">
                    <div className="flex justify-between gap-4 mt-4">
                      <table className="border-separate">
                        <tr>
                          <td className="text-sm w-56 pt-2 align-top">
                            Booking Frequency
                          </td>
                          <td>
                            <div>
                              <DropdownFM2
                                options={
                                  advanceBooking > 0 || calendarData
                                    ? [{ value: "One Time", label: "One Time" }]
                                    : facilityList?.facilty_list?.find(
                                      (item) => item.id?.toString() === id
                                    )?.chargeable === "Yes"
                                      ? BookingFrequencyTypeOptions?.filter(
                                        (item) =>
                                          rateChart?.some(
                                            (obj) =>
                                              obj.booking_type === item.value
                                          )
                                      )
                                      : BookingFrequencyTypeOptions
                                }
                                placeholder="Select Frequency"
                                className="text-[11px] ms-4"
                                width="208px"
                                height="36px"
                                onSelect={(selectedItem) => {
                                  setFieldValue(`dateOfBooking`, "");
                                  setFieldValue(`fromDate`, "");
                                  setFieldValue(`toDate`, "");
                                  setFieldValue(`weekDays`, []);
                                  setFieldValue(
                                    `frequency`,
                                    selectedItem.label
                                  );
                                }}
                                {...(values.frequency === "" && {
                                  value: null,
                                })}
                                name={`frequency`}
                              />
                            </div>
                          </td>
                        </tr>
                        {values.frequency &&
                          values.frequency === "One Time" && (
                            <tr>
                              <td className="text-sm w-56 pt-2 align-top">
                                Date Of Booking
                              </td>
                              <td>
                                <DatePickerComponentFM
                                  className="w-52 h-9 justify-between"
                                  onDateChange={(selectedDate) => {
                                    setFieldValue(
                                      "dateOfBooking",
                                      moment(selectedDate).format("YYYY-MM-DD")
                                    );
                                  }}
                                  name="dateOfBooking"
                                  defaultValue={
                                    values.dateOfBooking
                                      ? new Date(values.dateOfBooking)
                                      : null
                                  }
                                  unavailableDate={availableDays}
                                  minDate={new Date()}
                                  maxDate={advanceBooking > 0 && new Date().setDate(new Date().getDate() + (advanceBooking - 1))}
                                />
                              </td>
                            </tr>
                          )}
                        {values.frequency &&
                          values.frequency !== "One Time" && (
                            <>
                              <tr>
                                <td className="text-sm w-56 pt-2 align-top">
                                  From
                                </td>
                                <td>
                                  <DatePickerComponentFM
                                    className="w-52 h-9 justify-between"
                                    onDateChange={(selectedDate) => {
                                      if (
                                        new Date(selectedDate).getTime() >
                                        new Date(values.toDate).getTime()
                                      )
                                        setFieldValue("toDate", null);

                                      setFieldValue(
                                        "fromDate",
                                        moment(selectedDate).format(
                                          "YYYY-MM-DD"
                                        )
                                      );
                                    }}
                                    name="fromDate"
                                    defaultValue={
                                      values.fromDate
                                        ? new Date(values.fromDate)
                                        : null
                                    }
                                    unavailableDate={availableDays}
                                    minDate={new Date()}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="text-sm w-56 pt-2 align-top">
                                  To
                                </td>
                                <td>
                                  <DatePickerComponentFM
                                    className="w-52 h-9 justify-between"
                                    onDateChange={(selectedDate) => {
                                      setFieldValue(
                                        "toDate",
                                        moment(selectedDate).format(
                                          "YYYY-MM-DD"
                                        )
                                      );
                                    }}
                                    name="toDate"
                                    defaultValue={
                                      values.toDate
                                        ? new Date(values.toDate)
                                        : null
                                    }
                                    unavailableDate={availableDays}
                                    readOnly={!values.fromDate}
                                    minDate={new Date(values.fromDate)}
                                  />
                                </td>
                              </tr>
                            </>
                          )}
                        <tr>
                          <td className="text-sm w-56 align-top">Slot Type</td>
                          <td>
                            <div className="mb-4">
                              <TextRadio
                                label=""
                                className="w-52 text-xs whitespace-nowrap"
                                options={slotTypeRadioOptions}
                                defaultSelected={values.slotType}
                                onChange={(value) => {
                                  setFieldValue("slotType", value);
                                  setFieldValue("fromTime", "");
                                  setFieldValue("slotId", "");
                                  setFieldValue("slotHours", "");
                                  setFieldValue("slotName", "");
                                  setFieldValue("toTime", "");
                                  setFieldValue("applicableDays", "");
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2 align-top">
                            Time Slot
                          </td>
                          <td>
                            <div>
                              <DropdownFM2
                                loading={loadingTimeSlot}
                                options={timeSlotList?.slots
                                  ?.filter(
                                    (item) =>
                                      item.slot_type === values.slotType &&
                                      (item.applicabledays == "" ||
                                        (values.dateOfBooking
                                          ? item.applicabledays
                                            .split(",")
                                            .includes(
                                              new Date(values.dateOfBooking)
                                                .toString()
                                                .split(" ")[0]
                                            )
                                          : true))

                                    //     &&
                                    // rateChart
                                    //   ?.filter(
                                    //     (obj) =>
                                    //       obj.booking_type == values.frequency
                                    //   )
                                    //   ?.map((item) => Number(item?.time_slot))
                                    //   .includes(item?.id)
                                  )
                                  ?.map((item) => ({
                                    label: item.slot,
                                    value: item.id,
                                    slotType: item.slot_type,
                                    startTime: item.start_time,
                                    endTime: item.end_time,
                                    hours: item.slot_hours,
                                    applicableDays: item.applicabledays,
                                  }))}
                                placeholder="Select Slot"
                                className=""
                                width="208px"
                                height="37.6px"
                                onSelect={(selectedItem) => {
                                  setFieldValue(
                                    "slotType",
                                    selectedItem.slotType
                                  );
                                  setFieldValue("slotId", selectedItem.value);
                                  setFieldValue(
                                    "slotHours",
                                    selectedItem.hours ? selectedItem.hours : ""
                                  );
                                  setFieldValue("slotName", selectedItem.label);
                                  setFieldValue(
                                    "fromTime",
                                    selectedItem.slotType === "hours"
                                      ? ""
                                      : selectedItem.startTime
                                  );
                                  setFieldValue(
                                    "toTime",
                                    selectedItem.slotType === "hours"
                                      ? ""
                                      : selectedItem.endTime
                                  );
                                  setFieldValue(
                                    "applicableDays",
                                    selectedItem.applicableDays
                                  );
                                }}
                                name={"slotId"}
                                {...(values.slotId === "" && { value: null })}
                                disabled={
                                  !values.fromDate && !values.dateOfBooking
                                }
                              />
                            </div>
                          </td>
                        </tr>
                        {availableTicketsData && values.frequency === "One Time" &&
                          <tr>
                            <td className="text-sm w-56 py-2 align-top">
                              Available Tickets:
                            </td>
                            <td>
                              <div className=" text-sm">
                                <div>
                                  {availableTicketsData.avlbl_ticket_count}
                                </div>
                              </div>
                            </td>
                          </tr>
                        }
                        {values.frequency === "Weekly" &&
                          values.slotId &&
                          values.slotType === "timeRange" && (
                            <tr>
                              <td className="text-sm mt-2 w-56 pt-2.5 align-top">
                                Book Every
                              </td>
                              <td>
                                {(!values.applicableDays
                                  ? TimeSlotSelectedDaysOptions
                                  : TimeSlotSelectedDaysOptions?.filter(
                                    (item) =>
                                      values.applicableDays.includes(
                                        item.value
                                      )
                                  )
                                ).map((day) => (
                                  <button
                                    type="button"
                                    key={day.value}
                                    className={`px-1 py-1 text-xs rounded-md ${
                                      values.weekDays.includes(day.value)
                                        ? "bg-red-650 text-white"
                                        : "bg-gray-200 text-gray-700"
                                      } mx-1`}
                                    onClick={() => {
                                      if (values.weekDays?.includes(day.value))
                                        setFieldValue(
                                          `weekDays`,
                                          values.weekDays?.filter(
                                            (item) => item != day.value
                                          )
                                        );
                                      else
                                        setFieldValue(`weekDays`, [
                                          ...values.weekDays,
                                          day.value,
                                        ]);
                                    }}
                                  >
                                    {day.label}
                                  </button>
                                ))}
                                <div className="text-red-500 text-xs italic h-4">
                                  <ErrorMessage name={"weekDays"} />
                                </div>
                              </td>
                            </tr>
                          )}
                        {values.slotType === "hours" && values.slotId && (
                          <>
                            <tr>
                              <td className="text-sm w-56 pt-2 align-top">
                                Start Time
                              </td>
                              <td>
                                <div>
                                  <TimeSelectorFM2
                                    name={`fromTime`}
                                    width={"208px"}
                                    height={"39px"}
                                    setTime={(value) => {
                                      if (
                                        moment(
                                          values.fromDate ||
                                          values.dateOfBooking
                                        ).isSame(
                                          moment().format("YYYY-MM-DD")
                                        ) &&
                                        new Date().getMinutes() > 30 &&
                                        new Date().getHours() ===
                                        new Date(value).getHours()
                                      )
                                        setFieldValue(
                                          `fromTime`,
                                          moment(new Date(value)).format(
                                            "HH:45"
                                          )
                                        );
                                      else
                                        setFieldValue(
                                          `fromTime`,
                                          moment(new Date(value)).format(
                                            "HH:mm"
                                          )
                                        );
                                      setFieldValue(`toTime`, "");
                                    }}
                                    minutesStep={15}
                                    time={getDateObjectFromTimeString(
                                      values.fromTime
                                    )}
                                    minTime={
                                      moment(
                                        values.fromDate || values.dateOfBooking
                                      ).isSame(moment().format("YYYY-MM-DD"))
                                        ? getDateObjectFromTimeString(
                                          `${new Date().getMinutes() >= 45
                                            ? `${new Date().getHours() + 1
                                            }:00`
                                            : `${new Date().getHours()}:00`
                                          }`
                                        )
                                        : ""
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                        {values.fromTime && values.slotType === "hours" && (
                          <tr>
                            <td className="text-sm w-56 pt-2 align-top">
                              End Time
                            </td>
                            <td>
                              <div>
                                <TimeSelectorFM2
                                  width={"208px"}
                                  height={"39px"}
                                  setTime={(value) => {
                                    // if (
                                    //   values.fromTime?.split(":")?.[0] ===
                                    //   moment(new Date(value)).format("HH")
                                    // )
                                    //   setFieldValue(
                                    //     `toTime`,
                                    //     moment(new Date(value)).format("HH:15")
                                    //   );
                                    // else if (
                                    //   addHoursInTimeString(
                                    //     values.fromTime,
                                    //     parseInt(values.slotHours)
                                    //   ) ===
                                    //   moment(new Date(value)).format("HH:mm")
                                    // )
                                    //   setFieldValue(
                                    //     `toTime`,
                                    //     moment(new Date(value)).format("HH:15")
                                    //   );
                                    // else
                                    setFieldValue(
                                      `toTime`,
                                      moment(new Date(value)).format("HH:mm")
                                    );
                                  }}
                                  name={`toTime`}
                                  minutesStep={15}
                                  time={getDateObjectFromTimeString(
                                    values.toTime
                                  )}
                                  maxTime={
                                    timeSlotList?.slots
                                      ?.find(
                                        (item) => item.id == values?.slotId
                                      )
                                      ?.slot.split(" ")[0] == "less"
                                      ? parseInt(
                                        values.fromTime?.split(":")?.[0]
                                      ) +
                                        parseInt(values.slotHours) <
                                        24
                                        ? getDateObjectFromTimeString(
                                          addHoursMinutesInTimeString(
                                            values.fromTime,
                                            values.slotHours
                                          )
                                        )
                                        : ""
                                      : ""
                                  }
                                  minTime={
                                    timeSlotList?.slots
                                      ?.find(
                                        (item) => item.id == values?.slotId
                                      )
                                      ?.slot.split(" ")[0] == "less"
                                      ? getDateObjectFromTimeString(
                                        `${parseInt(
                                          values.fromTime?.split(":")?.[1]
                                        ) >= 45
                                          ? `${parseInt(
                                            values.fromTime?.split(
                                              ":"
                                            )?.[0]
                                          ) + 1
                                          }:00`
                                          : `${parseInt(
                                            values.fromTime?.split(
                                              ":"
                                            )?.[0]
                                          )}:${parseInt(
                                            values.fromTime?.split(
                                              ":"
                                            )?.[1]
                                          ) + 15
                                          }`
                                        }`
                                      )
                                      : getDateObjectFromTimeString(
                                        addHoursMinutesInTimeString(
                                          values.fromTime,
                                          values.slotHours
                                        )
                                      )
                                  }
                                  isDisabled={
                                    (parseInt(
                                      values.fromTime?.split(":")?.[0]
                                    ) == 23 &&
                                      parseInt(
                                        values.fromTime?.split(":")?.[1]
                                      ) >= 45
                                      ? true
                                      : false) ||
                                    (timeSlotList?.slots
                                      ?.find(
                                        (item) => item.id == values?.slotId
                                      )
                                      ?.slot.split(" ")[0] != "less"
                                      ? addHoursMinutesInTimeString(
                                        values.fromTime,
                                        values.slotHours
                                      )
                                        ?.split(":")
                                        .map(Number)
                                        .reduce((prevEl, newEl) => {
                                          return prevEl + newEl;
                                        }, 0) <
                                      values.fromTime
                                        ?.split(":")
                                        .map(Number)
                                        .reduce((prevEl, newEl) => {
                                          return prevEl + newEl;
                                        }, 0)
                                      : false)
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                        <tr className="align-top">
                          <td className="text-sm w-56 pt-2 align-top">
                            No. of Bookings
                          </td>
                          <td>
                            <div className="flex">
                              <TextInputFM
                                label=""
                                placeholder="Enter"
                                className="w-28 h-9"
                                type="number"
                                name="noOfBooking"
                              />
                              {availabilityData !== null &&
                                !!values.noOfBooking?.length > 0 &&
                                !!parseInt(values.noOfBooking) && (
                                  <div
                                    className={`${availabilityData
                                        ? "text-green-500 fill-green-500"
                                        : "text-red-500 fill-red-500"
                                      } flex mt-3 text-xs ms-2`}
                                  >
                                    {" "}
                                    {availabilityData ? (
                                      <ApproveIcon />
                                    ) : (
                                      <ClosedIcon />
                                    )}{" "}
                                    &nbsp;{" "}
                                    {!availabilityData
                                      ? "Not Available"
                                      : "Available"}
                                  </div>
                                )}
                            </div>
                            {availabilityError && values.noOfBooking && (
                              <div
                                className={
                                  "text-red-500 fill-red-500 flex mb-2 text-xs"
                                }
                              >
                                {" "}
                                {availabilityError}{" "}
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-sm w-56 pt-2 align-top">
                            User Guidance
                          </td>
                          <td>
                            <div className=" text-xs">
                              {facilityList?.facilty_list?.find((item) => item.id?.toString() === id)?.usage_guidance}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="p-2 flex justify-between">
                      <div>
                        {!loading && facilityList?.facilty_list
                          ? facilityList?.facilty_list?.find(
                            (item) => item.id?.toString() === id
                          )?.facilities_name
                          : ""}
                      </div>
                      {facilityList?.facilty_list?.find(
                        (item) => item.id?.toString() === id
                      )?.chargeable === "Yes" && (
                          <Button
                            label="View Rate Chart"
                            type="button"
                            className="h-8"
                            onClick={() => setModal(true)}
                          />
                        )}
                    </div>
                    <div>
                      {getPermission(loginData, [
                        permissions.VIEW_BOOKING_CALENDER_PERMISSION,
                      ]) && <CalenderComp />}
                    </div>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
        {!loading && (
          <div className="flex gap-3 my-2">
            <span className="text-sm text-[#222] whitespace-nowrap">
              Usage Guidance:
            </span>
            <span className="text-sm text-[#AAA] text-left">
              Hover on date to see details
            </span>
          </div>
        )}
      </div>
      {modal && (
        <RateChartModal
          isOpen={modal}
          onClose={() => setModal(false)}
          faciltyName={
            facilityList?.facilty_list?.find(
              (item) => item.id?.toString() === id
            )?.facilities_name
          }
          loading={loadingRateChart}
        />
      )}
    </Layout>
  );
};

export const CalenderComp = () => {
  const [date, setDate] = useState(new Date());
  const [calenderData, setCalenderData] = useState([]);
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { id } = useParams();
  const { loading, bookingList, facilityList } = useSelector(
    (state) => state.viewBookingReducer
  );

  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      page: 0,
      facilityId: id,
      year: date.getFullYear()?.toString(),
      month: (date.getMonth() + 1)?.toString(),
    };
    dispatch(getBookingList(data));
  };

  useEffect(() => {
    getTableData();
  }, [date]);

  useEffect(() => {
    let data = [];

    bookingList?.booking_list?.forEach((item) => {
      if (item.status === "A" || item.status === "UP") {
        if (moment(item.from_date)?.isSame(item?.to_date)) {
          let bookingDate = moment(item.from_date)?.format("DD-MM-YYYY");
          let existingDateIndex = data?.findIndex(
            (item) => item.date === bookingDate
          );

          if (existingDateIndex >= 0) {
            data[existingDateIndex].entries = [
              ...data[existingDateIndex].entries,
              {
                name: item?.user_name,
                status: item.status,
                time: `${moment(item?.start_time, "hh:mm:ss")?.format(
                  "hh:mm A"
                )} - ${moment(item?.end_time, "hh:mm:ss")?.format("hh:mm A")}`,
              },
            ];
          } else {
            data.push({
              date: moment(item.from_date)?.format("DD-MM-YYYY"),
              entries: [
                {
                  name: item?.user_name,
                  status: item.status,
                  time: `${moment(item?.start_time, "hh:mm:ss")?.format(
                    "hh:mm A"
                  )} - ${moment(item?.end_time, "hh:mm:ss")?.format(
                    "hh:mm A"
                  )}`,
                },
              ],
            });
          }
        } else {
          const start = new Date(item.from_date);
          const end = new Date(item.to_date);

          for (
            let date = start;
            date <= end;
            date.setDate(date.getDate() + 1)
          ) {
            let bookingDate = moment(date)?.format("DD-MM-YYYY");
            let existingDateIndex = data?.findIndex(
              (item) => item.date === bookingDate
            );

            if (existingDateIndex >= 0) {
              data[existingDateIndex].entries = [
                ...data[existingDateIndex].entries,
                {
                  name: item?.user_name,
                  status: item.status,
                  time: `${moment(item?.start_time, "hh:mm:ss")?.format(
                    "hh:mm A"
                  )} - ${moment(item?.end_time, "hh:mm:ss")?.format(
                    "hh:mm A"
                  )}`,
                },
              ];
            } else {
              data.push({
                date: moment(date)?.format("DD-MM-YYYY"),
                entries: [
                  {
                    name: item?.user_name,
                    status: item.status,
                    time: `${moment(item?.start_time, "hh:mm:ss")?.format(
                      "hh:mm: A"
                    )} - ${moment(item?.end_time, "hh:mm:ss")?.format(
                      "hh:mm: A"
                    )}`,
                  },
                ],
              });
            }
          }
        }
      }
    });
    setCalenderData(data);
  }, [bookingList]);

  return (
    <>
      <div className="p-2 flex justify-between items-center">
        <div className="text-sm gap-4 flex">
          <span className="text-[#03C708] flex items-center">
            <span className="mr-2">
              <RightIcon />
            </span>{" "}
            Approved
          </span>
          <span className="text-red-650 flex items-center">
            <span className="mr-2">
              <CrossIcon />
            </span>
            Unapproved
          </span>
        </div>
        <div className="flex gap-4 items-center my-3">
          {/* Year Dropdown */}
          <Dropdown2
            options={makeBookingYearOptionsForCalander}
            width="100px"
            height="36px"
            placeholder={date.getFullYear()}
            onSelect={(selectedItem) => {
              const newDate = new Date(date);
              newDate.setFullYear(parseInt(selectedItem.value));
              setDate(newDate);
            }}
            value={{
              label: date.getFullYear(),
              value: date.getFullYear(),
            }}
          />

          {/* Month Dropdown */}
          <Dropdown2
            options={monthOptions}
            width="100px"
            height="36px"
            placeholder={date.toLocaleString("en-US", { month: "long" })}
            onSelect={(selectedItem) => {
              const newDate = new Date(date);
              newDate.setDate(1);
              newDate.setMonth(parseInt(selectedItem.value) - 1);
              setDate(newDate);
            }}
            value={{
              label: monthOptions?.find(
                (item) =>
                  item.value ===
                  (date.getMonth() + 1)?.toString()?.padStart(2, "0")
              )?.label,
              value: (date.getMonth() + 1)?.toString()?.padStart(2, "0"),
            }}
          />
        </div>
      </div>

      <div className="ms-2">
        <div className="border-t border-x text-center border-[#DDDDDD] py-2 font-semibold">
          {moment(date).format("MMMM YYYY")}
        </div>
        <BookingCalender
          selectedDate={date}
          calenderData={calenderData}
          handleDateChange={undefined}
        />
      </div>
    </>
  );
};

export default MakeBooking;
