import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import styled from "@emotion/styled";

const headCells = [
  { id: "Date", label: "Date", width: "100px", },
  { id: "jv_no", label: "J.V. No.	", width: "120px" },
  { id: "type", label: "Entry Type", width: "80px" },
  { id: "	narration", label: "	Narration", sort: true, width: "250px" },
  { id: "dr_amount", label: "Dr Amount", sort: true, width: "100px" ,textAlign : 'right'},
  { id: "cr_amount", label: "Cr Amount", sort: true, width: "100px" ,textAlign : 'right' },
  { id: "balance", label: "Balance", sort: true, width: "80px" , textAlign : 'right'},
];
const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      zIndex: 0,
      color: "#AAAAAA",
      fontSize: "13px",
      padding: "12px 8px 12px 0",
      wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
     // borderBottom: "none",
      fontSize: "13px",
      padding: "12px 0px 12px 5px",
      wordBreak: "break-word",
      cursor: "pointer",
      
  },
}));
const MemberLedgerReportsTable = ({
  data,
}) => {
  return (
    <Box sx={{ width: "100%", fontSize: "10px"  }} className="table-component border-t-1" >
      {data &&
        data.map((item) => (
          <div className="py-2 border-t border-[#CCC] ">
            <div className="w-full  pb-1 text-lg font-bold align-middle text-center bg-[#CF001C]  text-white mb-2">{item.accountNm}({item.code})</div>
            {
              item.ledger ?
                <TableContainer
                  className="border-t border-[#CCC]  "
                  sx={{ minHeight: data?.length ? "100px" : "none",padding : "5" }}
                >
                  <Table  aria-label="sticky table" >
                    <TableHead>
                      <TableRow>
                        {headCells?.length
                          ? headCells?.map((column) => (   
                              <TableCell
                                sx={{
                                  padding : "5px",
                                  fontWeight: "bold",
                                  width: column.width || "fit-content",
                                  minWidth: column.width || "fit-content",
                                  textAlign : column.textAlign 
                                }}
                              >
                                {column.label}
                              </TableCell>
                          )) : null} 
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.ledger.length > 0 ? item.ledger.map((row) => (
                        <TableRow key={row.id} >
                          <TableCellStyled>
                            {row.date}
                          </TableCellStyled>
                          <TableCellStyled>
                            {row.jv_no}
                          </TableCellStyled>
                          <TableCellStyled  >
                            {row.type}
                          </TableCellStyled>
                          <TableCellStyled>
                            {row.narration}
                          </TableCellStyled>
                          <TableCellStyled  sx={{textAlign:"right"}}>
                            {row.dr_amount || ''}
                          </TableCellStyled>
                          <TableCellStyled sx={{textAlign:"right"}}>
                            {row.cr_amount || ''}
                          </TableCellStyled>
                          <TableCellStyled sx={{textAlign:"right"}}>
                            {row.balance || ''}
                          </TableCellStyled>
                        </TableRow>
                      )) :  <TableRow>
                      <TableCellStyled
                          sx={{ border: "none", textAlign: "center" }}
                          colSpan={headCells?.length + 1}
                      >
                         No Recodes Found
                      </TableCellStyled>
                  </TableRow>}
                    </TableBody>
                  </Table>
                </TableContainer>
                : <div>No recoder Found</div>
            }
          </div>
        ))


      }
    </Box>
  )
}
export default MemberLedgerReportsTable;