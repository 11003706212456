import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../pages/Layout/Layout";
import { Arrow } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { auditLogContentManagement } from "../../../redux/actions/SetupRecordsActions/ContentManagementActions/ContentManagementAction";
import moment from "moment";

const Accordion = ({ label, isOpen, toggleAccordion, oldContent, newContent, desc, descr }) => {
  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center">
          <button
            className="text-white text-base font-semibold mr-3 w-5 h-5 bg-red-650 
              border rounded flex justify-center items-center"
          >
            {isOpen ? (
              <FontAwesomeIcon icon={faChevronUp} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="xs" />
            )}
          </button>
          <div className="text-[#000000] text-sm font-semibold">{label}</div>
        </div>
        <div>
          <div className="text-xs text-right text-[#888]">{desc}</div>
          <div className="text-xs text-right text-[#888]">{descr}</div>
        </div>
      </div>

      {isOpen && (
        <>
          <div className="w-full text-sm p-2 font-bold bg-red-650 text-center text-white">New Content</div>
          <div className="w-full p-2 border">
            <div dangerouslySetInnerHTML={{ __html: newContent }} />
          </div>
          <div className="w-full text-sm p-2 font-bold bg-red-650 text-center text-white ">Old Content</div>
          <div className="w-full p-2 border">
            <div dangerouslySetInnerHTML={{ __html: oldContent }} />
          </div>
        </>
      )}
    </div>
  );
};

const ContentManagementTemplateAudit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { data, auditLoading } = useSelector((state) => state.contentReducer); // Use auditLoading from state
  const navigate = useNavigate();
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  useEffect(() => {
    const societyid = loginData?.default_community?.community_id;
    dispatch(
      auditLogContentManagement({
        id: id,
        societyid: societyid,
      })
    );
  }, [dispatch, id, loginData]);

  const handleNavigation = () => {
    navigate(-1);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="flex gap-5 pb-2">
          <div className="flex w-1/2">
            <button
              onClick={handleNavigation}
              className="text-sm p-2 text-red-650 font-semibold flex gap-2 justify-center items-center"
            >
              <span>
                <Arrow />
              </span>
              Back
            </button>
            <span className="border-r border-[#CCCCCC]"></span>
            <h1 className="text-sm font-semibold p-2">Audit Logs</h1>
          </div>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        
        {auditLoading ? ( 
          <div className="flex justify-center items-center py-6">
            <div className="loader border-t-transparent border-solid rounded-full animate-spin border-red-650 border-4 h-8 w-8"></div>
            <span className="ml-2 text-red-650">Loading audit logs...</span>
          </div>
        ) : (
          <div>
            {data?.length > 0 ? (
              data.map((audit, index) => (
                <Accordion
                  label={`Audit ${index + 1}`}
                  key={index}
                  oldContent={audit.old_specific_details}
                  newContent={audit.new_specific_details}
                  isOpen={index === openAccordionIndex}
                  toggleAccordion={() => toggleAccordion(index)}
                  desc={`Modified By ${audit?.modified_by} On ${moment(
                    new Date(audit?.modified_dateTime)
                  ).format("MMM DD, YYYY hh:mm A")}`}
                  descr={`IP Address: ${audit?.IpAddress}. Session ID: ${audit?.modified_sessionId}`}
                />
              ))
            ) : (
              <div className="text-center py-4 text-[#999999] flex justify-center">
                No Audits
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ContentManagementTemplateAudit;
