import * as Yup from "yup";

  export const CancellationValidation = () => {
    return Yup.object({
      reason: Yup.string().required('*Required')
    });
  };
  
  export const ChangeComplaintStatusValidation = () => {
    return Yup.object({
      status: Yup.string().required('*Required'),
      comment:Yup.string().required('*Required')
    });
  };

  export const PaymentAndAdjustmentValidation = () => {
    return Yup.object({
      date: Yup.string().required('*Required'),
      creditAccount: Yup.string().required('*Required'),
      debitAccountId: Yup.string().required('*Required'),
      amount: Yup.string().required('*Required'),
      remarks: Yup.string(),
    });
  };
  
  export const PaymentAndAdjustmentValidationInvoice = () => {
    return Yup.object({
      date: Yup.string().required('*Required'),
      creditAccount: Yup.string().required('*Required'),
      debitac: Yup.string().required('*Required'),
      amount: Yup.string().required('*Required'),
      remarks: Yup.string(),
    });
  };


  export const ChequePaymentValidation = () => {
    return Yup.object({
      amount: Yup.string().required('*Required'),
      remarks: Yup.string().required('*Required'),
    });
  };