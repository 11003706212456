import * as Yup from "yup";

export const addMoreHeadValidation = () => {
  return Yup.object({
    headName: Yup.string().required("*Required"),
    glCode: Yup.string().required("*Required"),
    type: Yup.string().required("*Required"),
    amount: Yup.number().typeError("*Must be number"),
    formula: Yup.string().when(['type'],(type,schema) => {
      if(type[0]==="normal"){
        return schema.required("*Required")
      }else if(type[0]==="utility"){
        return schema.required("*Required")
      }else{
        return schema.nullable()
      }
    }),
  });
};

export const addUtilitySlabsValidation = () => {
  return Yup.object({
    rangeType : Yup.string().required("*Required"),
    range1 : Yup.string().when(['rangeType'],(rangeType,schema) => {
      return rangeType[0]==="less" || rangeType[0]==="greater" || rangeType[0]==="range"  ? schema.required("*Required") : schema.nullable();
    }),
    range2 : Yup.string().when(['rangeType'],(rangeType,schema) => {
      return  rangeType[0]==="range"  ? schema.required("*Required") : schema.nullable();
    }),
    charge : Yup.number().required("*Required"),
  });
};

export const addInvoiceTypeValidation = () => {
  return Yup.object({
    title: Yup.string().required("*Required"),
    cat: Yup.string().required("*Required"),
    freq: Yup.string().required("*Required"),
  });
};

export const addInvoiceSeriesValidation = () => {
  return Yup.object({
    invoiceType: Yup.string().required("*Required"),
    fromDate: Yup.date().required("*Required"),
    toDate: Yup.date().required("*Required"),
    startNumber: Yup.number().typeError("*Must be number").required("*Required"),
    prefix: Yup.string(),
    suffix: Yup.string(),
    isZero: Yup.boolean(),
    numDigit: Yup.string().when(['isZero'],(isZero,schema) => {
      return isZero[0] ? schema.required("*Required") : schema.nullable();
    }),
  });
};
export const addTaxableSubHeadValidation = () => {
  return Yup.object({
    mainHeadId: Yup.number().required("*Required"),
    subHeadId: Yup.number().required("*Required"),
    type: Yup.number().required("*Required"),
    active: Yup.boolean(),
  });
};

export const headSettingValidation = () => {
   return Yup.object({
    headDetail : Yup.array().of(
      Yup.object().shape({
        active : Yup.string().nullable(),
        invoice_head : Yup.string().nullable(),
        id : Yup.string().nullable(),
        //mainerror : Yup.boolean(),
        //tariff_header : Yup.string().required("*Required"),
        tariff_header : Yup.string().when(['active'],(active,schema) => {
          return active[0]==="Yes" ? schema.required("*Required") : schema.nullable();
        }),
        order : Yup.number().when(['active','id'],(active,schema) => {
          //console.log(active)
          return active[0]==="Yes" && active[1]!=="roundoff" ? schema.required("*Required") : schema.nullable();
        })
      //   .test(
      //     'unique',
      //     'Only unique values allowed.',
      //     (value) => {
      //       if (!value) return true;
      //       return unique.length === value.length;
      // }
      //   )
        ,
        // .test("orderCheck", "Enter Right Order", function (value) {
        //   return !this.parent.mainerror && parseInt(value) != 0 
        //   //return false;
        // })
        //,
        gl_code : Yup.string().when('invoice_head', {
          alternatively: (val) => (val !== "Past Advances & Dues" && val !== "Principal Arrears" && val !== "Interest Arrears" && val !== 'Debitnote Arrears') ,// alternatively: (val) => val == true
          then: (schema) => schema.required("*Required"),
          otherwise: (schema) => schema.nullable(),
        }),
       
      })
      
    )
  });
};
