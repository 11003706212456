import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance2 from '../../../axiosInstance2'
import {logOut} from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';


export async function getMasterHeadDetailsReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('wing', action.data?.wingId);

    return axiosInstance2.post(apiEndpoints.GET_MASTER_HEAD_DETAILS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateMasterHeadDetailssReq(action) {
    const BODY ={
        'pageid': action.data?.societyId,
        'wingno': action.data?.wingId,
        'wingstatus': action.data?.wingStatus,
        'useTax': action.data?.useAnnualPropertyTax,
        'annual_property_tax': action.data?.annualPropertyTax,
        'built_up_area': action.data?.buildUpArea,
        'no_of_flats': action.data?.noOfFlats,
        'annual_water_charges': action.data?.annualWaterCharge,
        'no_of_water_inlets': action.data?.noOfInlets,
        'annual_security_charges': action.data?.annualSecurityCharge,
        'total_house_keeping': action.data?.totalHouseKeeping,
        'annual_lift_maintenance': action.data?.totalAnnualLiftMaintenance,
        'electricity_charges': action.data?.electricityCharge,
        'construction_cost': action.data?.constructionCost,
        'sinking_fund': action.data?.sinkingFund,
        'annual_insurance_charges': action.data?.insuranceCharge,
        'annual_lease_rent': action.data?.leaseRent,
        'annual_agricultural_tax': action.data?.nonAgriculturalTax,
        'repairs_maintenance': action.data?.repairFund,
        'other_funds': action.data?.otherFunds,
        'service_charges': action.data?.serviceCharges,
      }

    return axiosInstance2.post(apiEndpoints.UPDATE_MASTER_HEAD_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
