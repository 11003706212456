import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
// import { getChequeDepositSlipReport } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import parse from "html-react-parser";
import { getInvoiceReport } from "../../../../redux/actions/ReportActions/FinanceReportActions";
import moment from "moment";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
const invoice = [
  { label: "Invoice", value: "invoice" },
  { label: "Drafts Invoice", value: "draftsInvoice" },
];
const PrintInoviceReport = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [headCells, setHeadCells] = useState([]);
  const [total, setTotal] = useState([]);

  const { loginData } = useSelector((state) => state.loginReducer);
  const { invoiceReport, loading } = useSelector(
    (state) => state.financeReportReducer
  );

  const getTableData = () => {
    dispatch(
      getInvoiceReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("memberName") || "",
        invoiceType: searchParams.get("invoiceType") || "",
        invoice: searchParams.get("invoice") || invoice[0]?.value,
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        export: 0,
      })
    );
  };

  useEffect(() => {
    getTableData();

    const afterprint = (e) => {
      e.preventDefault();
    };

    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    };
    setHeadCells([]);
    setTotal([]);
  }, []);

  useEffect(() => {
    if (!loading && invoiceReport?.data?.length > 0) {
      setTimeout(function () {
        window.print();
      }, 2000);
    }
  }, [dispatch]);

  useEffect(() => {
    if (invoiceReport?.data?.length) {
      setHeadCells(
        Object.entries(invoiceReport?.data[0])
          ?.map((item) => item[0])
          ?.map((item, index) => {
            if (index > 5) {
              let total = invoiceReport.data.reduce(
                (prevVal, newVal) => {
                  return (Number(prevVal) + Number(newVal?.[item])).toFixed(2);
                },
                [0]
              );
              // if (total != 0) {
              return {
                label: item,
                id: item.replaceAll(" ", "_"),
                sort: true,
                width: "130px",
              };
              // }
            } else {
              return {
                label: item,
                id: item.replaceAll(" ", "_"),
                sort: true,
                width: "130px",
              };
            }
          })
          .filter((item) => item)
      );
    } else {
      setTotal([]);
    }
  }, [invoiceReport]);
  useEffect(() => {
    if (headCells?.length) {
      let totalCount = {};
      headCells.map((item, index) => {
        totalCount[item?.label] = invoiceReport.data.reduce(
          (prevVal, newVal) => {
            return index > 5 &&
              !["Cheque No.", "Transaction No.", "Bank Name"].includes(
                item?.label
              )
              ? isNaN(Number(newVal?.[item?.label]))
                ? ""
                : (Number(prevVal) + Number(newVal?.[item?.label])).toFixed(2)
              : index == 0
              ? "Total"
              : "";
          },
          [0]
        );
      });
      totalCount = [...invoiceReport?.data, totalCount];
      setTotal(totalCount);
    }
  }, [headCells]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        // <>{parse(chequeDepositSlipReport)}</>
        <div className="m-1">
          <table className="table-auto w-full mx-auto border-collapse border border-black">
            <caption className="caption-top  mt-4 text-center">
              <h1
                style={{
                  textDecoration: "underline",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {loginData?.default_community?.community_name}
              </h1>
              <div className="text-[11px] ">
                (REG No.{loginData?.default_community?.regn_no} Date{" "}
                {moment(
                  new Date(loginData?.default_community?.date_of_regn)
                )?.format("DD-MM-YYYY")}
                )
              </div>
              <div className="text-[13px] ">
                {loginData?.default_community?.office_address}
              </div>
              <div className="font-extrabold text-lg mb-3  text-start">
                Invoice Report From:{" "}
                {searchParams.get("startDate")
                  ? moment(new Date(searchParams.get("startDate")))?.format(
                      "DD-MM-YYYY"
                    )
                  : "-"}{" "}
                To:{" "}
                {searchParams.get("endDate")
                  ? moment(new Date(searchParams.get("endDate")))?.format(
                      "DD-MM-YYYY"
                    )
                  : "-"}
              </div>
            </caption>
            <thead>
              <tr>
                {headCells.length &&
                  headCells?.map((item) => (
                    <td
                      className="text-[11px] p-1 font-semibold border border-black"
                      key={item?.id}
                      style={{ minWidth: item.width || "fit-content" }}
                    >
                      {item?.label}
                    </td>
                  ))}
              </tr>
            </thead>
            <tbody>
              {total?.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  {headCells.length && (
                    <tr className="text-[#222] text-[11px]">
                      {headCells?.map((item, headIndex) => {
                        return (
                          <td
                            className={`${
                              rowIndex + 1 == total?.length
                                ? " !font-bold text-sm"
                                : ""
                            } p-1 border border-black align-top`}
                          >
                            {/* {(index + 1)?.toString()?.padStart(2, 0)} */}
                            {row[item?.label]}
                          </td>
                        );
                      })}
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          {!total?.length && !loading && (
            <div className="text-[11px] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PrintInoviceReport;
