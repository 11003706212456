import React, { useEffect } from "react";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Layout from "../../Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ViewBookingsDetailsTable from "./ViewBookingsDetailsTable";
import ButtonG from "../../../components/Button/ButtonG";
import Button from "../../../components/Button/Button";
import { useDispatch } from "react-redux";
import { changeBookingStatus, getBookingDetails, getBookingStatusList } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { CircularProgress } from "@mui/material";

export const bookingDetails = [
  {
    key: "user_name",
    label: "Name",
    value: "HImanshi Mehta",
  },
  {
    key: "wing_flat",
    label: "Wing & Flat No.",
    value: "B-Block - 102",
  },
  {
    key: "booking_purpose",
    label: "Purpose Of Booking",
    value: "N/A",
  },
  {
    key: "booking_frequency",
    label: "Booking Frequency",
    value: "Daily",
  },
  {
    key: "date_of_booking",
    label: "Date Of Booking",
    value: "16-03-2024 - 17-03-2024",
  },
  {
    key: "timeSlot",
    label: "Time Slot",
    value: "07:00 AM to 12:00 PM (27)",
  },
  {
    key: "no_of_booking",
    label: "No Of Booking",
    value: "1",
  },
  {
    key: "dueAmount",
    label: "Due Amount",
    value: "0.00",
  },
  {
    key: "security_deposit",
    label: "Security Deposit",
    value: "100.00",
  },
  {
    key: "total_amount",
    label: "Total Amount",
    value: "599.00",
  },
  {
    key: "status",
    label: "Status",
    value: "Approved",
  },
];

export const headCells = [
  { id: "sNo", label: "Sr. No.", width: "70px" },
  { id: "date", label: "Date Of Payment", width: "100px" },
  { id: "mode", label:"Payment Mode", sort: true, width: "120px" },
  { id: "amountPaid", label: "Amount Paid", sort: true, width: "150px" },
  { id: "amountDue", label: "Amount Due", sort: true, width: "170px" },
];

export const rows = [
  {
    sNo: 1,
    date: "2024-03-12",
    amountPaid: "1000.00",
    amountDue: "1231231230.00",
  },
];

function ViewBookingDetails() {
  const navigate = useNavigate();
  const {id} = useParams()
  const dispatch = useDispatch()
  const {loading, bookingDetailsData, bookingStatusList} = useSelector(state=>state.viewBookingReducer)

  const hancleChangeStatus = () =>{
    dispatch(changeBookingStatus({
      bookingId: id, 
      status: 'A',
      reason: '',
      type: 'view'
    }))
  }

  useEffect(()=>{
    dispatch(getBookingStatusList())
    dispatch(getBookingDetails({bookingId: id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            title="View Booking Details"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ButtonG
              label="Cancel"
              className="h-8"
              onClick={() => {
                navigate(-1);
              }}
            />
            {
              bookingDetailsData?.status === 'UP' &&
              <Button label={
                <span className="w-16 inline-block">
                  {
                  loading ?
                    <CircularProgress sx={{color: "white"}} size={17}/>
                    :
                    <>Approve</>
                  }
                </span>
                }  
                onClick={hancleChangeStatus} className="h-8" />
            }
          </ListHeaderBack>
        </div>
        <div className="py-1 gap-2 my-1">
          {
            loading ?
            <Loader/>
            :
            <>
              <table className="table-auto w-full">
                <tbody>
                  {bookingDetails?.slice(0, 4)?.map((item) => (
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                        {item.label}:
                      </td>
                      <td className="py-2">{bookingDetailsData?.[item.key] || "-"}</td>
                    </tr>
                  ))}
                  {
                    bookingDetailsData?.booking_frequency === 'Weekly' &&
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                        Book Every:
                      </td>
                      <td className="py-2">{bookingDetailsData?.booking_day?.replaceAll(',', ', ') || "-"}</td>
                    </tr>
                  }
                  {
                    bookingDetailsData?.booking_frequency === 'One Time' ?
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                        Date of Booking:
                      </td>
                      <td className="py-2">{moment(bookingDetailsData?.date_of_booking).format('DD-MM-YYYY') || "-"}</td>
                    </tr>
                    :
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                        Date Range:
                      </td>
                      <td className="py-2">
                        {
                          bookingDetailsData?.booking_frequency ==='One Time' ?
                            moment(bookingDetailsData?.from_date).format('DD-MM-YYYY') :
                            `From: ${moment(bookingDetailsData?.from_date).format('DD-MM-YYYY')} - To: ${moment(bookingDetailsData?.to_date).format('DD-MM-YYYY')}`
                        }
                      </td>
                    </tr>
                  }
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                      Time Slot:
                    </td>
                    <td className="py-2">
                    {
                      bookingDetailsData?.slot_type === 'hours' ?
                      bookingDetailsData?.time_slot
                      :
                      `${moment(bookingDetailsData?.time_slot?.slice(0, 9), 'hh:mm:ss').format('hh:mm A')} - ${moment(bookingDetailsData?.time_slot?.slice(12), 'hh:mm:ss').format('hh:mm A')} (${bookingDetailsData?.facilities_type})`
                    }
                    </td>
                  </tr>
                    {
                      bookingDetailsData?.slot_type === 'hours' &&
                      <tr className="text-[#222222] text-sm">
                        <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                          Time Range:
                        </td>
                        <td className="py-2">
                          {
                          `${moment(bookingDetailsData?.start_time, 'hh:mm:ss').format('hh:mm A')} - ${moment(bookingDetailsData?.end_time, 'hh:mm:ss').format('hh:mm A')} (${bookingDetailsData?.facilities_type})`
                          }
                        </td>
                      </tr>
                    }
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                      No of booking:
                    </td>
                    <td className="py-2">{bookingDetailsData?.no_of_booking?.toString() || "-"}</td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                      No of days:
                    </td>
                    <td className="py-2">{bookingDetailsData?.no_of_day?.toString() || "-"}</td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                      Amount (Rs):
                    </td>
                    <td className="py-2">{bookingDetailsData?.booking_amount?.toString() || "-"}</td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                      Security Deposit:
                    </td>
                    <td className="py-2">{bookingDetailsData?.security_deposit?.toString() || "-"}</td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                      Total Amount (Rs):
                    </td>
                    <td className="py-2">{bookingDetailsData?.total_amount?.toString() || "-"}</td>
                  </tr>
                  <tr className="text-[#222222] text-sm">
                    <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                      Status:
                    </td>
                    <td className="py-2">{bookingStatusList?.find(e=> e.value === bookingDetailsData?.status)?.key || "-"}</td>
                  </tr>
                  {
                    bookingDetailsData?.status === 'C' &&
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                        Cancel By:
                      </td>
                      <td className="py-2">{bookingDetailsData?.closedBy || "-"}</td>
                    </tr>
                  }
                  {
                    bookingDetailsData?.status === 'C' &&
                    <tr className="text-[#222222] text-sm">
                      <td className="px-2.5 w-40 py-2 text-[#AAAAAA] font-bold align-top">
                        Reason for cancellation:
                      </td>
                      <td className="py-2">{bookingDetailsData?.cancel_reason || "-"}</td>
                    </tr>
                  }
                </tbody>
              </table>
              <div>
                <div className="ml-1">
                  <ViewBookingsDetailsTable
                    data={bookingDetailsData?.payment_detail?.map((item, index)=>({
                      sNo: (index+1)?.toString()?.padStart(2, '0'),
                      date: moment(item.date_of_payment)?.format('DD-MM-YYYY'),
                      mode: item.modeofpayment,
                      amountPaid: item.paid_ammount,
                      amountDue: item.due_ammount
                    }))}
                    columns={headCells}
                    checkbox={false}
                    pagination={false}
                  ></ViewBookingsDetailsTable>
                </div>
                {!rows?.length && (
                  <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                    No Records
                  </div>
                )}
              </div>
            </>
          }
          </div>
      </div>
    </Layout>
  );
}

export default ViewBookingDetails;
