import React, { useState,Fragment} from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Button from "../Button/Button";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { INVOICE_SETTINGS_TAXABLE_INVOICE_SUBHEAD_ENDPOINT } from "../../Routing/routes";
import { getPermission } from "../../utils/helpers/getPermissions";
import * as permissions from "../../Routing/permissions";

const TaxableSubHeadList = ({ list,taxableList,setsubHeadTaxableList,handleDelete,deleteId,updateLoader,activeId,handleAction,handleOrderChange,loginData }) => {
  const navigate = useNavigate();
  const headCells = [
    { id: "move", label: "Move" },
    { id: "id", label: "Sr. No." },
    { id: "headerName", label: "Header Name"},
    // { id: "billingCycle", label: "Billing Cycle", sort: false, width: "230px" },
    { id: "action", label: "Action" },
  ];

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    console.log(e);
    let tempData = Array.from(list.sub_details);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setsubHeadTaxableList({...taxableList , sub_details:tempData});
    let result = tempData.map((item) => (item.id));
    handleOrderChange(result)
  };
  return (
    <div className="mt-4 App">
      
    <DragDropContext onDragEnd={handleDragEnd}>
      <TableContainer >
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {headCells?.map((head, index) => (
              <TableCell
               size="small"
               key={head.id + "heading"}
               align={head.numeric ? "right" : "left"}
               sx={{
                 width: head.width || "fit-content",
                 minWidth: head.width || "fit-content",
               }}
             >
               {head.label}
              </TableCell>
              //<TableCell></TableCell>
            ))}
          </TableRow>
        </TableHead>
        <Droppable droppableId="droppable-1">
          {(provider) => (
            <tbody
              className="text-capitalize"
              ref={provider.innerRef}
              {...provider.droppableProps}
            >
              { list?.sub_details.length>0 && list?.sub_details ? list?.sub_details?.map((tax, index) => (
                <Draggable
                  key={`${tax.id}`}
                  draggableId={`${tax.id}`}
                  index={index}
                >
                  {(provider) => (
                  <TableRow {...provider.draggableProps} ref={provider.innerRef}>
                    <TableCell {...provider.dragHandleProps}> = </TableCell>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{tax.name}</TableCell>
                    <TableCell>
                    {getPermission(loginData, [permissions.DELETE_SUB_HEAD_GROUP_SETTING]) && <Button
                          label={
                            <span className="inline-block w-12">
                              {activeId===tax.id ? (
                                <CircularProgress
                                  sx={{ color: "white" }}
                                  size={17}
                                />
                              ) : tax.active ===1 ?("Deactive") : ("Active")
                            }
                            </span>
                          }
                          className="h-8 mr-2"
                          onClick={() => handleAction(tax.id,tax.active===1?false:true)}
                          disabled={updateLoader}
                        />}
                       {getPermission(loginData, [permissions.ACTIVE_SUB_HEAD_GROUP_SETTING]) && <Button
                          label={
                            <span className="inline-block w-12">
                              {deleteId===tax.id ? (
                                <CircularProgress
                                  sx={{ color: "white" }}
                                  size={17}
                                />
                              ) : (
                                "Delete"
                              )}
                            </span>
                          }
                          className="h-8"
                          onClick={() => handleDelete(tax.id)}
                          disabled={updateLoader}
                        />}
                        </TableCell>
                  </TableRow>
                  )}
                </Draggable>
              )) : <></>}
              {provider.placeholder}
            </tbody>
          )}
       </Droppable>
     
        
        </Table>
      </TableContainer>
    </DragDropContext>
    { list?.sub_details.length ===0  ? (
          <div className="text-lg text-[#AAAAAA] mt-2 text-center italic">
            No Records
          </div>
        )
      :<></>}
  </div>
  );
};

export default TaxableSubHeadList;
