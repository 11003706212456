import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getComplaintTypeSetting,
  updateComplaintTypeSetting,
} from "../../../redux/actions/DeskActions/ComplaintCategoryAction";
import { Form, Formik } from "formik";
import { Box, Tab, Tabs, Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import {
  getComplaintEmailSms,
  getComplaintEscalationSettings,
  updateComplaintEmailSms,
} from "../../../redux/actions/DeskActions/DeskAction";
import ListHeader from "../../../components/ListHeader/ListHeader";
import GenericTable from "../../../components/TableComponent/Table";
import { EditIcon } from "../../../assets";
import { EDIT_COMPLAINT_ESCALATION_LEVEL_SETTINGS_ENDPOINT } from "../../../Routing/routes";
import ComplaintSetting from "./ComplaintSetting";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from '../../../Routing/permissions'

function ActionComponent({ id }) {
  const navigate = useNavigate();
  const { loginData } = useSelector((state) => state.loginReducer);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {
        getPermission(loginData, [permissions.EDIT_COMPLAINT_ESCALATION_LEVEL_PERMISSION]) &&
        <Tooltip title="Edit">
          <EditIcon
            className="fill-[#555] hover:fill-red-650 me-3"
            onClick={() => {
              navigate(
                `${EDIT_COMPLAINT_ESCALATION_LEVEL_SETTINGS_ENDPOINT}/${id}`
              );
            }}
          />
        </Tooltip>
      }
    </Box>
  );
}

const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false, width: "130px" },
  {
    id: "escalationLevel",
    label: "Escalation Level",
    sort: false,
    width: "200px",
  },
  { id: "stage", label: "Stage", sort: false, width: "160px" },
  { id: "duration", label: "Duration", sort: false },
  { id: "action", label: "Action", sort: false },
];

const StyledTab = styled(Tab)({
  textTransform: "none",
  fontSize: "15px",
  fontWeight: 500,
  fontFamily: "'Montserrat', sans-serif",
  borderRight: "1px solid #CCC",
  background: "#FDF2F3",

  "&.Mui-selected": {
    color: "#CF001C",
  },
});

const ComplaintGeneralSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { complaintTypeSetting } = useSelector(
    (state) => state.complaintCategoryReducer
    );
  const { loginData } = useSelector((state) => state.loginReducer);
  const { complaintEmailSmsSettings, complaintEscalationList } = useSelector(
    (state) => state.deskReducers
    );
    
  const tabData = [
      {
        index:0,
        label: "Complaint Setting",
        permissionPath: [permissions.VIEW_COMPLAINT_SETTING_PERMISSION]
      },
    {
      index:1,
      label: "Complaint General Settings",
      permissionPath: [permissions.COMPLAINT_TYPE_SETTING_PERMISSION, permissions.COMPLAINT_EMAIL_SMS_SETTING_PERMISSION]
    },
    {
      index:2,
      label: "Complaint Escalation Aging",
      permissionPath: [permissions.VIEW_COMPLAINT_ESCALATION_LEVEL_PERMISSION]
    },
  ]
  const [tabValue, setTabValue] = useState(tabData?.find(item=> getPermission(loginData, item.permissionPath))?.index);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const updateSettings = (e, setFieldValue, featureType, featureTypeName) => {
    setFieldValue("complaintTypeSetting", e.target.checked ? "Yes" : "No");

    dispatch(
      updateComplaintTypeSetting({
        yesNo: e.target.checked ? "Yes" : "No",
        societyId: loginData?.default_community?.community_id,
      })
    );
  };

  const updateSettings1 = (e, featureType, featureTypeName, setFieldValue) => {
    let status;
    if (e.target.checked) {
      setFieldValue(featureType, "Yes");
      status = "Yes";
    } else {
      setFieldValue(featureType, "No");
      status = "No";
    }
    dispatch(
      updateComplaintEmailSms({
        featureTypeName,
        status,
        societyId: loginData?.default_community?.community_id,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getComplaintTypeSetting({
        societyId: loginData?.default_community?.community_id,
      })
    );
    dispatch(
      getComplaintEmailSms({
        societyId: loginData?.default_community?.community_id,
      })
    );
    dispatch(
      getComplaintEscalationSettings({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            complaintTypeSetting:
              complaintTypeSetting?.complaint_type_community,
            assignmentOfComplaintSMS:
              complaintEmailSmsSettings?.assignment_complaint_sms,
            assignmentOfComplaintEmail:
              complaintEmailSmsSettings?.assignment_complaint_email,
            inProgressOfComplaintSMS:
              complaintEmailSmsSettings?.inprogress_complaint_sms,
            inProgressOfComplaintEmail:
              complaintEmailSmsSettings?.inprogress_complaint_email,
          }}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <div className="border-b border-[#CCC]">
                <ListHeader
                  leftContent="Complaint Settings"
                  onClick={() => {
                    navigate(-1);
                  }}
                ></ListHeader>
              </div>

              <div className="border-b border-[#ccc]">
                <Tabs
                  value={tabValue}
                  onChange={handleChangeTab}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#CF001C",
                    },
                  }}
                >
                  {
                    tabData?.map((item, index)=>(
                      getPermission(loginData, item?.permissionPath) ? <StyledTab key={index} value={item.index} label={item.label} /> : ''
                    ))
                  }
                </Tabs>
              </div>

              <div className="">
                {
                  getPermission(loginData, tabData[0]?.permissionPath) &&
                  <TabPanel value={tabValue} index={0}>
                    <div className="mt-2">
                      <ComplaintSetting />
                    </div>
                  </TabPanel>
                }
                {
                  getPermission(loginData, tabData[1]?.permissionPath) &&
                  <TabPanel value={tabValue} index={1}>
                    <div className="py-2 gap-2 m-2">
                      {
                        getPermission(loginData, [permissions.COMPLAINT_TYPE_SETTING_PERMISSION]) &&
                        <div className=" grid grid-cols-2 w-1/2 text-[#222222] text-sm mt-2 mb-4">
                          <div>Show Complaint Type Community</div>
                          <div className="ml-8">
                            <Checkbox
                              text={"Yes"}
                              initialchecked={
                                formik.values.complaintTypeSetting === "Yes"
                              }
                              onChange={(e) => {
                                updateSettings(e, formik.setFieldValue);
                              }}
                            />
                          </div>
                        </div>
                      }
                      {
                        getPermission(loginData, [permissions.COMPLAINT_EMAIL_SMS_SETTING_PERMISSION]) &&
                        <>
                          <div className="grid grid-cols-2 w-1/2 text-sm mb-4">
                            <div className="w-60 py-2 align-top">
                              Assignment Of Complaint (only Members and Tenant)
                            </div>
                            <div className="py-2">
                              <div className="ml-8">
                                <Checkbox
                                  text={"SMS"}
                                  className={"w-28"}
                                  initialchecked={
                                    formik.values.assignmentOfComplaintSMS === "Yes"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    updateSettings1(
                                      e,
                                      "assignmentOfComplaintSMS",
                                      "assignment_complaint_sms",
                                      formik.setFieldValue
                                    );
                                  }}
                                />

                                <Checkbox
                                  text={"Email"}
                                  initialchecked={
                                    formik.values.assignmentOfComplaintEmail === "Yes"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    updateSettings1(
                                      e,
                                      "assignmentOfComplaintEmail",
                                      "assignment_complaint_email",
                                      formik.setFieldValue
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 w-1/2 text-sm">
                            <div className="w-60 py-2 align-top">
                              In progress Of Complaint (only Members and Tenant)
                            </div>
                            <div className="py-2">
                              <div className="ml-8">
                                <Checkbox
                                  text={"SMS"}
                                  className={"w-28"}
                                  initialchecked={
                                    formik.values.inProgressOfComplaintSMS === "Yes"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    updateSettings1(
                                      e,
                                      "inProgressOfComplaintSMS",
                                      "inprogress_complaint_sms",
                                      formik.setFieldValue
                                    );
                                  }}
                                />

                                <Checkbox
                                  text={"Email"}
                                  initialchecked={
                                    formik.values.inProgressOfComplaintEmail === "Yes"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    updateSettings1(
                                      e,
                                      "inProgressOfComplaintEmail",
                                      "inprogress_complaint_email",
                                      formik.setFieldValue
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </TabPanel>
                }
                {
                  getPermission(loginData, tabData[2]?.permissionPath) &&
                  <TabPanel value={tabValue} index={2}>
                    <div className="px-2">
                      <GenericTable
                        data={complaintEscalationList?.map((item, index) => ({
                          sNo: (index + 1)?.toString()?.padStart(2, "0"),
                          id: item.id,
                          escalationLevel: item.level,
                          stage: item.stage,
                          duration: item.duration,
                        }))}
                        columns={headCells}
                        checkbox={false}
                        pagination={false}
                      >
                        <ActionComponent />
                      </GenericTable>
                      {!complaintEscalationList?.length && (
                        <div className="text-sm text-[#AAAAAA] my-4 text-center">
                          No Records
                        </div>
                      )}
                    </div>
                  </TabPanel>
                }
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default ComplaintGeneralSettings;
