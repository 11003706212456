import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getIncompleteTransactionDetails } from '../../../redux/actions/FinanceActions/BillingReportsAction';
import Layout from '../../Layout/Layout';
import { useSelector } from 'react-redux';
import ListHeaderBack from '../../../components/ListHeader/ListHeaderBack';
import Loader from '../../../components/Loader/Loader';

const invoiceDetails = [
    { "key": "propertytax", "label": "Property Tax" },
    { "key": "watercharges", "label": "Water Charges" },
    { "key": "electriccharges", "label": "Common Electricity Charges" },
    { "key": "repairnmaintenance", "label": "Repairs & Maintenance Fund" },
    { "key": "liftmaintenance", "label": "Expenses on Lift Maintenance" },
    { "key": "sinkingfund", "label": "Sinking Fund Contribution" },
    { "key": "housekeeping", "label": "Service Charges- House Keeping" },
    { "key": "officecharges", "label": "Service Charges- Office & Genral Ch" },
    { "key": "printingcharges", "label": "Service Charges- Printing & Stationery" },
    { "key": "parkingcharges", "label": "Parking Charges" },
    { "key": "defaultedcharges", "label": "Interest on the defaulted Charges" },
    { "key": "loancharges", "label": "Repayment of Loan and Interest" },
    { "key": "nonoccupancycharges", "label": "Non Occupancy Charges" },
    { "key": "insurancecharge", "label": "Insurance Charges" },
    { "key": "leaserent", "label": "Lease Rent" },
    { "key": "agriculturaltax", "label": "Non Agricultural Tax" },
    { "key": "otherchargesfn", "label": "Other Charges- Function Ch." },
    { "key": "othercharges", "label": "Other Charges-" },
    { "key": "repairfund", "label": "Major Repair Fund" },
    { "key": "paintingfund", "label": "Painting Fund" },
    { "key": "servicetax", "label": "SGST" },
    { "key": "Sb_Cess", "label": "CGST" },
    { "key": "Kk_Cess", "label": "IGST" },
    { "key": "servicecharge", "label": "Service Charge" },
    { "key": "securitycharges", "label": "Security Charges" },
    { "key": "type", "label": "Invoice" },
    { "key": "watertax", "label": "Water Tax" },
    { "key": "othertax", "label": "Other Tax" },
    { "key": "debitnote", "label": "Debit Note" },
    { "key": "chequereturncharges", "label": "Cheque Return Charges" },
    { "key": "other1", "label": "Other1" },
    { "key": "other2", "label": "Other2" },
    { "key": "other3", "label": "Other3" },
    { "key": "other4", "label": "Other4" },
    { "key": "roundoff", "label": "roundoff" }
]

function ViewIncompleteTransactions() {
    const { id } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { loginData } = useSelector(state => state.loginReducer)
    const { loading, incompleteTransactionDetails } = useSelector(state => state.billingReportsReducer)
    const [totalAmount, setTotalAmount] = useState("0.00");

    useEffect(() => {
        dispatch(getIncompleteTransactionDetails({
            societyId: loginData?.default_community?.community_id,
            id
        }))
    }, [])

    useEffect(() => {
        if (incompleteTransactionDetails) {
            if (incompleteTransactionDetails?.["Items Data"].length > 0) {
                setTotalAmount(incompleteTransactionDetails?.["Items Data"].reduce((acc, detail) => acc + Number(detail?.Amount || 0), 0).toFixed(2))
            } else {
                setTotalAmount(incompleteTransactionDetails?.["Received From"]?.["Amount"])
            }
        }
    }, [incompleteTransactionDetails])

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <div className="border-b border-[#CCC]">
                    <ListHeaderBack title="View Receipt" onClick={() => navigate(-1)}>
                    </ListHeaderBack>
                </div>
                <div className="ml-1 text-sm">
                    {
                        loading ?
                            <Loader />
                            :
                            <table className='w-full mt-2'>
                                <tbody className='border '>
                                    <tr>
                                        <td colspan="2" align="center">
                                            <table className='w-full border border-black border-collapse table-fixed'>
                                                <tbody className='text-sm'>
                                                    <tr>
                                                        <td colspan="2" className='text-center font-bold text-lg uppercase border  border-black p-2'><h2>Transaction Details</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-2 border border-black'>Transaction Number : {incompleteTransactionDetails?.["Transaction No"]}</td>
                                                        <td className='px-2 border border-black' align="right">Transaction Date : {incompleteTransactionDetails?.["Transaction Date"]}	</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-2 border border-black'>Invoice Reference Number : {incompleteTransactionDetails?.["Invoice Reference Number"]}</td>
                                                        <td className='px-2 border border-black' align="right">Invoice Date : {incompleteTransactionDetails?.["Invoice Date"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-2 border border-black'>Member Name : {incompleteTransactionDetails?.["Member Name"]}</td>
                                                        <td className='px-2 border border-black' align="right"> Bill Period : {incompleteTransactionDetails?.["Bill Period"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-2 border border-black'>Unit : {incompleteTransactionDetails?.Unit}</td>
                                                        <td className='px-2 border border-black' align="right">Payment Due Date : {incompleteTransactionDetails?.["Payment Due Date"]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <table className='w-full border border-black border-collapse table-fixed'>
                                                <tbody className='text-sm'>
                                                    <tr style={{ background: '#e6e6e6' }}>
                                                        <th className='px-2 border border-black' align="left">Item</th>
                                                        <th className='px-2 border border-black' align="left">Amount</th>
                                                    </tr>
                                                    {incompleteTransactionDetails?.["Items Data"].map((detail) => (
                                                        <tr>
                                                            <td className='px-2 border border-black'>{detail?.Item}</td>
                                                            <td className='px-2 border border-black'>{detail?.Amount}</td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td className='px-2 border border-black'><b>Sub Total <b></b></b></td>
                                                        <td className='px-2 border border-black'><b>{totalAmount}<b>
                                                        </b></b></td>
                                                    </tr>
                                                    {incompleteTransactionDetails?.["Invoice Arrear"]?.map((detail) => (
                                                        <tr>
                                                            <td className='px-2 border border-black'>{detail?.head}</td>
                                                            <td className='px-2 border border-black'>{detail?.amount}</td>
                                                        </tr>
                                                    ))}

                                                    {/* {incompleteTransactionDetails?.["Items Data"].length > 0 ?
                                                        <tr>
                                                            <td className='px-2 border border-black'><b>Sub Total <b></b></b></td>
                                                            <td className='px-2 border border-black'><b>{totalAmount.toFixed(2)}<b>
                                                            </b></b></td>
                                                        </tr>
                                                        :
                                                        <tr>
                                                            <td className='px-2 border border-black'><b>Sub Total <b></b></b></td>
                                                            <td className='px-2 border border-black'><b>{totalAmount.toFixed(2)}<b>
                                                            </b></b></td>
                                                        </tr>
                                                    } */}
                                                    <tr>
                                                        <td className='px-2 border border-black'>&nbsp;</td>
                                                        <td className='px-2 border border-black'>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-2 border border-black'><strong>Grand Total</strong></td>
                                                        <td className='px-2 border border-black'><strong>{incompleteTransactionDetails?.["Received From"]?.["Amount"]}</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <table className='w-full border border-black border-collapse table-fixed'>
                                                <tbody>
                                                    <tr>
                                                        <td className='px-2 border border-black' align="left">Received From :</td>
                                                        <td className='px-2 border border-black' align="left">{incompleteTransactionDetails?.["Member Name"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-2 border border-black'><b>{incompleteTransactionDetails?.["Received From"]?.["Payment Type"]} Details</b></td>
                                                        <td className='px-2 border border-black'>&nbsp;</td>
                                                    </tr>
                                                    {
                                                        (incompleteTransactionDetails?.["Received From"]?.["Payment Type"] === 'NEFT' || incompleteTransactionDetails?.["Received From"]?.["Payment Type"] === 'Cheque') &&
                                                        <tr>
                                                            <td className='px-2 border border-black'>Bank Name</td>
                                                            <td className='px-2 border border-black'></td>
                                                        </tr>
                                                    }
                                                    {
                                                        (incompleteTransactionDetails?.["Received From"]?.["Payment Type"] === 'NEFT') &&
                                                        <>
                                                            <tr>
                                                                <td className='px-2 border border-black'>Transaction Number</td>
                                                                <td className='px-2 border border-black'>{incompleteTransactionDetails?.["Received From"]?.["Transaction No"]}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='px-2 border border-black'>Transaction Dated</td>
                                                                <td className='px-2 border border-black'>{incompleteTransactionDetails?.["Received From"]?.["Payment Date"]}</td>
                                                            </tr>
                                                        </>
                                                    }

                                                    {
                                                        (incompleteTransactionDetails?.["Received From"]?.["Payment Type"] === 'Cheque') &&
                                                        <>
                                                            {/* <tr>
                                                                <td className='px-2 border border-black'>Branch Name</td>
                                                                <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.branch_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='px-2 border border-black'>Cheque Number</td>
                                                                <td className='px-2 border border-black'>{receiptDetails?.paymentDetail?.cheque_number}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='px-2 border border-black'>Cheque Dated</td>
                                                                <td className='px-2 border border-black'>{moment(receiptDetails?.paymentDetail?.cleardate)?.format('DD-MM-YYYY')}</td>
                                                            </tr> */}
                                                        </>
                                                    }
                                                    {incompleteTransactionDetails?.["Received From"]?.["Payment Type"] === 'Online' &&
                                                    <tr>
                                                        <td className='px-2 border border-black'>Transaction No.</td>
                                                        <td className='px-2 border border-black'>{incompleteTransactionDetails?.["Received From"]?.["Transaction No"]}</td>
                                                    </tr>
                                                    }
                                                    <tr>
                                                        <td className='px-2 border border-black'>Amount</td>
                                                        <td className='px-2 border border-black'>{incompleteTransactionDetails?.["Received From"]?.["Amount"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-2 border border-black'>Payment Date</td>
                                                        {/* <td className='px-2 border border-black'>{moment(receiptDetails?.societyPaymentAuditLog['Payment Date'])?.format('DD-MM-YYYY')}</td> */}
                                                        <td className='px-2 border border-black'>{incompleteTransactionDetails?.["Received From"]?.["Payment Date"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-2 border border-black'>Status</td>
                                                        <td className='px-2 border border-black'>{incompleteTransactionDetails?.["Received From"]?.["Status"]}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td className='px-2 border border-black'>Reason</td>
                                                        <td className='px-2 border border-black'></td>
                                                    </tr> */}

                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default ViewIncompleteTransactions;