import * as types from '../../index.types';

export function contentManagementList(data) {
    return {
        type: types.GET_CONTENT_MANAGEMENT_TEMPLATE,
        data
    };
}

export function updateContentManagement(data) {
    console.log('Action' ,data)
    return {
        type: types.UPDATE_CONTENT_MANAGEMENT_TEMPLATE,
        data
    };
}
export function auditLogContentManagement(data) {
    return {
        type: types.AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE,
        data
    };
}
