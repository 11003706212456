import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../api";
import axiosInstance from "../../../axiosInstance";
import { logOut } from "../../../../utils/helpers/logOut";
import { errorMsg } from "../../../../consts/responseMessages";
import axiosInstance2 from "../../../axiosInstance2";

export async function getSocietyEmailReportReq(action) {
    const BODY = {
        id: action.data?.societyId,
        search_content: action.data?.search_content,
        compsrchwing: action.data?.compsrchwing,
        compflatid: action.data?.compflatid,
        search_from_date: action.data?.search_from_date,
        search_to_date: action.data?.search_to_date,
        status1: action.data?.status1,
        page: action.data?.page,
        limit: action.data?.limit,
        export: action.data?.export
    }
    return axiosInstance2
        .post(
            `${apiEndpoints.GET_SOCIETY_EMAIL_REPORTS_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}


// url needs to be added
export async function downloadSocietyEmailReportReq(action) {
    const BODY = {
        id: action.data?.societyId,
        search_content: action.data?.search_content,
        compsrchwing: action.data?.compsrchwing,
        compflatid: action.data?.compflatid,
        search_from_date: action.data?.search_from_date,
        search_to_date: action.data?.search_to_date,
        status1: action.data?.status1,
        page: action.data?.page,
        limit: action.data?.limit,
        export: action.data?.export
    }
    return axiosInstance2
        .post(
            `${apiEndpoints.GET_SOCIETY_EMAIL_REPORTS_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}







export async function deleteSocietyReportExcelReq(action) {
    const BODY =
    {
        file_name: action?.data?.download_url
    }

    return axiosInstance2
        .post(
            `${apiEndpoints.DELETE_FINANCE_REPORT_EXCEL_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}

export async function getMemberLedgerReportReq(action) {
    const BODY = {
        id: action.data?.societyId,
        typeId: action.data?.invoiceType,
        wingId: action.data?.wingId,
        flatId: action.data?.flatId,
        startDate: action.data?.startDate,
        endDate: action.data?.endDate,
        glCode: action.data?.glCode,
        type : action.data?.type,
        financialYear : action.data?.financialYear,
    }
    return axiosInstance2
        .post(
            `${apiEndpoints.GET_MEMBER_LEDGER_REPORTS_API}`, { ...BODY }
        )
        .then((response) => {
            return response;
        })
        .catch((errors) => {
            console.log("errors", errors);
            if (
                errors.response?.data?.statusCode === 400 &&
                errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data?.message)
            ) {
                logOut();
            } else if (errors.response?.data?.statusCode === 501) {
                notify("warning", errors.response.data?.message);
            }
            return errors;
        });
}
