import React, { useEffect } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import PaymentStructure from "./PaymentStrucutre";
import PaymentMethods from "./PaymentMethods";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getBookingDetails } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import { getFacilityPaymentLink } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { Form, Formik } from "formik";
import TextRadio from "../../../components/InputFields/TextRadio";
import { TextAreaFM } from "../../../components/InputFields/TextArea";

const MakePaymentForBooking = () => {
  const navigate = useNavigate();
  const {id} = useParams()
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {onlinepaymentLinkData} = useSelector(state=>state.bookAFacilityReducer)

  const {loading, bookingDetailsData} = useSelector(state=>state.viewBookingReducer)
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  
  useEffect(()=>{
    dispatch(getBookingDetails({bookingId: id}))
  }, [])

  useEffect(()=>{
    if(bookingDetailsData)
    dispatch(getFacilityPaymentLink({
      societyId: loginData?.default_community?.community_id,
      amount: bookingDetailsData?.total_amount,
      invoiceId: bookingDetailsData?.id
    }))
  }, [bookingDetailsData])

  return (
    <>
      <Layout>
        <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
          <div className="border-b border-[#CCC]">
            <ListHeaderBack
              onClick={handleNavigation(-1)}
              title="Make Payment"
            >
            </ListHeaderBack>
          </div>
          {
            loading ?
            <Loader/>
            :
            bookingDetailsData &&
            <>
              <div className="my-4">
                <PaymentStructure />
              </div>
              <Formik
                initialValues={{
                  purpose: ' ',
                  other: ' ',
                  mode: 'Yes'
                }}
                enableReinitialize
                // validationSchema={BookValidation}
                // onSubmit={handleSubmit}
              >
                {
                  ({values, errors, setFieldValue})=>(
                    <Form>
                      <div>
                        <span className="font-semibold ml-2 mb-4">
                          Please Fill Up the Other Details
                        </span>

                        <table className="table-auto w-full">
                          <tbody>
                            <tr className="text-[#222222] text-sm">
                              <td className="p-2.5 w-52  text-[#AAAAAA] font-bold align-top">
                                Booking Type:
                              </td>
                              <td className="text-sm py-2.5">
                                <TextRadio
                                  options={[
                                    {label: 'Self', value: 'Self'},
                                    {label: 'Other', value: 'Other'},
                                  ]}
                                  defaultSelected={parseInt(bookingDetailsData?.member_id) === (loginData?.default_community?.subscriber_id || loginData?.default_community?.tenant_id) ? 'Self' : 'Other'}
                                  name='type'
                                  disabled={true}
                                />
                              </td>
                            </tr>
                            <tr className="text-[#222222] text-sm">
                              <td className="p-2.5 w-52  text-[#AAAAAA] font-bold align-top">
                                Booking For:
                              </td>
                              <td className="text-sm py-2.5">
                                {bookingDetailsData?.member_type}
                              </td>
                            </tr>
                            <tr className="text-[#222222] text-sm">
                              <td className="p-2.5 w-52  text-[#AAAAAA] font-bold align-top">
                                Wing No. & Flat No.:
                              </td>
                              <td className="text-sm py-2.5">
                                {bookingDetailsData?.wing_flat}
                              </td>
                            </tr>
                            <tr className="text-[#222222] text-sm">
                              <td className="p-2.5 w-52  text-[#AAAAAA] font-bold align-top">
                                {bookingDetailsData?.member_type} Name:
                              </td>
                              <td className="text-sm py-2.5">
                                {bookingDetailsData?.user_name}
                              </td>
                            </tr>
                            <tr className="text-[#222222] text-sm">
                              <td className="px-2.5 w-52 py-1 text-[#AAAAAA] font-bold align-top">
                                Purpose Of Booking:
                              </td>
                              <td>
                                <div>
                                  <TextAreaFM
                                    label=""
                                    placeholder="Enter"
                                    row="4"
                                    className="w-56"
                                    name='purpose'
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr className="text-[#222222] text-sm">
                              <td className="px-2.5 w-52 py-1 text-[#AAAAAA] font-bold align-top">
                                Other:
                              </td>
                              <td>
                                <div>
                                  <TextAreaFM
                                    label=""
                                    placeholder="Enter"
                                    row="4"
                                    className="w-56"
                                    name='other'
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <TextRadio
                                  options={[
                                    // {label: 'Book', value: 'No'},
                                    {label: 'Book & Pay', value: 'Yes'},
                                  ]}
                                  onChange={(value)=>setFieldValue('mode', value)}
                                  defaultSelected={values.mode}
                                  name='mode'
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div>
                        {/* <PaymentMethods /> */}
                        <div className="w-full gap-5 flex flex-col justify-between mt-2">
                          <a className="ml-[250px] mb-2 bg-red-650 text-white text-xs px-3 py-2 align-middle  font-semibold rounded-lg w-fit border-[#941A25] border " href={onlinepaymentLinkData?.payment_link}>
                            Submit
                          </a>
                        </div>
                      </div>
                    </Form>
                  )
                }
              </Formik>
            </>
          }
        </div>
      </Layout>
    </>
  );
};

export default MakePaymentForBooking;
