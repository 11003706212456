import * as Yup from "yup";
export const AddDebitNoteValidation = () => {
  return Yup.object({
    invoiceDate: Yup.string().required("*Required"),
    dueDate: Yup.string().required("*Required"),
    issuedTo: Yup.string().required("*Required"),
    // billType: Yup.string().required("*Required"),
    wingId: Yup.string().required("*Required"),
    flatId: Yup.string().required("*Required"),
    tenantId: Yup.string().required("*Required"),
    nonMemberId: Yup.string().required("*Required"),
    debitAccount: Yup.string().required("*Required"),
    details: Yup.array().of(
      Yup.object().shape({
        amount: Yup.string().required("*Required"),
      })
    ),
  });
};
export const AddMultiDebitNoteValidation = () => {
  return Yup.object({
    draftDebitNoteDetails: Yup.array().of(
      Yup.object().shape({
        invoice_date: Yup.string().required("*Required"),
        due_date: Yup.string().required("*Required"),
        issued_type: Yup.string().required("*Required"),
        // bill_type: Yup.string().required("*Required"),
        wing_id: Yup.string().required("*Required"),
        flat_id: Yup.string().required("*Required"),
        debitor_id: Yup.number().required("*Required"),
        debit_code: Yup.string().required("*Required"),
        head_detail: Yup.array().of(
          Yup.object().shape({
            amount: Yup.string().required("*Required"),

          })
        )
      })
    ),
  });
};

export const AddMultiPaymentValidation = () => {
  return Yup.object({
    invoiceList: Yup.array().of(
      Yup.object({
        modeofpayment: Yup.string().required("*Required Payment Mode"),
        amount: Yup.number().required("*Required Amount").positive(),
        paymentdate: Yup.string().required("*Required payment date"),
        //chequedate: Yup.string().defined().required("*Required"),
        chequedate: Yup.string().when(["modeofpayment"], (modeofpayment, schema) => {
          return modeofpayment[0] === "CHEQUE" || modeofpayment[0] === "NEFT" ? schema.required("*Required Cheque Date") : schema.nullable();
        }),
      }))
  });
};
export const AddCreditNoteValidation = () => {
  return Yup.object({
    memberType: Yup.string().required("*Required"),
    date: Yup.string().required("*Required"),
    voucher: Yup.string(),
    remarks: Yup.string().trim().required("*Required"),
    wingId: Yup.string().required("*Required"),
    flatId: Yup.string().required("*Required"),
    referenceInvoice: Yup.array().of(Yup.string()),
    gstApplicable: Yup.string(),
    sendEmail: Yup.string(),
    sendSMS: Yup.string(),
    details: Yup.array().of(
      Yup.object().shape({
        tariffHeaderId: Yup.string().required("*Required"),
        debitAccountId: Yup.string().required("*Required"),
        hsnCode: Yup.string().trim(),
        serviceGood: Yup.string(),
        amount: Yup.string().required("*Required"),
      })
    ),
  });
};

export const EditCreditNoteValidation = () => {
  return Yup.object({
    date: Yup.string().required("*Required"),
    voucher: Yup.string(),
    remarks: Yup.string().trim().required("*Required"),
    sendEmail: Yup.string(),
    sendSMS: Yup.string(),
    details: Yup.array().of(
      Yup.object().shape({
        tariffHeaderId: Yup.string().required("*Required"),
        debitAccountId: Yup.string().required("*Required"),
        hsnCode: Yup.string(),
        serviceGood: Yup.string(),
        amount: Yup.string().required("*Required"),
      })
    ),
  });
};

export const AddAdvanceNoteValidation = () => {
  return Yup.object({
    memberType: Yup.string().required("*Required"),
    date: Yup.string().required("*Required"),
    voucher: Yup.string(),
    debitAccountId: Yup.string().required("*Required"),
    wingId: Yup.string().when(["memberType"], (memberType, schema) => {
      return memberType[0] === "Member" ? schema.required("*Required") : schema;
    }),
    flatId: Yup.string().when(["memberType"], (memberType, schema) => {
      return memberType[0] === "Member" ? schema.required("*Required") : schema;
    }),
    vendorId: Yup.string().when(["memberType"], (memberType, schema) => {
      return memberType[0] === "Vendor" ? schema.required("*Required") : schema;
    }),
    chequeNumber: Yup.string().trim().required("*Required"),
    chequeDate: Yup.string().required("*Required"),
    paymentDate: Yup.string().required("*Required"),
    bankName: Yup.string().trim().required("*Required"),
    amount: Yup.string().trim().required("*Required"),

    remarks: Yup.string().trim().required("*Required"),
    sendEmail: Yup.string(),
    sendSMS: Yup.string(),
  });
};

export const EditAdvanceNoteValidation = () => {
  return Yup.object({
    memberType: Yup.string().required("*Required"),
    date: Yup.string().required("*Required"),
    voucher: Yup.string(),
    debitAccountId: Yup.string().required("*Required"),
    creditAccountId: Yup.string().required("*Required"),

    //userName: Yup.string().required("*Required"),
    //memberGLCode: Yup.string().required("*Required"),
    userId: Yup.string().required("*Required"),
    chequeNumber: Yup.string().trim().required("*Required"),
    chequeDate: Yup.string().required("*Required"),
    paymentDate: Yup.string().required("*Required"),
    bankName: Yup.string().trim().required("*Required"),
    amount: Yup.string().trim().required("*Required"),

    remarks: Yup.string().trim().required("*Required"),
    sendEmail: Yup.string(),
    sendSMS: Yup.string(),
  });
};

export const invoiceGeneratorSchema = () => {
  return Yup.object({
    from_date: Yup.string().required("*Required"),
    to_date: Yup.string().required("*Required"),
    invoice_date: Yup.string().required("*Required"),
    payment_due_date: Yup.string().required("*Required"),
    debitnote_include_as_arrear: Yup.string(),
    generate: Yup.string(),
    flat_no: Yup.array().of(
      Yup.string()),
  });
};

export const onlinePaymentSchema = Yup.object().shape({
  amount: Yup.string()
    .max(10)
    .matches(/^\d+$/, "Invalid Amount")
    .required("Amount required"),
  date: Yup.date().required("Date required"),
});

export const ChequeSchema = Yup.object().shape({
  amount: Yup.string()
    // .max(8)
    .matches(/^\d+$/, "Invalid Amount")
    .required("Amount required"),
  //bankId: Yup.string().required("Bank required"),
  date: Yup.date().required("Cheque date required"),
  cheque_number: Yup.string()
    .min(6, "Invalid Cheque number")
    .matches(/^\d+$/, "Invalid Cheque number ")
    .required("Cheque number required"),
  paymentdate: Yup.date().required("Payment date required"),
  cleardate: Yup.date().required("Clear date required"),
  bankname: Yup.string().required("Bank name required"),
  branchname: Yup.string()
    .min(2, "Invalid Branch name")
    .max(40, "Invalid Branch Name")
    .required("Branch name required"),
  remark: Yup.string(),
  modeofpayment: Yup.string(),
});

export const NEFTSchema = Yup.object().shape({
  amount: Yup.string()
    // .max(8, "Invalid amount")
    .matches(/^\d+$/, "Invalid Amount")
    .required("Amount required"),
  //bankId: Yup.string().required("Bank required"),
  transaction_number: Yup.string()
    .min(4, "Invalid Transaction number")
    .matches(/^[0-9a-zA-Z]+$/, "Invalid Transaction Number")
    .required("Transaction number required"),
  date: Yup.date().required("NEFT date required"),
  bankname: Yup.string().required("Bank name required"),
  paymentdate: Yup.date().required("Payment date required"),
  cleardate: Yup.date().required("Clear date required"),
  remark: Yup.string(),
  modeofpayment: Yup.string(),
});

export const CashSchema = Yup.object().shape({
  amount: Yup.string()
    // .max(8, "Invalid amount")
    .matches(/^\d+$/, "Invalid Amount")
    .required("Amount required"),

  // bankname: Yup.string().required("Bank name required"),
  paymentdate: Yup.date().required("Payment date required"),
  cleardate: Yup.date().required("Clear date required"),
  remark: Yup.string(),
});
