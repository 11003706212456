import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import {
    DUE_REMINDER_ENDPOINT,
  FIXED_DEPOSIT_MATURITY_REMINDER_ENDPOINT,
  MEMBER_DUE_REMINDER_ENDPOINT,
  NOMINATION_REMINDER_ENDPOINT,
} from "../../../../Routing/routes";
import { Form, Formik } from "formik";
import { DepositDueReminderValidation } from "../../../../validationSchema/financeSchema/settingSchema";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getDebitNoteDueReminderDetails, updateDebitNoteDueReminderDetails } from "../../../../redux/actions/FinanceActions/SettingActions/DueRemindersAction";
import Loader from "../../../../components/Loader/Loader";
import { TextInputFM } from "../../../../components/InputFields/TextInput";

const DepositDueRemider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, debitDueReminderData} = useSelector(state=>state.dueReminderReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSubmit = (values, {resetForm})=>{
    dispatch(updateDebitNoteDueReminderDetails({...values, societyId: loginData?.default_community?.community_id}))
  }
  
  useEffect(()=>{
    dispatch(getDebitNoteDueReminderDetails({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Deposit Due Reminder">
            <Button
                type='button'
              label="Due Reminder"
              onClick={handleNavigation(DUE_REMINDER_ENDPOINT)}
            />
            <Button
            type='button'
              label="Member Due Reminder"
              onClick={handleNavigation(MEMBER_DUE_REMINDER_ENDPOINT)}
            />
            <Button
            type='button'
              label="FD Maturity Reminder"
              onClick={handleNavigation(
                FIXED_DEPOSIT_MATURITY_REMINDER_ENDPOINT
              )}
            />
            <Button
            type='button'
              label="Nominees Reminder"
              onClick={handleNavigation(NOMINATION_REMINDER_ENDPOINT)}
            />
          </ListHeader>
        </div>
        <Formik
          initialValues={{  
            beforeId: debitDueReminderData?.beforeDueDate?.id || 0,
            noOfDaysBeforeDue: debitDueReminderData?.beforeDueDate?.no_of_day_prior || '',
            afterId: debitDueReminderData?.afterDueDate?.id || 0,
            noOfDaysAfterDue: debitDueReminderData?.afterDueDate?.no_of_day_prior || '',          }}
          enableReinitialize
          validationSchema={DepositDueReminderValidation}
          onSubmit={handleSubmit}
        >
          {
            ({values, errors, setFieldValue})=>(
              <Form>
                <div className="flex py-1.5 justify-end border-b border-[#CCC]">
                  <Button
                    type='submit'
                    label={
                      <span className="w-16 inline-block">
                        {
                        updateLoader ?
                          <CircularProgress sx={{color: "white"}} size={17}/>
                          :
                          <>Save</>
                        }
                      </span>
                      }
                      disabled={loading || updateLoader}
                  />
                </div>
                {
                  loading ?
                  <Loader/>
                  :
                  <>
                  <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg overflow-hidden mt-3">
                        <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
                            <h1 className="text-base items-center p-2 -mb-2 ml-1">Before Due Date</h1>
                        </div>
                        <div className=" p-2">
                            <div className="w-1/2">
                                <div className="w-[80%]">
                                    <TextInputFM
                                    name={`noOfDaysBeforeDue`}
                                    type='number'
                                    className='w-56'
                                    label='No. Of Days Before'
                                    placeholder="Enter No Of Days"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg overflow-hidden mt-3">
                        <div className=" border-b border-[#CCC] pb-2 bg-[#FDF2F3]">
                            <h1 className="text-base items-center p-2 -mb-2 ml-1">After Due Date</h1>
                        </div>
                        <div className=" p-2">
                            <div className="w-1/2">
                                <div className="w-[80%]">
                                    <TextInputFM
                                    name={`noOfDaysAfterDue`}
                                    type='number'
                                    className='w-56'
                                    label='No. Of Days After'
                                    placeholder="Enter No Of Days"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                  </>
                }
              </Form>
            )
          }
          </Formik>
      </div>
    </Layout>
  );
};

export default DepositDueRemider;
