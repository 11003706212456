import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import FilterTextField from "../../../../components/InputFields/FilterTextFields";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { TextInput } from "../../../../components/InputFields/TextInput";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt, UploadEx, PrinterIcon } from "../../../../assets";
import UploadTenantExcelModal from "../../../../components/Modal/UploadTenantExcelModal";
import UploadDebitNoteExcelModal from "../../../../components/Modal/UploadDebitNoteExcelUploadModal";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  ADD_DEBIT_NOTE_ENDPOINT,
  MAKE_PAYMENT_DEBIT_NOTE_MULTI_ENDPOINT,
  MANAGE_DEBIT_DRAFT_ENDPOINT,
  MANAGE_DEBIT_PREVIOUS_ENDPOINT,
  PRINT_RECEIPT_REPORT_ENDPOINT,
  RECEIPT_REPORT_ENDPOINT,
} from "../../../../Routing/routes";
import ManageDebitCurrentTable from "../../../../components/TableComponent/ManageDebitCurrentTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  downloadExcel,
  downloadFileFromURL,
  exportToCSV,
} from "../../../../utils/helpers/downloadMedia";
import {
  getDebitNoteCurrent,
  uploadDebitNoteCurrentExcel,
  downloadDebitNoteCurrentPayment,
  uploadDebitNoteCurrentPayment,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import ButtonG from "../../../../components/Button/ButtonG";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  downloadReceiptReport,
  getreceiptReport,
  getReceiptReport,
} from "../../../../redux/actions/ReportActions/FinanceReportActions";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import ReceiptReportTable from "./ReceiptReportTable";
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

const invoice = [
  { label: "Invoice", value: "invoice" },
  { label: "Drafts Invoice", value: "draftsInvoice" },
];
const searchBy = [
  { label: "Payment Date", value: "payment_date" },
  { label: "System Payment Date", value: "date" },
];
const gstNo = [
  { label: "B2B", value: "With GST No" },
  { label: "B2C", value: "WithOut GST No" },
];
const status = [
  { label: "All", value: "All" },
  { label: "Verified & Posted", value: "Verified" },
  { label: "Unverified", value: "Unverified" },
  { label: "Rejected", value: "Rejected" },
  { label: "Post Entry", value: "post" },
];

const ReceiptReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState(null);
  const [headCells, setHeadCells] = useState([]);

  const { receiptReport, loading } = useSelector(
    (state) => state.financeReportReducer
  );
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };
  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleDownloadExcel = () => {
    dispatch(
      downloadReceiptReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("memberName") || "",
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        status: searchParams.get("status") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        invoiceType: searchParams.get("invoiceType") || "",
        export: 1,
        onSuccessCallback: (url) => {
          downloadFileFromURL({ url, fileName: "sample.csv" });
        },
      })
    );
  };
  const handlePrintData = () => {
    navigate({
      pathname: `${PRINT_RECEIPT_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries()])}`,
    });
  };

  const handelSelectInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("invoiceType");
    navigate({
      pathname: `${RECEIPT_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["invoiceType", selectedItem.value],
      ])}`,
    });
  };
  const handleSelectStatus = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("status");
    navigate({
      pathname: `${RECEIPT_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["status", selectedItem.value],
      ])}`,
    });
  };
  const handleSearch = (text) => {
    if (text && text !== searchParams.get("memberName")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("memberName");
      navigate({
        pathname: `${RECEIPT_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["memberName", text],
        ])}`,
      });
    } else if (!text) {
      searchParams.delete("memberName");
      navigate({
        pathname: `${RECEIPT_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };
  const handleSearchBy = (text) => {
    if (text && text !== searchParams.get("searchBy")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("searchBy");
      navigate({
        pathname: `${RECEIPT_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["searchBy", text],
        ])}`,
      });
    }
  };

  const handleSelectDebitorType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    searchParams.delete("debitor_type");

    navigate({
      pathname: `${RECEIPT_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["debitor_type", selectedItem.value],
      ])}`,
    });
  };
  const handleSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${RECEIPT_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${RECEIPT_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${RECEIPT_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${RECEIPT_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    // setDefaultFromValue("");
    // setDefaultToValue("");
    navigate(`${RECEIPT_REPORT_ENDPOINT}?searchBy=payment_date`);
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
      );
    }
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );

    if (!searchParams.get("searchBy")) {
      navigate({
        pathname: `${RECEIPT_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([["searchBy", "payment_date"]])}`,
      });
    }
    setHeadCells([]);
  }, []);

  const getTableData = () => {
    dispatch(
      getReceiptReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("memberName") || "",
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        status: searchParams.get("status") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        invoiceType: searchParams.get("invoiceType") || "",
      })
    );
  };
  useEffect(() => {
    getTableData();
  }, [searchParams]);

  useEffect(() => {
    if (receiptReport?.data?.length)
      // setHeadCells(
      //   Object.keys(receiptReport.data[0]).map((item) => ({
      //     label: item,
      //     id: item.replaceAll(" ", "_"),
      //     sort: true,
      //     width: "130px",
      //   }))
      // );
      setHeadCells(
        Object.entries(receiptReport.data.slice(-1)[0])
          .filter((item) => item[1] !== 0)
          ?.map((item) => ({
            label: item[0],
            id: item[0].replaceAll(" ", "_"),
            sort: true,
            width: "130px",
          }))
      );
  }, [receiptReport]);

  // useEffect(() => {
  //   setHeadCells(
  //     Object.keys(demoData[0]).map((item) => ({
  //       label: item,
  //       id: item.replaceAll(" ", "_"),
  //       sort: true,
  //       width: "130px",
  //     }))
  //   );
  // }, [demoData]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Receipt Report">
            <div className="flex text-sm gap-3">
              <button
                type="button"
                onClick={handlePrintData}
                className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
              >
                <PrinterIcon className="mr-2 fill-red-650" /> Print
              </button>
              <ButtonIco
                type="button"
                onClick={handleDownloadExcel}
                icon={DownloadExt}
                children="Download Excel"
                // className={"opacity-30"}
              />
            </div>
          </ListHeader>
        </div>

        <div className="p-1 flex flex-row justify-between items-center gap-4 mt-1">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("memberName")}
            placeholder="Member Name"
          />
          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Invoice Type</div>
            <Dropdown2
              options={
                typeLists.length
                  ? typeLists?.map((item) => ({
                      label: item?.invoice_title,
                      value: item?.id,
                    }))
                  : []
              }
              placeholder="Invoice type"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectInvoiceType(selectedItem);
              }}
              value={
                searchParams.get("invoiceType") && {
                  value: searchParams.get("invoiceType"),
                  label: typeLists.length
                    ? typeLists?.find(
                        (item) =>
                          item?.id === parseInt(searchParams.get("invoiceType"))
                      )?.invoice_title
                    : "",
                }
              }
            />
          </div>
          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Search By</div>
            <Dropdown2
              options={searchBy}
              placeholder="Invoice Date"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSearchBy(selectedItem.value);
              }}
              value={
                searchParams.get("searchBy") && {
                  value: searchParams.get("searchBy"),
                  label: searchBy?.find(
                    (item) => item.value == searchParams.get("searchBy")
                  )?.label,
                }
              }
            />
            {/* <div className="border-r border-[#CCC] h-full"></div>

           
            <div className="border-r border-[#CCC] h-full"></div> */}

            <div className="flex justify-between gap-3 items-center">
              <RangeDatePicker
                className={"w-[190px] h-8"}
                defaultStartValue={
                  searchParams.get("startDate")
                    ? new Date(searchParams.get("startDate"))
                    : ""
                }
                defaultEndValue={
                  searchParams.get("endDate")
                    ? new Date(searchParams.get("endDate"))
                    : ""
                }
                onStartDateChange={handleStartDate}
                onEndDateChange={handleEndDate}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 p-2">
          <Dropdown2
            options={
              wingsData?.map((item) => ({
                value: item?.wing_id,
                label: item?.number,
              })) || []
            }
            placeholder="Select Wing"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectWing(selectedItem);
            }}
            value={
              searchParams.get("wingId") && {
                value: searchParams.get("wingId"),
                label: wingsData?.find(
                  (item) =>
                    item.wing_id?.toString() === searchParams.get("wingId")
                )?.number,
              }
            }
          />
          <Dropdown2
            options={
              (searchParams.get("wingId") &&
                flatsData?.manage_flat_details?.map((item) => ({
                  value: item?.flat_id,
                  label: item?.flat_no,
                }))) ||
              []
            }
            placeholder="Select Flat"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectFlat(selectedItem);
            }}
            value={
              searchParams.get("flatId") && {
                value: searchParams.get("flatId"),
                label: flatsData?.manage_flat_details?.find(
                  (item) =>
                    item.flat_id?.toString() === searchParams.get("flatId")
                )?.flat_no,
              }
            }
          />
          <Dropdown2
            options={status}
            placeholder="Status"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectStatus(selectedItem);
            }}
            value={
              searchParams.get("status") && {
                value: searchParams.get("status"),
                label: status.find(
                  (item) => item.value == searchParams.get("status")
                )?.label,
              }
            }
          />
          {/* <Dropdown2
            options={
              manageDebitNoteCurrent?.data?.invoice_type
                ? Object.entries(
                    manageDebitNoteCurrent?.data?.invoice_type
                  )?.map((item) => ({
                    label: item?.[1],
                    value: item?.[0],
                  }))
                : []
            }
            placeholder="Select Bill Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectBillType(selectedItem);
            }}
            value={
              searchParams.get("bill_type") && {
                value: searchParams.get("bill_type"),
                label:
                  manageDebitNoteCurrent?.data?.invoice_type?.[
                    searchParams.get("bill_type")
                  ],
              }
            }
          />
          <Dropdown2
            options={
              manageDebitNoteCurrent?.data?.debitor_type
                ? Object.entries(
                    manageDebitNoteCurrent?.data?.debitor_type
                  )?.map((item) => ({
                    label: item?.[1],
                    value: item?.[0],
                  }))
                : []
            }
            placeholder="Select Debitor Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectDebitorType(selectedItem);
            }}
            value={
              searchParams.get("debitor_type") && {
                value: searchParams.get("debitor_type"),
                label:
                  manageDebitNoteCurrent?.data?.debitor_type?.[
                    searchParams.get("debitor_type")
                  ],
              }
            }
          /> */}
          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </div>
        {/* <div>
          <div className="flex justify-end gap-3 items-center my-2">
            <div className="text-sm">Due Filter</div>
            <FilterTextField
              defaultFromValue={defaultFromValue && defaultFromValue}
              defaultToValue={defaultToValue && defaultToValue}
              onAmountFromChange={(e) => {
                setDefaultFromValue(e.target.value);
              }}
              onAmountToChange={(e) => {
                setDefaultToValue(e.target.value);
              }}
            />
            <Button
              label="Search"
              type="button"
              onClick={() => {
                handleAmountChange();
              }}
              className={`${
                !defaultFromValue || !defaultToValue ? "opacity-50" : ""
              }`}
              disabled={!defaultFromValue || !defaultToValue}
            />
            <Button
              label="Reset"
              type="button"
              className={`${
                !defaultFromValue || !defaultToValue ? "opacity-50" : ""
              }`}
              onClick={() => handleAmountChange(true)}
              disabled={!defaultFromValue || !defaultToValue}
            />
          </div>
        </div> */}
        <div>
          <ReceiptReportTable
            loading={loading}
            data={receiptReport?.data}
            columns={headCells}
            checkbox={false}
            pagination={receiptReport?.data?.length}
            totalCount={receiptReport?.paginate?.total_count}
            totalPages={receiptReport?.paginate?.total_page}
            start={
              receiptReport?.paginate?.current *
                receiptReport?.paginate?.limit +
              1
            }
            end={
              receiptReport?.paginate?.current *
                receiptReport?.paginate?.limit +
              receiptReport?.data?.length -
              1
            }
            currentPage={Number(receiptReport?.paginate?.current)}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
            selected={selected}
            setSelected={setSelected}
          ></ReceiptReportTable>

          {!receiptReport?.data?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ReceiptReport;
