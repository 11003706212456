import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { validateDate } from "../../../utils/helpers/universalFunctions";
import { getVisitorLog, } from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";

const headCells = [
    { id: "sNo", label: "Sr. No.", width: "45px" },
    { id: "name", label: "Name", sort: true, width: "11%" },
    { id: "phone", label: "Phone", sort: true, width: "11%" },
    { id: "type", label: "Type", sort: true, width: "11%" },
    { id: "from", label: "From", sort: true, width: "11%" },
    { id: "flat", label: "Flat", sort: true, width: "11%" },
    { id: "purpose", label: "Purpose", sort: true, width: "11%" },
    { id: "status", label: "Status", sort: true, width: "9%" },
    { id: "inOut", label: "In/Out", width: "13%" },
  ];

const PrintVisitorLog = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams()

  const { loginData } = useSelector((state) => state.loginReducer);
  const {loading, visitorLog} = useSelector(state=>state.visitorAndVehicleInAndOutReducer)


  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: 0,
      pageLimit: 10,
      searchText: searchParams.get("search") || '',
      fromDate: searchParams.get("fromDate") || '',
      toDate: searchParams.get("toDate") || '',
      vehicleType: searchParams.get("vehicleType") || '',
    }
    dispatch(getVisitorLog(data));
  }
  
  useEffect(()=>{      
    getTableData()

    const afterprint = (e) => {
      e.preventDefault()
    };
    
    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    }
  }, [])


  useEffect(() => {
    if(!loading && visitorLog){
      window.print()
    }
  }, [loading]);


  return loading ? (
    <Loader />
  ) : (
    <div className="m-1">
      <table className="table-auto w-full mx-auto border-collapse border border-black">
        <thead>
          <tr>
            {
              headCells?.map((item)=>(
                <td className="text-sm p-1 font-semibold border border-black" key={item?.id} width={item?.width}>
                  {item?.label}
                </td>
              ))
            }
          </tr>
        </thead>
        <tbody>
            {
              visitorLog?.logs?.map((item, index)=>(
                <React.Fragment  key={index}>
                {
                  !!Object.keys(item)?.length &&
                  <tr className="text-[#222] text-sm">
                    <td className="p-1 border border-black align-top">
                      {(index+1)?.toString()?.padStart(2, 0)}
                    </td>
                    <td className="p-1 border border-black align-top capitalize">
                      {`${item?.vname} ${item?.lname ? item?.lname : ''}` || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {`${item?.VCountryCode ? `+${item?.VCountryCode}-` : ''}${item.vphone}` || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.type || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.vfrom || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.flats_details || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item.purpose || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                      {item?.checkstatus || '-'}
                    </td>
                    <td className="p-1 border border-black align-top">
                    {
                        validateDate(item?.checkin) && 
                        <>
                          In: {moment(item?.checkin)?.utc()?.format('DD-MM-YYYY hh:mm:ss')} <br />
                        </>
                      }
                      {
                        validateDate(item?.checkout) && 
                        <>
                          Out: {moment(item?.checkout)?.utc()?.format('DD-MM-YYYY hh:mm:ss')}
                        </>
                      }
                    </td>
                  </tr>
                }
                </React.Fragment>
              ))
            }
          
        </tbody>
      </table>
      {!visitorLog?.logs?.length && !loading && (
          <div className="text-sm my-10 text-center italic">
            No Records
          </div>
        )}
    </div>
  );
};

export default PrintVisitorLog;
