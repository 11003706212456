import React, { useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../components/Button/ButtonG";
import Button from "../../../components/Button/Button";
import TextRadio from "../../../components/InputFields/TextRadio";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  addAssignedComplaintData,
  getAssignedComplaintData,
  getAssignedMemberListRoles,
  getAssignedReqRoles,
  getAssignedSubReqRoles,
  getComplaintStatusList,
  getSocietyDetailsList,
} from "../../../redux/actions/DeskActions/DeskAction";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import Loader from "../../../components/Loader/Loader";
import { Form, Formik } from "formik";
import { AssignmentComplaintValidation } from "../../../validationSchema/deskSchema/logaTicketSchema";
import {
  getFlatsNo,
  getWingsList,
} from "../../../redux/actions/DeskActions/LogaComplaintActions";
import { CircularProgress } from "@mui/material";

function AssignComplaint() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    loading,
    updateLoader,
    assignedComplaintData,
    assignedMembersList,
    assignedSubReqRoles,
    assignedReqRoles,
    societyDetailsList,
    complaintStatusList
  } = useSelector((state) => state.deskReducers);
  const { loginData } = useSelector((state) => state.loginReducer);
  const dropdownData = useSelector((state) => state.logaComplaintReducer);
  const contentRef = useRef();


  useEffect(() => {
    if (assignedComplaintData?.description && contentRef) {
      contentRef.current.innerHTML = assignedComplaintData?.description
    }
  }, [assignedComplaintData?.description]);

  const handleSaveClick = (values, { resetForm }) => {
    let sendRequest = {
      ...values,
      societyId: loginData?.default_community?.community_id,
      onSuccessCallback: () => {
        resetForm();
      },
    };
    dispatch(addAssignedComplaintData(sendRequest));
  };

  useEffect(() => {
    let pay = { societyId: loginData?.default_community?.community_id };
    dispatch(getAssignedComplaintData({ complaintId: id, ...pay }));
    dispatch(getAssignedReqRoles({ ...pay }));
    dispatch(getSocietyDetailsList(pay));
    dispatch(getComplaintStatusList());

  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            complaintId: assignedComplaintData?.id,
            servicedBy: assignedComplaintData?.serviced_by,
            writeComment: "",
            emailAssignee: 0,
            emailOwner: 0,
            status: assignedComplaintData?.status ? complaintStatusList?.find(item => item?.key === assignedComplaintData?.status)?.value : '',
            reqRole: "",
            reqSubRole: "",
            assignedMember: "",
            societyName: "",
            wingId: "",
            flatsNo: "",
            subscriber_id: "",
            reAssign: "employee",
            memberName: "",
            subscriberTitle: "",
            memberId: "",
          }}
          enableReinitialize
          validationSchema={AssignmentComplaintValidation}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack
                    title="Assignment Of Complaint"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ButtonG
                      label="Cancel"
                      className="h-8"
                      type={`button`}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <Button
                      label={
                        <span className="inline-block">
                          {updateLoader ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={17}
                            />
                          ) : (
                            "Save"
                          )}
                        </span>
                      }
                      className="h-8 px-4"
                      type={`submit`}
                    />
                  </ListHeaderBack>
                </div>
                <div className="p-2 gap-2">
                  {loading && id !== assignedComplaintData?.id ? (
                    <Loader />
                  ) : (
                    <table className="table-auto w-full text-[#222222] text-sm">
                      <tbody>
                        <tr>
                          <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                            Ticket ID:
                          </td>
                          <td className="py-2">
                            {assignedComplaintData?.ticket || ""}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                            Status:
                          </td>
                          <td className="py-2">
                            <DropdownFM2
                              options={complaintStatusList?.map((item) => ({
                                label: item.key,
                                value: item.value,
                              })) || []}
                              placeholder="Select Status"
                              className="text-[11px] ms-4"
                              width="13rem"
                              height="32px"
                              onSelect={(selectedItem) => {
                                formik.setFieldValue(`status`, selectedItem.value)
                              }}
                              name='status'
                              value={
                                formik.values.status ?
                                  {
                                    value: formik.values.status,
                                    label: complaintStatusList?.find(item => item?.value === formik.values.status)?.key
                                  }
                                  : null
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                            Logged By:
                          </td>
                          <td className="py-2">
                            {assignedComplaintData?.full_name || ""} (
                            {assignedComplaintData?.wing_flat || ""})
                          </td>
                        </tr>
                        <tr>
                          <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                            Re-assign:
                          </td>
                          <td className="py-2">
                            <TextRadio
                              options={[
                                {
                                  name: "re_assign",
                                  label: "Assign Employee",
                                  value: "employee",
                                },
                                {
                                  name: "re_assign",
                                  label: "Assign Society",
                                  value: "society",
                                },
                              ]}
                              defaultSelected={formik.values.reAssign}
                              onChange={(value) => {
                                formik.setFieldValue("reAssign", value);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="w-44 py-4 text-[#AAAAAA] font-bold align-top ">
                            Assigned To:
                          </td>
                          <td className="py-2">
                            {formik.values.reAssign === "employee" ? (
                              <>
                                <div>
                                  <DropdownFM2
                                    options={
                                      assignedReqRoles
                                        ? assignedReqRoles?.filter((item) => item.title !== "Managing Staff").map((item) => ({
                                          label: item.title,
                                          value: item.title,
                                        }))
                                        : []
                                    }
                                    width="13rem"
                                    height="37.6px"
                                    placeholder="Select Role"
                                    className="text-xs"
                                    onSelect={(selectedItem) => {
                                      formik.setFieldValue(
                                        "reqRole",
                                        selectedItem.value
                                      );
                                      if (
                                        formik.values.reqRole !==
                                        selectedItem.value
                                      ) {
                                        formik.setFieldValue("reqSubRole", "");
                                        formik.setFieldValue(
                                          "assignedMember",
                                          ""
                                        );
                                        let data = {
                                          societyId:
                                            loginData?.default_community
                                              ?.community_id,
                                          roleType: selectedItem.value,
                                        };
                                        dispatch(getAssignedSubReqRoles(data));
                                      }
                                    }}
                                    value={
                                      formik.values.reqRole && {
                                        value: formik.values.reqRole,
                                        label: assignedReqRoles?.filter(
                                          (item) =>
                                            item.title === formik.values.reqRole
                                        )?.[0]?.title,
                                      }
                                    }
                                    name="reqRole"
                                  />
                                </div>
                                <div className=" flex gap-4">
                                  <DropdownFM2
                                    options={
                                      assignedSubReqRoles
                                        ? assignedSubReqRoles?.map((item) => ({
                                          label: item.title,
                                          value: item.id,
                                        }))
                                        : []
                                    }
                                    width="13rem"
                                    height="37.6px"
                                    className="text-xs"
                                    placeholder="Select Sub Roles"
                                    onSelect={(selectedItem) => {
                                      formik.setFieldValue(
                                        "reqSubRole",
                                        selectedItem.value
                                      );
                                      if (
                                        formik.values.reqSubRole !==
                                        selectedItem.value
                                      ) {
                                        formik.setFieldValue(
                                          "assignedMember",
                                          ""
                                        );
                                        let data = {
                                          societyId:
                                            loginData?.default_community
                                              ?.community_id,
                                          roleType: formik.values.reqRole,
                                          reqSubRole: selectedItem.value,
                                        };
                                        dispatch(
                                          getAssignedMemberListRoles(data)
                                        );
                                      }
                                    }}
                                    value={
                                      formik.values.reqSubRole &&
                                      assignedSubReqRoles?.filter(
                                        (item) =>
                                          item.id === formik.values.reqSubRole
                                      )?.[0]?.title && {
                                        value: formik.values.reqSubRole,
                                        label: assignedSubReqRoles?.filter(
                                          (item) =>
                                            item.id === formik.values.reqSubRole
                                        )?.[0]?.title,
                                      }
                                    }
                                    name="reqSubRole"
                                  />
                                  <DropdownFM2
                                    options={
                                      formik.values.reqSubRole &&
                                        assignedMembersList
                                        ? assignedMembersList?.map((item) => ({
                                          label: item.full_name,
                                          value: item.id,
                                        }))
                                        : []
                                    }
                                    width="13rem"
                                    height="37.6px"
                                    className="text-xs"
                                    placeholder="Select Member"
                                    onSelect={(selectedItem) => {
                                      formik.setFieldValue(
                                        "assignedMember",
                                        selectedItem.value
                                      );
                                    }}
                                    value={
                                      formik.values.assignedMember &&
                                      assignedMembersList?.filter(
                                        (item) =>
                                          item.id ===
                                          formik.values.assignedMember
                                      )?.[0]?.full_name && {
                                        value: formik.values.assignedMember,
                                        label: assignedMembersList?.filter(
                                          (item) =>
                                            item.id ===
                                            formik.values.assignedMember
                                        )?.[0]?.full_name,
                                      }
                                    }
                                    name="assignedMember"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <DropdownFM2
                                    options={societyDetailsList?.map(
                                      (item) => ({
                                        label: item?.title,
                                        value: item.id,
                                      })
                                    )}
                                    width="224px"
                                    height="37.6px"
                                    placeholder="Select Society"
                                    className="text-xs"
                                    onSelect={(selectedItem) => {
                                      formik.setFieldValue(
                                        "societyName",
                                        selectedItem.value
                                      );
                                      let data = {
                                        societyId: selectedItem.value,
                                      };
                                      dispatch(getWingsList(data));
                                    }}
                                    name="societyName"
                                  />
                                </div>
                                <div className=" flex gap-4">
                                  <DropdownFM2
                                    className={"me-4"}
                                    options={dropdownData.wingsList?.map(
                                      (item) => ({
                                        label: item.title,
                                        value: item.id,
                                      })
                                    )}
                                    width="105px"
                                    height="36px"
                                    placeholder="Wing"
                                    name={"wingId"}
                                    onSelect={(selectedItem) => {
                                      formik.setFieldValue(
                                        "wingId",
                                        selectedItem.value
                                      );
                                      let data = {
                                        societyId: formik.values.societyName,
                                        wingId: selectedItem.value,
                                      };
                                      dispatch(getFlatsNo(data));
                                    }}
                                  />
                                  <DropdownFM2
                                    options={dropdownData.flatsList?.map(
                                      (item) => ({
                                        label: item.flat_no,
                                        value: item.house_unique_id,
                                        memberName: item.full_name,
                                        subscriberTitle: item.subscriber_title,
                                        memberId: item.memberid[0],
                                        subscriber_id: item.subscriber_id,
                                      })
                                    )}
                                    name={"flatsNo"}
                                    width="105px"
                                    height="36px"
                                    placeholder="Flat/Unit"
                                    onSelect={(selectedItem) => {
                                      formik.setFieldValue(
                                        "flatsNo",
                                        selectedItem.value
                                      );
                                      formik.setFieldValue(
                                        "memberName",
                                        selectedItem.memberName
                                      );
                                      formik.setFieldValue(
                                        "subscriberTitle",
                                        selectedItem.subscriberTitle
                                      );
                                      formik.setFieldValue(
                                        "memberId",
                                        selectedItem.memberId
                                      );
                                      formik.setFieldValue(
                                        "subscriber_id",
                                        selectedItem.subscriber_id
                                      );
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                        {formik.values.reAssign === "employee" ? (
                          <tr>
                            <td className="w-44 py-3  text-[#AAAAAA] font-bold align-top">
                              Serviced By:
                            </td>
                            <td>
                              <TextInputFM
                                placeholder={"Enter Name"}
                                className={"w-52"}
                                name={`servicedBy`}
                              />
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td className="w-44 py-3  text-[#AAAAAA] font-bold align-top">
                              Member Name:
                            </td>
                            <td>{formik.values.memberName}</td>
                          </tr>
                        )}
                        <tr>
                          <td className="w-44 py-3  text-[#AAAAAA] font-bold align-top">
                            Description:
                          </td>
                          <td>
                            <div className="vc-description" ref={contentRef}>
                              {assignedComplaintData?.description || ""}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="w-44 py-4 text-[#AAAAAA] font-bold align-top">
                            Comment:
                          </td>
                          <td className="py-4">
                            <div className="flex">
                              {assignedComplaintData?.comments &&
                                assignedComplaintData?.comments?.length > 0 ? (
                                <div className="grid grid-col-6 gap-3 w-full">
                                  {assignedComplaintData?.comments?.map(
                                    (comment, index) => (
                                      <div
                                        key={index}
                                        className="py-2 border-dashed border-b border-[#CCC]"
                                      >
                                        <div className="flex gap-3">
                                          <p> - </p>
                                          <p className=" text-[#AAA]">
                                            {comment.comments || "-"}{" "}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                "-"
                              )}
                              <div className="">
                                <div className="grid grid-col-6 gap-3 w-full">
                                  {assignedComplaintData?.comments?.map(
                                    (comment, index) => (
                                      <div
                                        key={index}
                                        className="py-2 text-[#AAA] whitespace-nowrap border-dashed border-b border-[#CCC]"
                                      >
                                        <div className="flex gap-3">
                                          <p> Status: </p>
                                          <p className="font-bold">
                                            {complaintStatusList?.filter((item) => item.value === comment.status_update).map((item) => {
                                              return item.key
                                            })}
                                          </p>
                                        </div>
                                        <div className="flex gap-3">
                                          <p> Posted By: </p>
                                          <p className="font-bold">
                                            {" "}
                                            {comment.comment_by}{" "}
                                          </p>
                                        </div>

                                        <div className="flex gap-3">
                                          <p> Posted On: </p>
                                          <p className=" text-[#AAA]">
                                            {comment.add_comment_date !==
                                              "0000-00-00 00:00:00"
                                              ? new Date(
                                                comment.add_comment_date
                                              ).toLocaleString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                                timeZone: "UTC"
                                              })
                                              : "-"}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="w-44 py-6 text-[#AAAAAA] font-bold align-top">
                            Write your comment here:
                          </td>
                          <td className="py-4">
                            <TextAreaFM
                              placeholder={"Enter"}
                              className={"w-52"}
                              row={4}
                              name={`writeComment`}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="w-44 py-2 text-[#AAAAAA] font-bold align-top">
                            Email to:
                          </td>
                          <td className="py-2">
                            <div>
                              <Checkbox
                                text={"Assignee"}
                                className={"w-28"}
                                initialchecked={
                                  formik.values.emailAssignee === 1
                                }
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `emailAssignee`,
                                    e.target.checked ? 1 : 0
                                  );
                                }}
                              />
                              <Checkbox
                                text={"Owner"}
                                className={"w-28"}
                                initialchecked={formik.values.emailOwner === 1}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    `emailOwner`,
                                    e.target.checked ? 1 : 0
                                  );
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
}

export default AssignComplaint;
