import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  societyStaffStatusOptions,
  societyStaffTypeOptions,
} from "../../../../consts/DropDownOptions";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ManageDebitNoteDraftTable from "../../../../components/TableComponent/ManageDebitDraftTable";
import {
  MANAGE_DEBIT_CURRENT_ENDPOINT,
  MANAGE_DEBIT_DRAFT_ENDPOINT,
  MANAGE_DEBIT_PREVIOUS_ENDPOINT,
} from "../../../../Routing/routes";
import { getDebitNoteDraft } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import ButtonG from "../../../../components/Button/ButtonG";
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { getPermission } from "../../../../utils/helpers/getPermissions";
import * as permissions from "../../../../Routing/permissions";

const headingsDebitNote = [
  { id: "sNo", label: "SNo.", sort: false, width: "50px" },
  // { id: "invoiceNo", label: "Bill Number", sort: true, width: "120px" },
  { id: "memberDetails", label: "Member Details", sort: true, width: "200px" },
  { id: "billDate", label: "Bill Date", sort: true, width: "100px" },
  {
    id: "generationDate",
    label: "Generation Date",
    sort: true,
    width: "130px",
  },
  { id: "amount", label: "Amount", sort: true, width: "120px" },
  { id: "status", label: "Status", sort: true, width: "100px" },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const ManageDebitDraft = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);

  const [selected, setSelected] = useState([]);

  const { manageDebitNoteDraft, loading } = useSelector(
    (state) => state.manageDebitNoteReducer
  );
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const handleNavigation = (path) => () => {
    navigate(path);
  };
  const handleSearch = (text) => {
    if (text && text !== searchParams.get("invoiceno")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("invoiceno");
      navigate({
        pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["invoiceno", text],
        ])}`,
      });
    } else if (!text) {
      searchParams.delete("invoiceno");
      navigate({
        pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };
  const handelSelectInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("typeid");
    navigate({
      pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["typeid", selectedItem.value],
      ])}`,
    });
  };
  const handeleSearchBy = (text) => {
    if (text && text !== searchParams.get("searchBy")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("searchBy");
      navigate({
        pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["searchBy", text.value],
        ])}`,
      });
    }
  };

  // const handelSelectStatus = (selectedItem) => {
  //   searchParams.delete("page");
  //   searchParams.delete("status");
  //   navigate({
  //     pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
  //     search: `?${createSearchParams([
  //       ...searchParams.entries(),
  //       ["status", selectedItem.value],
  //     ])}`,
  //   });
  // };

  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    searchParams.delete("bill_type");

    navigate({
      pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["bill_type", selectedItem.value],
      ])}`,
    });
  };
  const handleSelectDebitorType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    searchParams.delete("debitor_type");

    navigate({
      pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["debitor_type", selectedItem.value],
      ])}`,
    });
  };

  const handleSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };
  const handleClear = () => {
    navigate(`${MANAGE_DEBIT_DRAFT_ENDPOINT}?searchBy=invoice_date`);
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
      );
    }
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
    if (!searchParams.get("searchBy")) {
      navigate({
        pathname: `${MANAGE_DEBIT_DRAFT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["searchBy", "invoice_date"],
        ])}`,
      });
    }
  }, []);

  const getTableData = () => {
    dispatch(
      getDebitNoteDraft({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        invoiceno: searchParams.get("invoiceno") || "",
        subscriber_name: searchParams.get("subscriber_name") || "",
        searchBy: searchParams.get("searchBy") || "",
        status: searchParams.get("status") || "",
        from_date: searchParams.get("startDate") || "",
        to_date: searchParams.get("endDate") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        bill_type: searchParams.get("bill_type") || "",
        debitor_type: searchParams.get("debitor_type") || "",
        InvoiceType: searchParams.get("typeid") || "",
      })
    );
  };
  useEffect(() => {
    getTableData();
  }, [searchParams]);
  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists);
    }
  }, [typeLists]);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Debit Note - Draft">
            <div className="flex text-sm gap-3">
              <Button
                label="Previous"
                className="h-8"
                onClick={handleNavigation(MANAGE_DEBIT_PREVIOUS_ENDPOINT)}
              />
              {getPermission(loginData, [permissions.VIEW_DEBIT_NOTE]) && (
                <>
                  <Button
                    label="Current"
                    className="h-8 px-4"
                    onClick={handleNavigation(MANAGE_DEBIT_CURRENT_ENDPOINT)}
                  />
                  <div className="border-r border-[#CCC]"></div>
                </>
              )}
              {/* <Button
                label="Upload Debit Note"
                onClick={handleNavigation("")}
              /> */}
            </div>
          </ListHeader>
        </div>
        {getPermission(loginData, [permissions.VIEW_DRAFT_DEBIT_NOTE]) ? (
          <>
            <div className="p-1 flex flex-row justify-between items-center gap-4 mt-1">
              <Search
                height="8"
                onclick={handleSearch}
                value={searchParams.get("invoiceno")}
                placeholder="Search Bill No."
              />
              <div className="flex justify-between items-center gap-3">
                <div className="text-sm">Invoice Type</div>
                <Dropdown2
                  options={
                    invoiceTypeList
                      ? invoiceTypeList?.map((item) => ({
                          label: item?.invoice_title,
                          value: item?.id,
                        }))
                      : []
                  }
                  placeholder="Invoice type"
                  className="text-[11px] ms-4"
                  width="172px"
                  height="32px"
                  onSelect={(selectedItem) => {
                    handelSelectInvoiceType(selectedItem);
                  }}
                  value={
                    searchParams.get("typeid") && {
                      value: searchParams.get("typeid"),
                      label: invoiceTypeList
                        ? invoiceTypeList?.find(
                            (item) =>
                              item?.id === parseInt(searchParams.get("typeid"))
                          )?.invoice_title
                        : "",
                    }
                  }
                />
              </div>
              <div className="flex justify-between items-center gap-3">
                <div className="text-sm">Search By</div>
                <Dropdown2
                  options={
                    manageDebitNoteDraft?.data?.date_filter
                      ? Object.entries(
                          manageDebitNoteDraft?.data?.date_filter
                        )?.map((item) => ({
                          label: item?.[1],
                          value: item?.[0],
                        }))
                      : []
                  }
                  placeholder="Invoice Date"
                  className="text-[11px] ms-4"
                  width="160px"
                  height="32px"
                  onSelect={(selectedItem) => {
                    handeleSearchBy(selectedItem);
                  }}
                  value={
                    searchParams.get("searchBy") && {
                      value: searchParams.get("searchBy"),
                      label:
                        manageDebitNoteDraft?.data?.date_filter?.[
                          searchParams.get("searchBy")
                        ],
                    }
                  }
                />
                <div className="border-r border-[#CCC] h-full"></div>

                <div className="flex justify-between gap-3 items-center">
                  <RangeDatePicker
                    className={"w-[190px] h-8"}
                    defaultStartValue={
                      searchParams.get("startDate")
                        ? new Date(searchParams.get("startDate"))
                        : ""
                    }
                    defaultEndValue={
                      searchParams.get("endDate")
                        ? new Date(searchParams.get("endDate"))
                        : ""
                    }
                    onStartDateChange={handleStartDate}
                    onEndDateChange={handleEndDate}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2 p-2">
              {(loginData?.user_type == "Society"
                ? true
                : !getPermission(loginData, [
                    permissions.VIEW_OWN_DEBIT_NOTE,
                  ])) && (
                <>
                  {" "}
                  <Dropdown2
                    options={
                      wingsData?.map((item) => ({
                        value: item?.wing_id,
                        label: item?.number,
                      })) || []
                    }
                    placeholder="Select Wing"
                    className="text-[11px] ms-4"
                    width="172px"
                    height="32px"
                    onSelect={(selectedItem) => {
                      handleSelectWing(selectedItem);
                    }}
                    value={
                      searchParams.get("wingId") && {
                        value: searchParams.get("wingId"),
                        label: wingsData?.find(
                          (item) =>
                            item.wing_id?.toString() ===
                            searchParams.get("wingId")
                        )?.number,
                      }
                    }
                  />
                  <Dropdown2
                    options={
                      (searchParams.get("wingId") &&
                        flatsData?.manage_flat_details?.map((item) => ({
                          value: item?.flat_id,
                          label: item?.flat_no,
                        }))) ||
                      []
                    }
                    placeholder="Select Flat"
                    className="text-[11px] ms-4"
                    width="172px"
                    height="32px"
                    onSelect={(selectedItem) => {
                      handleSelectFlat(selectedItem);
                    }}
                    value={
                      searchParams.get("flatId") && {
                        value: searchParams.get("flatId"),
                        label: flatsData?.manage_flat_details?.find(
                          (item) =>
                            item.flat_id?.toString() ===
                            searchParams.get("flatId")
                        )?.flat_no,
                      }
                    }
                  />
                </>
              )}
              {/* <Dropdown2
            options={
              manageDebitNoteDraft?.data?.bill_status
                ? Object.entries(manageDebitNoteDraft?.data?.bill_status)?.map(
                    (item) => ({
                      label: item?.[1],
                      value: item?.[0],
                    })
                  )
                : []
            }
            placeholder="Select Status"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectStatus(selectedItem);
            }}
            value={
              searchParams.get("status") && {
                value: searchParams.get("status"),
                label:
                  manageDebitNoteDraft?.data?.bill_status?.[
                    searchParams.get("status")
                  ],
              }
            }
          /> */}
              {/* <Dropdown2
            options={
              manageDebitNoteDraft?.data?.invoice_type
                ? Object.entries(manageDebitNoteDraft?.data?.invoice_type)?.map(
                    (item) => ({
                      label: item?.[1],
                      value: item?.[0],
                    })
                  )
                : []
            }
            placeholder="Select Bill Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectBillType(selectedItem);
            }}
            value={
              searchParams.get("bill_type") && {
                value: searchParams.get("bill_type"),
                label:
                  manageDebitNoteDraft?.data?.invoice_type?.[
                    searchParams.get("bill_type")
                  ],
              }
            }
          /> */}
              <Dropdown2
                options={
                  manageDebitNoteDraft?.data?.debitor_type
                    ? Object.entries(
                        manageDebitNoteDraft?.data?.debitor_type
                      )?.map((item) => ({
                        label: item?.[1],
                        value: item?.[0],
                      }))
                    : []
                }
                placeholder="Select Debitor Type"
                className="text-[11px] ms-4"
                width="172px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleSelectDebitorType(selectedItem);
                }}
                value={
                  searchParams.get("debitor_type") && {
                    value: searchParams.get("debitor_type"),
                    label:
                      manageDebitNoteDraft?.data?.debitor_type?.[
                        searchParams.get("debitor_type")
                      ],
                  }
                }
              />
              <ButtonG label="Clear" className="h-8" onClick={handleClear} />
            </div>
            <div>
              <ManageDebitNoteDraftTable
                loading={loading}
                data={manageDebitNoteDraft?.data?.debitnote_details?.map(
                  (item, index) => ({
                    sNo:
                      manageDebitNoteDraft?.data?.paginate?.current *
                        manageDebitNoteDraft?.data?.paginate?.limit +
                      1 +
                      index,
                    id: item.id,
                    // invoiceNo: item?.invoice_no,
                    memberDetails: item?.subscriber_name,
                    wing_title: item?.wing_title,
                    flatNo: item?.flat?.flat_no,
                    billDate: item.invoice_date
                      ? moment(new Date(item?.invoice_date))?.format(
                          "DD-MM-YYYY"
                        )
                      : "-",
                    generationDate: item?.CreatedOn
                      ? moment(new Date(item?.CreatedOn))?.format("DD-MM-YYYY")
                      : "-",
                    amount: item.grandtotal,
                    amountPaid: item.amount_paid,
                    amountDue: item.dueAmount,
                    status: "Draft" || item.row_status,
                  })
                )}
                columns={headingsDebitNote}
                checkbox={true}
                pagination={
                  manageDebitNoteDraft?.data?.debitnote_details?.length
                }
                totalCount={manageDebitNoteDraft?.data?.paginate?.total_count}
                totalPages={manageDebitNoteDraft?.data?.paginate?.total_page}
                start={
                  manageDebitNoteDraft?.data?.paginate?.current *
                    manageDebitNoteDraft?.data?.paginate?.limit +
                  1
                }
                end={
                  manageDebitNoteDraft?.data?.paginate?.current *
                    manageDebitNoteDraft?.data?.paginate?.limit +
                  manageDebitNoteDraft?.data?.debitnote_details?.length
                }
                currentPage={parseInt(
                  manageDebitNoteDraft?.data?.paginate?.current
                )}
                rowsPerPage={searchParams.get("pageLimit") || 10}
                selected={selected}
                setSelected={setSelected}
                getTableData={getTableData}
              ></ManageDebitNoteDraftTable>

              {!manageDebitNoteDraft?.data?.debitnote_details?.length &&
                !loading && (
                  <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                    No Records
                  </div>
                )}
            </div>
          </>
        ) : (
          <div className="pt-5 p-2 text-center font-bold">
            You don't have access
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ManageDebitDraft;
