import * as type from "../../actions/index.types";

export function societyReportReducer(
  state = {
    loading: false,
    societyEmailReport: [],
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_SOCIETY_EMAIL_REPORTS:
      return action?.data?.export == 1
        ? { ...state }
        : {
            ...state,
            loading: true,
            error: null,
          };
    case type.GET_SOCIETY_EMAIL_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        societyEmailReport: action.payload,
      };
    case type.GET_SOCIETY_EMAIL_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        downLoader: false,
        societyEmailReport: [],
      };
    case type.GET_MEMBER_LEDGER_REPORTS:
      return {
            ...state,
            loading: true,
            error: null,
            reportList: [],
          };
    case type.GET_MEMBER_LEDGER_REPORTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        reportList: action.payload,
      };
    case type.GET_MEMBER_LEDGER_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        reportList: [],
      };
    default:
      return state;
  }
}
