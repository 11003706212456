import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Search from "../../../../components/SearchBox/Search";
import ListHeaderBack from "../../../../components/ListHeader/ListHeaderBack";
import ViewManagingCommitteeTable from "./ViewManagingCommitteeTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getManagingCommitteeDetails } from "../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction";
import { VIEW_COMMITTEE_FORM_ENDPOINT } from "../../../../Routing/routes";
import moment from "moment";
import {DatePickerComponent} from "../../../../components/DatePicker/DatePicker";
import ButtonG from "../../../../components/Button/ButtonG";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../../assets";
import { downloadFileFromURL } from "../../../../utils/helpers/downloadMedia";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false, width: '50px' },
  {
    id: "members",
    label: "Members(Flat No. & Wing No)",
    sort: true,
    width: "220px",
  },
  { id: "roles", label: "Roles & Responsibility", sort: true, width: '180px' },
  { id: "contact", label: "Contact No.", sort: true, width: '100px' },
  { id: "joinDate", label: "Join Date", sort: true, width: '100px' },
  { id: "leaveDate", label: "Leave Date", sort: true, width: '100px' },
  { id: "status", label: "Status", sort: true, width: '150px' },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];


const ViewManagingCommittee = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loading, managingCommitteeData} = useSelector(state=> state.managingCommitteeReducer)
  const {id} = useParams()

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete('pageLimit')
      searchParams.delete("search")
      navigate({
        pathname: `${VIEW_COMMITTEE_FORM_ENDPOINT}/${id}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text?.trim()]])}`,
      })
    }
  }

  const handleJoinDate = (selectedDate)=>{
    if(selectedDate && moment(selectedDate)?.format('YYYY-MM-DD') !== searchParams.get('joinDate')){
      searchParams.delete('pageLimit')
      searchParams.delete('joinDate')
      navigate({
        pathname: `${VIEW_COMMITTEE_FORM_ENDPOINT}/${id}`,
        search: `?${createSearchParams([...searchParams.entries(), ['joinDate', moment(selectedDate)?.format('YYYY-MM-DD')]])}`,
      })
    }
  }


  const handleLeaveDate = (selectedDate)=>{
    if(selectedDate && moment(selectedDate)?.format('YYYY-MM-DD') !== searchParams.get('leaveDate')){
      searchParams.delete('pageLimit')
      searchParams.delete('leaveDate')
      navigate({
        pathname: `${VIEW_COMMITTEE_FORM_ENDPOINT}/${id}`,
        search: `?${createSearchParams([...searchParams.entries(), ['leaveDate', moment(selectedDate)?.format('YYYY-MM-DD')]])}`,
      })
    }
  }

  const handleClear = ()=>{
    navigate(`${VIEW_COMMITTEE_FORM_ENDPOINT}/${id}`)
  }

  const getTableData =() =>{
    const data={
      committeeId: id,
      searchText: searchParams.get("search") || '',
      joinDate: searchParams.get("joinDate") || '',
      leaveDate: searchParams.get("leaveDate") || '',
    }

    dispatch(getManagingCommitteeDetails(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="">
          <ListHeaderBack className='capitalize' title={`View Managing Committees  ${!loading && managingCommitteeData? `- ${managingCommitteeData?.title}` : ''}`} onClick={() => {navigate(-1)}}>
            {managingCommitteeData?.resolution_doc_url && <ButtonIco icon={DownloadExt} children="Download Resolution Document" onClick={()=>{downloadFileFromURL({url: managingCommitteeData?.resolution_doc_url, fileName: 'Resolution Doc'})}} type='button' />}
          </ListHeaderBack>
        </div>
        <div className="border-b border-[#CCCCCC]"></div>
        <div className="flex justify-between gap-2 py-2">
          <Search height="8" placeholder={'Search...'} onclick={handleSearch} value={searchParams.get("search")} />          
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <span className="mr-4 text-[13px]">Join Date:</span>
              <DatePickerComponent
                className="w-40 h-8" 
                onDateChange={handleJoinDate} 
                defaultValue={searchParams.get('joinDate') ? new Date(searchParams.get('joinDate')) : ''}
              />
            </div>
            <div className="flex items-center">
              <span className="mr-4 text-[13px]">Leave Date:</span>
              <DatePickerComponent 
                className="w-40 h-8" 
                onDateChange={handleLeaveDate} 
                defaultValue={searchParams.get('leaveDate') ? new Date(searchParams.get('leaveDate')) : ''}
              />
            </div>
            <ButtonG label='Clear' className='h-8' onClick={handleClear} />
          </div>
        </div>

        <div className="">
          <ViewManagingCommitteeTable
            loading={loading && managingCommitteeData?.committee_id?.toString() !== id}
            data={managingCommitteeData?.members?.map((item, index)=>({
              sNo: (index+1)?.toString()?.padStart(2, "0"),
              roleId:item?.role_data_id,
              members: item?.subscriber_name || '-' ,
              flat: item?.flat_no || '-',
              roles: item?.roles  || '-',
              contact: item?.subscriber_number || '-',
              joinDate: validateDate(item?.join_date) ? moment(new Date(item?.join_date))?.format('DD-MM-YYYY') : '-',
              leaveDate: validateDate(item?.leave_date) ? moment(new Date(item?.leave_date))?.format('DD-MM-YYYY') : '-',
              status: item?.resign_retire_status === 'RES' ? 
                'Pending approval for Resignation	' : 
                (item?.resign_retire_status === 'RET' ? 
                  'Pending approval for Retirement' : 
                  (item?.leave_date && moment(item?.leave_date)?.isBefore(new Date()) ? 'Not Working' : 'Working')),

              statusType: item?.resign_retire_status
            }))}
            columns={headCells}
            checkbox={false}
            pagination={false}
            getTableData={getTableData}
          ></ViewManagingCommitteeTable>
        </div>

        {!managingCommitteeData?.members?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-4 text-center">
              No Records
            </div>
          )}
      </div>
    </Layout>
  );
};

export default ViewManagingCommittee;
