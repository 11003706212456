import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import {
  MANAGE_DEBIT_DRAFT_ENDPOINT,
} from "../../../../Routing/routes";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import {useNavigate,} from "react-router-dom";
import { DatePickerComponent } from "../../../../components/DatePicker/DatePicker";
import ButtonG from "../../../../components/Button/ButtonG";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addMultipleDebiteNote,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { NumberInput } from "../../../../components/InputFields/NumberInput";
import styled from "@emotion/styled";
import Calculation from "../../../../utils/helpers/debitNoteCalculation"
import { CircularProgress } from "@mui/material";
import Checkbox from "../../../../components/Checkbox/Checkbox";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  // &:nth-of-type(even) {
  //   background-color: #f4f5f8;
  // }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  // &:nth-of-type(even):hover {
  //   background-color: #f4f5f8;
  // }
`;

const Accordion = ({
  label,
  error,
  errorMsg,
  isOpen,
  toggleAccordion,
  children,
  totalAmount,
}) => {
  return (
    <div className="border rounded-lg border-[#CCCCCC] my-2">
      <div
        className="cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b"
        onClick={toggleAccordion}
      >
        <div className="flex items-center w-full">
          <div>
            <button
              className="flex items-center justify-center w-5 h-5 mr-3 text-base font-semibold text-white border rounded bg-red-650"
            >
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
          </div>
          <div className="text-[#000000] grow w-full text-sm font-semibold justify-between flex">
            <span className="">{label}</span>
            <div className="flex gap-2">
              {(error ?
                <span className="text-red-650">{errorMsg}</span>
                : <span >Total Amount: {totalAmount}</span>)}
            </div>
          </div>
        </div>
      </div>
      {isOpen && <>{children}</>}
    </div>
  );
};

// const DebitNoteMemberTypeOptions = [
//   { value: "Member", label: "Members" },
//   { value: "Vendor", label: "Non Member" },
//   { value: "Tenant", label: "Tenants" },
// ];
// const BillingType = [
//   { value: "debit_note", label: "Debit Note" },
//   { value: "supplementary", label: "Supplementary", isDisabled: false },
// ];
const headCells = [
  { label: "Tariff Header" },
  { label: "Credit A/C" },
  { label: "HSN Code" },
  { label: "Amount (Rs.)" },
  { label: "Description" },
  // { label: "From Date" },
  // { label: "To Date" },
];

const MultipleDebitNoteDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  
  const {
    detail,
    updateLoader,
    loading,
  } = useSelector((state) => state.manageDebitNoteReducer);
  const [debitNoteData, setDebitNoteData] = useState(detail);

  const [openAccordionIndex, setOpenAccordionIndex] = useState([]);
  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  useEffect(() => {
    if (detail.invoice) {
      detail.invoice.forEach((id,index) => {
        var data = Calculation(detail, index);
        Object.assign(detail, data);
      });
      setDebitNoteData({ ...detail });
    }
  }, [detail]);
  const handleCalculate = (data,index) => {
    var data1 = Calculation(data,index)
    Object.assign(data, data1);
    setDebitNoteData({...data})
  }
  const handleSave = (is_draft) => {
    console.log(debitNoteData.invoice);
    dispatch(
      addMultipleDebiteNote({
        debitList : debitNoteData.invoice,
        societyId : loginData?.default_community?.community_id,
        is_draft : is_draft, 
        onSuccessCallback: () => navigate(MANAGE_DEBIT_DRAFT_ENDPOINT),
    }));
  }
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">

        {/* <Formik
          initialValues={{
            debitNoteIds: Object.entries(editSingleDebitNoteDetail)
              .filter((item) => item?.[0] != "debitnote_header")
              .map((item, index) => item?.[0]),
            draftDebitNoteDetails: Object.entries(editSingleDebitNoteDetail)
              .filter((item) => item?.[0] != "debitnote_header")
              .map((item, index) => item?.[1]),
            debitnoteHeader: editSingleDebitNoteDetail?.debitnote_header,
            is_draft: 0,
          }}
          enableReinitialize={enableReinitialize}
          validationSchema={AddMultiDebitNoteValidation}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue }) => (
            <Form> */}
        <>
          <div className="border-b border-[#CCC]">
            <ListHeader leftContent="Add Debit Note">
              <div className="flex gap-2">
                <ButtonG
                  label="Cancel"
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="h-8"
                  type="button"
                />
                {/* <Button label="Save" className="h-8 px-5" type="submit" /> */}
                <Button
                  label={
                    updateLoader ? (
                      <CircularProgress sx={{ color: "white" }} size={17} />
                    ) : (
                      "Save as draft"
                    )
                  }
                  className="h-8 px-5"
                  onClick={() => {
                    handleSave(1);
                  }}
                />
              </div>
            </ListHeader>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {debitNoteData?.invoice.map((data, index) => {
                return (
                  <Accordion
                    key={data?.flat_id}
                    label={data?.member_name}
                    error={data?.error || false}
                    totalAmount={data?.grandtotal}
                    errorMsg={`${data?.error
                      ? data?.error_msg : ""
                      }`}
                    isOpen={index === openAccordionIndex}
                    toggleAccordion={() => {
                      //setEnableReinitialize(false);
                      toggleAccordion(index);
                    }}
                  >
                    <div className="p-3 text-[#222222] text-sm flex">
                      <div className="w-1/2">
                        {/* <div className="flex gap-5 mb-4">
                          <span className="w-[30%]">Invoice Number:</span>
                          <span>{data.invoiceNumber}</span>
                        </div> */}
                        <div className="flex gap-5 mb-4">
                          <span className="w-[30%]">Member Name:</span>
                          <span>{data.member_name}</span>
                        </div>
                        <div className="flex gap-5 mb-4">
                          <span className="w-[30%]">Unit:</span>
                          <span>{data.unit}</span>
                        </div>
                        <div className="flex gap-5 mb-4">
                          <span className="w-[30%]">Area:</span>
                          <span>{data.flatarea}</span>
                        </div>
                        <div className="flex gap-5 mb-4">
                          <span className="w-[30%]">GST Registration No.:</span>
                          <span>{data.gstRegistrationNo}</span>
                        </div>
                        <div className="flex gap-5 mb-4">
                          <span className="w-[30%]">Type:</span>
                          <span>{data.bill_type}</span>
                        </div>
                      </div>
                      <div className="w-1/2">
                        <div className="flex items-center justify-between mb-4">
                          <span>Debit Note Date:</span>
                          <span className="mr-12">
                            <DatePickerComponent
                              defaultValue={data.invoice_date ? new Date(data.invoice_date) : null}
                              className="w-52 h-9"
                              onDateChange={(selectedDate) => {
                                setDebitNoteData((prev) => {
                                  let newData = { ...prev };
                                  newData.invoice[index].invoice_date = moment(selectedDate).format("YYYY-MM-DD");
                                  return newData;
                                });
                              }}
                            />
                          </span>
                        </div>
                        <div className="flex items-center justify-between mb-4">
                          <span>Payment Due Date:</span>
                          <span className="mr-12">
                            <DatePickerComponent
                              defaultValue={data.due_date ? new Date(data.due_date) : null}
                              className="w-52 h-9"
                              onDateChange={(selectedDate) => {
                                setDebitNoteData((prev) => {
                                  let newData = { ...prev };
                                  newData.invoice[index].due_date = moment(selectedDate).format("YYYY-MM-DD");
                                  return newData;
                                });
                              }}
                            />
                          </span>
                        </div>
                        <div className="flex items-end justify-end mb-4">
                          {/* <span className="text-sm">Show Unit As “Unit: Wing No.</span> */}
                          <span className="w-64">
                            <Checkbox
                              text=" Send Email Notification To User"
                              className="text-xs"
                              initialChecked={data.send_email==='Y'}
                              onChange={(e) => {
                                setDebitNoteData((prev) => {
                                  let newData = { ...prev };
                                  newData.invoice[index].send_email = e.target.checked ? 'Y' : 'N';
                                  return newData;
                                });
                              }}
                            />
                          </span>
                        </div>

                        <div className="flex items-end justify-end">
                          {/* <span className="text-sm">Show Flat Area</span> */}
                          <div className="flex items-center justify-between w-64 gap-3">
                            <Checkbox
                              text=" Send SMS Notification To User"
                              className="-mt-3 text-xs"
                              initialChecked={data.send_sms==='Y'}
                              onChange={(e) =>{
                                setDebitNoteData((prev) => {
                                  let newData = { ...prev };
                                  newData.invoice[index].send_sms = e.target.checked ? 'Y' : 'N';
                                  return newData;
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      {data?.head_details &&
                        <Box
                          sx={{ width: "100%", height: "67%", fontSize: "13px" }}
                          className="table-component"
                        >
                          <TableContainer
                            className="border-t border-[#CCC]"
                            sx={{ minHeight: "500px" }}
                          >
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  {headCells.map((column) => (
                                    <TableCell
                                      size="small"
                                      key={column.id}
                                      align={column.numeric ? "right" : "left"}
                                      //sortDirection={orderBy === column.id ? order : false}
                                      sx={{
                                        pl: 0,
                                        pr: 1,
                                        fontWeight: 600,
                                        zIndex: 0,
                                        color: "#AAAAAA",
                                        wordWrap: "break-word",
                                        fontSize: "13px",
                                        width: column.width || "fit-content",
                                        minWidth: column.width || "fit-content",
                                      }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data?.head_details.map((key,id) => {
                                  return (
                                    <TableRowStyled
                                      key={key.id}
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell
                                        size="small"
                                        ////key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        {key?.cause}
                                      </TableCell>
                                      <TableCell
                                        size="small"
                                        ////key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        {key?.gl_name}
                                      </TableCell>
                                      <TableCell
                                        size="small"
                                        //key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        {key?.hsn_code}
                                      </TableCell>
                                      <TableCell
                                        size="small"
                                        key="amount"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        <NumberInput
                                          placeholder="Enter Amount"
                                          className="w-[200px]"
                                          value={key.amount || 0}
                                          type="number"
                                          onChange={(e) => {
                                            debitNoteData.invoice[index].head_details[id].amount = !isNaN(e.target.value) ? parseFloat(e.target.value) : 0;
                                            handleCalculate(debitNoteData, index)
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        size="small"
                                        ////key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        {key?.description || ""}
                                      </TableCell>
                                      
                                    </TableRowStyled>
                                  )
                                })}
                              </TableBody>
                              {(detail?.debitnote_header?.cgst_head && detail?.debitnote_header?.cgst_rate && detail?.debitnote_header?.cgst_rate>0) && <TableRowStyled
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell
                                        size="small"
                                        //key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        SGST
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell
                                        size="small"
                                        key="amount"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        <NumberInput
                                          placeholder="Enter Amount"
                                          className="w-[200px]"
                                          value={data.cgst_amount || 0}
                                          type="number"
                                          onChange={(e) => {
                                            debitNoteData.invoice[index].sgst_amount = !isNaN(e.target.value) ? parseFloat(e.target.value) : 0;
                                            handleCalculate(debitNoteData, index)
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      
                                    </TableRowStyled>}
                                    {(detail?.debitnote_header?.sgst_head && detail?.debitnote_header?.sgst_rate && detail?.debitnote_header?.sgst_rate>0) && <TableRowStyled
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell
                                        size="small"
                                        //key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        CGST
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell
                                        size="small"
                                        key="amount"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        <NumberInput
                                          placeholder="Enter Amount"
                                          className="w-[200px]"
                                          value={data.sgst_amount || 0}
                                          type="number"
                                          onChange={(e) => {
                                            debitNoteData.invoice[index].cgst_amount = !isNaN(e.target.value) ? parseFloat(e.target.value) : 0;
                                            handleCalculate(debitNoteData, index)
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      
                                    </TableRowStyled>}
                                    {(detail?.debitnote_header?.igst_head && detail?.debitnote_header?.igst_rate && detail?.debitnote_header?.igst_rate>0) && <TableRowStyled
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell
                                        size="small"
                                        //key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        IGST
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell
                                        size="small"
                                        key="amount"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        <NumberInput
                                          placeholder="Enter Amount"
                                          className="w-[200px]"
                                          value={data.igst_amount || 0}
                                          type="number"
                                          onChange={(e) => {
                                            debitNoteData.invoice[index].igst_amount = !isNaN(e.target.value) ? parseFloat(e.target.value) : 0;
                                            handleCalculate(debitNoteData, index)
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      
                                    </TableRowStyled>}
                                    {(detail?.debitnote_header?.roundoff_head && detail?.debitnote_header?.roundoff === 1) && <TableRowStyled
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell
                                        size="small"
                                        //key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        Round Off
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell
                                        size="small"
                                        key="amount"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        <NumberInput
                                          placeholder="Enter Amount"
                                          className="w-[200px]"
                                          value={data.roundoff_amount || 0}
                                          type="number"
                                          disabled={true}
                                        />
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      
                                    </TableRowStyled>}
                                    <TableRowStyled
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell
                                        size="small"
                                        //key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        Subtotal
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell
                                        size="small"
                                        key="amount"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        <NumberInput
                                          placeholder="Enter Amount"
                                          className="w-[200px]"
                                          value={data.subtotal || 0}
                                          type="number"
                                          disabled={true}
                                        />
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      
                                    </TableRowStyled>
                                    <TableRowStyled
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell
                                        size="small"
                                        //key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        Principal Arrear
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell
                                        size="small"
                                        key="amount"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        <NumberInput
                                          placeholder="Enter Amount"
                                          className="w-[200px]"
                                          value={data.principal_arrear || 0}
                                          type="number"
                                          disabled={true}
                                        />
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      
                                    </TableRowStyled>
                                    <TableRowStyled
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell
                                        size="small"
                                        //key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        Interest Arrear
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell
                                        size="small"
                                        key="amount"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        <NumberInput
                                          placeholder="Enter Amount"
                                          className="w-[200px]"
                                          value={data.interest_arrear || 0}
                                          type="number"
                                          disabled={true}
                                        />
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      
                                    </TableRowStyled>        
                                    <TableRowStyled
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell
                                        size="small"
                                        //key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        Interest 
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell
                                        size="small"
                                        key="amount"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        <NumberInput
                                          placeholder="Enter Amount"
                                          className="w-[200px]"
                                          value={data.interest || 0}
                                          type="number"
                                          onChange={(e) => {
                                            debitNoteData.invoice[index].interest = !isNaN(e.target.value) ? parseFloat(e.target.value) : 0;
                                            handleCalculate(debitNoteData, index)
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      
                                    </TableRowStyled>        
                                    <TableRowStyled
                                      role="checkbox"
                                      tabIndex={-1}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <TableCell
                                        size="small"
                                        //key="name"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        Grand Total 
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      <TableCell
                                        size="small"
                                        key="amount"
                                        align="left"
                                        sx={{
                                          borderBottom: "none",
                                          fontSize: "13px",
                                          pl: 0,
                                          pr: 1,
                                          wordBreak: "break-word",
                                          width: "150px",
                                        }}
                                      >
                                        <NumberInput
                                          placeholder="Enter Amount"
                                          className="w-[200px]"
                                          value={data.grandtotal || 0}
                                          type="number"
                                          disabled={true}
                                        />
                                      </TableCell>
                                      <TableCell sx={{borderBottom: "none",}}></TableCell>
                                      
                                    </TableRowStyled>        
                            </Table></TableContainer>
                        </Box>
                      }
                    </div>
                  </Accordion>
                
                );
              })}
            </>
          )}
        </>
        {/* </Form>
          )}
        </Formik> */}
      </div>
    </Layout>
  );
};
export default MultipleDebitNoteDetail;
