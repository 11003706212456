import React, { useEffect, useRef } from 'react';
import Layout from '../../../Layout/Layout';
import ListHeader from '../../../../components/ListHeader/ListHeader';
import Dropdown2 from '../../../../components/Dropdown/dropdown2';
import InvoiceDetailsForm from './InvoiceDetailsForm';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getWingsAndFlatDetails } from '../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction';
import { useSelector } from 'react-redux';
import { INVOICE_DETAILS_MAINTENANCE_AND_OTHER_CHARGES } from '../../../../Routing/routes';
import Loader from '../../../../components/Loader/Loader'
import { getMasterHeadDetails } from '../../../../redux/actions/FinanceActions/SettingActions/InvoiceDetailsAction';
import Button from '../../../../components/Button/Button';
import { CircularProgress } from '@mui/material';
import { getPermission } from "../../../../utils/helpers/getPermissions";
import * as permissions from "../../../../Routing/permissions";

const InvoiceDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const {wingsData} = useSelector(state=> state.wingsReducer)
  const {loading, updateLoader, invoiceDetails} = useSelector(state=> state.invoiceDetailsReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const formRef = useRef();

  const handleWingChange = (selectedItem)=>{
    navigate({
      pathname: INVOICE_DETAILS_MAINTENANCE_AND_OTHER_CHARGES,
      search: `?${createSearchParams([['wingId', selectedItem.value]])}`,
    }, { replace: true })
  }
  useEffect(()=>{
    dispatch(getWingsAndFlatDetails())
  }, [])
  useEffect(()=>{
    if(searchParams.get("wingId"))
      dispatch(getMasterHeadDetails({societyId: loginData?.default_community?.community_id, wingId: searchParams.get("wingId")}))
  }, [searchParams])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCCCCC] rounded-lg p-2">
        <div className='border-b border-[#CCC]'>
          <ListHeader leftContent={`Invoice Details - Maintenance & Other Charges - ${wingsData?.filter(item=>  item?.wing_id?.toString() === searchParams.get("wingId")?.toString())[0]?.number ? wingsData?.filter(item=>  item?.wing_id?.toString() === searchParams.get("wingId")?.toString())[0]?.number : ''} `}>
            <div className="flex items-center justify-between gap-2">
              <p className="text-[13px]">Select Wing: </p>
              <Dropdown2
                options={wingsData?.map((item) => ({
                  label: item.number,
                  value: item.wing_id
                })) || []}
                className="text-xs font-semibold"
                width="180px"
                height="32px"
                placeholder="Select Wing"
                onSelect={handleWingChange}
                value={wingsData?.find(item=>  item?.wing_id?.toString() === searchParams.get("wingId")?.toString()) ? {
                  label: wingsData?.filter(item=>  item?.wing_id?.toString() === searchParams.get("wingId")?.toString())[0]?.number,
                  value: searchParams.get("wingId")
                } : null}
              />
            </div>
            <div className="border-r border-[#CCC] h-full"></div>
            {getPermission(loginData, [permissions.EDIT_MASTER_TARIFF_SETTINGS]) && <Button label={
                  <span className="inline-block w-8">
                    {
                      updateLoader ?
                        <CircularProgress sx={{color: "white"}} size={17}/>
                        :
                        'Save'
                      }
                  </span>
                } 
                onClick={()=>formRef?.current?.submitForm?.()} className="h-8 px-4 mr-2"
                disabled={loading || !wingsData?.find(item=>  item?.wing_id?.toString() === searchParams.get("wingId")?.toString()) || updateLoader}
              />}
          </ListHeader>
        </div>
          <div>
          {loading || !wingsData ?
          <Loader/>
          :
          wingsData?.find(item=>  item?.wing_id?.toString() === searchParams.get("wingId")?.toString()) ?
            (
              <div>
                <InvoiceDetailsForm formRef={formRef} invoiceDetails={invoiceDetails}  wingId={searchParams.get("wingId")} />
              </div>
            ) : (
              <div className="mt-10 text-center">Select Wing First</div>
            )}
          </div>
      </div>
    </Layout>
  );
};

export default InvoiceDetails;
