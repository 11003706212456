import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import FilterTextField from "../../../../components/InputFields/FilterTextFields";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { TextInput } from "../../../../components/InputFields/TextInput";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt, UploadEx, PrinterIcon } from "../../../../assets";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT,
  PRINT_DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT,
} from "../../../../Routing/routes";
import ManageDebitCurrentTable from "../../../../components/TableComponent/ManageDebitCurrentTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  downloadExcel,
  downloadFileFromURL,
  exportToCSV,
} from "../../../../utils/helpers/downloadMedia";

import ButtonG from "../../../../components/Button/ButtonG";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { getDebitNoteInterestCalculationReport } from "../../../../redux/actions/ReportActions/FinanceReportActions";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import DebitNoteInterestCalculationReportTable from "./DebitNoteInterestCalculationReportTable";
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false, width: "40px" },
  { id: "Debit note No.", label: "Debit Note No.", sort: true, width: "160px" },
  { id: "Flat No.", label: "Flat No.", sort: true, width: "150px" },
  { id: "Member Name", label: "Member Name", sort: true, width: "100px" },
  {
    id: "Bill Amount",
    label: "Bill Amount",
    sort: true,
    width: "130px",
  },
  {
    id: "Interest in Debit note",
    label: "Interest in Debit note",
    sort: false,
    width: "130px",
  },
  {
    id: "Calculated Interest",
    label: "Calculated Interest",
    sort: true,
    width: "100px",
  },
  { id: "Bill Date", label: "Bill Date", sort: true, width: "100px" },
];

const DebitNoteInterestCalculationReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);

  const [selected, setSelected] = useState([]);

  const { debitNoteInterestCalculationtReport, loading } = useSelector(
    (state) => state.financeReportReducer
  );

  const handleDownloadExcel = () => {
    dispatch(
      getDebitNoteInterestCalculationReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("subscriber_name") || "",
        invoiceType: searchParams.get("invoiceType") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        export: 1,
        onSuccessCallback: (url) => {
          downloadFileFromURL({ url, fileName: "sample.csv" });
        },
      })
    );
  };
  const handlePrintData = () => {
    navigate({
      pathname: `${PRINT_DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries()])}`,
    });
  };
  const handleInvoiceType = (text) => {
    if (text && text !== searchParams.get("invoiceType")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("invoiceType");
      navigate({
        pathname: `${DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["invoiceType", text],
        ])}`,
      });
    }
  };
  const handleClear = () => {
    navigate(`${DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT}`);
  };

  const getTableData = () => {
    dispatch(
      getDebitNoteInterestCalculationReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("subscriber_name") || "",
        invoiceType: searchParams.get("invoiceType") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        export: 0,
      })
    );
  };
  useEffect(() => {
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);
  useEffect(() => {
    getTableData();
  }, [searchParams]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Debit Note Interest Calculation Report">
            <div className="flex text-sm gap-3">
              <div className="flex justify-between items-center gap-3">
                <div className="text-sm">Invoice Type</div>
                <Dropdown2
                  options={
                    typeLists.length
                      ? typeLists?.map((item) => ({
                          label: item?.invoice_title,
                          value: item?.id,
                        }))
                      : []
                  }
                  placeholder="Invoice type"
                  className="text-[11px] ms-4"
                  width="172px"
                  height="32px"
                  onSelect={(selectedItem) => {
                    handleInvoiceType(selectedItem.value);
                  }}
                  value={
                    searchParams.get("invoiceType") && {
                      value: searchParams.get("invoiceType"),
                      label: typeLists.length
                        ? typeLists?.find(
                            (item) =>
                              item?.id ===
                              parseInt(searchParams.get("invoiceType"))
                          )?.invoice_title
                        : "",
                    }
                  }
                />
                <ButtonG label="Clear" className="h-8" onClick={handleClear} />
              </div>
              <button
                type="button"
                onClick={handlePrintData}
                className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
              >
                <PrinterIcon className="mr-2 fill-red-650" /> Print
              </button>
              <ButtonIco
                type="button"
                onClick={handleDownloadExcel}
                icon={DownloadExt}
                children="Download Excel"
              />
              {/* <div className="border-r border-[#CCC]"></div> */}
            </div>
          </ListHeader>
        </div>

        <div>
          <DebitNoteInterestCalculationReportTable
            loading={loading}
            data={debitNoteInterestCalculationtReport?.data?.map(
              (item, index) => ({
                sNo:
                  debitNoteInterestCalculationtReport?.paginate?.current *
                    debitNoteInterestCalculationtReport?.paginate?.limit +
                  1 +
                  index,
                "Debit note No.": item?.["Debit note No."],
                "Flat No.": item?.["Flat No."],
                "Member Name": item?.["Member Name"],
                "Bill Amount": item?.["Bill Amount"],
                "Interest in Debit note": item?.["Interest in Debit note"],
                "Calculated Interest": item?.["Calculated Interest"],
                "Bill Date": item?.["Bill Date"],
                "Child Data": item?.["Child Data"],
              })
            )}
            columns={headCells}
            checkbox={false}
            pagination={debitNoteInterestCalculationtReport?.data?.length}
            totalCount={
              debitNoteInterestCalculationtReport?.paginate?.total_count
            }
            totalPages={
              debitNoteInterestCalculationtReport?.paginate?.total_page
            }
            start={
              debitNoteInterestCalculationtReport?.paginate?.current *
                debitNoteInterestCalculationtReport?.paginate?.limit +
              1
            }
            end={
              debitNoteInterestCalculationtReport?.paginate?.current *
                debitNoteInterestCalculationtReport?.paginate?.limit +
              debitNoteInterestCalculationtReport?.data?.length
            }
            currentPage={Number(
              debitNoteInterestCalculationtReport?.paginate?.current
            )}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
            selected={selected}
            setSelected={setSelected}
          ></DebitNoteInterestCalculationReportTable>

          {!debitNoteInterestCalculationtReport?.data?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default DebitNoteInterestCalculationReport;
