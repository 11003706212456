import React from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import SMSCreditPackageTable from "../../../components/TableComponent/SmsCreditPackage";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { SMS_CREDIT_ORDER_HISTORY_ENDPOINT } from "../../../Routing/routes";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "120px" },
  { id: "smsCredit", label: "SMS Credit", sort: false, width: "260px" },
  { id: "amount", label: "Amount", sort: true, width: "300px" },
  { id: "action", label: "Action", sort: true, width: "100px" },
];

const rows = [
  {
    id: `1`,
    smsCredit: "1000",
    amount: "350",
  },
  {
    id: `2`,
    smsCredit: "10000",
    amount: "450",
  },
];

const SmsCreditPackages = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
       
          <ListHeader leftContent="SMS Credit Package">
            <div className="text-[#AAA] items-center text-xs mt-2 font-semibold">Remaining SMS Credit (649)</div>
            <div className="border-r border-[#CCC]"></div>
            <Button
            label="Order History"
            onClick={handleNavigation(SMS_CREDIT_ORDER_HISTORY_ENDPOINT)}
          />
          </ListHeader>
        <div className="ml-1">
          <SMSCreditPackageTable
            data={rows}
            columns={headCells}
            checkbox={true}
            // pagination={staffPersonnelList?.staff_list?.length}
            // totalCount={staffPersonnelList?.total_count}
            // totalPages={staffPersonnelList?.total_pages}
            // start={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + 1}
            // end={(staffPersonnelList?.current_page - 1) * (searchParams.get("pageLimit") || 10) + staffPersonnelList?.staff_list?.length}
            // currentPage={parseInt(staffPersonnelList?.current_page)}
            // rowsPerPage={searchParams.get("pageLimit") || 10}
            // getTableData={getTableData}
          ></SMSCreditPackageTable>
        </div>
        {!rows?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SmsCreditPackages;
