import { notify } from '../../../utils/notification';
import apiEndpoints from '../../api';
import axiosInstance from '../../axiosInstance'
import {logOut} from '../../../utils/helpers/logOut'
import { errorMsg } from '../../../consts/responseMessages';
import moment from "moment";

export async function getRoleListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_ROLE_LIST_API}?society_id=${action.data.societyId}${action.data.roleType ? `&role_type=${action.data.roleType}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getRoleWiseListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_ROLE_WISE_LIST_API}?society_id=${action.data.societyId}${action.data.roleType ? `&role_type=${action.data.roleType}` : ''}${action.data.roleTypeId ? `&sub_role_type_id=${action.data.roleTypeId}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getVendorConcernedPersonListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_VENDOR_CONCERNED_PERSONS_LIST_API}?vendor_id=${action.data.vendorId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addFacilityReq(action) {

    const BODY= {
        "society_id": action.data.societyId,
        "facility_name": action.data.facilityName,
        "booking": action.data.booking,
        
        "is_chargeable": action.data.isChargeble,
        ...(action.data.chargeOn && {"charge_on": action.data.chargeOn}),
        "booking_avail_upto": action.data.bookingAvailableUpto,
        "booking_avail_upto_days":action.data.bookingAvailableUpto ?  Math.ceil(moment(new Date(action.data.bookingAvailableUpto)).diff(new Date(), 'days', true)) : 0,

        "advance_booking_window": action.data.advanceBookingWindow || 0,

        "security_deposit_amnt" : action.data.securityDepositAmount || 0,
        "security_deposit_acc": action.data.securityDepositAccId,
        "facility_charge_acc": action.data.facilityChargeAccId,
        "bank_account": action.data.bankAccId,

        "calendar": action.data.bookingPerFlatCalendar || '',
        "booking_per_flat": action.data.bookingPerFlat,
        "booking_auto_approve": action.data.bookingAutoApprove,
        "unavailable_date": action.data.unavailableDates?.filter(item=> item.date)?.map(item=> item.date),
        "manager": action.data.facilityManagerId,
        "facility_manager_members": action.data.employeeId,
        "committee": action.data.managingCommitteId,
        "managing_committee_member": action.data.managingCommitteMemberId,
        "vendor_cat_id": action.data.vendorCategoryId,
        "vendor_id": action.data.vendorId,
        "vendor_concerned_person": action.data.concernedPersonId,
        "guidance": action.data.guidance,
        "instructions": action.data.description,
        "image": action.data.attachments,
        "ip": action.data.ipAddress,
        "session_id": action.data.sessionId
}

    return axiosInstance.post(apiEndpoints.ADD_FACILITY_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
