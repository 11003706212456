import React, { useEffect } from "react";
import ListHeader from "../../../../../components/ListHeader/ListHeader";

import ButtonG from "../../../../../components/Button/ButtonG";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { INVOICE_AUDIT_REPORT_ENDPOINT } from "../../../../../Routing/routes";
import RangeDatePicker from "../../../../../components/DatePicker/RangeDatePicker";
import { getInvoiceAuditReport } from "../../../../../redux/actions/ReportActions/FinanceReportActions";
import FunctionalSettingsTable from "./FunctionalSettingsTable";

export const headCells = [
  { id: "item", label: "Lable", width: "33.33%" },
  { id: "oldValue", label: "Old Value	", width: "33.33%" },
  { id: "newValue", label: "New Value", width: "33.33%" },
];

const FunctionalSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, invoiceAuditReport } = useSelector(
    (state) => state.financeReportReducer
  );

  const handleClear = () => {
    navigate(INVOICE_AUDIT_REPORT_ENDPOINT);
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${INVOICE_AUDIT_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${INVOICE_AUDIT_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };
  useEffect(() => {
    dispatch(
      getInvoiceAuditReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("memberName") || "",
        invoiceType: searchParams.get("invoiceType") || "",
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        showType: "settings-audit",
      })
    );
  }, [searchParams]);

  return (
    <div className="mt-1">
      <div className="">
        <ListHeader leftContent="Invoice Settings Audit Log"></ListHeader>
      </div>

      <div className="ml-1">
        <FunctionalSettingsTable
          loading={loading}
          data={invoiceAuditReport?.data}
          columns={headCells}
          checkbox={false}
          pagination={true}
        ></FunctionalSettingsTable>
      </div>
      {/* {!loading && !invoiceAuditReport?.length && (
        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
          No Records
        </div>
      )} */}
    </div>
  );
};

export default FunctionalSettings;
