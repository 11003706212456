import React from "react";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Layout from "../../../Layout/Layout";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import TransferFlatTable from "./TransferFlatTable";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { societyStaffTypeOptions } from "../../../../consts/DropDownOptions";
import Search from "../../../../components/SearchBox/Search";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "50px" },
  {
    id: "memberFullName",
    label: "Member Full Name",
    sort: false,
    width: "100px",
  },
  { id: "wingNo", label: "Wing No.", sort: true, width: "90px" },
  { id: "flatNo.", label: "Flat Number", sort: true, width: "100px" },
  { id: "floor", label: "Floor", sort: true, width: "100px" },
  { id: "action", label: "Action", sort: true, width: "90px" },
];

const rows = [
  {
    id: "1",
    sNo: "01",
    memberFullName: "Himanshi Mehta",
    wingNo: "P-Block",
    flatNo: "TPP001",
    title: "NOC for Transfer",
  },
  {
    id: "2",
    sNo: "02",
    memberFullName: "Riya Mehta",
    wingNo: "C-Block",
    flatNo: "TPP002",
    title: "NOC for Passport",
  },
];

const TransferFlat = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="Flat List">
            <Search />
        <Dropdown2
            options={societyStaffTypeOptions}
            placeholder="Meeting Type"
            className=""
            width="182px"
            height="32px"
            onSelect={(selectedItem) => {}}
            //   {...(formik.values.subCategory === "" && {
            //     value: null,
            //   })}
            name={"subCategory"}
          />
          <span className="border-r border-[#CCC]"></span>
          <Button
            label="Flat Transfer Document"
            onClick={handleNavigation("")}
          />
          <Button label="Audit Log" onClick={handleNavigation("")} />
        </ListHeader>
        <div className="ml-1">
          <TransferFlatTable
            data={rows}
            columns={headCells}
            checkbox={false}
            pagination={true}
          ></TransferFlatTable>
        </div>
        {!rows?.length && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
    </Layout>
  );
};

export default TransferFlat;
