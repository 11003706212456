import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Button from "../../../components/Button/Button";
import { Link, createSearchParams } from "react-router-dom";
import RangeDatePicker from "../../../components/DatePicker/RangeDatePicker";
import VehicleDashboardTable from "./VehicleDashboardTable";
import VisitorSettingModal from "../VisitorDashboard/VisitorSettingsModal";
import AddGateModal from "../VisitorDashboard/AddGateModal";
import moment from "moment";
import { useDispatch } from "react-redux";
import { downloadVisitorVehicleLog, getVisitorOrVehicleDashboardData, getVisitorVehicleLog } from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import { useSelector } from "react-redux";
import { addPadZero, validateDate } from "../../../utils/helpers/universalFunctions";
import Search from "../../../components/SearchBox/Search";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import { GateVehicleTypeOptions } from "../../../consts/DropDownOptions";
import ButtonG from "../../../components/Button/ButtonG";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { downloadExcel } from "../../../utils/helpers/downloadMedia";
import { DownloadExt, RefreshIcon } from "../../../assets";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { PRINT_VISITOR_VEHICLE_LOGS_ENDPOINT } from "../../../Routing/routes";
import { CircularProgress, Skeleton } from "@mui/material";

const boxData = [
  {
    row1: {
      label: "2-Wheeler",
      value: "05",
      key: 'wheels_2'
    },
    row2: {
      label: "4-Wheeler",
      value: "05",
      key: 'wheels_4'
    },
    row3: {
      label: "Checked In",
      value: "05",
      key: 'checked_in'
    },
    row4: {
      label: "Checked Out",
      value: "05",
      key: 'checked_out'
    },
  },
];

const boxData1 = [
  {
    row1: {
      label: "2-Wheeler",
      value: "05",
      key: 'wheels_2'
    },
    row2: {
      label: "4-Wheeler",
      value: "05",
      key: 'wheels_4'
    },
    row3: {
      label: "Checked In",
      value: "05",
      key: 'checked_in'
    },
    row4: {
      label: "Checked Out",
      value: "05",
      key: 'checked_out'
    },
  },
];

const headCells = [
  { id: "sNo", label: "Sr. No.", width: "50px" },
  {
    id: "vehicleReg",
    label: "Vehicle Registration",
    sort: true,
    width: "110px",
  },
  { id: "uniqueRfid", label: "Unique RFID No.", sort: true, width: "130px" },
  { id: "type", label: "Type", sort: true, width: "80px" },
  { id: "from", label: "From", sort: true, width: "70px" },
  { id: "purpose", label: "Purpose", sort: true, width: "90px" },
  { id: "owner", label: "Owner", sort: true, width: "70px" },
  { id: "flat", label: "Flat", sort: true, width: "90px" },
  { id: "status", label: "Status", sort: true, width: "90px" },
  { id: "inOut", label: "In/Out", width: "160px" },
];

const renderBox = (boxData, heading, data, loading) => (
  <div className="flex items-center border-[1px] mb-2 border-[#CCC] text-[11px] w-full rounded-lg overflow-hidden bg-[#FDF2F3] h-[120px]">
  {
    loading ?
    <Skeleton animation='wave' variant="rounded" sx={{height: '120px', width: '100%',}} />
    :
    <>
      <div className="p-2 text-center">
        <div className="font-bold pe-1 border-r border-[#CCC]">
          {heading}
        </div>
        <div className="text-[#CF001C] font-bold text-[34px] pe-3 border-r border-[#CCC]">
          {addPadZero(data?.currently_in_premises)}
        </div>
        <div className="text-[#AAAAAA] text-center pe-3 border-r border-[#CCC]">
          Currently in Premises
        </div>
      </div>
      {boxData[0] && (
        <>
          <div className="py-1.5 font-bold text-center">
            <div className="grid grid-cols-2 text-[11px] h-12">
              <div className="grid grid-cols-2 text-[11px] h-12">
                <div className="col-span-2 border-b-[1px] border-[#CCC] mx-4 flex flex-col justify-center">
                  <div className={`${!boxData[0].row2.value ? "text-[#CF001C]" : ''}`}>
                    {boxData[0].row1.label}
                  </div>
                  <div className="text-[#CF001C] text-[20px]">
                    {addPadZero(data?.[boxData[0].row1.key])}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 text-[11px] h-12">
                <div className="col-span-2 border-b-[1px] border-[#CCC] mx-4 flex flex-col justify-center">
                  <div className={`${!boxData[0].row2.value ? "text-[#CF001C]" : ''}`}>
                    {boxData[0].row3.label}
                  </div>
                  <div className="text-[#CF001C] text-[20px]">
                    {addPadZero(data?.[boxData[0].row3.key])}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 text-[11px] h-12">
              <div className="grid grid-cols-2 text-[11px] h-12">
                <div className="col-span-2 mx-4 flex flex-col justify-center whitespace-nowrap mt-2">
                  <div className={`${!boxData[0].row2.value ? "text-[#CF001C]" : ''}`}>
                    {boxData[0].row2.label}
                  </div>
                  <div className="text-[#CF001C] text-[20px]">
                    {addPadZero(data?.[boxData[0].row2.key])}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 text-[11px] h-12">
                <div className="col-span-2 mx-4 flex flex-col justify-center whitespace-nowrap mt-2">
                  <div className={`${!boxData[0].row2.value ? "text-[#CF001C]" : ''}`}>
                    {boxData[0].row4.label}
                  </div>
                  <div className="text-[#CF001C] text-[20px]">
                    {addPadZero(data?.[boxData[0].row4.key])}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  }
</div>
);

function VehicleDashboard() {

  const [settings, setSettings] = useState(false);
  const [addGate, setAddGate] = useState(false);
  const [filter, setFilter] = useState({
    fromDate: moment().subtract(2, "days").format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    vehicleType: '',
    page: 1,
    pageLimit: 10,
    searchText: '',
    refreshedTime: new Date()
  })

  const dispatch =useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const { downLoader } = useSelector(state => state.downloadVisitorVehicleLogExcelReducer)
  const {loadingDashboard, loading, dashboardData, vehicleLog} = useSelector(state=>state.visitorAndVehicleInAndOutReducer)

  const handleSettings = () => {
    setSettings(true);
  };
  const handleChangeSettings = () => {
    setSettings(false);
  };
  const handleAddSetting = () => {
    setAddGate(true);
  };

  const handleStartDate = (selectedDate)=>{
    if(moment(selectedDate).format('YYYY-MM-DD') !== filter.fromDate){
      setFilter(prev=> ({...prev, fromDate: moment(selectedDate).format('YYYY-MM-DD'), page: 1}))
    }
  }

  const handleEndDate = (selectedDate)=>{
    if(moment(selectedDate).format('YYYY-MM-DD') !== filter.toDate){
      setFilter(prev=> ({...prev, toDate: moment(selectedDate).format('YYYY-MM-DD'), page: 1}))
    }
  }

  const handleSearch = (text)=>{
    if(text){
      setFilter(prev=> ({...prev, searchText: text?.trim(), page: 1}))
    }
  }

  const handleRefresh = ()=>{
    if(moment(filter.refreshedTime)?.format('DD-MM-YYYY hh:mm A') !== moment()?.format('DD-MM-YYYY hh:mm A')){
      setFilter(prev=> ({...prev, refreshedTime: new Date()}))
    }
  }

  const handleDownloadExcel = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'Vehicle logs.xlsx'})
    }
    const data = {
      societyId: loginData?.default_community?.community_id, 
      ...filter,
      onSucessCallback
    }
    dispatch(downloadVisitorVehicleLog(data))
  }

  const handleClear = ()=>{
    setFilter({
      fromDate: moment().subtract(2, "days").format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      vehicleType: '',
      page: 1,
      pageLimit: 10,
      searchText: ''
    })
  }

  const handlePrint =()=>{
    let filterData = {...filter}
    delete filterData.page;
    delete filterData.pageLimit;
    window.open(`${PRINT_VISITOR_VEHICLE_LOGS_ENDPOINT}?${createSearchParams([...Object.entries(filterData)])}`)
  }

  const getTableData = ()=>{
    const data={
      societyId: loginData?.default_community?.community_id,
      ...filter,
    }
    dispatch(getVisitorVehicleLog(data))
  }
  
  useEffect(()=>{
    getTableData()
  }, [filter])
  
  useEffect(()=>{
    const data={
      societyId: loginData?.default_community?.community_id,
      ...filter,
      flag: 'vehicles'
    }
    dispatch(getVisitorOrVehicleDashboardData(data))
  }, [filter.fromDate, filter.toDate, filter.refreshedTime])

  return (
    <Layout>
      <div className="w-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Vehicle Dashboard">
            <RangeDatePicker className="w-44 h-8"
              defaultStartValue={new Date(filter.fromDate)}
              defaultEndValue={new Date(filter.toDate)}
              onStartDateChange={handleStartDate}
              onEndDateChange={handleEndDate}
              />
            <div className="border-r border-[#CCC]"></div>
            <div className="flex text-sm gap-3">
              <Button
                label="Settings"
                className="h-8"
                onClick={handleSettings}
              />
              <Button
                label="Add Gate"
                className="h-8"
                onClick={handleAddSetting}
              />
            </div>
          </ListHeader>
        </div>
        

        <div className="p-1 mt-2 w-full">
          <div className="grid grid-cols-3 gap-3">
            {renderBox(boxData, "Resident's Vehicles", dashboardData?.residents_vehicle_data, loadingDashboard)}
            {renderBox(boxData1, "Visitor's Vehicle", dashboardData?.visitor_vehicle_data, loadingDashboard)}
            <div className="grid grid-cols-1 gap-y-0">
              <div
                  className="border-[1px] border-[#CCC] font-bold rounded-lg overflow-hidden bg-[#FDF2F3] h-[53px]"
                >
                  {
                    loadingDashboard ?
                    <Skeleton animation='wave' variant="rounded" sx={{height: '53px', width: '100%',}} />
                    :
                    <div className="px-2.5 flex items-center justify-between h-full">
                      <span className="text-sm">{'Registered Resident Vehicle'}</span>
                      <span className="text-[#CF001C] text-[24px] border-l-[1px] border-[#CCC] pl-3">
                        {addPadZero(dashboardData?.residents_vehicle_data?.resident_total)}
                      </span>
                    </div>
                  }
                </div>
                <div
                  className="border-[1px] border-[#CCC] font-bold rounded-lg overflow-hidden bg-[#FDF2F3] h-[53px]"
                >
                  {
                    loadingDashboard ?
                    <Skeleton animation='wave' variant="rounded" sx={{height: '53px', width: '100%',}} />
                    :
                    <div className="px-2.5 flex items-center justify-between h-full">
                      <span className="text-sm">{'Overstayed Vehicle'}</span>
                      <span className="text-[#CF001C] text-[24px] border-l-[1px] border-[#CCC] pl-3">
                        {addPadZero(dashboardData?.visitor_vehicle_data?.over_stay)}
                      </span>
                    </div>
                  }
                </div>
            </div>
          </div>
        </div>
        <div className="min-h-[500px]">
          <div className="border-t py-2 border-[#CCC] flex  justify-between ">
            <div className="flex">
              <Search height="8" placeholder={'Search by vehicle no.'} onclick={handleSearch} value={filter.searchText} />
              <div className="mx-2">
                <Dropdown2
                  options={GateVehicleTypeOptions}
                  placeholder="Select Vehicle Type"
                  className="text-[11px] ml-4"
                  width="180px"
                  height="32px"
                  onSelect={(selectedItem) => {
                    setFilter(prev=>({...prev, vehicleType: selectedItem.value}))
                  }}
                  value={
                    filter.vehicleType && {
                      value: filter.vehicleType,
                      label: GateVehicleTypeOptions?.find((item) => item.value === filter.vehicleType)?.label
                    }
                  }
                />
              </div>
              <ButtonG label='Clear' className='h-8' onClick={handleClear} />
            </div>
            <div className="flex  items-center">
              <div className="text-xs flex text-[#aaaaaa]">Updated On: {moment(filter.refreshedTime)?.format('DD-MM-YYYY hh:mm A')} <span className={`ms-2 cursor-pointer ${loading ? 'animate-spin': ''}`} onClick={handleRefresh}><RefreshIcon/></span> </div>
              <ButtonIco icon={faPrint} children="Print" onClick={handlePrint} className={'mx-2'} />
              {downLoader ? 
                <ButtonIco className=" w-32 justify-center" children={<CircularProgress sx={{ color: "#d32f2f" }} size={17} />} />
                : 
                <ButtonIco icon={DownloadExt} children='Download Excel' onClick={handleDownloadExcel} />
              }
              </div>
          </div>
            <VehicleDashboardTable
                loading={loading}
                data={vehicleLog?.logs?.map((item, index)=>({
                  sNo: (((vehicleLog?.current_page_no - 1) * (parseInt(vehicleLog?.page_limit) || 10) + 1)+index)?.toString()?.padStart(2, "0"),
                  vehicleReg: item?.vehicleno || '-',
                  uniqueRfid: item?.rfid || '-',
                  type: item?.vehicle_type ? `${item?.vehicle_type?.replace(/(wheeler)|(wheller)|(wheel)/gi, "")} Wheeler` : '-',
                  from: item?.vfrom || '-',
                  purpose: item?.purpose || '-',
                  owner: `${item?.vname} ${item?.lname ? item?.lname : ''}`,
                  flat: item?.flats_details || '-',
                  status: item?.checkstatus || '-',
                  checkIn: validateDate(item?.checkin) ? moment(item?.checkin)?.utc()?.format('DD-MM-YYYY hh:mm:ss') : '',
                checkOut: validateDate(item?.checkout) ? moment(item?.checkout)?.utc()?.format('DD-MM-YYYY hh:mm:ss') : ''
                }))}
                columns={headCells}
                checkbox={false}
                pagination={vehicleLog?.logs?.length}
                totalCount={vehicleLog?.total_record}
                totalPages={vehicleLog?.total_pages}
                start={(vehicleLog?.current_page_no - 1) * (parseInt(vehicleLog?.page_limit) || 10) + 1}
                end={(vehicleLog?.current_page_no - 1) * (parseInt(vehicleLog?.page_limit) || 10) + vehicleLog?.logs?.length}
                currentPage={parseInt(vehicleLog?.current_page_no)}
                rowsPerPage={parseInt(vehicleLog?.page_limit) || 10}
                setFilter={setFilter}
              >
              </VehicleDashboardTable>
          {!vehicleLog?.logs?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>

      </div>
      {
        settings &&
        <VisitorSettingModal
          isOpen={settings}
          onClose={() => {
            setSettings(false);
          }}
          onConfirm={() => {
            handleChangeSettings();
          }}
        />
      }
      {
        addGate &&
        <AddGateModal
          isOpen={addGate}
          onClose={() => {
            setAddGate(false);
          }}
        />
      }
    </Layout>
  );
}

export default VehicleDashboard;
