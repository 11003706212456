import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { validateDate } from "../../../utils/helpers/universalFunctions";
import { getFacilityReport } from "../../../redux/actions/ClubActions/ViewBookingsAction";

export const headCells = [
    { id: "sNo", label: "Sr. No.", width: "50px" },
    { id: "invoiceNo", label: "Invoice No.", sort: true, width: "7%" },
    { id: "status", label: "Status", width: "9%" },
    { id: "facility", label: "Facility", sort: true, width: "10%" },
    { id: "booking_frequency", label: "Frequency", width: "8%" },
    { id: "flatNo", label: "Flat No.", sort: true, width: "8%" },
    { id: "memberName", label: "Member Name", sort: true, width: "10%" },
    { id: "dateOfBooking", label: "Date Of Booking", sort: true, width: "10%" },
    { id: "from_date", label: "from Date", sort: true, width: "8%" },
    { id: "to_date", label: "to Date", sort: true, width: "8%" },
    { id: "bookingAmount", label: "Booking Amount", sort: true, width: "10%" },
    { id: "cgst", label: "CGST", sort: false, width: "8%" },
    { id: "sgst", label: "SGST", sort: false, width: "8%" },
    {
      id: "securityDeposit",
      label: "Security Deposit",
      sort: false,
      width: "70px",
    },
    { id: "totalAmount", label: "Total Amount", sort: false, width: "70px" },
  ];

const PrintFacilityReport = () => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const {loading, facilityReport} = useSelector(state=>state.viewBookingReducer)


  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
    }
    dispatch(getFacilityReport(data));
  }
  
  useEffect(()=>{      
    getTableData()

    const afterprint = (e) => {
      e.preventDefault()
    };
    
    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    }
  }, [])


  useEffect(() => {
    if(!loading && facilityReport){
      window.print()
    }
  }, [loading]);


  return loading ? (
    <Loader />
  ) : (
    <div className="m-1">
      <table className="table-auto w-full mx-auto border-collapse border border-black">
        <thead>
          <tr>
            {
              headCells?.map((item)=>(
                <td className="text-sm p-1 font-semibold border border-black" key={item?.id} width={item?.width}>
                  {item?.label}
                </td>
              ))
            }
          </tr>
        </thead>
        <tbody>
            {
              facilityReport?.map((item, index)=>(
                <React.Fragment  key={index}>
                {
                  !!Object.keys(item)?.length &&
                  <tr className="text-[#222] text-sm">
                    <td className="p-1 border border-black break-all align-top">
                      {(index+1)?.toString()?.padStart(2, 0)}
                    </td>
                    <td className="p-1 border border-black break-all align-top capitalize">
                      {item?.invoice_number || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top capitalize">
                      {item?.status || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {item?.facilities_name || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {item?.booking_frequency || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {item?.wing_flat || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {item?.member_name || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {item?.date_of_booking || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {moment(item?.from_date).format("DD-MM-YYYY") || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {moment(item?.to_date).format("DD-MM-YYYY") || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {item?.booking_amount?.toString() || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {item?.cgst?.toString() || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {item?.sgst?.toString() || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {item?.security_deposit?.toString() || '-'}
                    </td>
                    <td className="p-1 border border-black break-all align-top">
                      {item?.total_amount?.toString() || '-'}
                    </td>
                  </tr>
                }
                </React.Fragment>
              ))
            }
          
        </tbody>
      </table>
      {!facilityReport?.length && !loading && (
          <div className="text-sm my-10 text-center italic">
            No Records
          </div>
        )}
    </div>
  );
};

export default PrintFacilityReport;
