import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  ADD_COMMITTEE_FORM_ENDPOINT,
  ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT,
  COMMITTEE_DETAILS_AUDIT_ENDPOINT,
  MANAGING_COMMITTEE_ENDPOINT,
} from "../../../../Routing/routes";
import {DatePickerComponent} from "../../../../components/DatePicker/DatePicker";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {deleteManagingCommittee, getManagingCommitteeList} from '../../../../redux/actions/SetupRecordsActions/StaffManagementActions/ManangingCommitteeAction'
import moment from "moment";
import ManagingCommitteeTable from "./ManagingCommitteeTable";
import ButtonG from "../../../../components/Button/ButtonG";
import { CircularProgress } from "@mui/material";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import * as permissions from '../../../../Routing/permissions'
import { getPermission } from "../../../../utils/helpers/getPermissions";

const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false , width: "50px"},
  {
    id: "committeeName",
    label: "Committee Name",
    sort: true,
    width: "350px",
  },
  { id: "noOfMembers", label: "No. Of Members", sort: true, width: "180px" },
  { id: "mcFormationDate", label: "MC Formation Date", sort: true , width: "200px"},
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

const bulkActionOptions = [
  { value: "Delete", label: "Delete", permissions: [permissions.DELETE_MANAGING_COMMITTEE_PERMISSION] },
]
const ManagingCommittee = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [action, setAction] = useState('');
  const [selected, setSelected] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loading, updateLoader, managingCommitteeList} = useSelector(state=> state.managingCommitteeReducer)
  const {loginData} = useSelector(state=>state.loginReducer)

  const handleSearch = (text)=>{
    if(text && text !== searchParams.get("search")){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("search")
      navigate({
        pathname: `${MANAGING_COMMITTEE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["search", text?.trim()]])}`,
      })
    }
  }

  const handleDate = (selectedDate)=>{
    if(selectedDate && moment(selectedDate)?.format('YYYY-MM-DD') !== searchParams.get('date')){
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete('date')
      navigate({
        pathname: `${MANAGING_COMMITTEE_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ['date', moment(selectedDate)?.format('YYYY-MM-DD')]])}`,
      })
    }
  }
  const handleClear = ()=>{
    navigate(MANAGING_COMMITTEE_ENDPOINT)
  }

  const handleApply = ()=>{
    if(action === 'Delete'){
      let data = {
        committeeIds: selected, 
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 1,
        searchText: searchParams.get("search") || '',
        formationDate: searchParams.get("date") || '',
        pageLimit: searchParams.get("pageLimit") || 10,
        onSuccessCallback: ()=>{
          setSelected([])
        }
      }
      dispatch(deleteManagingCommittee(data))
    }
  }
  const getTableData =() =>{
    const data={
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || '',
      formationDate: searchParams.get("date") || '',
      pageLimit: searchParams.get("pageLimit") || 10,
    }

    dispatch(getManagingCommitteeList(data))
  }

  useEffect(()=>{      
    getTableData()
  }, [searchParams])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Managing Committees">
            <div className="flex gap-3 text-sm cursor-pointer">
              {
                getPermission(loginData, [permissions.VIEW_ARCHIVE_MANAGING_COMMITTEE_MEMBER_PERMISSION]) &&
                <Link to={ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT}>
                  <Button label="Archive Committee Member" className="h-8" />
                </Link>
              }
              {
                getPermission(loginData, [permissions.VIEW_MANAGING_COMMITTEE_AUDIT_LOGS_PERMISSION]) &&
                <Link to={COMMITTEE_DETAILS_AUDIT_ENDPOINT}>
                  <Button label="Audit Logs" className="h-8" />
                </Link>
              }
              <div className="border-r border-[#CCC]"></div>
              {
                getPermission(loginData, [permissions.ADD_MANAGING_COMMITTEE_PERMISSION]) &&
                <Link to={ADD_COMMITTEE_FORM_ENDPOINT} className="text-white">
                  <Button label="Add committee" className="h-8" />
                </Link>
              }
            </div>
          </ListHeader>
        </div>

        <div className="flex justify-between gap-2 py-1 my-1">
          <div className="flex">
            <Search height="8" onclick={handleSearch} value={searchParams.get("search")} />          
            <div className="flex items-center">
              <span className="ml-3 mr-4 text-[13px]">MC Formation:</span>
              <DatePickerComponent 
                className="w-40 h-8" 
                onDateChange={handleDate} 
                defaultValue={searchParams.get("date") ? new Date(searchParams.get("date")) : ''}
              />
              <ButtonG label='Clear' className='h-8 ml-3' onClick={handleClear} />
            </div>

          </div>
          <div>
          { !!selected?.length && (
                <div className="flex">
                  <div className="me-2">
                    <Dropdown2
                      options={bulkActionOptions.filter(item=> getPermission(loginData, item.permissions))}
                      placeholder="Choose Action"
                      className="text-[11px]"
                      width="160px"
                      height="32px"
                      onSelect={(selectedItem) => {setAction(selectedItem.value)}}
                    />
                  </div>
                  <Button label={
                      <span className="inline-block w-8">
                        {
                          updateLoader ?
                            <CircularProgress sx={{color: "white"}} size={17}/>
                            :
                            'Apply'
                          }
                      </span>
                    } 
                    className="h-8 px-4 font-semibold" type='button' onClick={handleApply} disabled={!action} />
                </div>
              )}
          </div>
        </div>
        <div className="">
          <ManagingCommitteeTable
            loading={loading}
            data={managingCommitteeList?.list?.map((item, index)=>({
              sNo: (((managingCommitteeList?.current_page_no - 1) * managingCommitteeList?.page_limit + 1)+index)?.toString()?.padStart(2, "0"),
              committeeId: item?.committee_id,
              committeeName: item?.title || '',
              noOfMembers: item?.members_count  || '0',
              mcFormationDate: item?.committee_creation_date ? moment(new Date(item?.committee_creation_date)).format('DD-MM-YYYY') : '-',
              status: (item?.status === 'A' ?'Active' : 'Inactive') || '-'
            }))}
            columns={headCells}
            checkbox={true}
            pagination={managingCommitteeList?.list?.length}
            totalCount={managingCommitteeList?.total_record}
            totalPages={managingCommitteeList?.total_pages}
            start={(managingCommitteeList?.current_page_no - 1) * managingCommitteeList?.page_limit + 1}
            end={(managingCommitteeList?.current_page_no - 1) * managingCommitteeList?.page_limit + managingCommitteeList?.list?.length}
            currentPage={managingCommitteeList?.current_page_no}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}

            selected={selected}
            setSelected={setSelected}

          ></ManagingCommitteeTable>
          
          {!managingCommitteeList?.list?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-4 text-center">
              No Records
            </div>
          )}
        </div>

      </div>
    </Layout>
  );
};

export default ManagingCommittee;
